export const getRandomSuggestions = (arr, n) => {
    let result = new Array(n)
    let len = arr.length
    let taken = new Array(len)

    if (n > len) throw new RangeError("getRandomSuggestions: more elements taken than available")

    while (n--) {
        const x = Math.floor(Math.random() * len)
        result[n] = arr[x in taken ? taken[x] : x]
        taken[x] = --len in taken ? taken[len] : len
    }
    return result
}

export const reorderSteps = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export const isActiveRoute = (route) => {
    return location.pathname.includes(route)
}
