import React, { useState } from "react"
import { Box, Typography, MenuItem, Divider, TextField } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import CircularProgress from "@mui/material/CircularProgress"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import { useSelector, useDispatch } from "react-redux"
import { doToolAction } from "../../../../../services/notebook/notebookServices"
import { handleShowToaster } from "../../../../../redux/actions/showToasterActions"
import makeStyles from "@mui/styles/makeStyles"
import { Controller } from "react-hook-form"
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"
import moment from "moment/moment"

const typeOptions = [
    "Payment Sent",
    "Online Session",
    "Account Created",
    "Payment Received",
    "Payout Request",
    "Chargeback",
]

const useStyles = makeStyles(() => ({
    editButton: {
        border: "1px solid #000000",
        borderRadius: "38px",
        textTransform: "none",
        color: "#000000",
        width: "55px",
        height: "32px",
        fontWeight: "400",
        "&:hover": {
            color: "#374957",
        },
    },
    entityBox: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
    input: {
        borderRadius: 10,
    },
    btnWrapper: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        position: "fixed",
        bottom: "0",
        height: "70px",
        width: "268px",
        borderTop: "1px solid #EFEEF1",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        zIndex: 2,
    },
    saveButton: {
        width: "75px",
        borderRadius: "38px",
        textTransform: "none",
        backgroundColor: "#000000",
        color: "#fff",
        fontWeight: "400",
        "&:hover": {
            backgroundColor: "#282727",
        },
    },
    cancelButton: {
        width: "75px",
        borderRadius: "38px",
        textTransform: "none",
        backgroundColor: "#EDEEF8",
        color: "#000000",
        fontWeight: "400",
    },
    dateInput: {
        width: "100%",
        height: "56px",
        borderRadius: "10px",
        padding: "16.5px 14px;",
        border: "1px solid #cecccc",
        cursor: "pointer",
        fontSize: "16px",
    },
    date: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        color: "#6B869A",
        fontSize: "12px",
        fontWeight: "400",
    },
}))

export function NewEvent({
    selectEntityView,
    eventId,
    control,
    values,
    refreshPageData,
    resetAllFields,
    handleDeleteTool,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const [logChanged, setLogChanged] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [reqDate, setReqDate] = useState(new Date())

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (eventId && values.tools[eventId]) {
                await doToolAction(
                    values.case_id,
                    values.tools[eventId]?.id,
                    values.tools[eventId]?.type,
                    values.tools[eventId].log,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                dispatch(handleShowToaster("success", "Successfully created new event"))
                selectEntityView(0)
            }
        } catch (error) {
            console.log(error)
            resetAllFields()
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleCancel = async () => {
        try {
            setIsLoading(true)
            await handleDeleteTool(eventId)
            selectEntityView(0)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const constructField = (name, selectedType) => {
        const displayIf = () => {
            return selectedType === "Payment Sent"
                ? values.tools[eventId].log.Type === "Online Session"
                    ? "none"
                    : "flex"
                : values.tools[eventId].log.Type === "Payment Sent"
                ? "none"
                : "flex"
        }
        return (
            <Box
                sx={{
                    display: displayIf(),
                    flexDirection: "column",
                    gap: "8px",
                }}
            >
                <Box
                    sx={{
                        display: displayIf(),
                    }}
                >
                    <Controller
                        name={`tools.${eventId}.log[${name}]`}
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                disabled={isLoading}
                                onChange={(value) => {
                                    field.onChange(value)
                                    // update in db by triggering log change
                                    setLogChanged(logChanged + 1)
                                }}
                                variant="outlined"
                                label={name}
                                InputProps={{
                                    className: classes.input,
                                }}
                                fullWidth
                            />
                        )}
                    />
                </Box>
            </Box>
        )
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" marginTop="30px">
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box display="flex" flexDirection="column" gap="16px" paddingBottom={"80px"}>
            <Box
                display="flex"
                alignItems="center"
                gap="8px"
                onClick={() => selectEntityView(0)}
                sx={{ cursor: "pointer" }}
            >
                <KeyboardBackspaceIcon />
                <Typography
                    style={{
                        color: "#374957",
                        fontSize: "14px",
                        fontWeight: 500,
                    }}
                >
                    Back
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography
                    style={{
                        color: "#000000",
                        fontSize: "20px",
                    }}
                >
                    New Event
                </Typography>
            </Box>
            <Divider />

            {eventId ? (
                <>
                    <Box display="flex" flexDirection="column">
                        <Typography color="#6B869A" fontSize="12px" marginLeft="5px">
                            Timestamp
                        </Typography>
                        <Controller
                            name={`tools.${eventId}.log.Date`}
                            defaultValue={reqDate}
                            control={control}
                            render={({ field: { onChange } }) => (
                                <Datetime
                                    onChange={(value) => {
                                        onChange(value)
                                    }}
                                    inputProps={{
                                        className: classes.dateInput,
                                        disabled: isLoading,
                                    }}
                                    initialValue={moment.utc(values.tools[eventId]?.log?.Date)}
                                    utc
                                    dateFormat="DD/MM/YYYY"
                                    timeFormat="HH:mm"
                                />
                            )}
                        />
                    </Box>
                    <Controller
                        name={`tools.${eventId}.log.Type`}
                        defaultValue="Company"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                select
                                {...field}
                                disabled={isLoading}
                                onChange={(value) => {
                                    field.onChange(value)
                                    // if id check Related Cases
                                    // if (entity?.log?.id) {
                                    //     handleEntityPastEvents()
                                    // }
                                    setLogChanged(logChanged + 1)
                                }}
                                variant="outlined"
                                label="Type"
                                InputProps={{
                                    className: classes.input,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            >
                                {typeOptions.map((actionTypeOption, index) => {
                                    return (
                                        <MenuItem key={index} value={actionTypeOption}>
                                            {actionTypeOption}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        )}
                    />

                    <Box display="flex" flexDirection="column" gap={"20px"}>
                        {constructField("Payer", "Payment Sent")}
                        {constructField("Payee", "Payment Sent")}
                        {constructField("Amount", "Payment Sent")}
                        {constructField("Payment Method ID", "Payment Sent")}
                        {constructField("Payment Method Type", "Payment Sent")}
                        {constructField("Currency", "currency", "Payment Sent")}
                        {/* fields of Online Session type */}
                        {constructField("Device ID", "Online Session")}
                        {constructField("Pages Visited (list)", "Online Session")}
                        {constructField("IP", "Online Session")}
                        {constructField("ISP", "Online Session")}
                        {constructField("Operation System", "Online Session")}
                        {constructField("Browser", "browser", "Online Session")}
                        {constructField("IP City", "Online Session")}
                        {constructField("IP Country", "Online Session")}
                    </Box>
                </>
            ) : null}
            <Box className={classes.btnWrapper}>
                <LoadingButton
                    loading={isLoading}
                    loadingIndicator={
                        <CircularProgress style={{ width: "15px", height: "15px" }} />
                    }
                    onClick={handleCancel}
                    className={classes.cancelButton}
                    variant="outlined"
                >
                    Cancel
                </LoadingButton>
                <LoadingButton
                    loadingIndicator={
                        <CircularProgress style={{ width: "15px", height: "15px" }} />
                    }
                    loading={isLoading}
                    onClick={handleSave}
                    className={classes.saveButton}
                    variant="outlined"
                >
                    Save
                </LoadingButton>
            </Box>
        </Box>
    )
}
