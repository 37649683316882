import React, { useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Box } from "@mui/material"
import { useParams } from "react-router-dom"
import closeIcon from "../../../../assets/icons/findingsClose.svg"
import { setFindingsState } from "../../../../redux/actions/findingsActions"
import { FindingsItem } from "./components/FindingsItem/FindingsItem"
import styles from "./FindingsList.module.scss"

export function FindingsList({ values, setValue, refreshPageData, toolbox = false }) {
    const dispatch = useDispatch()
    const { stepId } = useParams()
    const markers = useSelector((state) => state.findingsState.markers)
    const filteredMarkers = useMemo(() => {
        return markers?.filter((marker) => +marker?.step?.id === +stepId) || []
    }, [markers, stepId])

    const handleCloseFindingsList = () => {
        dispatch(setFindingsState(false, null))
    }

    return (
        <Box className={toolbox ? styles.findingsListWrapperToolbox : styles.findingsListWrapper}>
            <Box className={styles.findingsListTitle}>
                <Box className={styles.findingsListTitleText}>
                    Findings ({filteredMarkers.length})
                </Box>
                <img
                    onClick={() => handleCloseFindingsList()}
                    className={styles.findingsListTitleImg}
                    src={closeIcon}
                    data-testid="close-button"
                />
            </Box>
            <Box className={styles.findingsListItemsWrapper}>
                {filteredMarkers?.length === 0 ? (
                    <p>Add your findings by marking the labeled fields in the tools' results.</p>
                ) : (
                    filteredMarkers.map((marker, index) => (
                        <FindingsItem
                            {...marker}
                            key={`${marker?.name}${index}`}
                            markers={markers}
                            index={index}
                            values={values}
                            setValue={setValue}
                            refreshPageData={refreshPageData}
                            toolbox={toolbox}
                        />
                    ))
                )}
            </Box>
        </Box>
    )
}
