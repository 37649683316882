import { SET_COMPANIES_STATE } from "../actions/types"

const initialState = { companies: [], userCompanyId: null }

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANIES_STATE:
            return action.data
        default:
            return state
    }
}
