import React, { useState } from "react"
import {
    Box,
    ListItemButton,
    ListItemText,
    Typography,
    Modal,
    Fade,
    CircularProgress,
} from "@mui/material"
import { RemoveDialog } from "../../../../components/RemoveDialog/RemoveDialog"
import { PREVIEW_IMAGES_FORMATS } from "../../../../../../constants/appConstants"
import { extractFileName, extractFileExtension } from "../../../../../../helpers/fileHelpers"
import { PopOver } from "../../../../../../components/PopOver/PopOver"
import attachementIcon from "../../../../../../assets/icons/attachementIcon.svg"
import eyeIcon from "../../../../../../assets/icons/attachmentEyeIcon.svg"
import downloadIcon from "../../../../../../assets/icons/attachementDownloadIcon.svg"
import trashIcon from "../../../../../../assets/icons/attachementTrashIcon.svg"
import styles from "./Attachment.module.scss"

export function Attachment({ attachment, handleDeleteTool, isFileLoading = { isFileLoading } }) {
    const [openModal, setOpenModal] = useState(false)
    const [image, setImage] = useState(null)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleOpenModal = (value) => {
        setImage(value)
        setOpenModal(true)
    }

    const handleOpenDelete = (e) => {
        e.stopPropagation()
        setRemoveDialogOpen(true)
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const handleDowloadFile = async (file) => {
        const response = await fetch(file)
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = file
        document.body.appendChild(a)
        a.click()
        a.remove()
    }

    const handleDeleleFile = async (id) => {
        setIsDeleteLoading(true)
        setRemoveDialogOpen(false)
        try {
            await handleDeleteTool(id)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Box>
            <ListItemButton
                key={attachment.id}
                className={styles.attachmentsElement}
                disabled={isFileLoading}
            >
                <Box className={styles.attachmentsElementDescription}>
                    <img src={attachementIcon} />
                    <Box>
                        <ListItemText
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            style={{ width: "145px", height: "25px" }}
                            primary={
                                attachment?.log?.url
                                    ? extractFileName(attachment?.log?.url, 9)
                                    : "Untitled file"
                            }
                        />
                        <Typography fontSize="12px" color="#6B869A">
                            {attachment?.log?.file_size}
                        </Typography>
                    </Box>
                </Box>
                <Box className={styles.attachmentsElementIcons}>
                    {isDeleteLoading ? (
                        <CircularProgress style={{ width: "15px", height: "15px" }} />
                    ) : (
                        <>
                            {attachment?.log?.url &&
                            PREVIEW_IMAGES_FORMATS.includes(
                                extractFileExtension(attachment?.log?.url).toLowerCase(),
                            ) ? (
                                <img
                                    onClick={() => handleOpenModal(attachment?.log?.url)}
                                    className={styles.attachmentsElementIconsImage}
                                    src={eyeIcon}
                                ></img>
                            ) : null}
                            {attachment?.log?.url && (
                                <>
                                    <img
                                        onClick={() => handleDowloadFile(attachment?.log?.url)}
                                        className={styles.attachmentsElementIconsImage}
                                        src={downloadIcon}
                                    ></img>
                                </>
                            )}
                            <img
                                onClick={(e) => handleOpenDelete(e)}
                                className={styles.attachmentsElementIconsImage}
                                src={trashIcon}
                            ></img>
                        </>
                    )}
                </Box>
                <Modal
                    className={styles.attachmentsModal}
                    open={openModal}
                    onClose={handleCloseModal}
                    closeAfterTransition
                >
                    <Fade in={openModal} timeout={500} style={{ outline: "none" }}>
                        <img
                            src={image}
                            alt="image"
                            style={{ maxHeight: "90%", maxWidth: "90%" }}
                        />
                    </Fade>
                </Modal>
                <RemoveDialog
                    open={removeDialogOpen}
                    setClosed={() => setRemoveDialogOpen(false)}
                    handleAgree={() => handleDeleleFile(attachment?.id)}
                    name="attachement"
                />
                {attachment?.log?.url && extractFileName(attachment?.log?.url).length > 8 && (
                    <PopOver
                        text={extractFileName(attachment?.log?.url)}
                        anchorEl={anchorEl}
                        handlePopoverClose={handlePopoverClose}
                    />
                )}
            </ListItemButton>
        </Box>
    )
}
