import React, { useState } from "react"
import { Menu, styled, alpha, MenuItem, Divider, IconButton } from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"
import { useDispatch, useSelector } from "react-redux"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import ToolsMenu from "./ToolsMenu/ToolsMenu"
import { RemoveDialog } from "../components/RemoveDialog/RemoveDialog"

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: "rgb(55, 65, 81)",
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}))

export function DotsMenu({
    handleAddTools,
    index_x,
    index_y,
    values,
    integrations,
    toolsConfig,
    handleDeleteTool,
    tool_id,
    setIsDeleteLoading,
    setIsLoadingNewTool,
}) {
    const currentMarkersToolId = useSelector((state) => state.findingsState.tool_id)
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null)
    const [removetoolDialogOpen, setRemovetoolDialogOpen] = useState(false)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleOpenDelete = (e) => {
        e.stopPropagation()
        handleClose()
        setRemovetoolDialogOpen(true)
    }

    const deleteTool = async () => {
        setIsDeleteLoading(true)
        try {
            setRemovetoolDialogOpen(false)
            await handleDeleteTool(tool_id, currentMarkersToolId)
        } catch (e) {
            console.log(e)
            setIsDeleteLoading(false)
        }
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon sx={{ color: "#000" }} />
            </IconButton>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem disableRipple>
                    <ToolsMenu
                        handleCloseDotsMenu={handleClose}
                        handleAddTools={handleAddTools}
                        index_x={index_x}
                        index_y={index_y}
                        values={values}
                        integrations={integrations}
                        toolsConfig={toolsConfig}
                        setIsLoadingNewTool={setIsLoadingNewTool}
                    />
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={(e) => handleOpenDelete(e)} disableRipple>
                    <ClearIcon />
                    Delete
                </MenuItem>
            </StyledMenu>
            <RemoveDialog
                open={removetoolDialogOpen}
                setClosed={() => setRemovetoolDialogOpen(false)}
                handleAgree={deleteTool}
                name="tool"
            />
        </div>
    )
}
