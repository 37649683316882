import React from "react"
import { Box } from "@mui/material"
import { RadarChartGraphic } from "./components/RadarChart/RadarChart"
import { BarChartGraphic } from "./components/BarChart/BarChart"
import { InformativeSection } from "./components/InformativeSection/InformativeSection"
import styles from "./Operations.module.scss"

export function Operations() {
    return (
        <Box display="flex" gap="20px" width="100%">
            <Box className={styles.operationsRadar}>
                <RadarChartGraphic />
            </Box>
            <Box className={styles.operationsBar}>
                <BarChartGraphic />
            </Box>
            <Box display="flex" flexDirection="column" gap="30px">
                <InformativeSection
                    title={"Average Queue wait time"}
                    value={"4.5"}
                    inLimit={false}
                />
                <InformativeSection title={"New/Closed Ratio"} value={"0.95"} />
            </Box>
        </Box>
    )
}
