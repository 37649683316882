import { SET_DATA_MODE } from "../actions/types"
import { DATA_MODES } from "../../constants/appConstants"

const initialState = { mode: DATA_MODES[0] }

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_MODE:
            return action.data
        default:
            return state
    }
}
