import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Button, TextField, Divider, CircularProgress } from "@mui/material"
import { useFormik } from "formik"
import { useAuth0 } from "@auth0/auth0-react"
import * as yup from "yup"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContentText from "@mui/material/DialogContentText"
import { sendUserEmailInvitation } from "../../../../services/admin/adminServices"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import styles from "./NewUserDialog.module.scss"
import { LoadingButton } from "@mui/lab"

const validationSchema = yup.object({
    userEmail: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
})

function NewUserDialog({ open, setNewUserModalOpen }) {
    const dispatch = useDispatch()
    const { user } = useAuth0()
    const [isLoading, setIsLoading] = useState(false)

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const users = useSelector((state) => state.userState.users)

    const formik = useFormik({
        initialValues: {
            userEmail: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const emailExists = users.some((user) => user.email === values.userEmail)
            if (emailExists) {
                formik.setFieldError("userEmail", "User is already registered in the system")
                return
            }

            try {
                setIsLoading(true)
                await sendUserEmailInvitation(
                    values.userEmail,
                    user.email,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                dispatch(handleShowToaster("success", "Successfully send invitation to user"))
                handleCloseAndReset()
            } catch (error) {
                console.log(error)
                dispatch(handleShowToaster("error", "Action failed"))
            } finally {
                setIsLoading(false)
            }
        },
    })

    const handleCloseAndReset = () => {
        formik.resetForm({ values: { userEmail: "" } })
        setNewUserModalOpen(false)
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                className: styles.dialogWrapper,
            }}
        >
            <DialogTitle id="alert-dialog-title" fontWeight="400">
                Please enter user email.
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" fontSize="14px">
                    User will receive invite for registration on email.
                </DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <Box className={styles.newUserDialogInputsWrapper}>
                        <TextField
                            name="userEmail"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.userEmail}
                            variant="outlined"
                            label="User email"
                            disabled={isLoading}
                            error={formik.touched.userEmail && Boolean(formik.errors.userEmail)}
                            helperText={formik.touched.userEmail && formik.errors.userEmail}
                            InputProps={{
                                className: styles.newUserDialogInput,
                            }}
                            fullWidth
                        />
                    </Box>
                    <Divider style={{ width: "90%", alignSelf: "center", marginTop: "10px" }} />
                    <DialogActions style={{ height: "60px" }}>
                        <Button
                            disabled={isLoading}
                            onClick={handleCloseAndReset}
                            className={styles.cancelButton}
                            autoFocus
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={isLoading}
                            loadingIndicator={
                                <CircularProgress style={{ width: "15px", height: "15px" }} />
                            }
                            className={styles.removeButton}
                            type="submit"
                        >
                            Send
                        </LoadingButton>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default NewUserDialog
