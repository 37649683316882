import React from "react"
import { Box, MenuItem, Select, InputLabel } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"
import FormControl from "@mui/material/FormControl"
import { DATA_MODES } from "../../../../constants/appConstants"
import { isRedstringsUser } from "../../../../helpers/standardHelpers"

export function DataModeSelect({ currentDataMode, handleSelectDataMode }) {
    const { user } = useAuth0()

    return (
        isRedstringsUser(user) && (
            <Box>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel id="user_comany_label">Data mode</InputLabel>
                    <Select
                        id="data_mode"
                        labelId="data_mode_label"
                        value={currentDataMode?.value || DATA_MODES[0].value}
                        onChange={handleSelectDataMode}
                        label="Data Mode"
                    >
                        {DATA_MODES.map((mode) => (
                            <MenuItem key={mode.value} value={mode.value}>
                                {mode.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        )
    )
}
