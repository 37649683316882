import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_API_ADDRESS,
    timeout: 25000,
    headers: {
        "Content-Type": "application/json",
    },
})

export default instance
