import React, { useMemo } from "react"
import { TextField, Checkbox, FormControlLabel, Autocomplete, MenuItem } from "@mui/material"

export const SelectField = ({ item, field, textFieldProps }) => {
    const renderedOptions = useMemo(
        () =>
            item.options.map((itemOption) => (
                <MenuItem key={itemOption.value} value={itemOption.value}>
                    {itemOption.display}
                </MenuItem>
            )),
        [item.options],
    )

    return (
        <TextField select {...field} {...textFieldProps}>
            {renderedOptions}
        </TextField>
    )
}

export const AutocompleteField = ({ item, field, commonProps, classes }) => {
    const options = useMemo(
        () => item.options.map((itemOption) => itemOption.display),
        [item.options],
    )

    return (
        <Autocomplete
            autoSelect
            options={options}
            onChange={(event, values) => field.onChange(values)}
            value={field.value}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...commonProps}
                    placeholder="All"
                    onChange={field.onChange}
                    className={classes?.selectInput}
                />
            )}
        />
    )
}

export const CheckboxField = ({ item, field }) => (
    <FormControlLabel
        control={
            <Checkbox
                {...field}
                checked={field.value}
                style={{ color: "black" }}
                onChange={(e) => field.onChange(e.target.checked)}
            />
        }
        label={item.field}
    />
)
