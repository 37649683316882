import React, { useState, useEffect } from "react"
import { Box, Typography, Button, MenuItem, Divider, TextField } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import makeStyles from "@mui/styles/makeStyles"
import { Controller } from "react-hook-form"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import { doToolAction } from "../../../../../../services/notebook/notebookServices"
import { useSelector, useDispatch } from "react-redux"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import CircularProgress from "@mui/material/CircularProgress"
import deleteIcon from "../../../../../../assets/icons/Entity/delete.svg"
import calendarIcon from "../../../../../../assets/icons/Events/calendar-clock.svg"
import pencilIcon from "../../../../../../assets/icons/pencil.svg"
import trashIcon from "../../../../../../assets/icons/trash.svg"
import { RemoveDialog } from "../../../RemoveDialog/RemoveDialog"
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"
import moment from "moment/moment"
import styles from "./EventInfo.module.scss"

const typeOptions = [
    "Payment Sent",
    "Online Session",
    "Account Created",
    "Payment Received",
    "Payout Request",
    "Chargeback",
]

export function EventInfo({
    values,
    currentEditingEventId,
    selectEntityView,
    control,
    resetAllFields,
    handleDeleteTool,
    refreshPageData,
}) {
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const [edit, setEdit] = useState(false)
    const [logChanged, setLogChanged] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [removetoolDialogOpen, setRemovetoolDialogOpen] = useState(false)

    const toogleEdit = () => {
        setEdit(!edit)
    }

    const handleCancel = async () => {
        resetAllFields()
        setEdit(false)
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (currentEditingEventId && values.tools[currentEditingEventId]) {
                await doToolAction(
                    values.case_id,
                    values.tools[currentEditingEventId]?.id,
                    values.tools[currentEditingEventId]?.type,
                    values.tools[currentEditingEventId].log,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                setEdit(false)
                dispatch(handleShowToaster("success", "Successfully save entity details"))
            }
        } catch (error) {
            console.log(error)
            setEdit(false)
            resetAllFields()
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            await refreshPageData()
            setIsLoading(false)
        }
    }

    const handeRemove = async () => {
        try {
            setIsLoading(true)
            setRemovetoolDialogOpen(false)
            await handleDeleteTool(currentEditingEventId)
            dispatch(handleShowToaster("success", "Successfully removed event"))
            selectEntityView(0)
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
            setEdit(false)
        } finally {
            setIsLoading(false)
        }
    }
    const handleOpenDelete = (e) => {
        e.stopPropagation()
        setRemovetoolDialogOpen(true)
    }

    useEffect(() => {
        doToolAction(
            values.case_id,
            values.tools[currentEditingEventId]?.id,
            values.tools[currentEditingEventId].type,
            values.tools[currentEditingEventId].log,
            dispatch,
            refreshAccessTokenCounter,
        )
    }, [logChanged])

    const constructField = (name, selectedType) => {
        const displayIf = () => {
            return selectedType === "Payment Sent"
                ? values.tools[currentEditingEventId].log.Type === "Online Session"
                    ? "none"
                    : "flex"
                : values.tools[currentEditingEventId].log.Type === "Payment Sent"
                ? "none"
                : "flex"
        }
        return (
            <Box
                sx={{
                    display: displayIf(),
                    flexDirection: "column",
                    gap: "8px",
                }}
            >
                <Box
                    sx={{
                        display: displayIf(),
                    }}
                >
                    {!edit && (
                        <Typography color="#374957" fontSize="14px">
                            {name}
                        </Typography>
                    )}
                </Box>
                <Box
                    sx={{
                        display: displayIf(),
                    }}
                >
                    <Controller
                        name={`tools.${currentEditingEventId}.log[${name}]`}
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <>
                                {!edit ? (
                                    <Typography fontSize="16px">
                                        {field?.value || "None"}
                                    </Typography>
                                ) : (
                                    <TextField
                                        {...field}
                                        disabled={isLoading}
                                        onChange={(value) => {
                                            field.onChange(value)
                                            // update in db by triggering log change
                                            setLogChanged(logChanged + 1)
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: "#6B869A",
                                            },
                                        }}
                                        variant="outlined"
                                        label={name}
                                        InputProps={{
                                            className: styles.input,
                                        }}
                                        fullWidth
                                    />
                                )}
                            </>
                        )}
                    />
                </Box>
            </Box>
        )
    }

    if (!edit && isLoading)
        return (
            <Box display="flex" justifyContent="center" marginTop="30px">
                <CircularProgress />
            </Box>
        )

    return (
        <Box display="flex" flexDirection="column" gap="16px" paddingBottom={"80px"}>
            <Box
                display="flex"
                alignItems="center"
                gap="8px"
                onClick={() => selectEntityView(0)}
                sx={{
                    cursor: "pointer",
                }}
            >
                <KeyboardBackspaceIcon />
                <Typography
                    style={{
                        color: "#374957",
                        fontSize: "14px",
                        fontWeight: 500,
                    }}
                >
                    Back
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography
                    style={{
                        color: "#000000",
                        fontSize: "20px",
                    }}
                >
                    <Typography fontSize="20px" fontWeight="500">
                        {edit && "Edit"} {values.tools[currentEditingEventId]?.log?.Type}
                    </Typography>
                    <Typography className={styles.date}>
                        <img src={calendarIcon} width="13px" height="13px"></img>
                        {moment
                            .utc(values.tools[currentEditingEventId]?.log?.Date)
                            .format("DD/MM/YYYY HH:mm")}{" "}
                        UTC
                    </Typography>
                </Typography>
                {!edit && (
                    <Box display="flex" alignItems="center" gap="18px">
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={toogleEdit}
                            src={pencilIcon}
                        ></img>
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleOpenDelete(e)}
                            src={trashIcon}
                        ></img>
                    </Box>
                )}
            </Box>
            <Divider />

            {currentEditingEventId ? (
                <>
                    {!edit ? (
                        <Box display="flex" flexDirection="column" gap="8px">
                            <Typography color="#374957" fontSize="14px">
                                Timestamp UTC
                            </Typography>
                            <Typography fontSize="16px">
                                {moment
                                    .utc(values.tools[currentEditingEventId]?.log?.Date)
                                    .format("DD-MM-YYYY HH:mm")}
                            </Typography>
                        </Box>
                    ) : (
                        <Box display="flex" flexDirection="column">
                            <Typography color="#6B869A" fontSize="12px" marginLeft="5px">
                                Timestamp
                            </Typography>
                            <Controller
                                name={`tools.${currentEditingEventId}.log.Date`}
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Datetime
                                        onChange={(value) => {
                                            onChange(value)
                                        }}
                                        inputProps={{
                                            className: styles.dateInput,
                                            disabled: isLoading,
                                        }}
                                        initialValue={moment.utc(
                                            values.tools[currentEditingEventId]?.log?.Date,
                                        )}
                                        utc
                                        dateFormat="DD/MM/YYYY"
                                        timeFormat="HH:mm"
                                    />
                                )}
                            />
                        </Box>
                    )}
                    {!edit ? (
                        <Box display="flex" flexDirection="column" gap="8px">
                            <Typography color="#374957" fontSize="14px">
                                Type
                            </Typography>
                            <Typography fontSize="16px">
                                {values.tools[currentEditingEventId]?.log.Type}
                            </Typography>
                        </Box>
                    ) : (
                        <Controller
                            name={`tools.${currentEditingEventId}.log.Type`}
                            defaultValue="Company"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    select
                                    {...field}
                                    disabled={isLoading}
                                    onChange={(value) => {
                                        field.onChange(value)
                                        setLogChanged(logChanged + 1)
                                    }}
                                    required="true"
                                    variant="outlined"
                                    label="Type"
                                    InputProps={{
                                        className: styles.input,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            color: "#6B869A",
                                        },
                                    }}
                                    fullWidth
                                >
                                    {typeOptions.map((actionTypeOption, index) => {
                                        return (
                                            <MenuItem key={index} value={actionTypeOption}>
                                                {actionTypeOption}
                                            </MenuItem>
                                        )
                                    })}
                                </TextField>
                            )}
                        />
                    )}

                    <Box display="flex" flexDirection="column" gap={"20px"}>
                        {/* fields of Payment Sent type */}
                        {constructField("Payer", "Payment Sent")}
                        {constructField("Payee", "Payment Sent")}
                        {constructField("Amount", "Payment Sent")}
                        {constructField("Payment Method ID", "Payment Sent")}
                        {constructField("Payment Method Type", "Payment Sent")}
                        {constructField("Currency", "currency", "Payment Sent")}
                        {/* fields of Online Session type */}
                        {constructField("Device ID", "Online Session")}
                        {constructField("Pages Visited (list)", "Online Session")}
                        {constructField("IP", "Online Session")}
                        {constructField("ISP", "Online Session")}
                        {constructField("Operation System", "Online Session")}
                        {constructField("Browser", "browser", "Online Session")}
                        {constructField("IP City", "Online Session")}
                        {constructField("IP Country", "Online Session")}
                    </Box>
                </>
            ) : null}
            {edit && (
                <Box className={styles.btnWrapper}>
                    <LoadingButton
                        loading={isLoading}
                        loadingIndicator={
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        }
                        onClick={(e) => handleOpenDelete(e)}
                        className={styles.removeButton}
                        variant="outlined"
                    >
                        <img
                            width="12px"
                            height="12px"
                            style={{ display: isLoading ? "none" : "block" }}
                            src={deleteIcon}
                        ></img>
                        Remove
                    </LoadingButton>
                    <LoadingButton
                        loading={isLoading}
                        loadingIndicator={
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        }
                        onClick={handleCancel}
                        className={styles.cancelButton}
                        variant="outlined"
                    >
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        loading={isLoading}
                        loadingIndicator={
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        }
                        onClick={handleSave}
                        className={styles.saveButton}
                        variant="outlined"
                    >
                        Save
                    </LoadingButton>
                </Box>
            )}
            <RemoveDialog
                open={removetoolDialogOpen}
                setClosed={() => setRemovetoolDialogOpen(false)}
                handleAgree={() => handeRemove()}
                name="event"
            />
        </Box>
    )
}
