import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Provider, useDispatch, useSelector } from "react-redux"
import { PersistGate } from "redux-persist/lib/integration/react"
import { persistor, store } from "../redux/store"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import MuiAlert from "@mui/material/Alert"
import makeStyles from "@mui/styles/makeStyles"
import CssBaseline from "@mui/material/CssBaseline"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import { SnackbarProvider } from "notistack"
import { useAuth0 } from "@auth0/auth0-react"
import {
    login,
    getCompanies,
    menuConfigsByUserCompany,
} from "../services/standart/standartServices"
import { onConversationsAPIReady } from "../helpers/standardHelpers"
import { setIsAuthorized } from "../redux/actions/isAuthorizedActions"
import { changeMenuConfig } from "../redux/actions/menuConfigActions"
import { changeLoaderState } from "../redux/actions/loaderActions"
import LoadingOverlay from "react-loading-overlay"
import { hotjar } from "react-hotjar"
import ReactGA from "react-ga"
import axios from "../utils/axiosConfig"
import { routeTable } from "../routing/RouteTable"
import RedstringsSpinner from "../components/lotties/RedstringsSpinner"
import { Dialog, DialogTitle, DialogContent, Button, Box } from "@mui/material"
import Success from "../components/lotties/Success"
import Snackbar from "@mui/material/Snackbar"
import successIcon from "../assets/icons/successIcon.svg"
import errorIcon from "../assets/icons/errorIcon.svg"
import { ROUTES_TO_SHOW_DIALOG } from "../constants/appConstants"
import { ErrorBoundary } from "../components/ErrorBoundary/ErrorBoundary"
import { theme } from "../constants/theme"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        overflow: "hidden",
    },
    progress: {
        position: "absolute",
        top: "50%",
        right: "50%",
    },
    body: {
        width: "100%",
        marginTop: 64,
    },
    smallBody: {
        width: "100%",
        paddingLeft: 15,
        paddingRight: 15,
    },
    bottom: {
        bottom: "10px",
        marginTop: "5%",
    },
    snackBarSuccess: {
        zIndex: 9999,
        marginTop: "20px",
        "& .MuiPaper-root": {
            backgroundColor: "#4FBB7C",
            borderRadius: "12px",
            alignItems: "center",
        },
    },
    snackBarError: {
        zIndex: 9999,
        marginTop: "20px",
        "& .MuiPaper-root": {
            backgroundColor: "#E02B38",
            borderRadius: "12px",
            alignItems: "center",
        },
    },
}))

const App = () => {
    if (process.env.REACT_APP_ENV == "prod") {
        hotjar.initialize(3128311, 6)
    }

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <ThemedApp />
                    </ThemeProvider>
                </StyledEngineProvider>
            </Provider>
        </ErrorBoundary>
    )
}

const ThemedApp = () => {
    const classes = useStyles()
    const { user, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } =
        useAuth0()
    const dispatch = useDispatch()
    const loaderState = useSelector((state) => state.loaderState)
    const successState = useSelector((state) => state.successState)
    const location = useLocation()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const toaster = useSelector((state) => state.toasterState)

    const [size, setSize] = useState(window.innerWidth)
    const [smallScreen, setSmallScreen] = useState(size < 1200)
    const [showMobileNotification, setShowMobileNotification] = useState(false)

    useEffect(() => {
        function handleResize() {
            const shouldShowDialog = ROUTES_TO_SHOW_DIALOG.some((route) => {
                if (typeof route === "string") {
                    return location.pathname === route
                } else if (route instanceof RegExp) {
                    return route.test(location.pathname)
                }
                return false
            })

            if (shouldShowDialog && window.innerWidth <= 768) {
                setShowMobileNotification(true)
            } else {
                setShowMobileNotification(false)
            }
        }

        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, [location.pathname, ROUTES_TO_SHOW_DIALOG])

    useEffect(() => {
        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth)
    }, [size])

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
    }, [])

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [location])

    // for the first auth
    useEffect(() => {
        ;(async () => {
            if (isLoading) {
                onConversationsAPIReady("unknown visitor")
                return
            }
            if (!isAuthenticated) {
                dispatch(setIsAuthorized(false))
            } else if (getAccessTokenSilently) {
                const accessToken = await getAccessTokenSilently()
                axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
                dispatch(setIsAuthorized(true))
                await login(user, dispatch, refreshAccessTokenCounter)
                ReactGA.ga("set", "&uid", user.email)
                onConversationsAPIReady(user.email)
            }
        })()
    }, [isAuthenticated, isLoading])

    window.pendo.initialize({
        visitor: {
            id: "VISITOR-UNIQUE-ID",
        },

        account: {
            id: "ACCOUNT-UNIQUE-ID",
        },
    })

    // initiate pendo on user auth
    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            window.pendo.initialize({
                visitor: {
                    id: "VISITOR-UNIQUE-ID",
                },

                account: {
                    id: user.email,
                },
            })
        }
    }, [isAuthenticated, isLoading])

    // for every api call made
    useEffect(() => {
        ;(async () => {
            if (isAuthenticated && getAccessTokenSilently) {
                const accessToken = await getAccessTokenSilently()
                axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
            }
        })()
    }, [refreshAccessTokenCounter])

    const updateWidth = () => {
        setSize(window.innerWidth)
        setSmallScreen(window.innerWidth < 1200)
    }

    const bodyContainerClass = () => {
        if (smallScreen) {
            return classes.smallBody
        } else {
            return classes.body
        }
    }
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const menuConfig = useSelector((state) => state.menuConfig.value)

    // set menu config by user company & get companies
    useEffect(() => {
        if (isAuthorized) {
            ;(async () => {
                try {
                    await getCompanies(dispatch, refreshAccessTokenCounter)
                    dispatch(changeLoaderState(true))
                    if (menuConfig?.length === 0) {
                        // get data and put it in configs
                        const ret = await menuConfigsByUserCompany(
                            dispatch,
                            refreshAccessTokenCounter,
                        )
                        dispatch(changeMenuConfig(ret, null))
                    }
                } catch (e) {
                    console.log(e.message)
                } finally {
                    dispatch(changeLoaderState(false))
                }
            })()
        }
    }, [isAuthorized])

    return (
        <LoadingOverlay
            active={successState || loaderState}
            spinner={successState ? <Success /> : <RedstringsSpinner />}
            styles={{
                spinner: (base) => ({
                    ...base,
                }),
                overlay: (base) => ({
                    ...base,
                    background: "white",
                    height: "100%",
                }),
                content: (base) => ({
                    ...base,
                    position: "fixed",
                    left: "0px",
                    top: successState ? "0%" : "45%",
                    width: "100%",
                    height: document.documentElement.clientHeight || document.body.clientHeight,
                    zIndex: 9999,
                    color: "#61BF36",
                }),
            }}
        >
            <PersistGate
                loading={<CircularProgress className={classes.progress} />}
                persistor={persistor}
            >
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Dialog
                        style={{ zIndex: 9999 }}
                        open={showMobileNotification}
                        onClose={() => setShowMobileNotification(false)}
                    >
                        <DialogTitle>This site is optimized for desktop users.</DialogTitle>
                        <DialogContent>
                            <p>Please use a desktop browser for the best experience.</p>
                            <Button onClick={() => setShowMobileNotification(false)}>Close</Button>
                        </DialogContent>
                    </Dialog>
                    <CssBaseline />
                    <Snackbar
                        open={toaster.openToaster}
                        className={
                            toaster.severity === "success"
                                ? classes.snackBarSuccess
                                : classes.snackBarError
                        }
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <Alert
                            icon={
                                <img
                                    width="30px"
                                    height="30px"
                                    src={toaster.severity === "success" ? successIcon : errorIcon}
                                    alt="icon"
                                />
                            }
                            severity={toaster.severity}
                            sx={{ width: "100%" }}
                        >
                            {toaster.title}
                        </Alert>
                    </Snackbar>
                    <div className={classes.root}>
                        <Grid
                            // padding="0 64px"
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box
                                item
                                container
                                justifyContent="center"
                                alignItems="flex-start"
                                xs={11}
                                lg={12}
                                style={{ minHeight: "90vh" }}
                            >
                                <div className={bodyContainerClass()}>
                                    {routeTable(dispatch, loginWithRedirect)}
                                </div>
                            </Box>
                        </Grid>
                    </div>
                </SnackbarProvider>
            </PersistGate>
        </LoadingOverlay>
    )
}

export default App
