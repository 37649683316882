import { SET_USER_STATE, SET_ADMIN_USERS } from "./types"

export const setUserState = (user) => (dispatch) => {
    dispatch({
        type: SET_USER_STATE,
        user,
    })
}

export const setAdminUsersState = (users) => (dispatch) => {
    dispatch({
        type: SET_ADMIN_USERS,
        users,
    })
}
