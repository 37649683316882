import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import { isRedstringsUser } from "../../../helpers/standardHelpers"

export function IconAIComponents({ mock = false }) {
    const { user } = useAuth0()
    if (!isRedstringsUser(user) || !mock) return null
    return (
        <>
            <AutoAwesomeIcon style={{ width: "15px", height: "15px" }} />
        </>
    )
}
