import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { updateTemplateData, getTemplateData } from "../../services/playbook/playbookServices"
import { isEqual, isObject, transform, isUndefined } from "lodash"
import { handleShowToaster } from "../../redux/actions/showToasterActions"

export function useRepoTemplateData(templateId, refreshAccessTokenCounter) {
    const [templateData, setTemplateData] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const fetchTemplateData = async () => {
            if (!templateId) return
            try {
                setLoading(true)
                const data = await getTemplateData(templateId, dispatch, refreshAccessTokenCounter)
                setTemplateData(data)
            } catch (error) {
                console.error(error)
                navigate("/404")
            } finally {
                setLoading(false)
            }
        }

        fetchTemplateData()
    }, [templateId, dispatch, refreshAccessTokenCounter, navigate])

    const handleUpdateTemplateData = async (
        template,
        templateId,
        refreshAccessTokenCounter,
        successCallback,
    ) => {
        try {
            setLoading(true)
            const data = await updateTemplateData(
                { template, templateId },
                dispatch,
                refreshAccessTokenCounter,
            )
            setTemplateData(data)
            dispatch(handleShowToaster("success", "Your changes have been published"))
            successCallback && successCallback()
        } catch (error) {
            console.error(error)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setLoading(false)
        }
    }

    return [templateData, setTemplateData, loading, handleUpdateTemplateData]
}
