import axios from "../../utils/axiosConfig"
import { setRefreshAccessTokenCounter } from "../../redux/actions/refreshAccessTokenCounterActions"
import { setUserState, setAdminUsersState } from "../../redux/actions/userActions"
import { setCompanyState } from "../../redux/actions/companyActions"
import ENUMS from "../../constants/appEnums"

export const login = async (user, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.REGISTER_USER, {
            user_email: user?.email,
            displayName: user?.name,
            photoURL: user?.picture,
            auth0_id: user?.sub,
        })
        if (data && data?.admin) getUsers(dispatch, refreshAccessTokenCounter)
        return dispatch(setUserState(data))
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getUsers = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.GET_USERS)
        dispatch(setAdminUsersState(data))
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getCompanies = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.GET_COMPANIES)
        return dispatch(setCompanyState(data))
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getFeedback = async (object_id, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_FEEDBACK_ACTION, object_id)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getToolsConfig = async (tools, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_TOOLS_CONFIG, {
            tools,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const menuConfigsByUserCompany = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.SAVED_MENU_CONFIGS_BY_USER_COMPANY)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getCaseTypesWithFields = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.GET_CASE_TYPES_WITH_FIELDS)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getCaseTypes = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.GET_CASE_TYPES)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}
