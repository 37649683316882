import { useAuth0 } from "@auth0/auth0-react"
import { Fragment } from "react"
import { useDispatch } from "react-redux"
import { changeLoaderMessage, changeLoaderState } from "../../redux/actions/loaderActions"

const Unauthorized = () => {
    const dispatch = useDispatch()
    const { loginWithRedirect, loginWithPopup } = useAuth0()

    dispatch(changeLoaderMessage("Redirecting to login page..."))
    dispatch(changeLoaderState(true))

    loginWithRedirect({
        appState: {
            returnTo:
                process.env?.REACT_APP_DOMAIN_ADDRESS === "https://fraudfightersalliance.com"
                    ? "/home"
                    : "/",
        },
    })

    dispatch(changeLoaderState(false))

    return <Fragment />
}

export default Unauthorized
