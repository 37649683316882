import { useAuth0 } from "@auth0/auth0-react"
import { Button, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { changeLoaderMessage, changeLoaderState } from "../../redux/actions/loaderActions"
import EmptyBox from "../../pages/MainPage/components/EmptyBox"

const Redirect = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { logout, error } = useAuth0()
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const [showVerify, setShowVerify] = useState(false)

    useEffect(() => {
        dispatch(changeLoaderMessage("Please wait..."))
        dispatch(changeLoaderState(true))

        if (error) {
            if (error?.message === "Please verify your email before logging in.") {
                setShowVerify(true)
                dispatch(changeLoaderState(false))
            } else {
                logout({
                    returnTo: window.location.origin + "/unauthorized",
                })
            }
        }
    }, [dispatch, error, logout])

    return (
        showVerify && (
            <Grid
                container
                sx={{
                    maxWidth: "1178px",
                    margin: "auto",
                    marginBottom: "-1rem",
                }}
                alignItems="center"
                justifyContent="center"
            >
                <EmptyBox
                    mainText={"Welcome to Redstrings & Fraud Fighters Alliance!"}
                    subText={
                        "We just sent a confirmation email, please check your inbox(and spam) and click on the link to confirm your account."
                    }
                />
            </Grid>
        )
    )
}

export default Redirect
