import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Button } from "@mui/material"
import { useFeedback } from "../../../../../../../../hooks/repo/useFeedback"
import { isRedstringsUser } from "../../../../../../../../helpers/standardHelpers"
import { postUserFeedbackAction } from "../../../../../../../../services/playbook/playbookServices"
import { USER_REPO_FEEDBACK } from "../../../../../../../../constants/appConstants"
import likeIcon from "../../../../../../../../assets/icons/Repo/likeIcon.svg"
import likeIconActive from "../../../../../../../../assets/icons/Repo/likeIconActive.svg"
import userIcon from "../../../../../../../../assets/icons/Repo/userIcon.svg"
import redstringsUserIcon from "../../../../../../../../assets/icons/Repo/redstringsUserIcon.svg"
import styles from "./RepoPlaybookComponent.module.scss"
import { Link } from "react-router-dom"

export function RepoPlaybookComponent({
    name,
    userEmail,
    userName,
    caseType,
    templateId,
    handleOpenRepoPlaybook,
    handleOpenRepoAuthModal,
}) {
    const dispatch = useDispatch()

    const {
        likes: defaultLikes,
        dislikes: defaultDislikes,
        loadingFeedback,
    } = useFeedback(templateId)

    const [isLiked, setIsLiked] = useState(false)
    const [isDisliked, setIsDisliked] = useState(false)

    const [isProcessingFeedback, setIsProcessingFeedback] = useState(false)

    const [likes, setLikes] = useState(defaultLikes || [])
    const [dislikes, setDislikes] = useState(defaultDislikes || [])

    const userId = useSelector((state) => state.userState.user.id)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const handlePostUserFeedbackAction = async (data) => {
        try {
            if (!userId) {
                console.error("No user identifier (user or session_id) available.")
                return
            }

            const feedbacks = await postUserFeedbackAction(
                { ...data, object_id: templateId, user_id: userId },
                dispatch,
                refreshAccessTokenCounter,
            )
            setLikes(feedbacks.filter((item) => item.decision === 1) || [])
            setDislikes(feedbacks.filter((item) => item.decision === 2) || [])
        } catch (error) {
            console.log(error)
        }
    }

    const handleLike = async () => {
        if (!userId) {
            handleOpenRepoAuthModal()
            return
        }

        if (isProcessingFeedback) return
        setIsProcessingFeedback(true)

        if (isLiked) {
            setIsLiked(false)
            setLikes((prevLikes) => prevLikes.filter((like) => +like?.user_id !== userId))
        } else {
            setIsLiked(true)
            setIsDisliked(false)
            setLikes((prevLikes) => [
                ...prevLikes,
                { user_id: userId, decision: 1, object_type: 1 },
            ])
            setDislikes((prevDislikes) =>
                prevDislikes.filter((dislike) => +dislike?.user_id !== userId),
            )
        }

        try {
            await handlePostUserFeedbackAction(USER_REPO_FEEDBACK[1])
        } catch (error) {
            setIsLiked((prev) => !prev)
            setLikes((prevLikes) => prevLikes.filter((like) => +like?.user_id !== userId))
        }

        setIsProcessingFeedback(false)
    }

    useEffect(() => {
        if (!userId) return
        const userHasLiked = likes.some((like) => like.user_id == userId)
        const userHasDisliked = dislikes.some((dislike) => dislike.user_id == userId)

        setIsLiked(userHasLiked)
        setIsDisliked(userHasDisliked)
    }, [likes, defaultLikes, defaultDislikes, userId])

    useEffect(() => {
        setLikes(defaultLikes)
        setDislikes(defaultDislikes)
    }, [defaultLikes, defaultDislikes])

    return (
        <Box className={styles.repoPlaybookComponentWrapper}>
            <Box className={styles.repoPlaybookComponentName}>
                <Link to={`/playbook/${templateId}`}>{name}</Link>
            </Box>
            <Box className={styles.repoPlaybookComponentUser}>
                <img
                    src={!isRedstringsUser(userEmail) ? userIcon : redstringsUserIcon}
                    alt="userIcon"
                />
                {userName}
            </Box>
            <Box className={styles.repoPlaybookComponentFooter}>
                <Box className={styles.repoPlaybookComponentCaseType}>{caseType}</Box>
                <Button
                    onClick={handleLike}
                    className={
                        isLiked
                            ? styles.repoPlaybookComponentLikeActive
                            : styles.repoPlaybookComponentLike
                    }
                >
                    <img src={isLiked ? likeIconActive : likeIcon} />
                    {likes?.length > 0 ? likes?.length : null}
                </Button>
            </Box>
        </Box>
    )
}
