import React, { useEffect, useState } from "react"
import { Modal, Box, TextField, IconButton, MenuItem, Typography, Select } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import CloseIcon from "@mui/icons-material/Close"
import { transformGeneratedCase } from "../../services/playbook/playbookServices"
import { handleShowToaster } from "../../redux/actions/showToasterActions"
import { LoadingButton } from "@mui/lab"
import styles from "./PlaybookModal.module.scss"

export function PlaybookModal({ open, onClose, values, caseTypes = [] }) {
    const [inputValue, setInputValue] = useState("")
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedValue, setSelectedValue] = useState(1)

    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)
    const userId = useSelector((state) => state.userState.user.id)

    const isValidInput = (value) => {
        const regex = /^[a-zA-Z\s]{5,}$/

        if (!regex.test(value) || value.trim().length === 0) {
            if (value.trim().length < 5) {
                setError("Minimum length is 5 letters")
            } else {
                setError("Please use letters only, without numbers or other symbols")
            }
            return false
        } else {
            setError(null)
            return true
        }
    }

    const handleInputChange = (e) => {
        const newValue = e.target.value
        isValidInput(newValue)
        setInputValue(newValue)
    }

    const handleSubmit = async () => {
        if (error) return
        if (values?.case_id && inputValue && currentCompanyId && userId) {
            setIsLoading(true)

            const sanitizedInputValue = inputValue.replace(/\s+/g, " ").trim()

            const data = {
                case_id: values?.case_id,
                template_name: sanitizedInputValue,
                company_id: currentCompanyId,
                user_id: userId,
                case_type: selectedValue,
            }
            try {
                await transformGeneratedCase(data, dispatch, refreshAccessTokenCounter)
                dispatch(handleShowToaster("success", "Successfully saved a playbook"))
                onClose()
                setInputValue("")
            } catch (error) {
                console.log(error)
                dispatch(handleShowToaster("error", "Action failed"))
            } finally {
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        if (values?.type_name && caseTypes.length > 0) {
            setSelectedValue(caseTypes.find((ct) => ct.name === values?.type_name)?.id)
        }
    }, [values, caseTypes])

    return (
        <Modal open={open} onClose={onClose}>
            <Box className={styles.playbookModalWrapper}>
                <IconButton className={styles.playbookModalCloseButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <form onSubmit={handleSubmit}>
                    <Box className={styles.playbookModalContentContainer}>
                        <TextField
                            disabled={isLoading}
                            fullWidth
                            label="Playbook Name"
                            variant="outlined"
                            value={inputValue}
                            onChange={handleInputChange}
                            error={Boolean(error)}
                            helperText={error}
                            InputProps={{
                                className: styles.playbookModalInput,
                            }}
                        />
                        {caseTypes.length > 0 && (
                            <TextField
                                select
                                disabled={isLoading}
                                variant="outlined"
                                label="Type"
                                InputProps={{
                                    className: styles.playbookModalInput,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        color: "#6B869A",
                                    },
                                }}
                                fullWidth
                                value={selectedValue}
                                onChange={(e) => setSelectedValue(e.target.value)}
                            >
                                {caseTypes.map((caseType) => {
                                    return (
                                        <MenuItem key={caseType?.id} value={caseType?.id}>
                                            {caseType.name}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        )}

                        <LoadingButton
                            loading={isLoading}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={styles.playbookModalSaveButton}
                            disabled={
                                Boolean(error) ||
                                inputValue.length < 5 ||
                                !selectedValue ||
                                caseTypes.length === 0
                            }
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </form>
            </Box>
        </Modal>
    )
}
