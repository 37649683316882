import { Route, Routes } from "react-router-dom"
import PrivacyComponent from "../components/legal/PrivacyComponent"
import TermsComponent from "../components/legal/TermsComponent"
import RulesOfConduct from "../components/legal/RulesOfConduct"
import ConfigPage from "../pages/ConfigPage/ConfigPage"
import MainPageContainer from "../pages/MainPage/MainPageContainer"
import NotebookContainer from "../pages/Notebook/NotebookContainer"
import Integrations from "../pages/Integrations/Integrations"
import { AdminPanel } from "../pages/AdminPanel/AdminPanel"
import { NotFoundPage } from "../pages/NotFoundPage/NotFoundPage"
import { useEffect } from "react"
import { Callback, Redirect, Unauthorized } from "../components/auth0"
import ENUMS from "../constants/appEnums"
import MainPage from "../pages/MainPage/MainPage"
import { CaseOverview } from "../pages/Notebook/components/CaseOverview/CaseOverview"
import { Step } from "../pages/Notebook/components/Step/Step"
import { Playbook } from "../pages/Playbook/pages/Playbook/Playbook"
import { RepoElement } from "../pages/Playbook/pages/Repo/components/RepoElement/RepoElement"
import { Dashboard } from "../pages/Dashboard/Dashboard"
import Repo from "../pages/Playbook/pages/Repo/Repo"
import MyPlaybooks from "../pages/Playbook/pages/Repo/components/MyPlaybooks/MyPlaybooks"
import { Toolbox } from "../pages/Toolbox/Toolbox"
import { ToolboxRedirect } from "../pages/Toolbox/ToolboxRedirect"

const CloseTab = () => {
    const onClose = () => {
        window.opener = null
        window.open("", "_self")
        window.close()
    }
    useEffect(() => {
        onClose()
    }, [])
    return <div></div>
}
export const routeTable = () => {
    return (
        <Routes>
            <Route path="/" element={<MainPageContainer />}>
                <Route path="/" element={<MainPage />} />
                <Route path={ENUMS.ROUTES.DASHBOARD} element={<Dashboard />} />
                <Route path={ENUMS.ROUTES.ALL_INTEGRATIONS} element={<Integrations />} />
                <Route path={ENUMS.ROUTES.INTERNAL_CONFIGS} element={<ConfigPage />} />
                <Route path={ENUMS.ROUTES.ADMIN_PANEL} element={<AdminPanel />} />
                <Route exact path={ENUMS.ROUTES.MAIN_TOOLBOX} element={<ToolboxRedirect />} />
            </Route>
            <Route exact path={ENUMS.ROUTES.TERMS} element={<TermsComponent />} />
            <Route exact path={ENUMS.ROUTES.PRIVACY} element={<PrivacyComponent />} />
            <Route exact path={ENUMS.ROUTES.ROC} element={<RulesOfConduct />} />
            <Route exact path={ENUMS.ROUTES.REDIRECT} element={<Redirect />} />
            <Route exact path={ENUMS.ROUTES.UNAUTHORIZED} element={<Unauthorized />} />
            <Route exact path={ENUMS.ROUTES.OAUTH_CALLBACK} element={<Callback />} />
            <Route exact path="/404" element={<NotFoundPage />} />
            <Route path={ENUMS.ROUTES.CASE_ID} element={<NotebookContainer />}>
                <Route index element={<CaseOverview />} />
                <Route path={ENUMS.ROUTES.STEP_ID} element={<Step />} />
                <Route path={ENUMS.ROUTES.TOOLBOX} element={<Toolbox />} />
            </Route>
            <Route path={ENUMS.ROUTES.EASY_INTEGRATIONS} element={<CloseTab />} />
            <Route path={ENUMS.ROUTES.PLAYBOOK_ID} element={<Repo />} />
            <Route path={ENUMS.ROUTES.PLAYBOOK} element={<Repo />} />
            <Route path={ENUMS.ROUTES.MY_PLAYBOOKS} element={<MyPlaybooks />} />
            <Route path={ENUMS.ROUTES.MY_PLAYBOOKS_ID} element={<MyPlaybooks />} />
            {/* <Route path={ENUMS.ROUTES.PLAYBOOK_REPO} element={<Repo />} />
            <Route path={ENUMS.ROUTES.PLAYBOOK_REPO_ID} element={<Repo />} /> */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}
