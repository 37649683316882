import React, { useEffect } from "react"
import { Box, CircularProgress } from "@mui/material"
import { createCase } from "../../services/main/mainServices"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { addTools } from "../../services/notebook/notebookServices"
import styles from "./Toolbox.module.scss"
import { changeLoaderState } from "../../redux/actions/loaderActions"
import { changeRedirectState } from "../../redux/actions/redirectActions"

export function ToolboxRedirect() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const redirectState = useSelector((state) => state.redirectState)

    // Start loader if redirectState is false
    useEffect(() => {
        if (!redirectState) {
            dispatch(changeLoaderState(true))
        }
    }, [redirectState, dispatch])

    const handleCreateCaseAndStep = async () => {
        if (isAuthorized && !redirectState) {
            try {
                dispatch(changeRedirectState(true))
                const caseId = await createCase(true, dispatch, refreshAccessTokenCounter)
                if (caseId) {
                    const response = await addTools(
                        caseId,
                        [46],
                        0,
                        0,
                        null,
                        dispatch,
                        refreshAccessTokenCounter,
                    )
                    const foundTool = Object?.values(response)?.find((tool) => tool?.type === 46)

                    if (foundTool) {
                        navigate(`/${caseId}/step/${foundTool?.id}/toolbox`)
                    } else {
                        navigate("/")
                    }
                }
            } catch (err) {
                navigate("/")
                console.error(err)
            }
        } else {
            dispatch(changeRedirectState(false))
        }
    }

    // Invoke handleCreateCaseAndStep after initial render and whenever dependencies change
    useEffect(() => {
        handleCreateCaseAndStep()
    }, [isAuthorized]) // Dependencies

    return (
        <Box className={styles.toolboxContainer}>
            <Box className={styles.toolboxMainWrapper}>
                <Box width="100%" display="flex" justifyContent="center" mt={20}>
                    <CircularProgress />
                </Box>
            </Box>
        </Box>
    )
}
