import axios from "../../utils/axiosConfig"
import { setRefreshAccessTokenCounter } from "../../redux/actions/refreshAccessTokenCounterActions"
import ENUMS from "../../constants/appEnums"

export const updateUserDeletedStatus = async (
    user_id,
    deleted,
    auth0_user_id,
    dispatch,
    refreshAccessTokenCounter,
) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.UPDATE_USER_DELETED_STATUS, {
            user_id,
            deleted,
            auth0_user_id,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const sendUserEmailInvitation = async (
    email,
    inviter_email,
    dispatch,
    refreshAccessTokenCounter,
) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.SEND_USER_INVITATION_MAIL, {
            email,
            inviter_email,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getRunners = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.GET_RUNNERS)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const createNewRunner = async (name, description, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.CREATE_RUNNER, { name, description })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const deleteRunner = async (id, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.delete(`${ENUMS.API_ROUTES.RUNNER}/${id}`)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const updateRunner = async (id, name, description, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.put(`${ENUMS.API_ROUTES.RUNNER}/${id}`, { name, description })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}
