import React from "react"
import { Box } from "@mui/material"
import { RISK_LEVELS } from "../../../../../../constants/appConstants"
import { setFindingsState } from "../../../../../../redux/actions/findingsActions"
import { useDispatch } from "react-redux"
import styles from "./ApiRiskLevel.module.scss"

export function ApiRiskLevel({ riskLevel, quantity }) {
    const dispatch = useDispatch()

    if (!quantity) return null

    const handleOpenFindingsList = () => {
        dispatch(setFindingsState(true, null))
    }

    return (
        <Box onClick={() => handleOpenFindingsList()} className={styles.apiRiskLevelWrapper}>
            <img src={RISK_LEVELS.find((level) => level.value === riskLevel)?.img}></img>
            <Box className={styles.apiRiskLevelQuantity}>{quantity}</Box>
        </Box>
    )
}
