import { useState, useEffect } from "react"

export function useIsMobile(breakpoint = 768) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= breakpoint)
        }

        window.addEventListener("resize", handleResize)
        handleResize()

        return () => window.removeEventListener("resize", handleResize)
    }, [breakpoint])

    return isMobile
}
