import { Box, Grid, Typography } from "@mui/material"
import React from "react"

const EmptyBox = ({ mainText, subText }) => {
    return (
        <Grid
            container
            sx={{
                maxWidth: "1178px",
                margin: "auto",
                marginTop: "65px",
            }}
            justifyContent="space-between"
        >
            <Grid
                container
                item
                sx={{
                    boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.25)",
                    height: "247px",
                    borderRadius: "5px",
                }}
            >
                <Grid item color="rgba(0, 0, 0, 0.4)" textAlign="center" margin="auto">
                    {mainText && (
                        <Typography fontSize={{ sm: "40px", xs: "20px" }} marginBottom="40px">
                            {mainText}
                        </Typography>
                    )}
                    {subText && (
                        <Typography fontSize={{ sm: "32px", xs: "18px" }}>{subText}</Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EmptyBox
