import React, { useState, useEffect, useRef } from "react"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { RepoHeader } from "./components/RepoHeader/RepoHeader"
import { useAuth0 } from "@auth0/auth0-react"
import { RepoFooter } from "./components/RepoFooter/RepoFooter"
import { RepoMainGenerator } from "./components/RepoMainGenerator/RepoMainGenerator"
import { RepoElement } from "./components/RepoElement/RepoElement"
import { RepoMainPlaybooksList } from "./components/RepoMainPlaybooksList/RepoMainPlaybooksList"
import { useNavigate, useParams } from "react-router-dom"
import { createTemplate } from "../../../../services/playbook/playbookServices"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import { LoadingDialog } from "../Playbook/components/LoadingDialog/LoadingDialog"
import { RepoLoginModal } from "./components/RepoLoginModal/RepoLoginModal"
import { RepoMainUserPlaybooksList } from "./components/RepoMainUserPlaybooksList/RepoMainUserPlaybooksList"
import { useUserPlaybooks } from "../../../../hooks/repo/useUserPlaybooks"
import { useIsMobile } from "../../../../hooks/standart/useIsMobile"
import { RepoMainBlog } from "./components/RepoMainBlog/RepoMainBlog"
import { RepoMainOwnPlaybook } from "./components/RepoMainOwnPlaybook/RepoMainOwnPlaybook"
import styles from "./Repo.module.scss"

function Repo() {
    const { user } = useAuth0()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { templateId } = useParams()
    const {
        playbooks,
        loadingPlaybooks,
        fetchUserPlaybooks,
        setPlaybooks,
        recentlyGeneratedPlaybooks,
    } = useUserPlaybooks()

    const [isLoading, setIsLoading] = useState(false)
    const [isRepoOpen, setRepoOpen] = useState(false)
    const [openRepoAuthModal, setOpenRepoAuthModal] = useState(false)
    const [inputValue, setInputValue] = useState("")

    const generateInputRef = useRef(null)

    const userId = useSelector((state) => state.userState.user.id)
    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const handleOpenRepoPlaybook = (templateId) => {
        if (!user) {
            handleOpenRepoAuthModal()
            return
        }
        setRepoOpen(true)
        navigate(templateId, { replace: true })
    }

    const handleCreateTemplate = async (e, text) => {
        if (!user) {
            handleOpenRepoAuthModal()
            return
        }
        setIsLoading(true)
        let resultData = null
        try {
            const result = await createTemplate(
                { user_id: userId, company_id: currentCompanyId, text } || null,
                dispatch,
                refreshAccessTokenCounter,
            )
            if (result.status && result.status === "error") {
                dispatch(handleShowToaster("error", result.message))
            } else if (result) {
                resultData = result
                setRepoOpen(true)
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setTimeout(() => {
                    dispatch(handleShowToaster("error", error.response.data.error))
                }, 2000)
            } else {
                setTimeout(() => {
                    dispatch(handleShowToaster("error", error.response.data.error))
                }, 2000)
                dispatch(handleShowToaster("error", "Action failed"))
                console.log(error)
            }
        } finally {
            setTimeout(() => {
                setIsLoading(false)
            }, 2000)
            if (resultData) {
                navigate(`/playbook/${resultData}`, { replace: true })
            }
        }
    }

    const handleOpenRepoAuthModal = () => {
        setOpenRepoAuthModal(true)
    }

    const handleCloseRepoAuthModal = () => {
        setOpenRepoAuthModal(false)
    }

    useEffect(() => {
        if (templateId) {
            setRepoOpen(true)
        }
    }, [templateId])

    return (
        <>
            {isLoading ? (
                <LoadingDialog isLoading={isLoading} />
            ) : (
                <Box className={styles.repoContainer}>
                    <RepoHeader />
                    <Box className={styles.repoContainerMain}>
                        <RepoMainOwnPlaybook
                            fetchUserPlaybooks={fetchUserPlaybooks}
                            generateInputRef={generateInputRef}
                            handleOpenRepoAuthModal={handleOpenRepoAuthModal}
                        />
                        <RepoMainPlaybooksList
                            handleOpenRepoPlaybook={handleOpenRepoPlaybook}
                            handleOpenRepoAuthModal={handleOpenRepoAuthModal}
                        />
                    </Box>
                    <Box className={styles.repoContainerMainContent}>
                        <RepoMainGenerator
                            handleCreateTemplate={handleCreateTemplate}
                            isLoading={isLoading}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            generateInputRef={generateInputRef}
                        />
                        <RepoMainBlog
                            fetchUserPlaybooks={fetchUserPlaybooks}
                            generateInputRef={generateInputRef}
                        />
                    </Box>
                    <RepoFooter handleOpenRepoAuthModal={handleOpenRepoAuthModal} user={user} />
                    <RepoElement
                        playbook={true}
                        isOpen={isRepoOpen}
                        setOpen={setRepoOpen}
                        fetchUserPlaybooks={fetchUserPlaybooks}
                    />
                    <RepoLoginModal open={openRepoAuthModal} onClose={handleCloseRepoAuthModal} />
                </Box>
            )}
        </>
    )
}

export default Repo
