import { SET_FINDINGS_STATE, SET_HOVER_ID, SET_FINDINGS_CLICK_ID } from "./types"

export const setFindingsState = (isOpen, markers) => (dispatch) => {
    dispatch({
        type: SET_FINDINGS_STATE,
        isOpen,
        markers,
    })
}

export const setFindingHoverId = (id) => (dispatch) => {
    dispatch({
        type: SET_HOVER_ID,
        id,
    })
}

export const setFindingClickId = (id) => (dispatch) => {
    dispatch({
        type: SET_FINDINGS_CLICK_ID,
        id,
    })
}
