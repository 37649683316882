import { SET_FINDINGS_STATE, SET_HOVER_ID, SET_FINDINGS_CLICK_ID } from "../actions/types"

const initialState = { isOpen: false, markers: [], tool_id: null, id: null, clickedId: null }

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FINDINGS_STATE:
            return {
                ...state,
                isOpen: action.isOpen != null ? action.isOpen : state.isOpen,
                markers: action.markers != null ? action.markers : state.markers,
            }
        case SET_HOVER_ID:
            return {
                ...state,
                id: action.id,
            }
        case SET_FINDINGS_CLICK_ID:
            return {
                ...state,
                clickedId: action.id,
            }
        default:
            return state
    }
}
