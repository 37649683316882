import * as yup from "yup"

export const repoElementValidationSchema = yup
    .object()
    .shape({
        name: yup
            .string()
            .required("Name can't be empty")
            .max(50, "Name should be limited to 50 chars including spaces"),
        displayName: yup.string().required("Display name is required"),
        thumbnail: yup.string().url("Must be a valid URL").notRequired(),
        linkedin_profile: yup.string().url("Must be a valid URL").notRequired(),
        tools: yup.object().shape({
            ordered: yup.array().of(
                yup.object().shape({
                    log: yup.object().shape({
                        note: yup.string().required("Name can't be empty"),
                    }),
                    children: yup.array(),
                    red_flags: yup.array().of(yup.string()),
                    tool_type_id: yup.number().integer(),
                }),
            ),
            unordered: yup.array().of(
                yup.object().shape({
                    log: yup.object(),
                    log_map: yup.object(),
                    tool_type_id: yup.number().integer(),
                }),
            ),
        }),
        uuid: yup.string().uuid("Must be a valid UUID").required("UUID is required"),
    })
    .required()
