import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"
import { uuidv4 } from "../../helpers/standardHelpers"
import { getCookie, setCookie } from "../../helpers/cookieHelpers"

const Auth0ProviderWithHistory = ({ children }) => {
    const navigate = useNavigate()
    const { isAuthenticated } = useAuth0()

    const domain = process.env.REACT_APP_AUTH0_DOMAIN
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
    const audience = process.env.REACT_APP_AUDIENCE

    const onRedirectCallback = (appState) => {
        const lastReturnTo = localStorage.getItem("lastReturnTo")
        navigate(appState?.returnTo || lastReturnTo || window.location.pathname)
    }

    const params = {
        domain,
        clientId: clientId,
        redirectUri: window.location.origin + "/redirect",
        onRedirectCallback,
        audience,
        useRefreshTokens: true,
        cacheLocation: "localstorage",
    }

    useEffect(() => {
        if (!isAuthenticated) {
            let session_id = getCookie("session_id")

            if (!session_id) {
                session_id = uuidv4()
                setCookie("session_id", session_id, 365)
            }
        }
    }, [isAuthenticated])

    return <Auth0Provider {...params}>{children}</Auth0Provider>
}

export default Auth0ProviderWithHistory
