import React, { useRef } from "react"
import { Box, Button } from "@mui/material"
import styles from "./RepoMainOwnPlaybook.module.scss"
import { NewPlaybookButton } from "../RepoMainUserPlaybooksList/components/RepoMainUserPlaybooksListHeader/components/NewPlaybookButton/NewPlaybookButton"
import { useIsMobile } from "../../../../../../hooks/standart/useIsMobile"
import { useNavigate } from "react-router-dom"
import FFA_LOGO_TEXT from "../../../../../../assets/icons/Repo/FFA-log-with-text.svg"
// import styles from "../RepoMainUserPlaybooksListHeader.module.scss"
import { useAuth0 } from "@auth0/auth0-react"

const PATH_MY_TOOLBOX = "/toolbox"

export function RepoMainOwnPlaybook({
    fetchUserPlaybooks,
    generateInputRef,
    handleOpenRepoAuthModal,
}) {
    const isMobile = useIsMobile()
    const title = isMobile ? "Fraud Fighters Alliance" : "Welcome to Fraud Fighters Alliance"
    const { user } = useAuth0()
    const navigate = useNavigate()

    const launchToolbox = () => {
        if (!user) {
            handleOpenRepoAuthModal()
        } else {
            navigate(PATH_MY_TOOLBOX)
        }
    }
    return (
        <Box className={styles.repoMainOwnPlaybookMainWrapper}>
            <Box className={styles.repoMainOwnPlaybookLogo}>
                <img src={FFA_LOGO_TEXT} />
            </Box>
            <h1 className={styles.repoMainOwnPlaybookMainTitle}>{title}</h1>
            <h2 className={styles.repoMainOwnPlaybookTextDescription}>
                We provide free tools and resources for the fraud fighters community
            </h2>
            <Box className={styles.repoMainOwnPlaybookContainer}>
                <Box className={styles.repoMainOwnPlaybookMainButton}>
                    <NewPlaybookButton
                        fetchUserPlaybooks={fetchUserPlaybooks}
                        generateInputRef={generateInputRef}
                        handleOpenRepoAuthModal={handleOpenRepoAuthModal}
                    />
                </Box>
                <Box className={styles.repoMainOwnPlaybookMainButton}>
                    <Button
                        onClick={launchToolbox}
                        className={styles.repoMainOwnPlaybookAddNewButton}
                    >
                        Launch Toolbox
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
