import React, { useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector, useDispatch } from "react-redux"
import { Typography, Box } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { setCredentialsConnection } from "../../../services/integrations/integrationsServices"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const validationSchema = yup.object().shape({})

const useStyles = makeStyles(() => ({
    input: {
        height: 58,
        // width: "440px",
        maxWidth: "100%",
        borderRadius: "10px",
        // backgroundColor: "#f4f5f7" ,
    },
    label: {
        fontSize: "20px",
        color: "#000",
    },
}))

const CredentialsConnect = ({
    openCredentialsConnectPopup,
    setOpenCredentialsConnectPopup,
    name,
}) => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const [loading, setLoading] = useState(false)
    const [successConnecting, setSuccessConnecting] = useState(false)

    const initialValues = {}

    const {
        formState: { errors },
        control,
        watch,
        reset,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    })

    const values = watch()

    const setCredentialsConnectionHandler = async () => {
        setLoading(true)
        try {
            await setCredentialsConnection(name, values, dispatch, refreshAccessTokenCounter)
            setSuccessConnecting(true)
        } finally {
            reset(initialValues)
            setLoading(false)
            setOpenCredentialsConnectPopup(false)
        }
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setSuccessConnecting(false)
    }

    const getInputField = (fieldName, fieldLabel) => {
        return (
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "100%",
                    marginBottom: "20px",
                }}
            >
                <Box
                    component="label"
                    for="title"
                    sx={{
                        marginRight: "18px",
                        fontSize: "18px",
                        width: "200px",
                    }}
                >
                    {fieldLabel}
                </Box>
                <Controller
                    name={fieldName}
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id={fieldName}
                            variant="outlined"
                            InputProps={{
                                className: classes.input,
                            }}
                            placeholder={`Your ${fieldLabel} From ${name} Platform`}
                            fullWidth
                            disabled={loading}
                        />
                    )}
                />
            </Grid>
        )
    }

    return (
        <>
            <Snackbar
                open={successConnecting}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                sx={{ height: "100%" }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
                    {`Connected to ${name}!`}
                </Alert>
            </Snackbar>
            <Dialog
                open={openCredentialsConnectPopup}
                onClose={() => setOpenCredentialsConnectPopup(false)}
                maxWidth="md"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`${name} Connection`}</DialogTitle>
                <DialogContent>
                    {name === "Middesk" && getInputField("api_key", "API Key")}
                    {name === "Postgres" && (
                        <>
                            {getInputField("address", "Address")}
                            {getInputField("port", "Port")}
                            {getInputField("username", "Username")}
                            {getInputField("password", "Password")}
                        </>
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: "space-between",
                        padding: "20px 24px",
                    }}
                >
                    <LoadingButton
                        variant="outlined"
                        color="secondary"
                        sx={{
                            // background: '#F9F9F9 !important',
                            width: "200px",
                            height: "42.5px",
                            borderRadius: "10px",
                            borderColor: "#000000",
                        }}
                        loading={loading}
                        onClick={() => setOpenCredentialsConnectPopup(false)}
                    >
                        <Typography
                            color="#000000"
                            fontSize="16px"
                            fontWeight="700"
                            sx={{
                                textTransform: "none",
                            }}
                        >
                            Cancel
                        </Typography>
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        color="secondary"
                        sx={{
                            background: "#FFC824 !important",
                            width: "200px",
                            height: "42.5px",
                            borderRadius: "10px",
                        }}
                        loading={loading}
                        onClick={setCredentialsConnectionHandler}
                    >
                        <Typography
                            color="#000000"
                            fontSize="16px"
                            fontWeight="700"
                            sx={{
                                textTransform: "none",
                            }}
                        >
                            Connect
                        </Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CredentialsConnect
