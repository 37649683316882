import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import Drawer from "@mui/material/Drawer"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ViewListIcon from "@mui/icons-material/ViewList"
import SpeedIcon from "@mui/icons-material/Speed"
import BoltIcon from "@mui/icons-material/Bolt"
import { Box } from "@mui/material"
import LogoutIcon from "@mui/icons-material/Logout"
import SettingsIcon from "@mui/icons-material/Settings"
import { useAuth0 } from "@auth0/auth0-react"
import { useSelector, useDispatch } from "react-redux"
import { changeSidebarChoice } from "../../redux/actions/sidebarActions"
import PreviewIcon from "@mui/icons-material/Preview"
import SaveAltIcon from "@mui/icons-material/SaveAlt"
import AttachEmailIcon from "@mui/icons-material/AttachEmail"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import DeleteIcon from "@mui/icons-material/Delete"
import { PlaybookModal } from "../PlaybookModal/PlaybookModal"
import { isRedstringsUser } from "../../helpers/standardHelpers"

const drawerWidth = 240

const Sidebar = ({
    values,
    setCloseCaseDialogOpenHandler,
    setSendReportDialogOpen,
    setDeleteCasePopup,
    caseTypes = [],
}) => {
    const [openPlaybookModal, setOpenPlaybookModal] = useState(false)
    const [inNotebook, setInNotebook] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const dispatch = useDispatch()
    const { logout, user } = useAuth0()
    const navigate = useNavigate()
    const location = useLocation()

    const sidebarState = useSelector((state) => state.sidebarState)
    const sidebarChoice = useSelector((state) => state.sidebarChoice)
    const changeSidebarChoiceHandler = (path) => {
        dispatch(changeSidebarChoice(path))
    }

    useEffect(() => {
        if (parseInt(location.pathname.replace("/", ""))) {
            setInNotebook(true)
        } else {
            setInNotebook(false)
        }
    }, [location])

    const [previewEnabled, setPreviewEnabled] = useState(false)

    useEffect(() => {
        if (values?.tools) {
            const toolsLength = Object.keys(values.tools).length
            const previewEnabledResult =
                toolsLength > 0 &&
                (toolsLength > 1 ||
                    (toolsLength === 1 && Object.values(values.tools)[0]?.type != 20)) &&
                values.title
            setPreviewEnabled(previewEnabledResult)
        }
    }, [values?.tools, values?.title])

    // Popover handlers
    const handleClickPopever = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClosePopover = () => {
        setAnchorEl(null)
    }

    // Playbook handlers
    const handleOpenPlaybook = () => {
        setOpenPlaybookModal(true)
    }

    const handleClosePlaybook = () => {
        setOpenPlaybookModal(false)
    }

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined

    return inNotebook ? (
        <Drawer
            variant="permanent"
            anchor="right"
            style={{ display: sidebarState ? "block" : "none" }}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                ["& .MuiDrawer-paper"]: {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    zIndex: "100",
                    height: "auto",
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRadius: "10px",
                },
            }}
        >
            <Toolbar />
            <Box
                sx={{
                    position: "relative",
                    overflow: "auto",
                }}
            >
                <List>
                    <ListItem key={"Preview Report"} disablePadding>
                        <ListItemButton
                            onClick={(event) =>
                                previewEnabled
                                    ? setCloseCaseDialogOpenHandler()
                                    : handleClickPopever(event)
                            }
                        >
                            <ListItemIcon
                                style={{
                                    color: "#3e3e3e",
                                }}
                            >
                                <PreviewIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Preview Report"} />
                        </ListItemButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Typography sx={{ p: 2 }}>Adding Title And Tool Required</Typography>
                        </Popover>
                    </ListItem>
                    <ListItem key={"Email Report"} disablePadding>
                        <ListItemButton
                            onClick={(event) =>
                                previewEnabled
                                    ? setSendReportDialogOpen(true)
                                    : handleClickPopever(event)
                            }
                        >
                            <ListItemIcon
                                style={{
                                    color: "#3e3e3e",
                                }}
                            >
                                <AttachEmailIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Email Report"} />
                        </ListItemButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Typography sx={{ p: 2 }}>Adding Title And Tool Required</Typography>
                        </Popover>
                    </ListItem>
                    <ListItem key={"Save As Playbook"} disablePadding>
                        <ListItemButton onClick={() => handleOpenPlaybook()}>
                            <ListItemIcon
                                style={{
                                    color: "#3e3e3e",
                                }}
                            >
                                <SaveAltIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Save As Playbook"} />
                        </ListItemButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Typography sx={{ p: 2 }}>Adding Title And Tool Required</Typography>
                        </Popover>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem key={"Delete Case"} disablePadding>
                        <ListItemButton onClick={(event) => setDeleteCasePopup(true)}>
                            <ListItemIcon
                                style={{
                                    color: "#3e3e3e",
                                }}
                            >
                                <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Delete Case"} />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem key={"Log Out"} disablePadding>
                        <ListItemButton
                            onClick={() =>
                                logout({
                                    returnTo: window.location.origin,
                                })
                            }
                        >
                            <ListItemIcon
                                style={{
                                    color: "#3e3e3e",
                                }}
                            >
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Log Out"} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <PlaybookModal
                open={openPlaybookModal}
                onClose={handleClosePlaybook}
                values={values}
                caseTypes={caseTypes}
            />
        </Drawer>
    ) : (
        <Drawer
            variant="permanent"
            style={{ display: sidebarState ? "block" : "none" }}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                ["& .MuiDrawer-paper"]: {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    zIndex: "0",
                    height: "auto",
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRadius: "10px",
                },
            }}
        >
            <Toolbar />
            <Box
                sx={{
                    overflow: "auto",
                }}
            >
                <List>
                    <ListItem key={"My Cases"} disablePadding>
                        <ListItemButton
                            onClick={() => {
                                changeSidebarChoiceHandler("/")
                                navigate("/")
                            }}
                        >
                            <ListItemIcon
                                style={{
                                    color: sidebarChoice === "/" ? "#E02B38" : "#3e3e3e",
                                }}
                            >
                                <ViewListIcon />
                            </ListItemIcon>
                            <ListItemText primary={"My Cases"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={"Dashboard"} disablePadding>
                        <ListItemButton disabled>
                            <ListItemIcon>
                                <SpeedIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Dashboard (coming soon)"} />
                        </ListItemButton>
                    </ListItem>
                    {isRedstringsUser(user) && (
                        <ListItem key={"Internal Configs"} disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    navigate("/internal/configs")
                                }}
                            >
                                <ListItemIcon
                                    style={{
                                        color: "#3e3e3e",
                                    }}
                                >
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Internal Configs"} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    <Divider />
                    <ListItem key={"Integrations"} disablePadding>
                        <ListItemButton
                            onClick={() => {
                                changeSidebarChoiceHandler("/all/integrations")
                                navigate("/all/integrations")
                            }}
                        >
                            <ListItemIcon
                                style={{
                                    color:
                                        sidebarChoice === "/all/integrations"
                                            ? "#E02B38"
                                            : "#3e3e3e",
                                }}
                            >
                                <BoltIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Integrations"} />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem key={"Log Out"} disablePadding>
                        <ListItemButton
                            onClick={() =>
                                logout({
                                    returnTo: window.location.origin,
                                })
                            }
                        >
                            <ListItemIcon
                                style={{
                                    color: "#3e3e3e",
                                }}
                            >
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText
                                style={{
                                    color: "#3e3e3e",
                                }}
                                primary={"Log Out"}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    )
}

export default Sidebar
