import React, { useState } from "react"
import { Box, Typography, Divider } from "@mui/material"
import { Controller } from "react-hook-form"
import TextField from "@mui/material/TextField"
import { useSelector, useDispatch } from "react-redux"
import { doToolAction } from "../../../../../../services/notebook/notebookServices"
import CircularProgress from "@mui/material/CircularProgress"
import pencilIcon from "../../../../../../assets/icons/pencilDark.svg"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import styles from "./RecommendationItem.module.scss"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"

export function RecommendationItem({
    text,
    number,
    id,
    control,
    resetField,
    values,
    refreshPageData,
}) {
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const toogleEdit = () => {
        setEdit(!edit)
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (id && values) {
                await doToolAction(
                    values.case_id,
                    values.tools[id]?.id,
                    values.tools[id]?.type,
                    values.tools[id]?.log,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                setEdit(false)
                dispatch(handleShowToaster("success", "Successfully save recommendation details"))
            }
        } catch (error) {
            console.log(error)
            setEdit(false)
            dispatch(handleShowToaster("success", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleCancel = () => {
        resetField(`tools.${id}.log.note`)
        toogleEdit()
    }

    return (
        <>
            <Box className={styles.recommendationItemWrapper}>
                <Box className={styles.number}>#{number}</Box>
                {!edit ? (
                    <Box sx={{ marginTop: "10px", wordWrap: "break-word" }}>
                        <Typography fontSize="16px">{text}</Typography>
                    </Box>
                ) : (
                    <Box marginTop="10px">
                        <Controller
                            name={`tools.${id}.log.note`}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    placeholder="Type your recommendation here"
                                    variant="outlined"
                                    disabled={!edit || isLoading}
                                    InputProps={{
                                        className: styles.input,
                                    }}
                                    multiline
                                    fullWidth
                                />
                            )}
                        />
                    </Box>
                )}

                <Box>
                    <Box className={styles.icons}>
                        {isLoading ? (
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        ) : (
                            <>
                                {edit ? (
                                    <Box display="flex" gap="15px">
                                        <CloseIcon
                                            onClick={handleCancel}
                                            className={styles.standartIcon}
                                        />
                                        <DoneIcon
                                            onClick={handleSave}
                                            className={styles.standartIcon}
                                        />
                                    </Box>
                                ) : (
                                    <img
                                        style={{ cursor: "pointer" }}
                                        onClick={toogleEdit}
                                        src={pencilIcon}
                                    ></img>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <Divider style={{ marginTop: "5px" }} />
        </>
    )
}
