import { CHANGE_MENU_CONFIG } from "../actions/types"

// default menu config
const initialState = {
    value: [
        25, 28, 5, 6, 7, 9, 10, 12, 14, 16, 32, 33, 36, 38, 15, 17, 1, 42, 50, 53, 54, 55, 30, 62,
        66, 67, 68, 69, 70, 71,
    ],
    name: "default-25,28,5,6,7,9,10,12,14,16,32,33,36,38,15,17,1,42,50,53,54,55,30,62,66,67,68,69,70,71",
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_MENU_CONFIG:
            return {
                ...state,
                value: action.value,
                name: action.name != null ? action.name : state.name,
            }
        default:
            return state
    }
}
