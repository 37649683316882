import React from "react"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts"

const data = [
    {
        area: "James",
        Analyst: 20,
    },
    {
        area: "Emily",
        Analyst: 7,
    },
    {
        area: "Alexander",
        Analyst: 17,
    },
    {
        area: "Olivia",
        Analyst: 5,
    },
    {
        area: "Benjamin",
        Analyst: 5,
    },
]

const calculateAverage = (data) => {
    const total = data.reduce((sum, item) => sum + item.Analyst, 0)
    return total / data.length
}

export function BarChartGraphic() {
    const average = calculateAverage(data)
    return (
        <BarChart width={600} height={400} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="area" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Analyst" fill="#CAEBD8" barSize={20} />
            <text x={300} y={average + 10} textAnchor="middle" fill="#333">
                Average: {average.toFixed(2)}
            </text>
        </BarChart>
    )
}
