import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import styles from "./ErrorBoundary.module.scss"

export const ErrorBoundary = ({ children }) => {
    return (
        <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
                <div className={styles.errorBoundaryContainer}>
                    <h1>Oops, something went wrong!</h1>
                </div>
            )}
        >
            {children}
        </Sentry.ErrorBoundary>
    )
}
