import React from "react"
import { Box } from "@mui/material"
import { RepoMainUserPlaybooksListCard } from "../RepoMainUserPlaybooksListCard/RepoMainUserPlaybooksListCard"
import styles from "./PlaybooksList.module.scss"

export function PlaybooksList({ playbooks, setPlaybooks, columns, showPrompt = false }) {
    return (
        <Box>
            <Box className={styles.playbooksListHeader}>
                <Box className={styles.playbooksListHeaderItems}>
                    {columns.map((elem) => (
                        <Box sx={{ width: elem.width }} key={elem.name}>
                            {elem.name}
                        </Box>
                    ))}
                </Box>
                {/* Additional element like in table */}
                <Box sx={{ width: "25px", opacity: 0 }}></Box>
            </Box>
            <Box display="flex" flexDirection="column" gap="12px">
                {playbooks.map((playbook, index) => (
                    <RepoMainUserPlaybooksListCard
                        playbook={playbook}
                        key={index}
                        setPlaybooks={setPlaybooks}
                        showPrompt={showPrompt}
                    />
                ))}
            </Box>
        </Box>
    )
}
