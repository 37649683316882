import { CHANGE_SIDEBAR_STATE, CHANGE_SIDEBAR_CHOICE } from "./types"

export const changeSidebarState = (sidebarState) => {
    return {
        type: CHANGE_SIDEBAR_STATE,
        payload: sidebarState,
    }
}

export const changeSidebarChoice = (sidebarChoice) => (dispatch) => {
    dispatch({
        type: CHANGE_SIDEBAR_CHOICE,
        payload: sidebarChoice ? sidebarChoice : null,
    })
}
