import axios from "../../utils/axiosConfig"
import { setRefreshAccessTokenCounter } from "../../redux/actions/refreshAccessTokenCounterActions"
import ENUMS from "../../constants/appEnums"

export const handleSubscribeUser = async (data, dispatch, refreshAccessTokenCounter) => {
    try {
        await axios.post(ENUMS.API_ROUTES.SUBSCRIBE_USER, {
            ...data,
        })
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getToolsById = async (tools, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_TOOLS_BY_ID, {
            tools,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const postUserFeedbackAction = async (feedback, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.USER_FEEDBACK_ACTION, feedback)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getApprovedPlaybooks = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.GET_APPROVED_PLAYBOOKS)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getUserPlaybooks = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.GET_USER_PLAYBOOKS)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const postCopyPlaybookToUser = async (templateId, dispatch, refreshAccessTokenCounter) => {
    if (!templateId) return
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.COPY_PLAYBOOK_TO_USER, {
            template_id: templateId,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const createEmptyPlaybook = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.CREATE_EMPTY_PLAYBOOK)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const deleteUserPlaybook = async (templateId, dispatch, refreshAccessTokenCounter) => {
    if (!templateId) return
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.DELETE_USER_PLAYBOOK, {
            template_id: templateId,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const contactUsPlaybook = async (text, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.CONTACT_US_PLAYBOOK, { text })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const updateTemplateData = async (templateData, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.put(ENUMS.API_ROUTES.TEMPLATE, templateData)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getPlaybookToolsConfig = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_PLAYBOOK_TOOLS_CONFIG)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getTemplateData = async (template_id, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_TEMPLATE_DATA, {
            template_id,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const createTemplate = async (userData, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.TEMPLATE, userData)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const transformGeneratedCase = async (case_id, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.SAVE_TEMPLATE_FROM_CASE, case_id)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const sharePlaybookWithEmailInvites = async (
    emails,
    dispatch,
    refreshAccessTokenCounter,
) => {
    try {
        const { data } = await axios.post(
            ENUMS.API_ROUTES.SHARE_PLAYBOOK_WITH_EMAIL_INVITES,
            emails,
        )
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}
