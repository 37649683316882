import { Box } from "@mui/material"
import React from "react"

export function NotFoundPage() {
    return (
        <Box textAlign="center" padding="0 64px">
            <h1>404 - Page Not Found</h1>
        </Box>
    )
}
