import React, { useState, useEffect } from "react"
import { Box, TextField, CircularProgress } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { LoadingButton } from "@mui/lab"
import { useIsMobile } from "../../../../../../hooks/standart/useIsMobile"
import { SuggestionsButtons } from "./components/SuggestionsButtons/SuggestionsButtons"
import { suggestionsElements } from "../../../../../../constants/appConstants"
import { getRandomSuggestions } from "../../../../../../helpers/playbookHelpers"
import styles from "./PlaybookMain.module.scss"

const useStyles = makeStyles(() => ({
    input: {
        borderRadius: "10px",
        "& .MuiInputBase-root": {
            borderRadius: "10px",
            height: "70px",
        },
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": { borderColor: "#096bbb" },
        },
        "& .Mui-focused": {
            borderRadius: "10px",
        },
        "& .Mui-disabled": {
            color: "#908e8e",
        },
        "& .MuiLoadingButton-loading": {
            color: "#000",
        },
    },
    generateButton: {
        backgroundColor: "#096BBB",
        height: "50px",
        borderRadius: "10px",
        textTransform: "none",
        color: "#fff",
        "&.Mui-disabled": {
            color: "#adabab",
        },
        "&:hover": {
            backgroundColor: "#2a2828",
        },
    },
}))

export function PlaybookMain({ handleCreateTemplate, isLoading, inputRef, isRepoOpen }) {
    const classes = useStyles()
    const isMobile = useIsMobile()
    const inputPlaceholder = isMobile
        ? "Describe a case you've encountered"
        : "Describe a case you've encountered or detail the investigation playbook you'd like to create."

    const [inputValue, setInputValue] = useState("")
    const [randomSuggestions, setRandomSuggestions] = useState([])

    const handleInputChange = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = (event, text) => {
        event.preventDefault()
        if (text.trim()) {
            handleCreateTemplate(event, text)
        }
    }

    const renderLoadingButton = () => (
        <LoadingButton
            style={isMobile ? { width: "100%", marginTop: "10px" } : { width: "150px" }}
            type="submit"
            loading={isLoading}
            loadingIndicator={<CircularProgress size={20} />}
            onClick={(e) => handleCreateTemplate(e, inputValue)}
            disabled={!inputValue.trim()}
            className={classes.generateButton}
        >
            {!isLoading && "Generate"}
        </LoadingButton>
    )

    const handleButtonClick = (e, buttonText) => {
        if (buttonText) {
            setInputValue(buttonText)
            handleSubmit(e, buttonText)
        }
    }

    useEffect(() => {
        setRandomSuggestions(getRandomSuggestions(suggestionsElements, isMobile ? 2 : 4))
    }, [isMobile])

    return (
        <form
            onSubmit={(e) => handleSubmit(e, inputValue)}
            className={styles.playbookMainContainer}
        >
            <Box className={styles.playbookMainInfo}>
                <h1 className={styles.playbookMainInfoTitle}>Investigation Playbook Generator</h1>
                <h2 className={styles.playbookMainInfoSubTitle}>
                    Generate risk & fraud investigation playbook powered by generative AI
                </h2>
            </Box>
            <TextField
                disabled={isLoading}
                inputRef={inputRef}
                className={classes.input}
                fullWidth
                placeholder={inputPlaceholder}
                value={inputValue}
                onChange={handleInputChange}
                InputProps={
                    !isMobile && {
                        endAdornment: renderLoadingButton(),
                    }
                }
            />
            {isMobile && renderLoadingButton()}
            {!isLoading && !isRepoOpen && (
                <SuggestionsButtons
                    onButtonClick={handleButtonClick}
                    buttons={randomSuggestions}
                    isLoading={isLoading}
                />
            )}
        </form>
    )
}
