import { Typography, Box } from "@mui/material"
import React from "react"

const AppCard = ({ logo, name, connected, oauthUrl, appUrl, action, alwaysConnected }) => {
    return (
        <Box
            sx={{
                padding: "16px 40px 16px 55px",
                display: "grid",
                gridTemplateColumns: "auto 1fr auto auto",
                columnGap: "10px",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    width: "120px",
                }}
            >
                <img
                    src={logo}
                    alt="company logo"
                    style={{
                        maxHeight: "30px",
                        maxWidth: "100px",
                        // marginRight: "43px",
                        margin: "0 auto",
                        display: "block",
                    }}
                />
            </div>
            {!connected ? (
                <Typography
                    fontSize={"16px"}
                    color="rgba(45, 45, 45, 0.95)"
                    sx={{
                        paddingLeft: "50px",
                    }}
                >
                    {name}
                </Typography>
            ) : (
                <a href={appUrl || "#"}>
                    <Typography
                        fontSize={"16px"}
                        color="rgba(45, 45, 45, 0.95)"
                        sx={{
                            paddingLeft: "50px",
                            textDecoration: "none",
                        }}
                    >
                        {name}
                    </Typography>
                </a>
            )}

            {oauthUrl ? (
                <>
                    {connected && (
                        <Typography
                            fontSize="13px"
                            fontWeight="500"
                            color="#329F36"
                            sx={{
                                padding: "5px 18px",
                                background: !connected ? "" : "#E02B38",
                                borderRadius: "5px",
                            }}
                        >
                            Connected
                        </Typography>
                    )}
                    <a
                        href={oauthUrl}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            cursor: "pointer",
                            margin: "0 34.5px",
                            textDecoration: "none",
                        }}
                    >
                        <Typography
                            fontSize={"13px"}
                            fontWeight="500"
                            color={!connected ? "#329F36" : "#FFFFFF"}
                            sx={{
                                padding: "5px 18px",
                                border: !connected ? "1px solid #329F36" : "",
                                background: !connected ? "" : "#E02B38",
                                borderRadius: "5px",
                            }}
                        >
                            {!connected ? "Connect" : "Disconnect"}
                        </Typography>
                    </a>
                </>
            ) : alwaysConnected ? (
                <>
                    <Typography
                        fontSize="13px"
                        fontWeight="500"
                        color="#329F36"
                        sx={{
                            padding: "5px 18px",
                            background: "",
                            borderRadius: "5px",
                        }}
                    >
                        Connected
                    </Typography>
                    <Typography
                        fontSize="13px"
                        fontWeight="500"
                        color="#A9A9A9"
                        sx={{
                            padding: "5px 18px",
                            background: !connected ? "" : "#E02B38",
                            borderRadius: "5px",
                        }}
                    >
                        Provided by Redstrings
                    </Typography>
                </>
            ) : (
                <>
                    {connected && (
                        <Typography
                            fontSize="13px"
                            fontWeight="500"
                            color="#329F36"
                            sx={{
                                padding: "5px 18px",
                                background: "",
                                borderRadius: "5px",
                            }}
                        >
                            Connected
                        </Typography>
                    )}

                    <a
                        onClick={action}
                        style={{
                            cursor: "pointer",
                            margin: "0 34.5px",
                            textDecoration: "none",
                        }}
                    >
                        <Typography
                            fontSize={"13px"}
                            fontWeight="500"
                            color={!connected ? "#329F36" : "#FFFFFF"}
                            sx={{
                                padding: "5px 18px",
                                border: !connected ? "1px solid #329F36" : "",
                                background: !connected ? "" : "#E02B38",
                                borderRadius: "5px",
                            }}
                        >
                            {!connected ? "Connect" : "Disconnect"}
                        </Typography>
                    </a>
                </>
            )}
        </Box>
    )
}

export default AppCard
