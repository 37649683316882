import { LIGHT_THEME, DARK_THEME } from "../actions/types"

const initialState = {
    darkTheme: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LIGHT_THEME:
            return {
                ...state,
                darkTheme: false,
            }
        case DARK_THEME:
            return {
                ...state,
                darkTheme: true,
            }
        default:
            return state
    }
}
