import { SET_TOASTER_STATE } from "./types"

export const handleShowToaster =
    (severity, title, autoHideDuration = null) =>
    (dispatch) => {
        dispatch({
            type: SET_TOASTER_STATE,
            openToaster: true,
            severity: severity,
            title: title,
        })

        const timer = setTimeout(() => {
            dispatch({
                type: SET_TOASTER_STATE,
                openToaster: false,
                severity: "success",
                title: "",
            })
        }, autoHideDuration || 3000)

        return () => clearTimeout(timer)
    }
