import React, { useState } from "react"
import { Box, CircularProgress } from "@mui/material"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { AddStepIcon } from "./components/AddStepIcon/AddStepIcon"
import { StepContent } from "./components/StepContent/StepContent"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import styles from "./StepsCarouselCard.module.scss"

export function StepsCarouselCard({ log, index_x, id, handleAddTools, openMenu }) {
    const { caseId, stepId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const width = openMenu ? 200 : ""

    const [isLoading, setIsLoading] = useState(false)

    const changeStep = () => {
        if (caseId && id) navigate(`/${caseId}/step/${id}`)
    }

    const handleAddNewStep = async (position) => {
        try {
            setIsLoading(true)
            await handleAddTools([46], position, 0, null)
            dispatch(handleShowToaster("success", "Successfully add new step"))
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box className={styles.stepsCarouselCardContainer}>
            {!isLoading && <AddStepIcon index_x={index_x} onClick={handleAddNewStep} />}
            <Box
                className={
                    +stepId === +id
                        ? styles.stepsCarouselCardActive
                        : styles.stepsCarouselCardWrapper
                }
                onClick={changeStep}
                style={{ width }}
            >
                {isLoading ? (
                    <Box className={styles.stepsCarouselCardLoading}>
                        <CircularProgress style={{ width: "25px", height: "25px" }} />
                    </Box>
                ) : (
                    <>
                        <StepContent log={log} index_x={index_x} />
                        <Box className={styles.stepsCarouselCardName}>{log?.note}</Box>
                    </>
                )}
            </Box>
            {!isLoading && <AddStepIcon index_x={index_x + 1} onClick={handleAddNewStep} />}
        </Box>
    )
}
