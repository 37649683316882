import React from "react"
import styles from "./MainSection.module.scss"
import { Box, Typography } from "@mui/material"
import { PresentPicture } from "../Section/Section"

export function MainSection({ name, url, profile_pic }) {
    return (
        <Box className={styles.apiResponseMainSectionWrapper}>
            {profile_pic && (
                <PresentPicture
                    src={profile_pic}
                    alt={profile_pic}
                    className={styles.apiResponseMainSectionImage}
                />
            )}
            {name || url ? (
                <Box className={styles.apiResponseMainSectionText}>
                    {name && (
                        <Typography className={styles.apiResponseMainSectionName}>
                            <span className={styles.apiResponseMainSectionTextBold}>Name:</span>{" "}
                            {name}
                        </Typography>
                    )}
                    {url && (
                        <Typography className={styles.apiResponseMainSectionUrl}>
                            <span className={styles.apiResponseMainSectionTextBold}>Link:</span>{" "}
                            <a target="_blank" rel="noopener noreferrer" href={url}>
                                {url}
                            </a>
                        </Typography>
                    )}
                </Box>
            ) : null}
        </Box>
    )
}
