import React, { useState } from "react"
import { RemoveDialog } from "../components/RemoveDialog/RemoveDialog"
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import makeStyles from "@mui/styles/makeStyles"
import ToolsMenu from "./ToolsMenu/ToolsMenu"
import { Box, Grid, Typography } from "@mui/material"

const useStyles = makeStyles((theme) => ({
    input: {
        height: 35,
        backgroundColor: "#f4f5f7",
    },
}))

function Readme({
    tool_id,
    index,
    index_x,
    index_y,
    handleAddTools,
    handleDeleteTool,
    values,
    integrations,
    toolsConfig,
    subtitle,
}) {
    const [removetoolDialogOpen, setRemovetoolDialogOpen] = useState(false)

    return (
        <Grid xs={12}>
            <RemoveDialog
                open={removetoolDialogOpen}
                setClosed={() => setRemovetoolDialogOpen(false)}
                handleAgree={() => {
                    handleDeleteTool(tool_id)
                    setRemovetoolDialogOpen(false)
                }}
            />
            <div>
                <ToolsMenu
                    handleAddTools={handleAddTools}
                    index_x={index_x}
                    index_y={index_y}
                    values={values}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                />
            </div>
            <Box
                sx={{
                    background: "#f3f3f3",
                    padding: "28px 23px",
                    borderRadius: "10px",
                    position: "relative",
                }}
            >
                <Grid container alignItems="flex-start" justifyContent="space-between">
                    <Typography color="black" variant="h6" fontWeight="400" marginBottom="41px">
                        Readme
                    </Typography>
                </Grid>
                <Grid container direction="column" spacing={1} alignItems="flex-start">
                    <Grid item>
                        <Typography
                            color="#3e3e3e"
                            variant="h6"
                            fontSize="1.3em"
                            fontWeight="400"
                            marginBottom="10px"
                        >
                            Welcome to Redstrings' Notebook Early access!
                        </Typography>
                        <Typography color="#3e3e3e" variant="p" fontSize="1.1em" fontWeight="400">
                            Redstrings notebook is a modular workspace for investigating fraud and
                            compliance cases.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color="#3e3e3e" variant="p" fontSize="1.1em" fontWeight="400">
                            Each step of the investigation starts with pressing the + button to add
                            a new tool/section.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <video
                            src="https://static-resources.redstrings.io/add_tool.mp4"
                            loop="true"
                            autoPlay="autoplay"
                            controls=""
                            id="vid"
                            muted
                            width="600px"
                        ></video>
                        <script>document.getElementById('vid').play();</script>
                    </Grid>
                    <Grid item style={{ marginTop: "-60px" }}>
                        <Typography color="#3e3e3e" variant="p" fontSize="1.1em" fontWeight="400">
                            In order for us to be able to provide risk teams with the most value, we
                            are asking you to describe an example of an investigation/case type you
                            frequently handle.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color="#3e3e3e" variant="p" fontSize="1.1em" fontWeight="400">
                            To get started click the + button and choose the “RedStrings Champions
                            Toolbox” section, there you will have tools to:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color="#3e3e3e" variant="p" fontSize="1.1em" fontWeight="400">
                            <ol type="1">
                                <li>Describe the steps of your investigation.</li>
                                <li>Provide feedback for our Risk Notebook.</li>
                                <li>Suggest new features you are interested in.</li>
                                <li>
                                    Request an API/Data-feed/Tool you’d like to see in our Notebook.
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color="#3e3e3e" variant="p" fontSize="1.1em" fontWeight="400">
                            Here is an{" "}
                            <a
                                href="https://static-resources.redstrings.io/investigation_process.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                example
                            </a>{" "}
                            of a documented investigation process.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color="#3e3e3e" variant="p" fontSize="1.1em" fontWeight="400">
                            Here's an{" "}
                            <a
                                href="https://static-resources.redstrings.io/investigation_flow.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                example
                            </a>{" "}
                            of the same suggested case, as an investigation flow, with all the tools
                            needed for the task.
                        </Typography>
                    </Grid>
                </Grid>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        setRemovetoolDialogOpen(true)
                    }}
                    size="large"
                    sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                    }}
                >
                    <ClearIcon fontSize="medium" color="cblack" />
                </IconButton>
            </Box>
        </Grid>
    )
}

export default Readme
