import * as yup from "yup"

export const noteBookContainerValidationSchema = yup.object().shape({
    title: yup
        .string()
        .min(3, "Title must be at least 3 characters")
        .max(65, "Title is limited to 65 characters")
        .required("Required"),
    tools: yup
        .array()
        .of(
            yup.object().shape({
                type: yup.string().required("Required"),
                log: yup.object().required("Required"),
            }),
        )
        .required("Required"),
})
