import React, { useState } from "react"
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { Link } from "react-router-dom"
import { PopOver } from "../../../../../../components/PopOver/PopOver"
import styles from "../../AsideMenu.module.scss"

export const AsideMenuItem = ({ menuItem, selectedMenuItem, handleMenuItemClick, openMenu }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMouseLeave = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <ListItem
                disablePadding
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={styles.asideMenuListItem}
            >
                <ListItemButton
                    disabled={menuItem.disabled}
                    component={Link}
                    to={menuItem.to}
                    onClick={() => handleMenuItemClick(menuItem.id)}
                    className={styles.asideMenuListElement}
                    sx={{ backgroundColor: selectedMenuItem === menuItem.id && "#292222" }}
                >
                    <ListItemIcon className={styles.asideMenuListIcon}>
                        {selectedMenuItem === menuItem.id ? menuItem.activeIcon : menuItem.icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={menuItem.label}
                        sx={{
                            opacity: openMenu ? 1 : 0,
                            transition: "all 0.5s ease",
                            color: selectedMenuItem === menuItem.id ? "#fff" : null,
                        }}
                    />
                </ListItemButton>
                {!openMenu && (
                    <PopOver
                        text={menuItem.label}
                        open={anchorEl !== null}
                        anchorEl={anchorEl}
                        horizontal="right"
                        vertical="center"
                        handlePopoverClose={handleMouseLeave}
                    />
                )}
            </ListItem>
        </>
    )
}
