import React from "react"
import { Box, Typography, Button } from "@mui/material"
import emptyToolsImage from "../../../assets/images/emptyTools.svg"
import makeStyles from "@mui/styles/makeStyles"
import ToolsMenu from "../Tools/ToolsMenu/ToolsMenu"
import ControlPointIcon from "@mui/icons-material/ControlPoint"

const useStyles = makeStyles(() => ({
    addToolBtn: {
        backgroundColor: "#000000",
        border: "1px solid #000000",
        borderRadius: "18px",
        width: "150px",
        height: "40px",
        color: "#FFFFFF",
        margin: "0px auto",
        display: "flex",
        justifyContent: "center",
    },
}))

export function EmptyTools({
    handleAddTools,
    values,
    integrations,
    toolsConfig,
    index_x,
    index_y,
}) {
    const classes = useStyles()

    return (
        <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            width="100%"
            height="calc(100vh - 80px)"
            gap="16px"
        >
            <Box>
                <img src={emptyToolsImage}></img>
            </Box>
            <Box textAlign="center">
                <Typography fontSize="24px">Interactive investigation</Typography>
                <Typography color="#9AA3AB" fontSize="16px">
                    Collect data from multiple sources using our data collection toolbox
                </Typography>
            </Box>
            <Box width="100%" height="75px">
                <Box className={classes.addToolBtn}>
                    <Button
                        style={{ textTransform: "none", width: "100%", fontWeight: 400 }}
                        variant="standart"
                    >
                        <ToolsMenu
                            style={{ width: "150px", height: "40px" }}
                            handleAddTools={handleAddTools}
                            index_x={index_x}
                            index_y={index_y}
                            values={values}
                            integrations={integrations}
                            toolsConfig={toolsConfig}
                            title={"Add a tool"}
                            titleColor="#fff"
                            icon={<ControlPointIcon style={{ marginRight: "5px" }} />}
                        />
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
