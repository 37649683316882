import React, { useState, useEffect } from "react"
import { Box } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { AllEvents } from "./components/AllEvents/AllEvents"
import { EventInfo } from "./components/EventInfo/EventInfo"
import { NewEvent } from "./components/NewEvent"

const useStyles = makeStyles(() => ({
    eventWrapper: {
        backgroundColor: "#FFFFFF",
        width: "300px",
        padding: "20px 16px",
        height: "100%",
        borderRight: "1px solid #EFEEF1",
        overflow: "auto",
    },
}))

export function Event({
    values,
    entityTypes,
    events,
    handleAddTools,
    control,
    resetAllFields,
    handleDeleteTool,
    refreshPageData,
}) {
    const classes = useStyles()
    const [currentView, setCurrentView] = useState(0)
    const [currentEditingEventId, setCurrentEditingEventId] = useState(null)
    const [newEventId, setNewEventId] = useState(null)
    // const [newEntity, setNewEntity] = useState(null)

    const onHandleSelelectEvent = (id) => {
        setCurrentEditingEventId(id)
        setCurrentView(2)
    }

    const selectEntityView = (number) => {
        setCurrentView(number)
    }

    useEffect(() => {
        setNewEventId(null)
    }, [currentView])

    const curentViewComponent = () => {
        switch (currentView) {
            case 0:
                return (
                    <AllEvents
                        events={events}
                        handleAddTools={handleAddTools}
                        onHandleSelelectEvent={onHandleSelelectEvent}
                        setNewEventId={setNewEventId}
                        refreshPageData={refreshPageData}
                        newEventId={newEventId}
                    />
                )
            case 1:
                return (
                    // Now not in use, due to changed flow
                    <NewEvent
                        selectEntityView={selectEntityView}
                        eventId={newEventId}
                        control={control}
                        values={values}
                        refreshPageData={refreshPageData}
                        resetAllFields={resetAllFields}
                        handleDeleteTool={handleDeleteTool}
                    />
                )
            case 2:
                return (
                    <EventInfo
                        values={values}
                        entityTypes={entityTypes}
                        currentEditingEventId={currentEditingEventId}
                        selectEntityView={selectEntityView}
                        control={control}
                        resetAllFields={resetAllFields}
                        handleDeleteTool={handleDeleteTool}
                        refreshPageData={refreshPageData}
                    />
                )
        }
    }

    return <Box className={classes.eventWrapper}>{curentViewComponent()}</Box>
}
