import React, { useState, useRef, useEffect } from "react"
import { Box, Button, CircularProgress } from "@mui/material"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useAuth0 } from "@auth0/auth0-react"
import { GenerateWithAiModal } from "../GenerateWithAiModal/GenerateWithAiModal"
import { handleShowToaster } from "../../../../../../../../../../redux/actions/showToasterActions"
import { createEmptyPlaybook } from "../../../../../../../../../../services/playbook/playbookServices"
import styles from "../../RepoMainUserPlaybooksListHeader.module.scss"

export function NewPlaybookButton({
    fetchUserPlaybooks,
    generateInputRef,
    handleOpenRepoAuthModal = null,
}) {
    const [showOptions, setShowOptions] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoadingEmptyPlaybook, setIsLoadingEmptyPlaybook] = useState(false)

    const optionsRef = useRef(null)
    const buttonRef = useRef(null)

    const { user } = useAuth0()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const handleNewPlaybookButtonClick = () => {
        setShowOptions(!showOptions)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const handleCreateEmptyTemplate = async () => {
        if (!user) {
            if (handleOpenRepoAuthModal) {
                setShowOptions(false)
                handleOpenRepoAuthModal()
                return
            } else {
                return
            }
        }
        setIsLoadingEmptyPlaybook(true)
        try {
            const uuid = await createEmptyPlaybook(dispatch, refreshAccessTokenCounter)
            if (uuid) {
                setTimeout(async () => {
                    await fetchUserPlaybooks()
                }, 2000)
                navigate(`/my-playbooks/${uuid}`, { replace: true })
                setShowOptions(false)
            }
        } catch (e) {
            console.log(e)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoadingEmptyPlaybook(false)
        }
    }

    const handleGenerateWithAiClick = () => {
        if (location.pathname === "/my-playbooks") {
            setIsModalOpen(true)
        } else if (generateInputRef && generateInputRef?.current) {
            setShowOptions(false)
            generateInputRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
            generateInputRef.current.focus()
        }
    }

    const handleClickOutside = (event) => {
        const clickedOutsideOptions = optionsRef.current?.contains(event.target)
        const clickedOutsideButton = buttonRef.current?.contains(event.target)

        if (!clickedOutsideOptions && !clickedOutsideButton) {
            setShowOptions(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)

        // Handle initial scroll if "generateAI" is present in search params
        if (
            generateInputRef &&
            generateInputRef.current &&
            location.search.includes("generateAI")
        ) {
            setTimeout(() => {
                // Ensure the element is still visible before scrolling
                const element = generateInputRef.current
                if (!element.getClientRects().length) {
                    return // Element is not visible, don't scroll
                }
                generateInputRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
                generateInputRef.current.focus()
            }, 500)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [generateInputRef, location.search])

    return (
        <Box position={"relative"}>
            <Button
                className={styles.repoMainUserPlaybooksListHeaderAddNewButton}
                onClick={handleNewPlaybookButtonClick}
                ref={buttonRef}
            >
                New Playbook
            </Button>
            {showOptions && (
                <Box ref={optionsRef} className={styles.repoMainUserPlaybooksListHeaderOptions}>
                    {isLoadingEmptyPlaybook ? (
                        <Box display="flex" justifyContent="center" width="100%">
                            <CircularProgress style={{ width: "20px", height: "20px" }} />
                        </Box>
                    ) : (
                        <>
                            <span
                                className={styles.repoMainUserPlaybooksListHeaderOptionsItem}
                                onClick={handleCreateEmptyTemplate}
                            >
                                <Box
                                    className={
                                        styles.repoMainUserPlaybooksListHeaderOptionsIconEmpty
                                    }
                                />
                                Empty Playbook
                            </span>
                            <span
                                className={styles.repoMainUserPlaybooksListHeaderOptionsItem}
                                onClick={handleGenerateWithAiClick}
                            >
                                <Box
                                    className={styles.repoMainUserPlaybooksListHeaderOptionsIconAi}
                                />
                                Generate with AI
                            </span>
                        </>
                    )}
                </Box>
            )}
            {isModalOpen && <GenerateWithAiModal open={isModalOpen} onClose={handleCloseModal} />}
        </Box>
    )
}
