import React, { useState, useEffect } from "react"
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import makeStyles from "@mui/styles/makeStyles"
import ToolsMenu from "./ToolsMenu/ToolsMenu"
import { Controller } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import LoadingButton from "@mui/lab/LoadingButton"
import { doToolAction, getMiddeskBusinessList } from "../../../services/notebook/notebookServices"
import { useAuth0 } from "@auth0/auth0-react"
import { Box, Grid, Typography } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import { RemoveDialog } from "../components/RemoveDialog/RemoveDialog"
import { changeLoaderState, changeLoaderMessage } from "../../../redux/actions/loaderActions"

const useStyles = makeStyles((theme) => ({
    input: {
        height: 42,
        backgroundColor: "#fff",
        marginBottom: "18px",
        [theme.breakpoints.up("sm")]: {
            marginBottom: "0",
        },
    },
}))

function MiddeskRetrieveBusiness({
    tool_id,
    index,
    index_x,
    index_y,
    handleAddTools,
    handleDeleteTool,
    control,
    values,
    setValue,
    title,
    inputTitle,
    buttonTitle,
    logo_url,
    logoHref,
    integrations,
    toolsConfig,
    subtitle,
}) {
    const classes = useStyles()
    const [removetoolDialogOpen, setRemovetoolDialogOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const { isAuthenticated, isLoading } = useAuth0()

    const getAPIResults = async () => {
        if (isAuthorized) {
            setIsSubmitting(true)
            const log = await doToolAction(
                values.case_id,
                values.tools[tool_id]?.id,
                values.tools[tool_id].type,
                values.tools[tool_id].log,
                dispatch,
                refreshAccessTokenCounter,
            )
            setValue(`tools.${tool_id}.log.response`, log["response"])
            setValue(`tools.${tool_id}.log.raw_response`, log["raw_response"])
            setValue(`tools.${tool_id}.show_response`, true)
            setIsSubmitting(false)
        }
    }

    const response = values.tools[tool_id].log.response

    useEffect(() => {
        if (isAuthorized) {
            doToolAction(
                values.case_id,
                values.tools[tool_id]?.id,
                // type 1 so it will change only the note and not trigger the API again!
                1,
                values.tools[tool_id].log,
                dispatch,
                refreshAccessTokenCounter,
            )
        }
    }, [response])

    useEffect(() => {
        ;(async () => {
            try {
                dispatch(changeLoaderState(true))
                dispatch(changeLoaderMessage("Retrieving Available Middesk Business List..."))
                const res = await getMiddeskBusinessList(dispatch, refreshAccessTokenCounter)
                setValue("middesk_account_businesses_list", res)
            } catch (e) {
                console.log(e.message)
            } finally {
                dispatch(changeLoaderState(false))
            }
        })()
    }, [])

    return (
        <Grid container direction="row">
            <Grid item xs={12} style={{ padding: "16px 0 0 16px" }}>
                <RemoveDialog
                    open={removetoolDialogOpen}
                    setClosed={() => setRemovetoolDialogOpen(false)}
                    handleAgree={() => {
                        handleDeleteTool(tool_id)
                        setRemovetoolDialogOpen(false)
                    }}
                />
                <div>
                    <ToolsMenu
                        handleAddTools={handleAddTools}
                        index_x={index_x}
                        index_y={index_y}
                        values={values}
                        integrations={integrations}
                        toolsConfig={toolsConfig}
                    />
                </div>
                <Box
                    sx={{
                        background: "#f3f3f3",
                        padding: "28px 23px",
                        borderRadius: "10px",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "space-between",
                            flexDirection: "column-reverse",
                            height: "100%",
                        }}
                    >
                        <Box
                            component="a"
                            sx={{
                                padding: "10px",
                                width: "100%",
                                maxWidth: "90px",
                                maxHeight: "15px",
                                height: "auto",
                                marginBottom: "5px",
                                marginRight: "11px",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                            href={logoHref}
                            target="_blank"
                        >
                            <Box
                                component="img"
                                sx={{
                                    // maxWidth: "90px",
                                    maxHeight: "21px",
                                }}
                                src={logo_url}
                                alt="logo"
                            ></Box>
                        </Box>
                        <div
                            style={{
                                padding: "20px 0px 0px 10px",
                                position: "relative",
                                display: "none",
                            }}
                        >
                            <ToolsMenu
                                handleAddTools={handleAddTools}
                                index_x={index_x}
                                index_y={index_y}
                                values={values}
                                integrations={integrations}
                                toolsConfig={toolsConfig}
                            />
                        </div>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                setRemovetoolDialogOpen(true)
                            }}
                            size="large"
                            sx={{
                                padding: "5px",
                            }}
                        >
                            <ClearIcon fontSize="medium" color="cblack" />
                        </IconButton>
                    </Box>
                    <Typography color="black" variant="h6" fontWeight="400" marginBottom="41px">
                        {title}
                    </Typography>
                    <Grid container alignItems="center" paddingBottom={{ xs: "18px", sm: "0" }}>
                        <Typography
                            component="label"
                            variant="body1"
                            color="#3E3E3E"
                            marginRight="18px"
                            marginBottom={{ xs: "18px", sm: "0" }}
                        >
                            {inputTitle}
                        </Typography>
                        <Grid item xs={12} sm={4} marginRight={{ xs: "0", sm: "36px" }}>
                            <Controller
                                name={`tools.${tool_id}.log.request`}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        select
                                        {...field}
                                        variant="outlined"
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    >
                                        {values.middesk_account_businesses_list &&
                                            values.middesk_account_businesses_list.map(
                                                (business) => {
                                                    return (
                                                        <MenuItem
                                                            key={business?.id}
                                                            value={business?.id}
                                                        >
                                                            {`${business.name} (${business?.id})`}
                                                        </MenuItem>
                                                    )
                                                },
                                            )}
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <LoadingButton
                            variant="contained"
                            size="large"
                            sx={{
                                fontWeight: "700",
                                fontSize: "14px",
                                width: { xs: "100%", sm: "auto" },
                                background: "linear-gradient(45deg, #E02B38 0%, #FEA993 100%)",
                            }}
                            onClick={getAPIResults}
                            loading={isSubmitting}
                            loadingIndicator="Loading..."
                        >
                            {buttonTitle}
                        </LoadingButton>
                    </Grid>
                </Box>
            </Grid>
            {values.tools[tool_id]["show_response"] && (
                <Grid item xs={12}>
                    <Box
                        sx={{
                            // background: "#f3f3f3",
                            padding: "50px 50px 50px 66px",
                            // borderRadius: "10px",
                            position: "relative",
                        }}
                    >
                        <Grid>
                            <Box
                                component="a"
                                sx={{
                                    width: "100%",
                                    maxWidth: "90px",
                                    maxHeight: "15px",
                                    height: "auto",
                                    display: "inline",
                                    marginRight: "10px",
                                }}
                                href={logoHref}
                                target="_blank"
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        // maxWidth: "90px",
                                        maxHeight: "21px",
                                    }}
                                    src={logo_url}
                                    alt="logo"
                                ></Box>
                            </Box>

                            <Typography
                                color="black"
                                variant="h6"
                                fontWeight="400"
                                style={{ display: "inline" }}
                            >
                                - {title}
                            </Typography>
                        </Grid>

                        <div
                            style={{
                                background: "#f3f3f3",
                                padding: "28px 23px",
                                borderRadius: "10px",
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: "5px",
                                    padding: "10px",
                                    backgroundColor: "#f3f3f3",
                                }}
                            >
                                <Typography>{values.tools[tool_id].log.response}</Typography>
                            </div>

                            {/* <div>
                                <Accordion
                                    style={{
                                        backgroundColor: "#f3f3f3",
                                        borderTop: "1px solid #d1d1d1",
                                        boxShadow: "none",
                                        borderRadius: "0",
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Raw Response</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <Typography>
                                                {
                                                    values.tools[tool_id].log
                                                        .raw_response
                                                }
                                            </Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div> */}
                        </div>
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}

export default MiddeskRetrieveBusiness
