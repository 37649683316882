import React, { useEffect, useState } from "react"
import { Dialog, DialogContent, DialogTitle, Box, Button, CircularProgress } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { TemplateInfo } from "./components/TemplateInfo/TemplateInfo"
import { useParams, useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { TemplateDescription } from "./components/TemplateDescription/TemplateDescription"
import { setPlaybookTemplateState } from "../../../../../../redux/actions/playbookTemplateActions"
import { useIsMobile } from "../../../../../../hooks/standart/useIsMobile"
import {
    getToolsById,
    getPlaybookToolsConfig,
    postCopyPlaybookToUser,
} from "../../../../../../services/playbook/playbookServices"
import { useRepoTemplateData } from "../../../../../../hooks/repo/useRepoTemplateData"
import { useFeedback } from "../../../../../../hooks/repo/useFeedback"
import FFA_LOGO_SMALL from "../../../../../../assets/icons/Repo/FFA-small.svg"
import redstringsLogo from "../../../../../../assets/icons/Repo/redstings-logo.svg"
import redstingsSmallLogo from "../../../../../../assets/icons/Repo/redstringsSmallLogo.svg"
import rightArrow from "../../../../../../assets/icons/Repo/right-arrow.svg"
import plusIcon from "../../../../../../assets/icons/plusIconWhite.svg"
import { useForm } from "react-hook-form"
import { repoTemplateInitialValues } from "../../../../../../constants/initialValues"
import { repoElementValidationSchema } from "../../../../../../utils/validators/repoElement"
import { yupResolver } from "@hookform/resolvers/yup"
import { CALENDLY_LINK } from "../../../../../../constants/appConstants"
import { useAuth0 } from "@auth0/auth0-react"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import styles from "./RepoElement.module.scss"
import { isRedstringsUser } from "../../../../../../helpers/standardHelpers"

export function RepoElement({ playbook = false, isOpen, setOpen, fetchUserPlaybooks }) {
    const { user } = useAuth0()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { templateId } = useParams()

    const isAuthorized = useSelector((state) => state.isAuthorized)
    const menuConfig = useSelector((state) => state.menuConfig.value)
    const userId = useSelector((state) => state.userState.user.id)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const [templateData, setTemplateData, loadingTemplateData] = useRepoTemplateData(templateId)
    const { likes, dislikes, loadingFeedback } = useFeedback(templateId)

    const [isAllowToEdit, setIsAllowToEdit] = useState(false)
    const [localOpen, setLocalOpen] = useState(playbook ? playbook : true)
    const [selectedStepIndex, setSelectedStepIndex] = useState(isMobile ? null : 0)
    const [toolsIdArray, setToolsIdArray] = useState([])
    const [toolsConfig, setToolsConfig] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [toolsDim, setToolsDim] = useState()
    const [isLoadingAddingToRepo, setIsLoadingAddingToRepo] = useState(false)

    const open = isOpen !== undefined ? isOpen : localOpen
    const actualSetOpen = setOpen || setLocalOpen

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue,
        reset,
    } = useForm({
        defaultValues: repoTemplateInitialValues,
        resolver: yupResolver(repoElementValidationSchema),
    })
    const values = watch()

    useEffect(() => {
        if (templateData && userId && templateData?.user_id === userId) {
            setIsAllowToEdit(true)
        } else {
            setIsAllowToEdit(false)
        }
    }, [templateData, userId])

    useEffect(() => {
        if (templateData && templateId) {
            reset(templateData)
        }
    }, [templateData, templateId])

    // Function to reset values when templateId null
    useEffect(() => {
        if (!templateId) {
            reset(repoTemplateInitialValues)
            setTemplateData(null)
        }
    }, [templateId])

    useEffect(() => {
        handleGetToolsById(toolsIdArray)
    }, [toolsIdArray])

    useEffect(() => {
        if (!values?.tools?.ordered || values.tools.ordered.length === 0) {
            return
        }

        const newToolsIdArray = values.tools.ordered
            .flatMap((item) => item?.children?.map((child) => child.tool_type_id) ?? [])
            .filter((id) => id !== null)

        if (
            newToolsIdArray.length !== toolsIdArray.length ||
            newToolsIdArray.some((id, index) => id !== toolsIdArray[index])
        ) {
            setToolsIdArray(newToolsIdArray)
        }
    }, [values?.tools?.ordered, values])

    useEffect(() => {
        if (isAuthorized) {
            ;(async () => {
                try {
                    const toolsConfigResults = await getPlaybookToolsConfig(
                        dispatch,
                        refreshAccessTokenCounter,
                    )
                    setToolsConfig(toolsConfigResults)
                } catch (e) {
                    console.log(e.message)
                }
            })()
        }
    }, [isAuthorized])

    const handleSelectStep = (index) => {
        setSelectedStepIndex(index)
    }

    const handleGetToolsById = async (array) => {
        if (array?.length > 0) {
            try {
                const tools = await getToolsById(array, dispatch, refreshAccessTokenCounter)
                setToolsDim(tools)
            } catch (error) {
                console.log(error)
                navigate("/404")
            }
        }
    }

    const handleDialogClose = () => {
        actualSetOpen(false)
        setEditMode(false)
        reset()
        const currentPath = window.location.pathname
        const newPath = currentPath.includes("/my-playbooks") ? "/my-playbooks" : "/home"
        navigate(newPath, { replace: true })
    }

    const handleTryIt = () => {
        if (templateId) {
            dispatch(setPlaybookTemplateState(templateId))
            navigate("/", { replace: true })
        }
    }

    const handleCopyToMyRepo = async () => {
        if (templateId) {
            try {
                setIsLoadingAddingToRepo(true)
                await postCopyPlaybookToUser(templateId, dispatch, refreshAccessTokenCounter)
                await fetchUserPlaybooks()
                dispatch(
                    handleShowToaster("success", "Playbook successfully added to your collection"),
                )
            } catch (error) {
                console.log(error)
                dispatch(handleShowToaster("error", "Action failed"))
            } finally {
                setIsLoadingAddingToRepo(false)
            }
        }
    }

    const MobileContent = (
        <>
            {values && (
                <>
                    <TemplateInfo
                        templateData={values}
                        setValue={setValue}
                        control={control}
                        handleSelectStep={handleSelectStep}
                        selectedStepIndex={selectedStepIndex}
                        playbook={playbook}
                        defaultLikes={likes}
                        defaultDislikes={dislikes}
                        reset={reset}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        setEditMode={setEditMode}
                        editMode={editMode}
                        isAllowToEdit={false}
                        toolsDim={toolsDim}
                        fetchUserPlaybooks={fetchUserPlaybooks}
                    />
                </>
            )}
        </>
    )

    const DesktopContent = (
        <>
            {values && (
                <>
                    <TemplateInfo
                        templateData={values}
                        setValue={setValue}
                        control={control}
                        handleSelectStep={handleSelectStep}
                        selectedStepIndex={selectedStepIndex}
                        playbook={playbook}
                        defaultLikes={likes}
                        defaultDislikes={dislikes}
                        reset={reset}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        setEditMode={setEditMode}
                        editMode={editMode}
                        isAllowToEdit={isAllowToEdit}
                        setToolsIdArray={setToolsIdArray}
                        oolsIdArray={toolsIdArray}
                        toolsConfig={toolsConfig}
                        fetchUserPlaybooks={fetchUserPlaybooks}
                    />
                    <TemplateDescription
                        toolsDim={toolsDim}
                        templateData={values}
                        selectedStepData={values?.tools?.ordered[selectedStepIndex]}
                        selectedStepChildren={values?.tools?.ordered[selectedStepIndex]?.children}
                        selectedStepIndex={selectedStepIndex}
                        setValue={setValue}
                        setToolsIdArray={setToolsIdArray}
                        toolsIdArray={toolsIdArray}
                        toolsConfig={toolsConfig}
                        setEditMode={setEditMode}
                        editMode={editMode}
                        isAllowToEdit={isAllowToEdit}
                        errors={errors}
                    />
                </>
            )}
        </>
    )
    // TODO - Fix problem with toolsDim
    if (loadingFeedback || loadingTemplateData || !templateData) {
        return null
    }

    return (
        <div>
            <Dialog
                open={open}
                className={styles.repoElementDialog}
                PaperProps={{ className: styles.repoElementDialogContaier }}
            >
                <DialogTitle className={styles.repoElementDialogTitle}>
                    <Box className={styles.repoElementDialogTitleLogo}>
                        <span>
                            <img src={FFA_LOGO_SMALL} />
                            Fraud Fighters Alliance
                        </span>
                        {playbook && isMobile ? (
                            <Box
                                onClick={handleDialogClose}
                                className={styles.repoElementDialogTitleCloseIcon}
                            >
                                <CloseIcon />
                            </Box>
                        ) : (
                            <Box className={styles.repoElementDialogTitleIcon}>
                                <span>Powered by </span>
                                <Link
                                    to={"https://www.redstrings.io/"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.repoElementDialogTitleIconLink}
                                >
                                    <img src={redstringsLogo} alt="Red Strings Logo" />
                                </Link>
                            </Box>
                        )}
                    </Box>
                    {playbook && !isMobile && (
                        <Box className={styles.repoElementDialogTitleActions}>
                            {isRedstringsUser(user) && (
                                <Button
                                    onClick={handleTryIt}
                                    className={styles.repoElementDialogTitleIconTry}
                                >
                                    Try It
                                    <img src={rightArrow} alt="arrow" />
                                </Button>
                            )}
                            {!isAllowToEdit && (
                                <Button
                                    onClick={handleCopyToMyRepo}
                                    className={styles.repoElementDialogTitleIconAddToMyRepo}
                                    disabled={isLoadingAddingToRepo}
                                >
                                    {isLoadingAddingToRepo ? (
                                        <CircularProgress
                                            style={{ width: "20px", height: "20px" }}
                                        />
                                    ) : (
                                        <Box display="flex" alignItems="center" gap="5px">
                                            Add To My Repo
                                            <img src={plusIcon} alt="plus" />
                                        </Box>
                                    )}
                                </Button>
                            )}
                            <Link
                                to={CALENDLY_LINK}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.link}
                            >
                                <Button className={styles.repoElementDialogTitleDemo}>
                                    <img src={redstingsSmallLogo} />
                                    Schedule a Demo
                                </Button>
                            </Link>
                            <Box
                                onClick={handleDialogClose}
                                className={styles.repoElementDialogTitleCloseIcon}
                            >
                                <CloseIcon />
                            </Box>
                        </Box>
                    )}
                </DialogTitle>
                <DialogContent className={styles.repoElementDialogContent}>
                    {isMobile ? MobileContent : DesktopContent}
                </DialogContent>
            </Dialog>
        </div>
    )
}
