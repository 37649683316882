import React from "react"
import ToolsMenu from "./ToolsMenu/ToolsMenu"
import { Box, Grid, Typography } from "@mui/material"
import { DotsMenu } from "./DotsMenu"

function EasterEgg({
    tool_id,
    index,
    index_x,
    index_y,
    handleAddTools,
    handleDeleteTool,
    values,
    integrations,
    toolsConfig,
    logo,
    title,
    subtitle,
}) {
    return (
        <Grid item xs={12} style={{ padding: "16px 0 0 16px", position: "relative" }}>
            <DotsMenu
                handleAddTools={handleAddTools}
                index_x={index_x}
                index_y={index_y}
                values={values}
                integrations={integrations}
                toolsConfig={toolsConfig}
                handleDeleteTool={handleDeleteTool}
                tool_id={tool_id}
            />
            <Box
                sx={{
                    background: "#f3f3f3",
                    padding: "28px 23px",
                    borderRadius: "10px",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                        flexDirection: "column-reverse",
                        height: "100%",
                    }}
                >
                    <Box
                        component="a"
                        sx={{
                            padding: "10px",
                            width: "100%",
                            maxWidth: "90px",
                            maxHeight: "15px",
                            height: "auto",
                            marginBottom: "5px",
                            marginRight: "11px",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                        target="_blank"
                    >
                        <Box
                            component="img"
                            sx={{
                                // maxWidth: "90px",
                                maxHeight: "21px",
                            }}
                            src={logo}
                            alt="logo"
                        ></Box>
                    </Box>
                </Box>
                <Typography color="black" variant="h6" fontWeight="400" marginBottom="41px">
                    {title}
                </Typography>
                <img
                    style={{
                        width: "200px",
                    }}
                    src="https://i0.wp.com/media2.giphy.com/media/c4rB7DMXKgktG/giphy.gif?resize=640%2C640&ssl=1&crop=1"
                />

                <div
                    style={{
                        padding: "20px 0px 0px 10px",
                        position: "relative",
                        display: "none",
                    }}
                >
                    <ToolsMenu
                        handleAddTools={handleAddTools}
                        index={index + 1}
                        values={values}
                        integrations={integrations}
                        toolsConfig={toolsConfig}
                    />
                </div>
            </Box>
        </Grid>
    )
}

export default EasterEgg
