import React from "react"
import {
    Box,
    Typography,
    MenuItem,
    CircularProgress,
    Toolbar,
    Button,
    TextField,
    FormControl,
} from "@mui/material"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useDispatch } from "react-redux"
import { applySuccessLoader } from "../../../redux/actions/successActions"
import { Controller } from "react-hook-form"
import styles from "../Topbar.module.scss"

export function NotebookHeader({
    classes,
    sidebarRef,
    mock,
    caseId,
    control,
    caseTitle,
    handleOpenCalendly,
    handleOpenPlaybook,
    steps,
    pendingStepsLength,
    isDataLoading,
    updateCaseInfoMap,
    currentResolutions,
    sidebarState,
    changeSidebarState,
    setValue,
    caseStatuses,
    isFromToolbox,
}) {
    const dispatch = useDispatch()

    const changeSidebarStateHandler = () => {
        dispatch(changeSidebarState(!sidebarState))
    }

    const handleChange = async (value) => {
        setValue("status", value)
        updateCaseInfoMap("status", value)
        updateCaseInfoMap("resolution", "")
    }

    const handleDecline = async () => {
        dispatch(applySuccessLoader())
        await updateCaseInfoMap("status", "Closed")
        await updateCaseInfoMap("resolution", currentResolutions.bad)
    }

    const handleApprove = async () => {
        dispatch(applySuccessLoader())
        await updateCaseInfoMap("status", "Closed")
        await updateCaseInfoMap("resolution", currentResolutions.good)
    }

    return (
        <Toolbar className={styles.topBarToolBar}>
            <Box
                container
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                padding="0 25px"
                width="100%"
                ref={sidebarRef}
            >
                <Box item display="flex" alignItems="center" justifyContent="flex-start" flex="1">
                    <Box width="130px" marginRight="30px">
                        <a className={styles.topBarNotebookLinkBack} href="/">
                            <KeyboardBackspaceIcon
                                sx={{
                                    color: "#374957",
                                    ":hover": {
                                        color: "#587286",
                                    },
                                }}
                            />
                            Back to cases
                        </a>
                    </Box>
                </Box>
                {!mock && !isFromToolbox && (
                    <Box display="flex" flex="2" alignItems="center" justifyContent="center">
                        <Box display="flex" direction="row" alignItems="center">
                            <Typography
                                style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                }}
                            >
                                Case #{caseId}
                            </Typography>
                            <Controller
                                name="title"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                    <Typography
                                        {...field}
                                        id="title"
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            marginLeft: "10px",
                                        }}
                                    >
                                        {caseTitle}
                                    </Typography>
                                )}
                            />
                        </Box>
                    </Box>
                )}
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ marginTop: 0 }}
                    flex="1"
                >
                    {mock ? (
                        <Box className={styles.topBarNotebookButtonsWrapper}>
                            <Button
                                onClick={handleOpenPlaybook}
                                className={styles.topBarNotebookRequestButton}
                            >
                                Save as Playbook
                            </Button>
                            <Button
                                onClick={handleOpenCalendly}
                                className={styles.topBarNotebookRequestButton}
                            >
                                Request a Demo
                            </Button>
                        </Box>
                    ) : (
                        <>
                            {!isFromToolbox && (
                                <Box display="flex" alignItems="center" gap="12px">
                                    {steps?.length > 0 && (
                                        <Box className={styles.topBarStepsStatus}>
                                            {pendingStepsLength}{" "}
                                            {pendingStepsLength !== 1
                                                ? "Pending Steps"
                                                : "Pending Step"}
                                        </Box>
                                    )}

                                    <Box width="95px">
                                        <Typography fontSize="16px" color="#374957">
                                            Case status:
                                        </Typography>
                                    </Box>
                                    {isDataLoading ? (
                                        <Box className={styles.topBarLoaderWrapper}>
                                            <CircularProgress
                                                style={{ width: "25px", height: "25px" }}
                                            />
                                        </Box>
                                    ) : (
                                        <FormControl sx={{ m: 1 }} variant="standard">
                                            <Controller
                                                name="menu_only_case_status"
                                                defaultValue=""
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        select
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e)
                                                            if (e.target.value === "Decline") {
                                                                handleDecline()
                                                            } else if (
                                                                e.target.value === "Approve"
                                                            ) {
                                                                handleApprove()
                                                            } else {
                                                                handleChange(e.target.value)
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className={classes.customInput}
                                                        fullWidth
                                                    >
                                                        {caseStatuses.map((actionTypeOption) => {
                                                            return (
                                                                <MenuItem
                                                                    key={actionTypeOption.value}
                                                                    value={actionTypeOption.title}
                                                                >
                                                                    {actionTypeOption.title}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </TextField>
                                                )}
                                            />
                                        </FormControl>
                                    )}
                                </Box>
                            )}
                        </>
                    )}
                    {!isFromToolbox && (
                        <Box
                            item
                            style={{
                                padding: "0",
                            }}
                        >
                            <Button
                                onClick={changeSidebarStateHandler}
                                autoFocus
                                disableRipple
                                style={{
                                    fontSize: "1.5em",
                                    textTransform: "none",
                                    padding: "0",
                                    flexDirection: "column",
                                }}
                                sx={{
                                    color: "#3e3e3e",
                                    "&:hover": {
                                        color: "#d9d9d9",
                                    },
                                }}
                            >
                                <MoreVertIcon
                                    style={{
                                        fontSize: "1.5em",
                                    }}
                                />
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Toolbar>
    )
}
