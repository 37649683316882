import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch } from "react-redux"
import { handleShowToaster } from "../../../redux/actions/showToasterActions"
import LoadingButton from "@mui/lab/LoadingButton"
import { Box, Typography, Divider, TextField, MenuItem } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { Controller } from "react-hook-form"
import pencilIcon from "../../../assets/icons/pencil.svg"
import moment from "moment/moment"
import { TagsInput } from "react-tag-input-component"

const useStyles = makeStyles(() => ({
    caseDetailsWrapper: {
        backgroundColor: "#FFFFFF",
        width: "300px",
        height: "100%",
        borderRight: "1px solid #EFEEF1",
        overflowY: "auto",
    },
    editButton: {
        border: "1px solid #000000",
        borderRadius: "38px",
        textTransform: "none",
        color: "#000000",
        width: "55px",
        height: "32px",
        fontWeight: "400",
        "&:hover": {
            color: "#374957",
        },
    },
    btnWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px",
        position: "fixed",
        bottom: "0",
        height: "70px",
        width: "268px",
        borderTop: "1px solid #EFEEF1",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        zIndex: 2,
    },
    saveButton: {
        borderRadius: "38px",
        textTransform: "none",
        backgroundColor: "#000000",
        color: "#fff",
        fontWeight: "400",
        height: "32px",
        "&:hover": {
            backgroundColor: "#282727",
        },
    },
    cancelButton: {
        borderRadius: "38px",
        textTransform: "none",
        backgroundColor: "#EDEEF8",
        color: "#000000",
        fontWeight: "400",
        height: "32px",
    },
    caseBox: {
        display: "flex",
        position: "relative",
        flexDirection: "column",
        gap: "10px",
        "& .rti--container": {
            padding: "16px 14px",
            borderRadius: 10,
            width: "100%",
            minHeight: "56px",
        },
    },
    caseInput: {
        border: "none",
        fontSize: "16px",
        fontWeight: 500,
    },
    stepDot: {
        width: "16px",
        height: "16px",
        borderRadius: "100%",
        zIndex: "10",
    },
    stepLine: {
        width: "44px",
        height: "2px",
        backgroundColor: "#E1E1E1",
    },
    stepper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "200px",
        position: "relative",
    },
    stepProgress: {
        backgroundColor: "#E1E1E1",
        position: "absolute",
        top: "50%",
        left: "0",
        transform: "translateY(-50%)",
        height: "2px",
        width: "100%",
        zIndex: "0",
        transition: ".4s ease",
    },
    input: {
        borderRadius: 10,
    },
    tagsInput: {
        fontWeight: "400",
        fontSize: "14px",
        color: "#6B869A",
        backgroundColor: "rgba(94, 74, 253, 0.05)",
        border: "1px solid rgba(94, 74, 253, 0.1)",
        borderRadius: "4px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    tagsInputEmpty: {
        width: "100%",
    },
    tagInputLabel: {
        position: "absolute",
        left: "15px",
        top: "-8px",
        width: "30px",
        height: "16px",
        zIndex: "2",
        backgroundColor: "#fff",
        fontSize: "12px",
        color: "#6B869A",
    },
    addToolBtn: {
        backgroundColor: "#EDEEF8",
        border: "2px dashed #FFFFFF",
        borderRadius: "10px",
        width: "220px",
        height: "45px",
        margin: "0px auto",
        display: "flex",
        justifyContent: "center",
    },
}))

export function CaseInformation({
    control,
    values,
    setValue,
    caseTypes,
    mainPageVersion,
    updateCaseInfoMap,
    resetAllFields,
    refreshPageData,
}) {
    const classes = useStyles()
    const location = useLocation()
    const dispatch = useDispatch()
    const caseId = parseInt(location?.pathname?.replace("/", ""))
    const [edit, setEdit] = useState(false)
    const [remainingTimeSla, setRemainingTimeSla] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const urgencyOptions = ["Low", "Medium", "High"]

    const toogleEdit = () => {
        setEdit(!edit)
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            values.title && (await updateCaseInfoMap("title", values.title))
            values.type && (await updateCaseInfoMap("type", values.type))
            values.description && (await updateCaseInfoMap("description", values.description))
            values.urgency && (await updateCaseInfoMap("urgency", values.urgency))
            await updateCaseInfoMap("tags", values.tags || [])
            await refreshPageData()
            setEdit(false)
            dispatch(handleShowToaster("success", "Successfully save case details"))
        } catch (error) {
            console.log(error)
            setEdit(false)
            resetAllFields()
            dispatch(handleShowToaster("error", "Action failed"))
        }
        setIsLoading(false)
    }

    const handleCancel = async () => {
        resetAllFields()
        setEdit(false)
    }

    useEffect(() => {
        const updateRemainingTime = () => {
            if (values.sla_days && values.created_at) {
                const currentDate = moment().add(1, "hours")
                const reportedDate = moment(values.created_at)
                    .add(values.sla_days, "days")
                    .subtract(2, "hours")
                const duration = moment.duration(reportedDate.diff(currentDate))
                const days = Math.floor(duration.asDays())
                const hours = duration.hours()
                const minutes = duration.minutes()
                const remainingTime = `${days}d ${hours}h ${minutes}m`
                setRemainingTimeSla(remainingTime)
            }
        }

        const interval = setInterval(updateRemainingTime, 1000)

        updateRemainingTime()

        return () => {
            clearInterval(interval)
        }
    }, [values.sla_days])

    const [templates, setTemplates] = useState([{ id: -1, name: "None" }])
    const [chosenTemplate, setChosenTemplate] = useState({})

    useEffect(() => {
        if (mainPageVersion) {
            setValue("template_id", null)
            setValue("template_fields", null)
            setChosenTemplate(null)
            let chosenCaseType = caseTypes.filter((obj) => {
                return obj?.id === values.type
            })[0]
            setTemplates(
                chosenCaseType?.templates
                    ? [{ id: -1, name: "None" }].concat(chosenCaseType.templates)
                    : [{ id: -1, name: "None" }],
            )
        }
    }, [values.type])

    useEffect(() => {
        if (mainPageVersion && values?.template_id) {
            let currChosenTemplate = templates.filter((obj) => {
                return obj?.id === values.template_id
            })[0]
            setValue("template_fields", null)
            setChosenTemplate(currChosenTemplate)
        }
    }, [values.template_id])

    return (
        <Box className={classes.caseDetailsWrapper}>
            <Box padding="20px" display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <Typography fontSize="20px" fontWeight="500" color="#000000" flexBasis="100%">
                        {edit ? "Edit Case Details" : "Case Details"}
                    </Typography>
                    <Typography fontSize="13px" color="#6B869A">
                        Case ID: #{caseId}
                    </Typography>
                </Box>
                {!edit && (
                    <Box display="flex" alignItems="center" gap="18px">
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={toogleEdit}
                            src={pencilIcon}
                        ></img>
                    </Box>
                )}
            </Box>
            <Divider />
            <Box
                padding="16px"
                paddingBottom="80px"
                display="flex"
                flexDirection="column"
                gap={"20px"}
            >
                <Box className={classes.caseBox}>
                    {!edit && (
                        <>
                            <Typography fontSize="14px" color="#374957">
                                Name
                            </Typography>
                        </>
                    )}
                    <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                            <>
                                {!edit ? (
                                    <Typography fontSize="16px">{field.value}</Typography>
                                ) : (
                                    <TextField
                                        InputLabelProps={{
                                            style: {
                                                color: "#6B869A",
                                            },
                                        }}
                                        disabled={isLoading}
                                        {...field}
                                        id="title"
                                        label={"Name"}
                                        placeholder="Enter you case name"
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                    />
                                )}
                            </>
                        )}
                    />
                </Box>
                <Box className={classes.caseBox}>
                    {!edit ? (
                        <>
                            <Typography fontSize="14px" color="#374957">
                                Tags
                            </Typography>
                            <Typography fontSize="16px">
                                {values?.tags?.join(", ") || "No tags"}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography className={classes.tagInputLabel}>Tags</Typography>
                            <Controller
                                name="tags"
                                control={control}
                                render={({ field }) => (
                                    <TagsInput
                                        {...field}
                                        placeHolder="Write a tag and press Enter"
                                        classNames={{
                                            tag: classes.tagsInput,
                                            input: classes.tagsInputEmpty,
                                        }}
                                        maxTags={10}
                                    />
                                )}
                            />
                        </>
                    )}
                </Box>
                <Box className={classes.caseBox}>
                    {!edit && (
                        <Typography fontSize="14px" color="#374957">
                            Type
                        </Typography>
                    )}
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <>
                                {!edit ? (
                                    <Typography fontSize="16px">
                                        {caseTypes.find((elem) => elem?.id === field?.value)?.name}
                                    </Typography>
                                ) : (
                                    <TextField
                                        select
                                        {...field}
                                        disabled={isLoading}
                                        variant="outlined"
                                        label="Type"
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                color: "#6B869A",
                                            },
                                        }}
                                        fullWidth
                                    >
                                        {caseTypes.map((caseType) => {
                                            return (
                                                <MenuItem key={caseType?.id} value={caseType?.id}>
                                                    {caseType.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>
                                )}
                            </>
                        )}
                    />
                </Box>
                {templates && templates.length > 1 && (
                    <Box className={classes.caseBox}>
                        <Typography fontSize="14px" color="#374957">
                            Template
                        </Typography>
                        <Controller
                            name="template_id"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    select
                                    {...field}
                                    disabled={isLoading}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            color: "#6B869A",
                                        },
                                    }}
                                    fullWidth
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                >
                                    {templates.map((template) => {
                                        return (
                                            <MenuItem key={template?.id} value={template?.id}>
                                                {template.name}
                                            </MenuItem>
                                        )
                                    })}
                                </TextField>
                            )}
                        />
                    </Box>
                )}
                {values?.salesforce_case_info && (
                    <Box className={classes.caseBox} marginLeft={edit && "5px"}>
                        {Object.keys(values?.salesforce_case_info).length > 0 && (
                            <>
                                <Typography fontSize="14px" color={edit ? "#6B869A" : "#374957"}>
                                    SF Case Number
                                </Typography>
                                <a
                                    href={`${values?.salesforce_case_info?.CaseLink}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Typography
                                        component="label"
                                        variant="outlined"
                                        color="#2364AA"
                                        style={{
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        }}
                                        marginRight="0px"
                                        marginBottom={{
                                            xs: "18px",
                                            sm: "0",
                                        }}
                                    >
                                        {values?.salesforce_case_info?.CaseNumber}
                                    </Typography>
                                </a>
                            </>
                        )}
                    </Box>
                )}

                <Box className={classes.caseBox}>
                    {!edit && (
                        <Typography fontSize="14px" color="#374957">
                            Description:
                        </Typography>
                    )}
                    <Controller
                        name="description"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <>
                                {!edit ? (
                                    <Typography sx={{ whiteSpace: "pre-line" }} fontSize="16px">
                                        {field.value}
                                    </Typography>
                                ) : (
                                    <TextField
                                        {...field}
                                        multiline
                                        disabled={isLoading}
                                        rows={5}
                                        variant="outlined"
                                        label="Description"
                                        id="description"
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                color: "#6B869A",
                                            },
                                        }}
                                        fullWidth
                                    />
                                )}
                            </>
                        )}
                    />
                </Box>
                <Box className={classes.caseBox}>
                    <Typography fontSize="14px" color={edit ? "#6B869A" : "#374957"}>
                        Assignee
                    </Typography>
                    <Typography>{values.reported_by}</Typography>
                </Box>
                <Box className={classes.caseBox}>
                    <Typography fontSize="14px" color={edit ? "#6B869A" : "#374957"}>
                        Created at
                    </Typography>
                    <Typography>{moment(values?.created_at)?.format("DD/MM/YY HH:mm")}</Typography>
                </Box>
                <Box className={classes.caseBox}>
                    {!edit && (
                        <>
                            <Typography fontSize="14px" color="#374957">
                                Urgency:
                            </Typography>
                            <Typography>{values.urgency}</Typography>
                        </>
                    )}
                    <Controller
                        name="urgency"
                        control={control}
                        render={({ field }) => (
                            <>
                                {!edit ? (
                                    <Typography fontSize="16px">
                                        {
                                            caseTypes.find((elem) => elem?.id === field?.value)
                                                ?.urgency
                                        }
                                    </Typography>
                                ) : (
                                    <TextField
                                        select
                                        {...field}
                                        disabled={isLoading}
                                        variant="outlined"
                                        label="Urgency"
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                color: "#6B869A",
                                            },
                                        }}
                                        fullWidth
                                    >
                                        {urgencyOptions.map((option) => {
                                            return (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>
                                )}
                            </>
                        )}
                    />
                </Box>

                {values.sla_days && (
                    <>
                        <Box className={classes.caseBox}>
                            <Typography fontSize="14px" color={edit ? "#6B869A" : "#374957"}>
                                SLA
                            </Typography>
                            <Typography
                                component="label"
                                variant="body1"
                                color="#3E3E3E"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {values?.sla_days} days
                            </Typography>
                        </Box>
                        <Box className={classes.caseBox}>
                            <Typography fontSize="14px" color={edit ? "#6B869A" : "#374957"}>
                                Remaining
                            </Typography>
                            <Typography color="#3E3E3E">{remainingTimeSla}</Typography>
                        </Box>
                    </>
                )}
                {edit && (
                    <Box className={classes.btnWrapper}>
                        <LoadingButton
                            loadingIndicator={
                                <CircularProgress style={{ width: "15px", height: "15px" }} />
                            }
                            loading={isLoading}
                            onClick={handleCancel}
                            className={classes.cancelButton}
                            variant="outlined"
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            loadingIndicator={
                                <CircularProgress style={{ width: "15px", height: "15px" }} />
                            }
                            loading={isLoading}
                            onClick={handleSave}
                            className={classes.saveButton}
                            variant="outlined"
                        >
                            Save
                        </LoadingButton>
                    </Box>
                )}
            </Box>
        </Box>
    )
}
