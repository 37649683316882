import { CHANGE_SIDEBAR_CHOICE } from "../actions/types"

const initialState = "/"

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_SIDEBAR_CHOICE:
            return action.payload
        default:
            return state
    }
}
