import React, { useEffect, useState } from "react"
import {
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
} from "@mui/material"
import { useSelector } from "react-redux"
import { MAIN_TABLE_CELLS, MAIN_TABLE_CELLS_DONE } from "../../../../constants/appConstants"
import CaseTableRow from "./components/CaseTableRow/CaseTableRow"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import styles from "./CasesTable.module.scss"

const CasesTable = ({
    values,
    control,
    updateCaseInfoMap,
    urgencyOptions,
    cases,
    headerTitle,
    done = false,
    controllerName,
    loadMainPageData,
}) => {
    const [cells, setCells] = useState(MAIN_TABLE_CELLS)
    const [currentPage, setCurrentPage] = useState(1)
    const [showIcons, setShowIcons] = useState({})

    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)

    useEffect(() => {
        if (done) setCells(MAIN_TABLE_CELLS_DONE)
    }, [cases, done])

    const itemsPerPage = 5
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    const handleMouseEnter = (index) => {
        setShowIcons({ ...showIcons, [index]: true })
    }

    const handleMouseLeave = (index) => {
        setShowIcons({ ...showIcons, [index]: false })
    }

    return (
        <Box className={styles.CasesTableContainer}>
            <Box className={styles.CasesTableHeader}>
                <Typography className={styles.CasesTableHeaderStatus}>{headerTitle}</Typography>
                <Typography className={styles.CasesTableHeaderQuantity}>
                    {cases?.length} {cases?.length === 1 ? "Case" : "Cases"}
                </Typography>
            </Box>
            <TableContainer className={styles.CasesTableMainContainer}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {cells.map((cell, index) => (
                                <TableCell
                                    style={{ width: cell?.width }}
                                    align={cell?.align}
                                    key={`${cell?.name}${index}`}
                                    className={styles.CasesTableMainTitleCell}
                                >
                                    <Typography
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={() => handleMouseLeave(index)}
                                        window="100px"
                                        fontSize="16px"
                                        fontWeight="500"
                                    >
                                        {cell?.name}
                                        {showIcons[index] && currentCompanyId === 5 && (
                                            <>
                                                <ArrowUpwardIcon
                                                    sx={{ width: "15px", height: "15px" }}
                                                />
                                                <MoreVertIcon
                                                    sx={{ width: "15px", height: "15px" }}
                                                />
                                            </>
                                        )}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cases?.slice(startIndex, endIndex)?.map((row, index) => (
                            <CaseTableRow
                                key={row?.id}
                                row={row}
                                index={startIndex + index}
                                done={done}
                                controllerName={controllerName}
                                control={control}
                                updateCaseInfoMap={updateCaseInfoMap}
                                cases={cases}
                                urgencyOptions={urgencyOptions}
                                loadMainPageData={loadMainPageData}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box marginTop="10px">
                <Pagination
                    count={Math.ceil(cases?.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                />
            </Box>
        </Box>
    )
}

export default CasesTable
