import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Box } from "@mui/material"
import { LoadingDialog } from "../../../Playbook/components/LoadingDialog/LoadingDialog"
import { useUserPlaybooks } from "../../../../../../hooks/repo/useUserPlaybooks"
import { RepoHeader } from "../RepoHeader/RepoHeader"
import { RepoMainUserPlaybooksList } from "../RepoMainUserPlaybooksList/RepoMainUserPlaybooksList"
import { RepoFooter } from "../RepoFooter/RepoFooter"
import { RepoElement } from "../RepoElement/RepoElement"
import { useAuth0 } from "@auth0/auth0-react"
import withCustomAuthenticationRequired from "../../../../../../components/Hoc/with-custom-authentication-required"
import styles from "./MyPlaybooks.module.scss"

export function MyPlaybooks() {
    const [isRepoOpen, setRepoOpen] = useState(false)
    const { templateId } = useParams()

    const { user } = useAuth0()

    const {
        playbooks,
        loadingPlaybooks,
        fetchUserPlaybooks,
        setPlaybooks,
        recentlyGeneratedPlaybooks,
    } = useUserPlaybooks()

    useEffect(() => {
        if (templateId) {
            setRepoOpen(true)
        }
    }, [templateId])

    return (
        <>
            <Box className={styles.myPlaybooksContainer}>
                <RepoHeader />
                <Box className={styles.myPlaybooksContainerMain}>
                    {user && playbooks && (
                        <RepoMainUserPlaybooksList
                            playbooks={playbooks}
                            recentlyGeneratedPlaybooks={recentlyGeneratedPlaybooks}
                            loadingPlaybooks={loadingPlaybooks}
                            setPlaybooks={setPlaybooks}
                            fetchUserPlaybooks={fetchUserPlaybooks}
                        />
                    )}
                </Box>
                <RepoFooter user={user} />
                <RepoElement
                    playbook={true}
                    isOpen={isRepoOpen}
                    setOpen={setRepoOpen}
                    fetchUserPlaybooks={fetchUserPlaybooks}
                />
            </Box>
        </>
    )
}
export default withCustomAuthenticationRequired(MyPlaybooks)
