import React, { useState, useEffect } from "react"
import { doToolAction, getEntityRelatedCases } from "../../../services/notebook/notebookServices"
import { useSelector, useDispatch } from "react-redux"
import { useAuth0 } from "@auth0/auth0-react"
import { Modal, Fade, Box, Grid, Typography, Autocomplete } from "@mui/material"
import { Controller } from "react-hook-form"
import TextField from "@mui/material/TextField"
import makeStyles from "@mui/styles/makeStyles"
import MenuItem from "@mui/material/MenuItem"
import TitleAndLinks from "../components/EntitiesTitleAndLinks"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { DotsMenu } from "./DotsMenu"

const useStyles = makeStyles(() => ({
    gridList: {
        flexWrap: "nowrap",
        transform: "translateZ(0)",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    img: {
        outline: "none",
    },
    input: {
        // width: "440px",
        maxWidth: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
    },
    textArea: {
        // width: "440px",
        maxWidth: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
    },
    label: {
        width: "50%",
        marginTop: "10px",
    },
    inputParent: {
        gridColumn: "span 3 !important",
    },
    noBefore: {
        "&::before": {
            display: "none",
        },
    },
}))

export function EntityFields({
    tool_id,
    control,
    values,
    entityTypes,
    logChanged,
    setLogChanged,
    handleEntityPastEvents,
}) {
    const classes = useStyles()
    const isAuthorized = useSelector((state) => state.isAuthorized)

    const [allFields, setAllFields] = useState([])

    useEffect(() => {
        if (isAuthorized && entityTypes) {
            var fields = Object.keys(entityTypes).reduce((res, v) => {
                return res.concat(entityTypes[v])
            }, [])
            var uniqueFieldsNames = [...new Set(fields.map((item) => item.field))]
            const uniqueFields = uniqueFieldsNames.map((field) => {
                for (let i = 0; i < fields.length; i++) {
                    if (field === fields[i].field) {
                        return fields[i]
                    }
                }
            })
            setAllFields(uniqueFields)
        }
    }, [entityTypes])

    const constructField = (field, hide) => {
        var fieldController = <></>
        if (field.type === "select-autocomplete") {
            fieldController = (
                <Controller
                    control={control}
                    name={`tools.${tool_id}.log[${field.field}]`}
                    render={({ field: { onChange, value } }) => (
                        <Autocomplete
                            // freeSolo
                            autoSelect
                            options={field.options.map((item) => item.display)}
                            onChange={(event, values) => {
                                onChange(values)
                                setLogChanged(logChanged + 1)
                            }}
                            value={value}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="All"
                                    variant="outlined"
                                    onChange={onChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    )}
                />
            )
        } else {
            const name = field.field
            fieldController = (
                <Controller
                    name={`tools.${tool_id}.log[${field.field}]`}
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            onChange={(value) => {
                                field.onChange(value)
                                // update in db by triggering log change
                                setLogChanged(logChanged + 1)
                            }}
                            variant="outlined"
                            label={name}
                            InputProps={{
                                className: classes.input,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    )}
                />
            )
        }
        return (
            <>
                <Box
                    sx={{
                        display: hide ? "none" : "block",
                    }}
                >
                    {fieldController}
                </Box>
            </>
        )
    }

    return (
        <>
            {/* Type */}
            <Box>
                <Box className={classes.inputParent}>
                    <Controller
                        name={`tools.${tool_id}.log.Type`}
                        defaultValue="Company"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                select
                                {...field}
                                onChange={(value) => {
                                    field.onChange(value)
                                    // if id check Related Cases
                                    if (values.tools[tool_id].log?.id) {
                                        if (handleEntityPastEvents) {
                                            handleEntityPastEvents()
                                        }
                                    }
                                    // update in db by triggering log changeee
                                    setLogChanged(logChanged + 1)
                                }}
                                variant="outlined"
                                label="Type"
                                InputProps={{
                                    className: classes.input,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            >
                                {entityTypes &&
                                    Object.keys(entityTypes).map((actionTypeOption, index) => {
                                        return (
                                            <MenuItem key={index} value={actionTypeOption}>
                                                {actionTypeOption}
                                            </MenuItem>
                                        )
                                    })}
                            </TextField>
                        )}
                    />
                </Box>
            </Box>
            {allFields &&
                allFields.length > 0 &&
                allFields?.map((field) => {
                    const hide = !entityTypes[values.tools[tool_id].log?.Type]
                        ?.map((field) => field.field)
                        .includes(field.field)
                    return constructField(field, hide)
                })}
        </>
    )
}

function Entity({
    tool_id,
    index,
    handleAddTools,
    handleDeleteTool,
    control,
    values,
    setValue,
    integrations,
    toolsConfig,
    entityTypes,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const { isAuthenticated, isLoading } = useAuth0()

    const [logChanged, setLogChanged] = useState(0)

    const [open, setOpen] = useState(false)
    const [image, setImage] = useState("false")

    const handleClose = () => {
        setOpen(false)
    }

    const handleImage = (value) => {
        setImage(value)
        setOpen(true)
    }

    useEffect(() => {
        if (isAuthorized) {
            doToolAction(
                values.case_id,
                values.tools[tool_id]?.id,
                values.tools[tool_id].type,
                values.tools[tool_id].log,
                dispatch,
                refreshAccessTokenCounter,
            )
        }
    }, [logChanged])

    const handleEntityPastEvents = async () => {
        if (values.tools[tool_id].log["ID"]) {
            const entityRelatedCases = await getEntityRelatedCases(
                values.tools[tool_id].log.Type,
                values.tools[tool_id].log["ID"],
                values.case_id,
                dispatch,
                refreshAccessTokenCounter,
            )
            setValue(`tools.${tool_id}.log.related_cases`, entityRelatedCases)
            setLogChanged(logChanged + 1)
        }
    }

    useEffect(() => {
        if (values.tools[tool_id].log["ID"]) {
            handleEntityPastEvents()
        }
    }, [])

    return (
        <Grid item xs={12} marginBottom={"15px"} marginTop={"15px"} position={"relative"}>
            <Modal className={classes.modal} open={open} onClose={handleClose} closeAfterTransition>
                <Fade in={open} timeout={500} className={classes.img}>
                    <img src={image} alt="file" style={{ maxHeight: "90%", maxWidth: "90%" }} />
                </Fade>
            </Modal>
            <Box
                sx={{
                    borderRadius: "10px",
                    width: "100%",
                }}
            >
                <DotsMenu
                    handleAddTools={handleAddTools}
                    values={values}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    handleDeleteTool={handleDeleteTool}
                    tool_id={tool_id}
                />
                <Accordion
                    defaultExpanded
                    style={{
                        backgroundColor: "#f3f3f3",
                        boxShadow: "none",
                        borderRadius: "10px",
                        margin: "0",
                    }}
                    className={classes.noBefore}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography color="black" variant="h6" fontWeight="400">
                            Entity
                        </Typography>
                    </AccordionSummary>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "no-wrap",
                            padding: "0px  28px 16px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                flexGrow: 3,
                            }}
                        >
                            <EntityFields
                                tool_id={tool_id}
                                control={control}
                                values={values}
                                entityTypes={entityTypes}
                                handleEntityPastEvents={handleEntityPastEvents}
                                logChanged={logChanged}
                                setLogChanged={setLogChanged}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                                minWidth: "300px",
                                "@media (max-width: 1400px)": {
                                    width: "20%",
                                },
                                "@media (max-width: 1200px)": {
                                    width: "100%",
                                    marginTop: "20px",
                                },
                            }}
                        >
                            {values.tools[tool_id].log?.Attachments &&
                                values.tools[tool_id].log.Attachments.length > 0 && (
                                    <TitleAndLinks
                                        title={"Attachments"}
                                        links={
                                            values.tools[tool_id].log?.Attachments
                                                ? values.tools[tool_id].log?.Attachments.map(
                                                      (attachment) => {
                                                          return {
                                                              title: `${attachment["description"]}`,
                                                              goto:
                                                                  // if MRC game do nothing
                                                                  values?.template_id === 3
                                                                      ? () => {}
                                                                      : () =>
                                                                            handleImage(
                                                                                attachment[
                                                                                    "address"
                                                                                ],
                                                                            ),
                                                          }
                                                      },
                                                  )
                                                : []
                                        }
                                    />
                                )}
                            {values.tools[tool_id].log?.related_cases &&
                                values.tools[tool_id].log.related_cases.length > 0 && (
                                    <TitleAndLinks
                                        title={"Related Cases"}
                                        links={values.tools[tool_id].log.related_cases.map(
                                            (past_case) => {
                                                return {
                                                    title: `${past_case["id"]}: ${past_case["type"]} - "${past_case["title"]}"`,
                                                    goto:
                                                        // if MRC game do nothing
                                                        values?.template_id === 3
                                                            ? () => {}
                                                            : () => {
                                                                  window.open(
                                                                      `/${past_case["id"]}`,
                                                                      "_blank",
                                                                  )
                                                              },
                                                }
                                            },
                                        )}
                                    />
                                )}
                        </Box>
                    </Box>
                </Accordion>
            </Box>
        </Grid>
    )
}

export default Entity
