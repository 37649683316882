import React from "react"
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Svg,
    G,
    Path,
    Defs,
    Rect,
    Font,
    Circle,
} from "@react-pdf/renderer"
import moment from "moment/moment"
import { CaseReportStep } from "./components/CaseReportStep"
import Arial from "../../../../assets/fonts/Arial Unicode MS.ttf"

Font.register({
    family: "Arial",
    fonts: [{ src: Arial }],
})

const styles = StyleSheet.create({
    standardText: {
        fontSize: "14px",
        fontWeight: 600,
    },
    page: {
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "30px",
        fontFamily: "Arial",
    },
    sectionCaseTitle: {
        lineHeight: "140%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
    sectionCaseTitleText: {
        fontSize: "22px",
        fontWeight: 600,
        width: "80%",
    },
    sectionCaseData: {
        display: "flex",
        flexDirection: "column",
        color: "#374957",
        fontSize: "11px",
        fontWeight: 400,
        lineHeight: "160%",
        marginBottom: "25px",
        marginTop: "10px",
        gap: "3px",
    },
    sectionCaseInfo: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        color: "#374957",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "160%",
        borderRadius: "8px",
        padding: "12px 18px",
        border: "1px solid #EFEEF1",
        marginTop: "12px",
    },
    sectionCaseInfoTitle: {
        fontWeight: 500,
    },
    sectionSteps: {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        width: "100%",
        marginTop: "48px",
    },
    sectionSummary: {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        fontSize: "12px",
        marginTop: "30px",
    },
    sectionSummaryTitle: {
        fontSize: "14px",
        fontWeight: "500",
    },
    sectionSummaryTitleWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "6px",
    },
    sectionSummaryContent: {
        display: "flex",
        padding: "12px 18px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "6px",
        alignSelf: "stretch",
        borderRadius: "8px",
        border: "1px solid #EFEEF1",
    },
    poweredBy: {
        position: "absolute",
        bottom: "10px",
        width: "100%",
        fontSize: "12px",
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "center",
        gap: "10px",
    },
    standardBlackText: {
        color: "#000",
    },
})

export function CaseReport({ caseTitle, values, markers }) {
    const {
        case_id,
        reported_by,
        created_at,
        status,
        resolution,
        type_name,
        description,
        urgency,
        tools,
    } = values
    const steps = Object.values(tools).filter((tool) => tool.type === 46)
    const allowedTypes = new Set([31, 35, 27, 26, 46, 8])
    const filteredTools = Object.values(tools).filter((tool) => !allowedTypes.has(tool.type))

    const summaryTool = Object.values(tools).find((tool) => tool.type === 27)

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.sectionCaseTitle}>
                    <View>
                        <Text style={styles.sectionCaseTitleText}>Case Report - {caseTitle}</Text>
                    </View>
                    <View>
                        <Svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100"
                            height="35"
                            viewBox="0 0 100 35"
                            fill="none"
                        >
                            <G clip-path="url(#clip0_5901_20274)">
                                <Path
                                    d="M23.3872 30.9987L20.8509 26.526H19.7642V30.9987H17.457V19.5215H21.7734C22.6634 19.5215 23.4197 19.6778 24.0462 19.9905C24.6726 20.3032 25.1424 20.7245 25.4537 21.2564C25.767 21.7883 25.9236 22.3832 25.9236 23.0409C25.9236 23.7978 25.7039 24.4803 25.2647 25.0885C24.8254 25.6967 24.1722 26.1161 23.3051 26.3468L26.0554 31.0006H23.3872V30.9987ZM19.7623 24.7987H21.6894C22.3158 24.7987 22.7818 24.6481 23.0893 24.3469C23.3968 24.0456 23.5515 23.6262 23.5515 23.0886C23.5515 22.5509 23.3968 22.1544 23.0893 21.8627C22.7818 21.5729 22.3158 21.4261 21.6894 21.4261H19.7623V24.7968V24.7987Z"
                                    fill="#262626"
                                />
                                <Path
                                    d="M35.8878 27.1338H29.2165C29.2719 27.7915 29.5011 28.3063 29.9079 28.6799C30.3147 29.0536 30.8132 29.2386 31.4072 29.2386C32.2647 29.2386 32.874 28.8706 33.2349 28.1366H35.7216C35.4581 29.0136 34.9519 29.7342 34.2052 30.2986C33.4584 30.8629 32.5416 31.1451 31.4549 31.1451C30.5764 31.1451 29.7876 30.9506 29.0905 30.5617C28.3934 30.1727 27.849 29.6218 27.4594 28.9087C27.0698 28.1957 26.875 27.374 26.875 26.4417C26.875 25.5094 27.0679 24.6705 27.4518 23.9594C27.8357 23.2464 28.3743 22.6992 29.0656 22.316C29.757 21.9328 30.5535 21.7402 31.4549 21.7402C32.3564 21.7402 33.0993 21.9271 33.785 22.2988C34.4706 22.6725 35.0035 23.2006 35.3836 23.8851C35.7617 24.5695 35.9527 25.3569 35.9527 26.2453C35.9527 26.5752 35.9298 26.8707 35.8878 27.1338ZM33.5653 25.5876C33.5539 24.9947 33.34 24.5218 32.9236 24.1653C32.5054 23.8088 31.9954 23.6315 31.3919 23.6315C30.8208 23.6315 30.3395 23.805 29.9499 24.1501C29.5603 24.4951 29.3216 24.9756 29.2337 25.5895H33.5653V25.5876Z"
                                    fill="#262626"
                                />
                                <Path
                                    d="M37.0168 23.9624C37.3854 23.2494 37.8877 22.7022 38.5237 22.319C39.1597 21.9358 39.8683 21.7432 40.6494 21.7432C41.2415 21.7432 41.8087 21.8729 42.3454 22.1303C42.884 22.3876 43.3118 22.7308 43.6308 23.1579V18.8301H45.9704V30.9994H43.6308V29.6514C43.3443 30.1014 42.9451 30.4636 42.4275 30.7363C41.9119 31.0108 41.3121 31.1481 40.6322 31.1481C39.8625 31.1481 39.1597 30.9498 38.5237 30.5551C37.8858 30.1605 37.3835 29.6038 37.0168 28.8869C36.6482 28.1682 36.4648 27.3446 36.4648 26.4123C36.4648 25.48 36.6482 24.6754 37.0168 23.9624ZM43.3175 25.0072C43.0979 24.6068 42.8019 24.2999 42.4275 24.0863C42.0532 23.8728 41.654 23.766 41.2243 23.766C40.7946 23.766 40.4011 23.8709 40.0383 24.0787C39.6754 24.2865 39.3812 24.5916 39.1578 24.9919C38.9324 25.3923 38.8197 25.867 38.8197 26.4142C38.8197 26.9614 38.9324 27.4418 39.1578 27.8536C39.3832 28.2654 39.6792 28.58 40.0478 28.7992C40.4164 29.0185 40.8079 29.1272 41.2262 29.1272C41.6445 29.1272 42.0551 29.0204 42.4294 28.8069C42.8019 28.5933 43.0998 28.2864 43.3194 27.886C43.5391 27.4856 43.6499 27.0071 43.6499 26.4466C43.6499 25.8861 43.5391 25.4075 43.3194 25.0072H43.3175Z"
                                    fill="#262626"
                                />
                                <Path
                                    d="M49.1291 30.7447C48.537 30.4759 48.0672 30.1117 47.7215 29.6504C47.3758 29.189 47.1867 28.6799 47.1523 28.1213H49.4748C49.5187 28.4721 49.6906 28.7619 49.9943 28.9926C50.296 29.2233 50.6723 29.3377 51.123 29.3377C51.5737 29.3377 51.9061 29.25 52.1524 29.0746C52.3988 28.8992 52.523 28.6742 52.523 28.3997C52.523 28.1042 52.3721 27.8811 52.0703 27.7343C51.7686 27.5856 51.2873 27.4255 50.6283 27.2501C49.9465 27.0861 49.3907 26.9164 48.9572 26.741C48.5236 26.5656 48.1493 26.2968 47.838 25.9346C47.5248 25.5723 47.3682 25.0843 47.3682 24.4704C47.3682 23.967 47.5133 23.5057 47.8055 23.0881C48.0958 22.6725 48.5141 22.3427 49.0584 22.1025C49.6027 21.8623 50.2425 21.7402 50.9779 21.7402C52.0646 21.7402 52.9317 22.011 53.581 22.5543C54.2285 23.0977 54.5856 23.8279 54.6525 24.7487H52.4447C52.4122 24.3865 52.2613 24.0986 51.992 23.8851C51.7227 23.6715 51.3637 23.5648 50.9129 23.5648C50.4947 23.5648 50.1738 23.641 49.9484 23.7955C49.7231 23.9499 49.6104 24.1634 49.6104 24.436C49.6104 24.743 49.7632 24.9756 50.0726 25.1357C50.3801 25.294 50.8575 25.456 51.505 25.62C52.1639 25.7839 52.7082 25.9536 53.136 26.129C53.5639 26.3044 53.9344 26.5752 54.2476 26.9431C54.5608 27.3111 54.7232 27.7953 54.7327 28.3978C54.7327 28.924 54.5875 29.3949 54.2953 29.8124C54.005 30.2299 53.5868 30.556 53.0424 30.7905C52.4981 31.025 51.864 31.1432 51.1402 31.1432C50.4163 31.1432 49.7231 31.0078 49.131 30.7409L49.1291 30.7447Z"
                                    fill="#262626"
                                />
                                <Path
                                    d="M58.7509 23.7815V28.1893C58.7509 28.4963 58.8254 28.7193 58.9725 28.8547C59.1214 28.992 59.3697 29.0606 59.7211 29.0606H60.7926V31.0014H59.343C57.3987 31.0014 56.4266 30.0596 56.4266 28.1741V23.7815H55.3398V21.8902H56.4266V19.6367H58.749V21.8902H60.7907V23.7815H58.749H58.7509Z"
                                    fill="#262626"
                                />
                                <Path
                                    d="M65.3785 22.1677C65.856 21.8931 66.4022 21.7559 67.0172 21.7559V24.1733H66.4079C65.6841 24.1733 65.136 24.343 64.7693 24.6824C64.4007 25.0217 64.2173 25.6146 64.2173 26.4592V30.9967H61.9102V21.8893H64.2173V23.3039C64.5133 22.8216 64.901 22.4441 65.3785 22.1696V22.1677Z"
                                    fill="#262626"
                                />
                                <Path
                                    d="M68.1491 20.4181C67.8798 20.1607 67.7461 19.8404 67.7461 19.4553C67.7461 19.0702 67.8798 18.7518 68.1491 18.4925C68.4184 18.2351 68.7564 18.1055 69.1613 18.1055C69.5662 18.1055 69.9043 18.2351 70.1736 18.4925C70.4429 18.7499 70.5766 19.0702 70.5766 19.4553C70.5766 19.8404 70.441 20.1607 70.1736 20.4181C69.9043 20.6754 69.5662 20.8051 69.1613 20.8051C68.7564 20.8051 68.4165 20.6774 68.1491 20.4181ZM70.2996 21.8899V30.9992H67.9925V21.8899H70.2996Z"
                                    fill="#262626"
                                />
                                <Path
                                    d="M79.7067 22.7854C80.377 23.4699 80.7113 24.4269 80.7113 25.6547V30.9987H78.4041V25.9674C78.4041 25.2448 78.2227 24.6881 77.8598 24.2992C77.4969 23.9103 77.0023 23.7158 76.3777 23.7158C75.7532 23.7158 75.2375 23.9103 74.8708 24.2992C74.5022 24.6881 74.3189 25.2448 74.3189 25.9674V30.9987H72.0117V21.8894H74.3189V23.0237C74.6264 22.6291 75.0198 22.3202 75.4973 22.0953C75.9747 21.8703 76.5 21.7578 77.071 21.7578C78.1577 21.7578 79.0363 22.101 79.7067 22.7854Z"
                                    fill="#262626"
                                />
                                <Path
                                    d="M87.6775 22.1426C88.1932 22.4114 88.6 22.7584 88.896 23.1873V21.889H91.2185V31.065C91.2185 31.9096 91.0485 32.6627 90.7085 33.3262C90.3686 33.9896 89.8567 34.5158 89.1768 34.9048C88.495 35.2937 87.6718 35.4881 86.7054 35.4881C85.4086 35.4881 84.3467 35.1869 83.5178 34.5845C82.6889 33.982 82.2191 33.1584 82.1102 32.1174H84.4002C84.5205 32.5331 84.7821 32.8648 85.1832 33.1126C85.5843 33.3586 86.0694 33.4825 86.6405 33.4825C87.3108 33.4825 87.8532 33.2823 88.2715 32.8819C88.6879 32.4816 88.8979 31.8753 88.8979 31.065V29.6504C88.6019 30.0775 88.1913 30.434 87.6699 30.72C87.1485 31.004 86.5526 31.147 85.8822 31.147C85.1125 31.147 84.4097 30.9487 83.7737 30.5541C83.1358 30.1594 82.6335 29.6027 82.2668 28.8859C81.8982 28.1671 81.7148 27.3435 81.7148 26.4112C81.7148 25.479 81.8982 24.6744 82.2668 23.9614C82.6354 23.2483 83.1339 22.7012 83.7661 22.318C84.3982 21.9347 85.103 21.7422 85.8822 21.7422C86.5621 21.7422 87.1619 21.8756 87.6775 22.1445V22.1426ZM88.5675 25.0042C88.3479 24.6039 88.0519 24.2969 87.6775 24.0834C87.3032 23.8699 86.904 23.7631 86.4743 23.7631C86.0446 23.7631 85.6511 23.8679 85.2882 24.0758C84.9254 24.2836 84.6312 24.5886 84.4078 24.989C84.1824 25.3893 84.0697 25.8641 84.0697 26.4112C84.0697 26.9584 84.1824 27.4388 84.4078 27.8507C84.6332 28.2625 84.9292 28.577 85.2978 28.7963C85.6664 29.0155 86.0579 29.1242 86.4762 29.1242C86.8945 29.1242 87.3051 29.0174 87.6794 28.8039C88.0519 28.5904 88.3498 28.2834 88.5694 27.8831C88.7891 27.4827 88.8999 27.0042 88.8999 26.4436C88.8999 25.8831 88.7891 25.4046 88.5694 25.0042H88.5675Z"
                                    fill="#262626"
                                />
                                <Path
                                    d="M94.3986 30.7447C93.8065 30.4759 93.3367 30.1117 92.991 29.6504C92.6453 29.189 92.4563 28.6799 92.4219 28.1213H94.7443C94.7882 28.4721 94.9601 28.7619 95.2638 28.9926C95.5656 29.2233 95.9418 29.3377 96.3925 29.3377C96.8433 29.3377 97.1756 29.25 97.422 29.0746C97.6683 28.8992 97.7925 28.6742 97.7925 28.3997C97.7925 28.1042 97.6416 27.8811 97.3398 27.7343C97.0381 27.5856 96.5568 27.4255 95.8979 27.2501C95.216 27.0861 94.6603 26.9164 94.2267 26.741C93.7932 26.5656 93.4188 26.2968 93.1075 25.9346C92.7943 25.5723 92.6377 25.0843 92.6377 24.4704C92.6377 23.967 92.7828 23.5057 93.0751 23.0881C93.3654 22.6725 93.7836 22.3427 94.3279 22.1025C94.8723 21.8623 95.5121 21.7402 96.2474 21.7402C97.3341 21.7402 98.2012 22.011 98.8506 22.5543C99.498 23.0977 99.8552 23.8279 99.922 24.7487H97.7142C97.6817 24.3865 97.5308 24.0986 97.2615 23.8851C96.9922 23.6715 96.6332 23.5648 96.1825 23.5648C95.7642 23.5648 95.4433 23.641 95.218 23.7955C94.9926 23.9499 94.8799 24.1634 94.8799 24.436C94.8799 24.743 95.0327 24.9756 95.3421 25.1357C95.6496 25.294 96.1271 25.456 96.7745 25.62C97.4334 25.7839 97.9777 25.9536 98.4056 26.129C98.8334 26.3044 99.2039 26.5752 99.5171 26.9431C99.8303 27.3111 99.9927 27.7953 100.002 28.3978C100.002 28.924 99.8571 29.3949 99.5649 29.8124C99.2746 30.2299 98.8563 30.556 98.312 30.7905C97.7677 31.025 97.1336 31.1432 96.4097 31.1432C95.6859 31.1432 94.9926 31.0078 94.4005 30.7409L94.3986 30.7447Z"
                                    fill="#262626"
                                />
                                <Path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4.50281 27.12C3.76177 27.12 3.16016 26.5195 3.16016 25.7797C3.16016 25.04 3.76177 24.4395 4.50281 24.4395C5.24385 24.4395 5.84546 25.04 5.84546 25.7797C5.84546 26.5195 5.24385 27.12 4.50281 27.12Z"
                                    fill="#262626"
                                />
                                <Path
                                    d="M6.60058 21.549H1.99202V26.6985L2.57835 26.117C2.7025 26.8262 3.21244 27.4058 3.88472 27.6289L0 31.4858V19.5605H6.60249C8.77595 19.5605 10.5445 21.3279 10.5445 23.4975C10.5445 24.7596 9.93526 25.9207 8.96312 26.649L7.52115 25.2095C8.13996 24.8759 8.54676 24.2201 8.54676 23.4956C8.54676 22.4241 7.67394 21.549 6.60058 21.549Z"
                                    fill="#E02B38"
                                />
                                <Path
                                    d="M6.3852 26.3066C6.1923 26.9911 5.63652 27.523 4.9375 27.6812L8.742 31.4599H11.5667L6.3852 26.3066Z"
                                    fill="#E02B38"
                                />
                            </G>
                            <Defs>
                                <clipPath id="clip0_5901_20274">
                                    <Rect width="100" height="35" fill="white" />
                                </clipPath>
                            </Defs>
                        </Svg>
                    </View>
                </View>
                <View style={styles.sectionCaseData}>
                    <Text>
                        <Text style={styles.standardBlackText}>Created By:</Text>{" "}
                        {reported_by || ""}
                    </Text>
                    <Text>
                        <Text style={styles.standardBlackText}>Date: </Text>
                        {created_at && moment(created_at).format("DD/MM/YY HH:mm")}
                    </Text>
                    <Text>
                        <Text style={styles.standardBlackText}>Case ID: </Text>
                        {case_id || ""}
                    </Text>
                </View>
                <Text style={styles.standardText}>Case Information</Text>
                <View style={styles.sectionCaseInfo}>
                    <Text style={styles.sectionCaseInfoTitle}>
                        <Text style={styles.standardBlackText}>Status:</Text> {status}
                    </Text>
                    <Text style={styles.sectionCaseInfoTitle}>
                        <Text style={styles.standardBlackText}>Resolution:</Text> {resolution}
                    </Text>
                    <Text style={styles.sectionCaseInfoTitle}>
                        <Text style={styles.standardBlackText}>Type:</Text> {type_name}
                    </Text>
                    <Text style={styles.sectionCaseInfoTitle}>
                        <Text style={styles.standardBlackText}>Description:</Text> {description}
                    </Text>
                    <Text style={styles.sectionCaseInfoTitle}>
                        <Text style={styles.standardBlackText}>Urgency:</Text> {urgency}
                    </Text>
                </View>
                <View style={styles.sectionSteps}>
                    {steps?.map((step, index) => (
                        <CaseReportStep
                            key={step?.id}
                            step={step}
                            index={index}
                            filteredTools={filteredTools}
                            values={values}
                            markers={markers}
                        />
                    ))}
                </View>
                {summaryTool?.log?.note && (
                    <View style={styles.sectionSummary} wrap={false}>
                        <View style={styles.sectionSummaryTitleWrapper}>
                            <Svg
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <Circle
                                    cx="9"
                                    cy="9.5"
                                    r="7"
                                    fill="#5E4AFD"
                                    stroke="#EDEEF8"
                                    strokeWidth="4"
                                />
                            </Svg>
                            <Text style={styles.sectionSummaryTitle}>Summary</Text>
                        </View>

                        <View style={styles.sectionSummaryContent}>
                            <Text>{summaryTool?.log?.note}</Text>
                        </View>
                    </View>
                )}
                <View style={styles.poweredBy}>
                    <Text>Powered by</Text>
                    <Svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="86"
                        height="15"
                        viewBox="0 0 86 15"
                        fill="none"
                    >
                        <G clip-path="url(#clip0_5901_20530)">
                            <Path
                                d="M20.3766 11.1253L18.2207 7.26545H17.297V11.1253H15.3359V1.2207H19.0048C19.7613 1.2207 20.4042 1.35562 20.9367 1.62544C21.4692 1.89527 21.8685 2.25887 22.1331 2.71791C22.3994 3.17694 22.5325 3.69027 22.5325 4.25789C22.5325 4.91106 22.3458 5.50007 21.9724 6.02492C21.599 6.54976 21.0438 6.91172 20.3068 7.1108L22.6445 11.1269H20.3766V11.1253ZM17.2954 5.77483H18.9334C19.4659 5.77483 19.862 5.64486 20.1234 5.3849C20.3847 5.12495 20.5162 4.76299 20.5162 4.29902C20.5162 3.83505 20.3847 3.49283 20.1234 3.2411C19.862 2.99102 19.4659 2.86434 18.9334 2.86434H17.2954V5.77319V5.77483Z"
                                fill="#262626"
                            />
                            <Path
                                d="M31.0046 7.79121H25.334C25.3811 8.35883 25.5759 8.80306 25.9217 9.12553C26.2675 9.44801 26.6912 9.6076 27.1961 9.6076C27.925 9.6076 28.4429 9.29006 28.7497 8.65663H30.8634C30.6393 9.41346 30.2091 10.0354 29.5744 10.5224C28.9396 11.0094 28.1604 11.2529 27.2367 11.2529C26.4899 11.2529 25.8194 11.0851 25.2269 10.7494C24.6344 10.4138 24.1717 9.9383 23.8405 9.32296C23.5093 8.70763 23.3438 7.99852 23.3438 7.19397C23.3438 6.38943 23.5077 5.66551 23.834 5.05182C24.1603 4.43649 24.6181 3.96429 25.2058 3.63359C25.7935 3.30289 26.4704 3.13672 27.2367 3.13672C28.0029 3.13672 28.6344 3.29796 29.2172 3.61879C29.8 3.94126 30.253 4.397 30.576 4.98766C30.8975 5.57831 31.0598 6.25781 31.0598 7.02451C31.0598 7.30914 31.0403 7.56416 31.0046 7.79121ZM29.0305 6.45689C29.0208 5.94521 28.839 5.53718 28.4851 5.22951C28.1296 4.92185 27.6961 4.76883 27.1831 4.76883C26.6977 4.76883 26.2886 4.91856 25.9574 5.21635C25.6263 5.51415 25.4233 5.92876 25.3487 6.45854H29.0305V6.45689Z"
                                fill="#262626"
                            />
                            <Path
                                d="M31.9614 5.05213C32.2747 4.4368 32.7016 3.96461 33.2422 3.63391C33.7828 3.3032 34.3851 3.13703 35.0491 3.13703C35.5523 3.13703 36.0345 3.24891 36.4907 3.47102C36.9485 3.69314 37.3121 3.98929 37.5832 4.35783V0.623047H39.5719V11.1249H37.5832V9.96165C37.3397 10.3499 37.0004 10.6625 36.5605 10.8978C36.1221 11.1347 35.6124 11.2532 35.0345 11.2532C34.3802 11.2532 33.7828 11.0821 33.2422 10.7415C32.7 10.4009 32.273 9.92051 31.9614 9.30189C31.648 8.68162 31.4922 7.97086 31.4922 7.16632C31.4922 6.36178 31.648 5.66747 31.9614 5.05213ZM37.317 5.95375C37.1303 5.60824 36.8787 5.34335 36.5605 5.15908C36.2423 4.97481 35.903 4.88267 35.5377 4.88267C35.1725 4.88267 34.838 4.97316 34.5296 5.1525C34.2211 5.33183 33.9711 5.59508 33.7812 5.94059C33.5896 6.28609 33.4938 6.69577 33.4938 7.16796C33.4938 7.64016 33.5896 8.05477 33.7812 8.41015C33.9728 8.76553 34.2244 9.037 34.5377 9.22621C34.851 9.41541 35.1838 9.50919 35.5393 9.50919C35.8949 9.50919 36.2439 9.41706 36.5621 9.23279C36.8787 9.04852 37.1319 8.78363 37.3186 8.43812C37.5053 8.09261 37.5994 7.67964 37.5994 7.19593C37.5994 6.71222 37.5053 6.29926 37.3186 5.95375H37.317Z"
                                fill="#262626"
                            />
                            <Path
                                d="M42.2623 10.9035C41.759 10.6715 41.3596 10.3572 41.0658 9.95907C40.772 9.56091 40.6113 9.12162 40.582 8.63956H42.5561C42.5934 8.94229 42.7395 9.19237 42.9977 9.39145C43.2542 9.59053 43.574 9.68925 43.9571 9.68925C44.3402 9.68925 44.6227 9.61356 44.8321 9.4622C45.0415 9.31083 45.1471 9.11669 45.1471 8.87977C45.1471 8.62475 45.0188 8.43225 44.7623 8.30557C44.5058 8.17724 44.0967 8.03903 43.5366 7.88767C42.9571 7.74617 42.4847 7.59974 42.1162 7.44838C41.7476 7.29701 41.4295 7.06503 41.1648 6.75242C40.8986 6.43982 40.7655 6.01863 40.7655 5.48885C40.7655 5.0545 40.8889 4.65634 41.1372 4.29602C41.384 3.93735 41.7395 3.65272 42.2022 3.44542C42.6649 3.23811 43.2087 3.13281 43.8337 3.13281C44.7574 3.13281 45.4945 3.36644 46.0464 3.83535C46.5968 4.30425 46.9003 4.93439 46.9572 5.72906H45.0805C45.0529 5.41646 44.9246 5.16802 44.6957 4.98375C44.4668 4.79948 44.1616 4.70734 43.7785 4.70734C43.423 4.70734 43.1503 4.77315 42.9587 4.90642C42.7671 5.03969 42.6714 5.22396 42.6714 5.45924C42.6714 5.72413 42.8012 5.92485 43.0642 6.06305C43.3256 6.19961 43.7314 6.33946 44.2818 6.48095C44.8419 6.62245 45.3045 6.76888 45.6682 6.92024C46.0318 7.07161 46.3468 7.30524 46.613 7.62278C46.8792 7.94032 47.0172 8.35822 47.0253 8.87812C47.0253 9.33222 46.902 9.7386 46.6536 10.0989C46.4068 10.4592 46.0513 10.7406 45.5886 10.9429C45.1259 11.1453 44.587 11.2473 43.9717 11.2473C43.3564 11.2473 42.7671 11.1305 42.2639 10.9002L42.2623 10.9035Z"
                                fill="#262626"
                            />
                            <Path
                                d="M50.4385 4.89519V8.69908C50.4385 8.96397 50.5018 9.15646 50.6268 9.27328C50.7534 9.39174 50.9645 9.45097 51.2632 9.45097H52.1739V11.1259H50.9417C49.2891 11.1259 48.4628 10.3131 48.4628 8.68591V4.89519H47.5391V3.26308H48.4628V1.31836H50.4368V3.26308H52.1723V4.89519H50.4368H50.4385Z"
                                fill="#262626"
                            />
                            <Path
                                d="M56.0731 3.50382C56.479 3.2669 56.9433 3.14844 57.466 3.14844V5.23465H56.9481C56.3329 5.23465 55.8669 5.38108 55.5552 5.67394C55.2419 5.9668 55.0861 6.47848 55.0861 7.20734V11.1231H53.125V3.26361H55.0861V4.4844C55.3377 4.06815 55.6673 3.74238 56.0731 3.50546V3.50382Z"
                                fill="#262626"
                            />
                            <Path
                                d="M58.4246 1.99377C58.1957 1.77166 58.082 1.49525 58.082 1.1629C58.082 0.830558 58.1957 0.555796 58.4246 0.332038C58.6535 0.109926 58.9408 -0.00195312 59.285 -0.00195312C59.6291 -0.00195312 59.9165 0.109926 60.1454 0.332038C60.3743 0.554151 60.4879 0.830558 60.4879 1.1629C60.4879 1.49525 60.3727 1.77166 60.1454 1.99377C59.9165 2.21588 59.6291 2.32776 59.285 2.32776C58.9408 2.32776 58.6518 2.21753 58.4246 1.99377ZM60.2525 3.26392V11.1251H58.2915V3.26392H60.2525Z"
                                fill="#262626"
                            />
                            <Path
                                d="M68.2477 4.0372C68.8176 4.62785 69.1017 5.45378 69.1017 6.51334V11.1251H67.1406V6.78316C67.1406 6.1596 66.9864 5.67918 66.6779 5.34355C66.3695 5.00791 65.949 4.84009 65.4181 4.84009C64.8873 4.84009 64.449 5.00791 64.1373 5.34355C63.824 5.67918 63.6681 6.1596 63.6681 6.78316V11.1251H61.707V3.26391H63.6681V4.24286C63.9295 3.90228 64.2639 3.63575 64.6698 3.4416C65.0756 3.24746 65.522 3.15039 66.0074 3.15039C66.9312 3.15039 67.6779 3.44654 68.2477 4.0372Z"
                                fill="#262626"
                            />
                            <Path
                                d="M75.0253 3.48223C75.4636 3.71421 75.8094 4.01365 76.061 4.38384V3.26341H78.0351V11.1821C78.0351 11.911 77.8906 12.5609 77.6017 13.1334C77.3127 13.706 76.8776 14.1601 76.2997 14.4957C75.7201 14.8314 75.0204 14.9992 74.199 14.9992C73.0967 14.9992 72.1941 14.7392 71.4895 14.2193C70.785 13.6994 70.3856 12.9886 70.2931 12.0903H72.2395C72.3418 12.449 72.5642 12.7353 72.9051 12.9492C73.2461 13.1614 73.6584 13.2683 74.1438 13.2683C74.7136 13.2683 75.1747 13.0956 75.5302 12.7501C75.8841 12.4046 76.0627 11.8814 76.0627 11.1821V9.96133C75.811 10.3299 75.462 10.6375 75.0188 10.8843C74.5756 11.1295 74.0691 11.2529 73.4993 11.2529C72.8451 11.2529 72.2477 11.0818 71.7071 10.7412C71.1648 10.4006 70.7379 9.9202 70.4262 9.30158C70.1129 8.68131 69.957 7.97055 69.957 7.16601C69.957 6.36146 70.1129 5.66716 70.4262 5.05182C70.7395 4.43649 71.1632 3.96429 71.7006 3.63359C72.2379 3.30289 72.837 3.13672 73.4993 3.13672C74.0772 3.13672 74.587 3.25189 75.0253 3.48387V3.48223ZM75.7818 5.95179C75.5951 5.60628 75.3435 5.34139 75.0253 5.15712C74.7071 4.97285 74.3678 4.88071 74.0026 4.88071C73.6373 4.88071 73.3029 4.9712 72.9944 5.15054C72.686 5.32987 72.436 5.59312 72.246 5.93863C72.0545 6.28414 71.9587 6.69381 71.9587 7.16601C71.9587 7.6382 72.0545 8.05281 72.246 8.40819C72.4376 8.76357 72.6892 9.03504 73.0025 9.22425C73.3159 9.41346 73.6487 9.50724 74.0042 9.50724C74.3597 9.50724 74.7087 9.4151 75.0269 9.23083C75.3435 9.04656 75.5967 8.78167 75.7834 8.43616C75.9701 8.09065 76.0643 7.67769 76.0643 7.19397C76.0643 6.71026 75.9701 6.2973 75.7834 5.95179H75.7818Z"
                                fill="#262626"
                            />
                            <Path
                                d="M80.7388 10.9035C80.2356 10.6715 79.8362 10.3572 79.5424 9.95907C79.2485 9.56091 79.0878 9.12162 79.0586 8.63956H81.0327C81.07 8.94229 81.2161 9.19237 81.4742 9.39145C81.7307 9.59053 82.0505 9.68925 82.4337 9.68925C82.8168 9.68925 83.0993 9.61356 83.3087 9.4622C83.5181 9.31083 83.6236 9.11669 83.6236 8.87977C83.6236 8.62475 83.4954 8.43225 83.2389 8.30557C82.9824 8.17724 82.5733 8.03903 82.0132 7.88767C81.4336 7.74617 80.9612 7.59974 80.5927 7.44838C80.2242 7.29701 79.906 7.06503 79.6414 6.75242C79.3752 6.43982 79.242 6.01863 79.242 5.48885C79.242 5.0545 79.3654 4.65634 79.6138 4.29602C79.8606 3.93735 80.2161 3.65272 80.6788 3.44542C81.1414 3.23811 81.6853 3.13281 82.3103 3.13281C83.234 3.13281 83.971 3.36644 84.523 3.83535C85.0733 4.30425 85.3769 4.93439 85.4337 5.72906H83.5571C83.5295 5.41646 83.4012 5.16802 83.1723 4.98375C82.9434 4.79948 82.6382 4.70734 82.2551 4.70734C81.8996 4.70734 81.6268 4.77315 81.4353 4.90642C81.2437 5.03969 81.1479 5.22396 81.1479 5.45924C81.1479 5.72413 81.2778 5.92485 81.5408 6.06305C81.8022 6.19961 82.208 6.33946 82.7583 6.48095C83.3184 6.62245 83.7811 6.76888 84.1447 6.92024C84.5084 7.07161 84.8233 7.30524 85.0896 7.62278C85.3558 7.94032 85.4938 8.35822 85.5019 8.87812C85.5019 9.33222 85.3785 9.7386 85.1301 10.0989C84.8834 10.4592 84.5279 10.7406 84.0652 10.9429C83.6025 11.1453 83.0635 11.2473 82.4483 11.2473C81.833 11.2473 81.2437 11.1305 80.7404 10.9002L80.7388 10.9035Z"
                                fill="#262626"
                            />
                            <Path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.32485 7.77811C3.69497 7.77811 3.18359 7.25984 3.18359 6.62147C3.18359 5.98311 3.69497 5.46484 4.32485 5.46484C4.95473 5.46484 5.4661 5.98311 5.4661 6.62147C5.4661 7.25984 4.95473 7.77811 4.32485 7.77811Z"
                                fill="#262626"
                            />
                            <Path
                                d="M6.1105 2.96993H2.19321V7.4138L2.6916 6.91199C2.79712 7.52403 3.23057 8.02419 3.80201 8.21669L0.5 11.5451V1.25391H6.11212C7.95956 1.25391 9.46283 2.77908 9.46283 4.65141C9.46283 5.74058 8.94497 6.74255 8.11865 7.37105L6.89298 6.12887C7.41896 5.84094 7.76475 5.27497 7.76475 4.64976C7.76475 3.72511 7.02285 2.96993 6.1105 2.96993Z"
                                fill="#E02B38"
                            />
                            <Path
                                d="M5.92586 7.07422C5.76189 7.66487 5.28948 8.12391 4.69531 8.26046L7.92914 11.5214H10.3302L5.92586 7.07422Z"
                                fill="#E02B38"
                            />
                        </G>
                        <Defs>
                            <clipPath id="clip0_5901_20530">
                                <Rect
                                    width="85"
                                    height="15"
                                    fill="white"
                                    transform="translate(0.5)"
                                />
                            </clipPath>
                        </Defs>
                    </Svg>
                </View>
            </Page>
        </Document>
    )
}
