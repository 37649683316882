import React from "react"
import { Box, Typography } from "@mui/material"
import { NewPlaybookButton } from "../RepoMainUserPlaybooksListHeader/components/NewPlaybookButton/NewPlaybookButton"
import styles from "./PlaybooksListEmpty.module.scss"

export function PlaybooksListEmpty({ fetchUserPlaybooks, generateInputRef }) {
    return (
        <Box className={styles.playbooksListEmptyContainer}>
            <Typography fontWeight={500}>You don’t have any playbooks yet</Typography>
            <NewPlaybookButton
                fetchUserPlaybooks={fetchUserPlaybooks}
                generateInputRef={generateInputRef}
            />
        </Box>
    )
}
