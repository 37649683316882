import React from "react"
import { Box, CircularProgress } from "@mui/material"
import completedIcon from "../../../../../../assets/icons/stepCompleted.svg"
import stepsArrow from "../../../../../../assets/icons/stepsArrow.svg"
import { Link, useOutletContext, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { calculateAverageRisk } from "../../../../../../helpers/notebookHelpers"
import { RISK_LEVELS } from "../../../../../../constants/appConstants"
import styles from "./WorkflowCard.module.scss"

function WorkflowCard({ log, id, index, stepsLength, isDataLoading, filteredTools }) {
    const markers = useSelector((state) => state.findingsState.markers)
    const stepFindings = markers.filter((item) => +item?.step?.id === +id)
    const filteredToolsByFather = filteredTools.filter((tool) => tool.father === +id)
    const averageRiskLevel = calculateAverageRisk(filteredToolsByFather)

    const riskObject = RISK_LEVELS.find((risk) => risk.value === averageRiskLevel)

    const riskColor = averageRiskLevel === 0 ? "#000" : riskObject ? riskObject.color : "#000"

    if (isDataLoading) {
        return (
            <Box className={styles.workflowCardLoading}>
                <CircularProgress style={{ width: "30px", height: "30px" }} />
            </Box>
        )
    }

    return (
        <Box className={styles.workflowCardContainer}>
            <Link to={`step/${id}`} className={styles.workflowCardLink}>
                <Box className={styles.workflowCardName}>{log?.note}</Box>
                <Box className={styles.workflowCardParameters}>
                    <Box
                        style={{ color: riskColor }}
                        className={styles.workflowCardParametersRiskLevel}
                    >
                        {averageRiskLevel}
                    </Box>
                    <Box className={styles.workflowCardParametersFindings}>
                        {stepFindings?.length} {stepFindings?.length !== 1 ? "Findings" : "Finding"}
                    </Box>
                    {log?.status === "Completed" ? (
                        <img src={completedIcon} alt="completedIcon" />
                    ) : null}
                </Box>
            </Link>
            {index < stepsLength - 1 && (
                <Box className={styles.workflowCardIcon}>
                    <img
                        src={stepsArrow}
                        alt="stepsArrow"
                        style={{
                            position: "absolute",
                            right: "-10px",
                            top: "0",
                        }}
                    />
                </Box>
            )}
        </Box>
    )
}

export default WorkflowCard
