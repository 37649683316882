import React, { useEffect, useState } from "react"
import { savedMenuConfigs, updateUserCompany, resetDemoUser } from "../../services/configPage/configPageServices"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useAuth0 } from "@auth0/auth0-react"
import withCustomAuthenticationRequired from "../../components/Hoc/with-custom-authentication-required"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import { changeMenuConfig } from "../../redux/actions/menuConfigActions"
import { useForm, Controller } from "react-hook-form"
import { Box, CircularProgress, Button } from "@mui/material"
import { CompanySelect } from "./components/CompanySelect/CompanySelect"
import { handleShowToaster } from "../../redux/actions/showToasterActions"
import { DataModeSelect } from "./components/DataModeSelect/DataModeSelect"
import { DATA_MODES } from "../../constants/appConstants"
import { setDataModeState } from "../../redux/actions/dataModeActions"
import { isRedstringsUser } from "../../helpers/standardHelpers"

function ConfigPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useAuth0()
    const [isLoadingData, setIsLoadingData] = useState(false)

    const [configs, setConfigs] = useState([])
    const [currentCompany, setCurrentCompany] = useState(null)

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const currentMenuConfig = useSelector((state) => state.menuConfig.value)
    const currentMenuConfigName = useSelector((state) => state.menuConfig.name)
    const companies = useSelector((state) => state.companiesState.companies)
    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)
    const currentDataMode = useSelector((state) => state.dataModeState.mode)

    if (!isRedstringsUser(user)) navigate("/")

    const { watch, control, setValue } = useForm({
        defaultValues: {
            configs: configs,
            config: "",
        },
    })
    const values = watch()

    useEffect(() => {
        setValue("config", currentMenuConfigName)
    }, [configs, currentMenuConfig, currentMenuConfigName, setValue])

    useEffect(() => {
        if (companies?.length > 0 && currentCompanyId) {
            setCurrentCompany(companies.find((company) => company.id === currentCompanyId))
        }
    }, [companies, currentCompanyId])


    const getMenuConfigs = async () => {
        if (isAuthorized) {
            setIsLoadingData(true)
            try {
                const ret = await savedMenuConfigs(dispatch, refreshAccessTokenCounter)
                setValue("configs", ret)
                setConfigs(ret)
            } catch (e) {
                console.log(e.message)
            } finally {
                setIsLoadingData(false)
            }
        }
    }

    useEffect(() => {
        getMenuConfigs()
    }, [])

    const handleSelectCompany = async ({ target: { value } }) => {
        const selectedCompanyId = Number(value)
        const selectedCompany = companies.find((company) => company.id === selectedCompanyId)

        setCurrentCompany(selectedCompany)

        try {
            await updateUserCompany(selectedCompanyId, dispatch, refreshAccessTokenCounter)
            dispatch(handleShowToaster("success", "Successfully updated company"))
        } catch (error) {
            console.error(error)
            dispatch(handleShowToaster("error", "Action failed"))
        }
    }

    const handleSelectDataMode = async ({ target: { value } }) => {
        if (!value) return
        const selectedDataMode = DATA_MODES.find((mode) => mode.value === value)

        if (selectedDataMode) {
            dispatch(setDataModeState({ mode: selectedDataMode }))
        }
    }

    const handleResetDemoUser = async ({ target: { value } }) => {
        try {
            await resetDemoUser(dispatch, refreshAccessTokenCounter)
            dispatch(handleShowToaster("success", "Successfully Reset demo user"))
        } catch (error) {
            console.error(error)
            dispatch(handleShowToaster("error", "Action failed"))
        }
    }

    return (
        <Box marginLeft="30px" padding="0 64px">
            {isLoadingData ? (
                <Box display="flex" justifyContent="center" alignItems="center" marginTop="100px">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <p>CHOOSE MENU CONFIGS</p>
                    {values.configs && Object.keys(values.configs).length > 0 && (
                        <Controller
                            name="config"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    onChange={(value) => {
                                        const backToArray = value.target.value
                                            .slice(value.target.value.indexOf("-") + 1)
                                            .split(",")
                                            .map(Number)
                                        dispatch(changeMenuConfig(backToArray, value.target.value))
                                    }}
                                    variant="outlined"
                                    label="Configs"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                >
                                    {Object.keys(values.configs).map((config, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                // transform to unique string
                                                value={config + "-" + values.configs[config]}
                                            >
                                                {config}
                                            </MenuItem>
                                        )
                                    })}
                                </TextField>
                            )}
                        />
                    )}
                    <p>CHOOSE COMPANY</p>
                    <CompanySelect
                        currentCompany={currentCompany}
                        handleSelectCompany={handleSelectCompany}
                        companies={companies}
                    />
                    <p>CHOOSE DATA MODE</p>
                    <DataModeSelect
                        currentDataMode={currentDataMode}
                        handleSelectDataMode={handleSelectDataMode}
                        companies={companies}
                    />
                    <p>RESET user@demo.com CASES TO INITIAL STATE</p>
                    <Button
                        onClick={handleResetDemoUser}
                        variant="outlined"
                        color="success"
                    >
                        Reset DEMO User
                    </Button>
                </>
            )}
        </Box>
    )
}

export default withCustomAuthenticationRequired(ConfigPage)
