import React from "react"
import { Box } from "@mui/material"
import integrationsIcon from "../../../../../../../../../../assets/icons/Repo/integrations-icon.svg"
import styles from "../../TemplateDescription.module.scss"

export function Integrations({ integrations }) {
    return (
        <Box className={styles.templateDescriptionDesignationsIntegrations}>
            <Box className={styles.templateDescriptionDesignationsIntegrationsTitle}>
                <img src={integrationsIcon} />
                Integration Requirements: {integrations?.length} internal data sources:
            </Box>
            <ul className={styles.templateDescriptionDesignationsIntegrationsList}>
                {integrations?.map((integration, index) => (
                    <li key={`${integration}${index}`}>{integration}</li>
                ))}
            </ul>
        </Box>
    )
}
