import React, { useEffect, useState } from "react"
import {
    Typography,
    Button,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
    CircularProgress,
} from "@mui/material"
import { useSelector } from "react-redux"
import { RUNNERS_TABLE_CELLS } from "../../../../../constants/appConstants"
import { RunnersTableRow } from "../RunnersTableRow/RunnersTableRow"
import styles from "./RunnersTable.module.scss"
import { NewRunnerDialog } from "../NewRunnerDialog/NewRunnerDialog"
import { useRunners } from "../../../../../hooks/admin/useRunners"
import { EditRunnerDialog } from "../EditRunnerDialog/EditRunnerDialog"

export const RunnersTable = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [newRunnerModalOpen, setNewRunnerModalOpen] = useState(false)
    const [editRunnerModalOpen, setEditRunnerModalOpen] = useState(false)
    const [currentEditingRunner, setCurrentEditingRunner] = useState(null)

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)

    const {
        runners,
        loadingRunners,
        handleCreateNewRunner,
        handleDeleteRunner,
        handleUpdateRunner,
    } = useRunners(refreshAccessTokenCounter)

    const itemsPerPage = 5
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    const handleOpenAddNewRunner = () => {
        setNewRunnerModalOpen(true)
    }

    return (
        <Box className={styles.runnersTableContainer} marginTop={5}>
            <Box className={styles.runnersTableHeader}>
                <Typography className={styles.runnersTableHeaderStatus}>{"Runners"}</Typography>
                <Button
                    onClick={handleOpenAddNewRunner}
                    className={styles.runnersTableHeaderAddButton}
                    variant="outlined"
                    color="success"
                >
                    + Add new runner
                </Button>
            </Box>
            <TableContainer className={styles.runnersTableMainContainer}>
                {loadingRunners ? (
                    <Box className={styles.runnersTableLoadingWrapper}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {RUNNERS_TABLE_CELLS.map((cell, index) => (
                                    <TableCell
                                        style={{ width: cell?.width, backgroundColor: "#F7F8FD" }}
                                        align={cell?.align}
                                        key={`${cell?.name}${index}`}
                                        className={styles.runnersTableMainTitleCell}
                                    >
                                        <Typography window="100px" fontSize="16px" fontWeight="500">
                                            {cell?.name}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {runners?.slice(startIndex, endIndex)?.map((row, index) => (
                                <RunnersTableRow
                                    key={row?.id}
                                    row={row}
                                    handleDeleteRunner={handleDeleteRunner}
                                    setCurrentEditingRunner={setCurrentEditingRunner}
                                    setEditRunnerModalOpen={setEditRunnerModalOpen}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            {runners.length > 0 && !loadingRunners && (
                <Box marginTop="10px">
                    <Pagination
                        count={Math.ceil(runners?.length / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, value) => setCurrentPage(value)}
                    />
                </Box>
            )}
            <NewRunnerDialog
                open={newRunnerModalOpen}
                setNewRunnerModalOpen={setNewRunnerModalOpen}
                handleCreateNewRunner={handleCreateNewRunner}
            />
            <EditRunnerDialog
                open={editRunnerModalOpen}
                currentEditingRunner={currentEditingRunner}
                setEditRunnerModalOpen={setEditRunnerModalOpen}
                setCurrentEditingRunner={setCurrentEditingRunner}
                handleUpdateRunner={handleUpdateRunner}
                handleDeleteRunner={handleDeleteRunner}
            />
        </Box>
    )
}
