export const CHANGE_MENU_CONFIG = "CHANGE_MENU_CONFIG"
export const LIGHT_THEME = "LIGHT_THEME"
export const DARK_THEME = "DARK_THEME"
export const CHANGE_SIDEBAR_CHOICE = "CHANGE_SIDEBAR_CHOICE"
export const CHANGE_SIDEBAR_STATE = "CHANGE_SIDEBAR_STATE"
export const SET_OPEN_SUB_DIALOG = "SET_OPEN_SUB_DIALOG"
export const SET_SUBSCRIBE_USER = "SET_SUBSCRIBE_USER"
export const SET_REFERRER_LINK = "SET_REFERRER_LINK"
export const SET_REFRESH_ACCESS_TOKEN_COUNTER = "SET_REFRESH_ACCESS_TOKEN_COUNTER"
export const SET_IS_AUTHORIZED = "SET_IS_AUTHORIZED"
export const SET_LOADER_STATE = "SET_LOADER_STATE"
export const SET_REDIRECT_STATE = "SET_REDIRECT_STATE"
export const SET_LOADER_MESSAGE = "SET_LOADER_MESSAGE"
export const SET_SUCCESS_STATE = "SET_SUCCESS_STATE"
export const SET_TOASTER_STATE = "SET_TOASTER_STATE"
export const SET_FINDINGS_STATE = "SET_FINDINGS_STATE"
export const SET_HOVER_ID = "SET_HOVER_ID"
export const SET_FINDINGS_CLICK_ID = "SET_FINDINGS_CLICK_ID"
export const SET_COMPANIES_STATE = "SET_COMPANIES_STATE"
export const SET_USER_STATE = "SET_USER_STATE"
export const SET_ADMIN_USERS = "SET_ADMIN_USERS"
export const SET_PLAYGROUND_TEMPLATE_STATE = "SET_PLAYGROUND_TEMPLATE_STATE"
export const SET_DATA_MODE = "SET_DATA_MODE"
export const SET_PLAYBOOK_STATE = "SET_PLAYBOOK_STATE"
