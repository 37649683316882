import React, { useEffect, useState, useMemo, useRef } from "react"
import { Box, TextField, Divider, MenuItem, CircularProgress } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import { setFindingClickId } from "../../../../../../redux/actions/findingsActions"
import findingsDots from "../../../../../../assets/icons/findingsDots.svg"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { RISK_LEVELS } from "../../../../../../constants/appConstants"
import { LoadingButton } from "@mui/lab"
import { updateLog } from "../../../../../../services/notebook/notebookServices"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import { RemoveDialog } from "../../../RemoveDialog/RemoveDialog"
import makeStyles from "@mui/styles/makeStyles"
import { FindingsItemOptionsPopOver } from "../FindingsItemOptionsPopOver/FindingsItemOptionsPopOver"
import styles from "./FindingsItem.module.scss"

const useStyles = makeStyles(() => ({
    submitButton: {
        borderRadius: "38px",
        backgroundColor: "#000000",
        color: "#fff",
        alignSelf: "center",
        fontSize: "14px",
        height: "24px",
        width: "80px",
        fontWeight: "500",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#282727",
        },
        "&.Mui-disabled": {
            color: "#d4d5d6",
        },
        "&.MuiLoadingButton-loading": {
            color: "#000",
        },
    },
}))

export function FindingsItem({
    name,
    value,
    description,
    risk_level,
    tool_name,
    uniqueId,
    request,
    markers,
    index,
    values,
    setValue,
    refreshPageData,
    toolbox,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const dotsButtonRef = useRef()

    const [isEdit, setIsEdit] = useState(false)
    const [riskLevel, setRiskLevel] = useState(risk_level)
    const [descriptionText, setDescriptionText] = useState(description)
    const [removetoolDialogOpen, setRemovetoolDialogOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showOptionsPopOver, setShowOptionsPopOver] = useState(false)

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const hoverId = useSelector((state) => state.findingsState.id)

    const riskLevelImage = useMemo(
        () => RISK_LEVELS.find((level) => level.value === risk_level)?.img,
        [risk_level],
    )

    const riskColor = useMemo(() => {
        if (riskLevel === 0) return "#9aa3ab"
        return RISK_LEVELS.find((level) => level.value === riskLevel)?.color
    }, [riskLevel])

    const handleEdit = () => {
        setShowOptionsPopOver(false)
        setIsEdit(true)
    }

    const handleCancel = () => {
        setIsEdit(false)
        setDescriptionText(description)
        setRiskLevel(risk_level)
    }

    const handleChangeRiskLevel = (level) => {
        setRiskLevel(level)
    }

    const handleOpenDelete = (e) => {
        e.stopPropagation()
        setShowOptionsPopOver(false)
        setRemovetoolDialogOpen(true)
    }

    const handleViewClick = () => {
        dispatch(setFindingClickId(uniqueId))
    }

    const handleToggleOptionPopOver = (e) => {
        e.stopPropagation()
        setShowOptionsPopOver(!showOptionsPopOver)
    }

    useEffect(() => {
        setDescriptionText(description)
    }, [description])

    useEffect(() => {
        setRiskLevel(risk_level)
    }, [risk_level])

    const handleToolAction = async (
        updatedMarkers,
        successMessage,
        errorMessage,
        tool_id = null,
    ) => {
        try {
            setIsLoading(true)
            const log = await updateLog(
                values.case_id,
                values.tools[tool_id]?.id,
                {
                    ...values.tools[tool_id].log,
                    standard_response_risk_levels: updatedMarkers,
                },
                dispatch,
                refreshAccessTokenCounter,
            )
            setValue(
                `tools.${tool_id}.log.standard_response_risk_levels`,
                log["standard_response_risk_levels"],
            )
            await refreshPageData()
            setIsEdit(false)
            dispatch(handleShowToaster("success", successMessage))
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", errorMessage))
        } finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async () => {
        if (index < 0) return

        const { tool_id: toolId, uniqueId } = markers[index]
        const toolMarkers = [...(values.tools[toolId]?.log?.standard_response_risk_levels || [])]
        const updatedMarkers = toolMarkers.map((marker) => {
            if (marker.uniqueId === uniqueId) {
                return {
                    ...marker,
                    risk_level: riskLevel,
                    description: descriptionText,
                }
            }
            return marker
        })

        handleToolAction(
            updatedMarkers,
            "Successfully updated marker information",
            "Action failed",
            toolId,
        )
    }

    const handleDelete = async () => {
        if (index < 0) return

        const { tool_id: toolId, uniqueId } = markers[index]
        const {
            log: { standard_response_risk_levels: toolMarkers },
        } = values.tools[toolId]

        const updatedMarkers = toolMarkers.filter((marker) => marker.uniqueId !== uniqueId)

        handleToolAction(updatedMarkers, "Successfully deleted marker", "Deletion failed", toolId)
        setRemovetoolDialogOpen(false)
    }

    return (
        <Box
            className={styles.findingsItemWrapper}
            style={{
                border: uniqueId === hoverId ? `1px solid ${riskColor}` : "",
            }}
        >
            <Box className={styles.findingsItemTitleWrapper}>
                <Box className={styles.findingsItemTitleTextName}>{tool_name}</Box>
                {!isLoading ? (
                    <Box className={styles.findingsItemTitleIconWrapper}>
                        {!toolbox && (
                            <VisibilityIcon
                                style={{ width: "15px", cursor: "pointer" }}
                                onClick={handleViewClick}
                            />
                        )}

                        <Box
                            ref={dotsButtonRef}
                            onClick={handleToggleOptionPopOver}
                            className={styles.findingsItemTitleIcon}
                        >
                            <img src={findingsDots}></img>
                        </Box>
                    </Box>
                ) : (
                    <CircularProgress style={{ width: "20px", height: "20px" }} />
                )}
            </Box>
            <Divider />
            <Box className={styles.findingsItemMainWrapper}>
                <Box className={styles.findingsItemMainNameWrapper}>
                    <Box className={styles.findingsItemMainName}>{name}:</Box>
                    <Box className={styles.findingsItemRiskLevelIcon}>
                        <img src={riskLevelImage}></img>
                    </Box>
                </Box>

                <Box className={styles.findingsItemMainValue}>{String(value) || "None"}</Box>
            </Box>
            <Box className={styles.findingsItemDescriptionWrapper}>
                {isEdit ? (
                    <TextField
                        onChange={(e) => setDescriptionText(e.target.value)}
                        disabled={isLoading}
                        value={descriptionText}
                        variant="outlined"
                        multiline
                        placeholder="Description"
                        InputProps={{
                            className: styles.input,
                        }}
                        InputLabelProps={{
                            style: {
                                color: "#6B869A",
                            },
                        }}
                        fullWidth
                    />
                ) : (
                    <Box className={styles.findingsItemDescriptionText}>{description}</Box>
                )}
            </Box>
            {isEdit && (
                <>
                    <Box className={styles.findingsItemRiskScoreIcons}>
                        {RISK_LEVELS.map((level) => (
                            <MenuItem
                                disabled={isLoading}
                                selected={riskLevel === level.value}
                                onClick={() => handleChangeRiskLevel(level.value)}
                                sx={{
                                    "&.MuiMenuItem-root": {
                                        padding: "8px",
                                        borderRadius: "4px",
                                        border: "1px solid #EFEEF1",
                                    },
                                    "&.Mui-selected": {
                                        backgroundColor: "#EDEEF8",
                                        borderRadius: "4px",
                                        border: "1px solid #5E4AFD",
                                    },
                                }}
                                key={level?.value}
                                value={level?.title}
                            >
                                <img src={level?.img}></img>
                            </MenuItem>
                        ))}
                    </Box>
                    <Box className={styles.findingsItemRiskScoreButtons}>
                        <LoadingButton
                            disabled={isLoading}
                            onClick={handleCancel}
                            className={styles.findingsItemRiskScoreButtonsCancel}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            loadingIndicator={
                                <CircularProgress style={{ width: "15px", height: "15px" }} />
                            }
                            disabled={isLoading}
                            loading={isLoading}
                            onClick={handleSubmit}
                            className={classes.submitButton}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </>
            )}
            <RemoveDialog
                open={removetoolDialogOpen}
                setClosed={() => setRemovetoolDialogOpen(false)}
                handleAgree={handleDelete}
                name="finding"
            />

            {showOptionsPopOver && (
                <FindingsItemOptionsPopOver
                    setShowOptionsPopOver={setShowOptionsPopOver}
                    dotsButtonRef={dotsButtonRef}
                    handleEdit={handleEdit}
                    handleOpenDelete={handleOpenDelete}
                />
            )}
            {/* {hoveredElement && hoveredElement?.text?.length > 10 && (
                <PopOver text={hoveredElement?.text} anchorEl={anchorEl} />
            )} */}
        </Box>
    )
}
