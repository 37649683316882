import React, { useState, useMemo } from "react"
import { RISK_LEVELS } from "../../../../../../constants/appConstants"
import { Link } from "react-router-dom"
import { Box, TableRow, TableCell } from "@mui/material"
import { PopOver } from "../../../../../../components/PopOver/PopOver"
import styles from "./DiscoveryRow.module.scss"

export function DiscoveryRow({ name, value, description, step, risk_level, user, tool_id }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [hoveredElement, setHoveredElement] = useState(null)

    const riskLevelImage = useMemo(
        () => RISK_LEVELS.find((level) => level.value === risk_level)?.img,
        [risk_level],
    )

    const handlePopoverOpen = (event, key) => {
        setAnchorEl(event.currentTarget)
        setHoveredElement({ text: cells[key] })
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
        setHoveredElement(null)
    }

    const textToDisplay = {
        tool_name: `${name}: ${String(value) || "None"}`,
        description: description,
        step: step?.note,
    }
    const shouldShowPopover = (key) => textToDisplay[key] && textToDisplay[key].length > 25

    const cells = {
        tool_name: (
            <Link
                to={`step/${step?.id}`}
                state={{ scrollToElement: true, id: tool_id }}
                className={styles.discoveryRowLink}
                onMouseEnter={
                    shouldShowPopover("tool_name") ? (e) => handlePopoverOpen(e, "tool_name") : null
                }
                onMouseLeave={handlePopoverClose}
            >
                {/* TODO: Rewrite to better way */}
                {value !== " " ? `${name}:` : name} {String(value) || "None"}
            </Link>
        ),
        description: (
            <div
                onMouseEnter={
                    shouldShowPopover("description")
                        ? (e) => handlePopoverOpen(e, "description")
                        : null
                }
                onMouseLeave={handlePopoverClose}
            >
                {description}
            </div>
        ),
        step: (
            <div
                onMouseEnter={
                    shouldShowPopover("step") ? (e) => handlePopoverOpen(e, "step") : null
                }
                onMouseLeave={handlePopoverClose}
            >
                {step?.note}
            </div>
        ),
        user: user,
        risk_level: (
            <Box className={styles.discoveryRowRiskImg}>
                <img src={riskLevelImage} alt="Risk Level" />
            </Box>
        ),
    }

    return (
        <TableRow style={{ padding: "20px" }}>
            {Object.keys(cells).map((key, index) => (
                <TableCell key={key} className={styles.discoveryRowCell}>
                    {cells[key]}
                </TableCell>
            ))}
            <PopOver
                text={hoveredElement?.text}
                anchorEl={anchorEl}
                handlePopoverClose={handlePopoverClose}
            />
        </TableRow>
    )
}
