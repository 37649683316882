import React from "react"
import { Modal, Box, CircularProgress, Button } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"
import styles from "./RepoLoginModal.module.scss"

export function RepoLoginModal({ open, onClose }) {
    const { loginWithRedirect } = useAuth0()
    return (
        <Modal
            open={open}
            onClose={onClose}
            className={styles.repoLoginModalContainer}
            role="dialog"
        >
            <Box className={styles.repoLoginModalWrapper}>
                <Box className={styles.repoLoginModalText}>
                    <p>
                        Please log in or register to access our exclusive content. Your security is
                        important to us, and logging in ensures a personalized and secure
                        experience.
                    </p>
                </Box>
                <Button
                    className={styles.repoLoginModalContinueButton}
                    onClick={() =>
                        loginWithRedirect({
                            appState: {
                                returnTo: "/home",
                            },
                        })
                    }
                >
                    Continue
                </Button>
            </Box>
        </Modal>
    )
}
