import React, { useState, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { doToolAction } from "../../../services/notebook/notebookServices"
import { useSelector, useDispatch } from "react-redux"
import { useAuth0 } from "@auth0/auth0-react"
import { Box, Grid, Typography } from "@mui/material"
import { Controller } from "react-hook-form"
import TextField from "@mui/material/TextField"
import Checkbox from "@mui/material/Checkbox"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { DotsMenu } from "./DotsMenu"

const useStyles = makeStyles((theme) => ({
    input: {
        height: 39,
        // backgroundColor: "#f4f5f7" ,
        padding: "0 5px",
        fontSize: "1em",
    },
    noBefore: {
        "&::before": {
            display: "none",
        },
    },
}))

function ActionItems({
    tool_id,
    index,
    index_x,
    index_y,
    handleAddTools,
    handleDeleteTool,
    control,
    values,
    setValue,
    resetField,
    watch,
    setFocus,
    integrations,
    toolsConfig,
    subtitle,
    isFromToolbox,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const { isAuthenticated, isLoading } = useAuth0()

    const action_items = watch(`tools.${tool_id}.log.action_items`)

    const [updatedActionItems, setUpdatedActionItems] = useState(null)

    useEffect(() => {
        if (updatedActionItems) setValue(`tools.${tool_id}.log.action_items`, updatedActionItems)
    }, [updatedActionItems])

    useEffect(() => {
        if (!action_items) {
            setValue(`tools.${tool_id}.log.action_items`, [
                {
                    checked: false,
                    value: "",
                },
            ])
        }
    }, [])

    const [updateEveryFieldChange, setUpdateEveryFieldChange] = useState(true)
    const [focusNow, setFocusNow] = useState(false)
    const [indexToFocusOn, setIndexToFocusOn] = useState(0)

    useEffect(() => {
        if (action_items && focusNow) {
            setFocus(`tools.${tool_id}.log.action_items.${indexToFocusOn}.value`)
            setFocusNow(false)
        }
    }, [action_items, updateEveryFieldChange])

    const sendUpdatedDataToServer = (updatedData) => {
        if (isAuthorized) {
            doToolAction(
                values.case_id,
                values.tools[tool_id]?.id,
                values.tools[tool_id].type,
                { action_items: updatedData },
                dispatch,
                refreshAccessTokenCounter,
            )
        }
    }

    const handleActionItemChange = (ev, action_item_index) => {
        const newValue = ev.target.value
        const updatedData = values.tools[tool_id].log.action_items.map((item, index) => {
            if (index === action_item_index) {
                return { ...item, value: newValue }
            }
            return item
        })
        setValue(`tools.${tool_id}.log.action_items`, updatedData)
        sendUpdatedDataToServer(updatedData)
    }

    const handleOnKeyDown = (ev, action_item_index, action_item) => {
        if (ev.key === "Enter") {
            var new_action_item = {
                checked: false,
                value: "",
            }
            const newData = values.tools[tool_id].log.action_items.slice(0)
            newData.splice(action_item_index + 1, 0, new_action_item)
            resetField(`tools.${tool_id}.log.action_items`)
            setUpdatedActionItems(newData)
            setIndexToFocusOn(action_item_index + 1)
            setFocusNow(true)
            sendUpdatedDataToServer(newData)
        } else if (action_item_index > 0 && ev.key === "Backspace" && action_item.value === "") {
            const newData = values.tools[tool_id].log.action_items.filter(
                (action_item, key) => action_item_index != key,
            )
            resetField(`tools.${tool_id}.log.action_items`)
            setUpdatedActionItems(newData)
            setIndexToFocusOn(action_item_index - 1)
            setFocusNow(true)
            sendUpdatedDataToServer(newData)
        }
        setUpdateEveryFieldChange(!updateEveryFieldChange)
    }

    return (
        <Grid xs={12} marginBottom={"20px"} marginTop={"20px"} position="relative">
            <Box
                sx={{
                    background: "#f3f3f3",
                    borderRadius: "10px",
                }}
            >
                {!isFromToolbox && (
                    <DotsMenu
                        handleAddTools={handleAddTools}
                        index_x={index_x}
                        index_y={index_y}
                        values={values}
                        integrations={integrations}
                        toolsConfig={toolsConfig}
                        handleDeleteTool={handleDeleteTool}
                        tool_id={tool_id}
                    />
                )}

                <Accordion
                    defaultExpanded
                    style={{
                        backgroundColor: "#f3f3f3",
                        boxShadow: "none",
                        borderRadius: "10px",
                        margin: "0",
                    }}
                    className={classes.noBefore}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography color="black" variant="h6" fontWeight="400">
                            Action Items
                        </Typography>
                    </AccordionSummary>
                    {action_items &&
                        action_items.map((action_item, action_item_index) => {
                            return (
                                <Grid
                                    key={action_item_index}
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    marginLeft="20px"
                                    marginBottom="20px"
                                >
                                    <Grid item xs="0.5" style={{ textAlign: "left" }}>
                                        <Controller
                                            name={`tools.${tool_id}.log.action_items.${action_item_index}.checked`}
                                            control={control}
                                            render={({ field: props }) => (
                                                <Checkbox
                                                    checked={action_item.checked}
                                                    onChange={(e) => {
                                                        props.onChange(e.target.checked)
                                                        setUpdateEveryFieldChange(
                                                            !updateEveryFieldChange,
                                                        )
                                                    }}
                                                    style={{
                                                        color: action_item.checked ? "#26DBA0" : "",
                                                        transform: "scale(1.5)",
                                                    }}
                                                    disabled={!action_item.value}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs="11.5">
                                        <Controller
                                            name={`tools.${tool_id}.log.action_items.${action_item_index}.value`}
                                            // not working with default value!
                                            control={control}
                                            render={({ field: { ref, ...field } }) => (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    id="ip"
                                                    variant="standard"
                                                    placeholder="Type your action"
                                                    InputProps={{
                                                        className: classes.input,
                                                        disableUnderline: true,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(ev) =>
                                                        handleActionItemChange(
                                                            ev,
                                                            action_item_index,
                                                        )
                                                    }
                                                    fullWidth
                                                    onKeyDown={(ev) =>
                                                        handleOnKeyDown(
                                                            ev,
                                                            action_item_index,
                                                            action_item,
                                                        )
                                                    }
                                                    style={{
                                                        fontSize: "1.3em",
                                                        textDecoration: action_item.checked
                                                            ? "line-through"
                                                            : "",
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        })}
                </Accordion>
            </Box>
        </Grid>
    )
}

export default ActionItems
