import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import Grid from "@mui/material/Grid"
import SendIcon from "@mui/icons-material/Send"
import MuiAlert from "@mui/material/Alert"
import { Box } from "@mui/material"
import { ReactMultiEmail, isEmail } from "react-multi-email"
import { useSelector, useDispatch } from "react-redux"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import { sharePlaybookWithEmailInvites } from "../../../../../../services/playbook/playbookServices"
import "react-multi-email/style.css"
import styles from "./RepoHeader.module.scss"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function ShareDialog({ sendToolboxDialogOpen, setSendToolboxDialogOpen }) {
    const [emails, setEmails] = useState([])

    const dispatch = useDispatch()

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const handleCloseMailingListDialog = (event, reason) => {
        setSendToolboxDialogOpen(false)
    }

    const handleSendMails = async () => {
        try {
            // send mails
            await sharePlaybookWithEmailInvites(emails, dispatch, refreshAccessTokenCounter)
            handleCloseMailingListDialog()
            dispatch(handleShowToaster("success", "The toolbox was sent to your mail successfuly"))
        } catch (error) {
            dispatch(handleShowToaster("error", "Action failed"))
            console.error("Error sending emails", error)
        } finally {
            setEmails([])
        }
    }

    return (
        <div>
            <Dialog
                onClose={handleCloseMailingListDialog}
                open={sendToolboxDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    className: styles.repoHeaderShare,
                }}
            >
                <DialogTitle>Invite your collegeus, spread the word!</DialogTitle>
                <DialogContent style={{ padding: "0 24px 16px 24px" }}>
                    <DialogContentText
                        id="alert-dialog-description"
                        fontSize="16px"
                        style={{ marginBottom: "50px" }}
                    >
                        Did you find this toolbox helpful? Share it with your teammates and
                        colleagues.
                    </DialogContentText>
                    <Grid item container direction="row" alignItems="center">
                        <Grid item xs={11}>
                            <ReactMultiEmail
                                placeholder="email addresses"
                                emails={emails}
                                onChange={(_emails) => setEmails(_emails)}
                                validateEmail={(email) => {
                                    return isEmail(email)
                                }}
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                        <div data-tag key={index}>
                                            {email}
                                            <span
                                                data-tag-handle
                                                onClick={() => removeEmail(index)}
                                            >
                                                ×
                                            </span>
                                        </div>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSendMails}
                        autoFocus
                        style={{
                            fontSize: "1em",
                            textTransform: "none",
                            padding: "5px 24px",
                            color: "white",
                            borderRadius: "38px",
                            fontWeight: 400,
                            margin: "10px 24px",
                            backgroundColor: emails.length > 0 ? "#096BBB" : "grey",
                        }}
                        disabled={!(emails.length > 0)}
                    >
                        Send
                        <SendIcon
                            style={{
                                fontSize: "1em",
                                marginLeft: "5px",
                            }}
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
