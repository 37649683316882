import React from "react"
import { Box, Typography, Divider } from "@mui/material"
import styles from "./Summary.module.scss"
import makeStyles from "@mui/styles/makeStyles"


const useStyles = makeStyles(() => ({
    caseDetailsWrapper: {
        backgroundColor: "#FFFFFF",
        width: "300px",
        height: "100%",
        borderRight: "1px solid #EFEEF1",
        overflowY: "auto",
    },
    editButton: {
        border: "1px solid #000000",
        borderRadius: "38px",
        textTransform: "none",
        color: "#000000",
        width: "55px",
        height: "32px",
        fontWeight: "400",
        "&:hover": {
            color: "#374957",
        },
    },
    btnWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px",
        position: "fixed",
        bottom: "0",
        height: "70px",
        width: "268px",
        borderTop: "1px solid #EFEEF1",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        zIndex: 2,
    },
    saveButton: {
        borderRadius: "38px",
        textTransform: "none",
        backgroundColor: "#000000",
        color: "#fff",
        fontWeight: "400",
        height: "32px",
        "&:hover": {
            backgroundColor: "#282727",
        },
    },
    cancelButton: {
        borderRadius: "38px",
        textTransform: "none",
        backgroundColor: "#EDEEF8",
        color: "#000000",
        fontWeight: "400",
        height: "32px",
    },
    caseBox: {
        display: "flex",
        position: "relative",
        flexDirection: "column",
        gap: "10px",
        "& .rti--container": {
            padding: "16px 14px",
            borderRadius: 10,
            width: "100%",
            minHeight: "56px",
        },
    },
    caseInput: {
        border: "none",
        fontSize: "16px",
        fontWeight: 500,
    },
    stepDot: {
        width: "16px",
        height: "16px",
        borderRadius: "100%",
        zIndex: "10",
    },
    stepLine: {
        width: "44px",
        height: "2px",
        backgroundColor: "#E1E1E1",
    },
    stepper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "200px",
        position: "relative",
    },
    stepProgress: {
        backgroundColor: "#E1E1E1",
        position: "absolute",
        top: "50%",
        left: "0",
        transform: "translateY(-50%)",
        height: "2px",
        width: "100%",
        zIndex: "0",
        transition: ".4s ease",
    },
    input: {
        borderRadius: 10,
    },
    tagsInput: {
        fontWeight: "400",
        fontSize: "14px",
        color: "#6B869A",
        backgroundColor: "rgba(94, 74, 253, 0.05)",
        border: "1px solid rgba(94, 74, 253, 0.1)",
        borderRadius: "4px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    tagsInputEmpty: {
        width: "100%",
    },
    tagInputLabel: {
        position: "absolute",
        left: "15px",
        top: "-8px",
        width: "30px",
        height: "16px",
        zIndex: "2",
        backgroundColor: "#fff",
        fontSize: "12px",
        color: "#6B869A",
    },
    addToolBtn: {
        backgroundColor: "#EDEEF8",
        border: "2px dashed #FFFFFF",
        borderRadius: "10px",
        width: "220px",
        height: "45px",
        margin: "0px auto",
        display: "flex",
        justifyContent: "center",
    },
}))

export function Savings({ values}) {
    const classes = useStyles()

    return (
        <Box className={classes.caseDetailsWrapper}>
            <Box padding="20px" display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <Typography fontSize="20px" fontWeight="500" color="#000000" flexBasis="100%">
                    Case Cost & Time Saved
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box
                padding="16px"
                paddingBottom="80px"
                display="flex"
                flexDirection="column"
                gap={"20px"}
            >
                <Box className={classes.caseBox}>                    
                    <Typography fontSize="14px" color="#374957">Case Cost</Typography>
                    <Typography fontSize="16px">${values["savings_metrics"]["case_cost_usd"]}</Typography>
                </Box>
                <Box className={classes.caseBox}>                     
                    <Typography fontSize="14px" color="#374957">Manual Time Saved</Typography>
                    <Typography fontSize="16px">{values["savings_metrics"]["time_saved_hours"]}h</Typography>
                </Box>
                <Box className={classes.caseBox}>
                    <Typography fontSize="14px" color="#374957">Manual Work Cost Saved</Typography>
                    <Typography fontSize="16px">${values["savings_metrics"]["cost_saved_usd"]}</Typography>
                </Box>
            </Box>
        </Box>
    )
}
