import React, { useState } from "react"
import { TableRow, TableCell } from "@mui/material"
import styles from "./RunnersTableRow.module.scss"
import moment from "moment/moment"

export const RunnersTableRow = ({
    row,
    handleDeleteRunner,
    setCurrentEditingRunner,
    setEditRunnerModalOpen,
}) => {
    const [removetoolDialogOpen, setRemovetoolDialogOpen] = useState(false)
    const { id, name, description, last_heartbeat_at, created_at } = row
    const formattedDate = moment(created_at)?.format("DD-MM-YYYY HH:mm")

    const handleRowClick = (e) => {
        e.stopPropagation()
        setEditRunnerModalOpen(true)
        setCurrentEditingRunner(row)
    }

    return (
        <TableRow onClick={handleRowClick} className={styles.runnersTableRow}>
            <TableCell>{id}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{description}</TableCell>
            <TableCell>{last_heartbeat_at}</TableCell>
            <TableCell align="right" style={{ display: "flex", gap: "10px" }}>
                {formattedDate}
            </TableCell>
        </TableRow>
    )
}
