import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Box } from "@mui/material"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import makeStyles from "@mui/styles/makeStyles"
import WorkflowCard from "../WorkflowCard/WorkflowCard"
import { LoadingButton } from "@mui/lab"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import ControlPointIcon from "@mui/icons-material/ControlPoint"
import styles from "./Workflow.module.scss"

const useStyles = makeStyles(() => ({
    arrows: {
        "& .slick-next": {
            right: 0,
            transform: "none",
            "&:before": {
                color: "#6B869A",
            },
        },
        "& .slick-prev": {
            left: -22,
            transform: "none",
            "&:before": {
                color: "#6B869A",
            },
        },
    },
}))

export function Workflow({
    steps,
    handleAddTools,
    isDataLoading,
    openMenu,
    selectedMenuItem,
    filteredTools,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const sliderLength = openMenu || selectedMenuItem != null ? 4 : 5

    const stepsLength = steps?.length || 0

    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: sliderLength,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: 0,
        draggable: false,
    }

    const emptySlides = Array.from({ length: sliderLength - stepsLength }, (_, index) => (
        <div key={"empty-" + index} style={{ visibility: "hidden" }}>
            {/* Empty slide to avoid center all slides less sliderLength */}
        </div>
    ))

    const handleAddNewStep = async () => {
        try {
            setIsLoading(true)
            await handleAddTools([46], steps.length, 0, null)
            dispatch(handleShowToaster("success", "Successfully add new step"))
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box className={styles.workFlowContainer}>
            <Box>
                Workflow Analysis ({stepsLength} {stepsLength !== 1 ? "Steps" : "Step"})
            </Box>
            {stepsLength > 0 && (
                <Slider {...settings} className={classes.arrows}>
                    {steps.map((step, index) => (
                        <WorkflowCard
                            log={step?.log}
                            key={step?.id || index}
                            id={step?.id}
                            step={step}
                            index={index}
                            stepsLength={stepsLength}
                            isDataLoading={isDataLoading}
                            filteredTools={filteredTools}
                        />
                    ))}
                    {emptySlides}
                </Slider>
            )}

            {stepsLength === 0 && (
                <LoadingButton
                    loading={isLoading}
                    onClick={handleAddNewStep}
                    className={styles.workFlowNewToolBtn}
                >
                    <ControlPointIcon style={{ width: "15px", height: "15px" }} />
                    Add New Step
                </LoadingButton>
            )}
        </Box>
    )
}
