import * as Sentry from "@sentry/react"
import { SENTRY_DSN } from "../constants/appConstants"

export const sentryOptions = {
    dsn: SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: [
                /^https:\/\/www\.app-dev\.redstrings\.io.*$/,
                /^https:\/\/www\.app\.redstrings\.io.*$/,
            ],
        }),
        new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
}
