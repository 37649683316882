import React, { useEffect, useState, useRef } from "react"
import { Box } from "@mui/material"
import { deleteToolAction } from "../../services/notebook/notebookServices"
import { useSelector, useDispatch } from "react-redux"
import ShareCaseDialog from "./components/ShareCaseDialog"
import DeleteCaseDialog from "./components/DeleteCaseDialog"
import Sidebar from "../../components/SideBar/Sidebar"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import Topbar from "../../components/TopBar/Topbar"
import { AsideMenu } from "./components/AsideMenu/AsideMenu"
import { CaseInformation } from "./components/CaseInformation"
import { Entity } from "./components/Entity/Entity"
import { Event } from "./components/Event/Event.js"
import { Summary } from "./components/Summary/Summary"
import { Savings } from "./components/Savings/Savings.js"
import { Recommendations } from "./components/Recommendations/Recommendations"
import { handleShowToaster } from "../../redux/actions/showToasterActions"
import { Attachments } from "./components/Attachments/Attachments"
import { FindingsList } from "./components/FindingsList/FindingsList"
import { setFindingsState } from "../../redux/actions/findingsActions"
import { RepoHeader } from "../Playbook/pages/Repo/components/RepoHeader/RepoHeader"
import styles from "./Notebook.module.scss"

const Notebook = ({
    refreshPageData,
    control,
    values,
    setValue,
    resetField,
    setCloseCaseDialogOpenHandler,
    watch,
    sendReportDialogOpen,
    setSendReportDialogOpen,
    successEmailOpen,
    setSuccessEmailOpen,
    failEmailOpen,
    setFailEmailOpen,
    setFocus,
    handleAddTools,
    toolsConfig,
    integrations,
    caseTypes,
    entityTypes,
    updateCaseInfoMap,
    resetAllFields,
    caseTitle,
    isDataLoading,
    isFromToolbox,
}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const prevTools = useRef(values?.tools)

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const [saveAsTemplatePopup, setSaveAsTemplatePopup] = useState(false)
    const [deleteCasePopup, setDeleteCasePopup] = useState(false)
    const [selectedMenuItem, setSelectedMenuItem] = useState(0)
    const [numberOfTools, setNumberOfTools] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const [toolEntities, setToolEntities] = useState([])
    const [events, setEvents] = useState([])
    const [recommendations, setRecommendations] = useState([])
    const [summaryTool, setSummaryTool] = useState({})
    const [attachments, setAttachments] = useState([])
    const [filteredTools, setFilteredTools] = useState([])
    const [steps, setSteps] = useState([])
    const [addedToolId, setAddedToolId] = useState(null)

    // set markers
    useEffect(() => {
        const standardResponseRiskLevels = Object.values(values?.tools)
            ?.flatMap((tool) => {
                // Check if standard_response_risk_levels exists and is not empty
                if (
                    tool.log.standard_response_risk_levels &&
                    tool.log.standard_response_risk_levels.length > 0
                ) {
                    // Add tool_id and step.id to each item in standard_response_risk_levels
                    return tool.log.standard_response_risk_levels.map((riskLevel) => ({
                        ...riskLevel,
                        tool_id: tool.id, // Add tool_id field
                        step: {
                            ...riskLevel.step,
                            id: tool.father, // Replace step.id with tool's father value
                        },
                    }))
                }
                return []
            })
            ?.filter(Boolean) // Filter out any falsy values

        const sortedResponseRiskLevels = standardResponseRiskLevels.sort(
            (a, b) => b.risk_level - a.risk_level,
        )

        dispatch(setFindingsState(null, sortedResponseRiskLevels))
    }, [values?.tools])

    const handleRemoveMarkers = () => {
        dispatch(setFindingsState(false, []))
    }

    const handleDeleteTool = async (
        tool_id,
        currentMarkersToolId = null,
        message = "Successfully delete tool",
    ) => {
        const raw = values.tools
        const allowed = []
        try {
            for (const id of Object.keys(values.tools)) {
                if (tool_id != id) {
                    allowed.push(id)
                } else {
                    await deleteToolAction(values.case_id, id, dispatch, refreshAccessTokenCounter)
                    if (currentMarkersToolId && currentMarkersToolId === tool_id) {
                        dispatch(setFindingsState(false, [], null))
                    }
                    await refreshPageData()
                    dispatch(handleShowToaster("success", message))
                }
            }

            const filtered = Object.keys(raw)
                .filter((key) => allowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = raw[key]
                    return obj
                }, {})

            setValue("tools", filtered)
        } catch (e) {
            console.log(e)
            dispatch(handleShowToaster("error", "Action failed"))
        }
    }

    useEffect(() => {
        if (location.state?.scrollToElement && location.state?.id) {
            const element = document.getElementById(`tool-${location.state?.id}`)
            if (element) {
                element.scrollIntoView({ behavior: "smooth" })
                navigate(location.pathname, {
                    state: {
                        ...location.state,
                        scrollToElement: false,
                        id: null,
                    },
                    replace: true,
                })
            }
        }
    }, [location, filteredTools, navigate])

    useEffect(() => {
        if (values?.tools) {
            const filteredTools = []
            const filteredSteps = []
            const entitiesTools = []
            const eventsTools = []
            const recommendationsTools = []
            const attachments = []

            Object.entries(values.tools).forEach(([key, tool]) => {
                if (tool.id) {
                    if (
                        tool.type !== 31 &&
                        tool.type !== 35 &&
                        tool.type !== 27 &&
                        tool.type !== 26 &&
                        tool.type !== 46 &&
                        tool.type !== 8
                    ) {
                        filteredTools.push(tool)
                    }
                    if (tool.type === 8) {
                        attachments.push(tool)
                    }
                    if (tool.type === 46) {
                        filteredSteps.push(tool)
                    }
                    if (tool.type === 31) {
                        entitiesTools.push(tool)
                    }
                    if (tool.type === 35) {
                        eventsTools.push(tool)
                    }
                    if (tool.type === 26) {
                        recommendationsTools.push(tool)
                    }
                    if (tool.type === 27) {
                        setSummaryTool(tool)
                    }
                }
            })

            setFilteredTools(filteredTools.sort((a, b) => a.index_y - b.index_y))
            setSteps(filteredSteps.sort((a, b) => a.index_x - b.index_x))
            setToolEntities(entitiesTools)
            setEvents(eventsTools)
            setAttachments(attachments)
            setRecommendations(recommendationsTools)
            setNumberOfTools(Object.keys(values.tools).length)
        }
        if (values?.tools && prevTools.current) {
            const prevToolIds = Object.keys(prevTools.current)
            const currentToolIds = Object.keys(values.tools)
            const addedToolId = currentToolIds.find((id) => !prevToolIds.includes(id))
            if (addedToolId) {
                setAddedToolId(+addedToolId)
            }
        }
        prevTools.current = values?.tools
    }, [values.tools])

    const selectedMenuComponent = () => {
        switch (selectedMenuItem) {
            case 0:
                return (
                    <CaseInformation
                        control={control}
                        values={values}
                        setValue={setValue}
                        caseTypes={caseTypes}
                        updateCaseInfoMap={updateCaseInfoMap}
                        resetAllFields={resetAllFields}
                        refreshPageData={refreshPageData}
                    />
                )
            case 1:
                return (
                    <Entity
                        values={values}
                        entityTypes={entityTypes}
                        entities={toolEntities}
                        handleAddTools={handleAddTools}
                        control={control}
                        resetAllFields={resetAllFields}
                        handleDeleteTool={handleDeleteTool}
                        refreshPageData={refreshPageData}
                    />
                )
            case 2:
                return (
                    <Event
                        values={values}
                        entityTypes={entityTypes}
                        events={events}
                        handleAddTools={handleAddTools}
                        control={control}
                        resetAllFields={resetAllFields}
                        handleDeleteTool={handleDeleteTool}
                        refreshPageData={refreshPageData}
                    />
                )
            case 3:
                return (
                    <Attachments
                        values={values}
                        attachments={attachments}
                        handleAddTools={handleAddTools}
                        handleDeleteTool={handleDeleteTool}
                        refreshPageData={refreshPageData}
                    />
                )
            case 4:
                return (
                    <Recommendations
                        recommendations={recommendations}
                        control={control}
                        resetField={resetField}
                        values={values}
                        refreshPageData={refreshPageData}
                        handleAddTools={handleAddTools}
                    />
                )
            case 5:
                return (
                    <Summary
                        control={control}
                        summaryTool={summaryTool}
                        values={values}
                        refreshPageData={refreshPageData}
                        setValue={setValue}
                        resetField={resetField}
                    />
                )
            case 6:
                return <Savings values={values} />
        }
    }

    if (isFromToolbox)
        return (
            <Box paddingRight={isFromToolbox ? "0" : "64px"}>
                <RepoHeader toolbox={true} />
                <Box className={styles.notebookComponentWrapper}>
                    <Outlet
                        context={[
                            openMenu,
                            steps,
                            selectedMenuItem,
                            handleAddTools,
                            isDataLoading,
                            filteredTools,
                            values,
                            refreshPageData,
                            integrations,
                            toolsConfig,
                            handleDeleteTool,
                            control,
                            setValue,
                            watch,
                            resetField,
                            setFocus,
                            entityTypes,
                            addedToolId,
                        ]}
                    />
                </Box>
            </Box>
        )

    return (
        <Box padding="0 64px">
            <Topbar
                caseTitle={caseTitle}
                control={control}
                setValue={setValue}
                updateCaseInfoMap={updateCaseInfoMap}
                caseType={values.type}
                values={values}
                steps={steps}
                isDataLoading={isDataLoading}
                caseTypes={caseTypes}
            />
            <AsideMenu
                setSelectedMenuItem={setSelectedMenuItem}
                setOpenMenu={setOpenMenu}
                openMenu={openMenu}
                selectedMenuItem={selectedMenuItem}
            />
            <ShareCaseDialog
                caseTitle={values.title}
                values={values}
                sendReportDialogOpen={sendReportDialogOpen}
                setSendReportDialogOpen={setSendReportDialogOpen}
                successEmailOpen={successEmailOpen}
                setSuccessEmailOpen={setSuccessEmailOpen}
                failEmailOpen={failEmailOpen}
                setFailEmailOpen={setFailEmailOpen}
                setValue={setValue}
            />
            <DeleteCaseDialog
                deleteCasePopup={deleteCasePopup}
                setDeleteCasePopup={setDeleteCasePopup}
                caseId={values.case_id}
            />
            <Sidebar
                values={values}
                setSaveAsTemplatePopup={setSaveAsTemplatePopup}
                setCloseCaseDialogOpenHandler={setCloseCaseDialogOpenHandler}
                setSendReportDialogOpen={setSendReportDialogOpen}
                setDeleteCasePopup={setDeleteCasePopup}
                caseTypes={caseTypes}
            />
            <Box className={styles.notebookContainer}>
                {selectedMenuItem !== null ? (
                    <Box
                        className={styles.notebookMenuComponentWrapper}
                        style={{
                            marginLeft: openMenu ? "160px" : "0px",
                        }}
                    >
                        {selectedMenuComponent()}
                    </Box>
                ) : null}

                <Box className={styles.notebookComponentWrapper}>
                    <Outlet
                        context={[
                            openMenu,
                            steps,
                            selectedMenuItem,
                            handleAddTools,
                            isDataLoading,
                            filteredTools,
                            values,
                            refreshPageData,
                            integrations,
                            toolsConfig,
                            handleDeleteTool,
                            control,
                            setValue,
                            watch,
                            resetField,
                            setFocus,
                            entityTypes,
                            addedToolId,
                        ]}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default Notebook
