import { createStore, applyMiddleware, compose } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import thunk from "redux-thunk"
import rootReducer from "./reducers"

const persistConfig = {
    key: "root",
    storage: storage,
    blacklist: ["isAuthorized", "toasterState", "findingsState", "companiesState", "userState"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleware = [thunk]

export let store = {}
if (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) {
    store = createStore(
        persistedReducer,
        compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        ),
    )
} else {
    store = createStore(persistedReducer, compose(applyMiddleware(...middleware)))
}

export const persistor = persistStore(store)
