import React from "react"
import { Box } from "@mui/material"
import { Discovery } from "./components/Discovery/Discovery"
import { Workflow } from "./components/Workflow/Workflow"
import { useOutletContext } from "react-router-dom"
import { calculateAverageRisk } from "../../../../helpers/notebookHelpers"
import { RISK_LEVELS } from "../../../../constants/appConstants"
import styles from "./CaseOverview.module.scss"

export function CaseOverview() {
    const [
        openMenu,
        steps,
        selectedMenuItem,
        handleAddTools,
        isDataLoading,
        filteredTools,
        values,
    ] = useOutletContext()

    const averageRiskLevel = calculateAverageRisk(filteredTools)
    const riskObject = RISK_LEVELS.find((risk) => risk.value === averageRiskLevel)

    return (
        <Box
            className={styles.caseOverviewContainer}
            style={{
                padding: openMenu && selectedMenuItem == null ? "0 24px 0 195px" : "0 24px 0 24px",
                transition: "all 0.5s ease",
            }}
        >
            <Box className={styles.caseOverviewTitle}>
                <Box>Case overview</Box>
                {averageRiskLevel > 0 && (
                    <Box className={styles.caseOverviewTitleRiskScore}>
                        Risk Score{" "}
                        <Box
                            className={styles.caseOverviewTitleRiskScoreNumber}
                            sx={{ backgroundColor: riskObject?.color }}
                        >
                            {averageRiskLevel}
                        </Box>
                    </Box>
                )}
            </Box>
            <Discovery isDataLoading={isDataLoading} filteredTools={filteredTools} steps={steps} />
            <Workflow
                steps={steps}
                handleAddTools={handleAddTools}
                isDataLoading={isDataLoading}
                openMenu={openMenu}
                selectedMenuItem={selectedMenuItem}
                filteredTools={filteredTools}
            />
        </Box>
    )
}
