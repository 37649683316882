import React, { useRef } from "react"
import { Box, Tab, Tabs } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

import styles from "./RepoMainUserPlaybooksListHeader.module.scss"
import { NewPlaybookButton } from "./components/NewPlaybookButton/NewPlaybookButton"

const useStyles = makeStyles(() => ({
    tab: {
        textTransform: "none",
        fontSize: "25px",
        fontWeight: 700,
        lineHeight: "140%",
        color: "#262626",
        "&.Mui-selected": {
            color: "#7F61FD",
        },
    },
}))

export function RepoMainUserPlaybooksListHeader({
    showRecentlyGenerated = true,
    fetchUserPlaybooks,
    myPlaybookQuantity,
    recentlyGeneratedPlaybooksQuantity,
    setActiveTab,
    activeTab,
    generateInputRef,
}) {
    const classes = useStyles()

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue)
    }

    return (
        <Box className={styles.repoMainUserPlaybooksListHeaderWrapper}>
            <Tabs
                value={activeTab}
                onChange={handleChangeTab}
                TabIndicatorProps={{
                    style: {
                        color: "#7F61FD",
                        backgroundColor: "#7F61FD",
                    },
                }}
            >
                <Tab
                    label={
                        myPlaybookQuantity ? `My Playbooks (${myPlaybookQuantity})` : "My Playbooks"
                    }
                    className={classes.tab}
                />
                {showRecentlyGenerated && (
                    <Tab
                        label={
                            myPlaybookQuantity
                                ? `Recently Generated (${recentlyGeneratedPlaybooksQuantity})`
                                : "Recently Generated"
                        }
                        className={classes.tab}
                    />
                )}
            </Tabs>
            <NewPlaybookButton
                fetchUserPlaybooks={fetchUserPlaybooks}
                generateInputRef={generateInputRef}
            />
        </Box>
    )
}
