import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App/App"
import * as serviceWorker from "./utils/serviceWorker"
import { BrowserRouter } from "react-router-dom"
import Auth0ProviderWithHistory from "./components/auth0/auth0-provider-with-history"
import * as Sentry from "@sentry/react"
import { sentryOptions } from "./utils/sentryConfig"

Sentry.init(sentryOptions)

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
    <BrowserRouter>
        <Auth0ProviderWithHistory>
            <App />
        </Auth0ProviderWithHistory>
    </BrowserRouter>,
)

serviceWorker.unregister()
