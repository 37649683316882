import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { getFeedback } from "../../services/standart/standartServices"

export function useFeedback(templateId, refreshAccessTokenCounter) {
    const [likes, setLikes] = useState([])
    const [dislikes, setDislikes] = useState([])
    const [loadingFeedback, setLoadingFeedback] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchFeedback = async () => {
            if (templateId) {
                setLoadingFeedback(true)
                const data = { object_type: 1, object_id: templateId }
                try {
                    const response = await getFeedback(data, dispatch, refreshAccessTokenCounter)
                    if (response && Array.isArray(response) && response.length > 0) {
                        setLikes(response?.filter((item) => item.decision === 1) || [])
                        setDislikes(response?.filter((item) => item.decision === 2) || [])
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    setLoadingFeedback(false)
                }
            }
        }

        fetchFeedback()
    }, [templateId, dispatch, refreshAccessTokenCounter])

    return { likes, dislikes, loadingFeedback }
}
