import React from "react"
import styles from "./Skeleton.module.scss"

export function Skeleton({ width, height }) {
    const skeletonStyle = {
        width: width || "80%",
        height: height || "20px",
    }

    return <div className={styles.skeleton} style={skeletonStyle} data-testid="skeleton"></div>
}
