import { SET_OPEN_SUB_DIALOG, SET_SUBSCRIBE_USER } from "../actions/types"

const initialState = { openSubDialog: false, subscribeUser: false }

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_OPEN_SUB_DIALOG:
            return {
                ...state,
                openSubDialog: action.payload,
            }
        case SET_SUBSCRIBE_USER:
            return {
                ...state,
                subscribeUser: action.payload,
            }
        default:
            return state
    }
}
