import React, { useState } from "react"
import {
    Box,
    CircularProgress,
    Breadcrumbs,
    Stack,
    Typography,
    TextField,
    Divider,
} from "@mui/material"
import { useOutletContext, useParams, Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { setFindingsState } from "../../../../redux/actions/findingsActions"
import LoadingButton from "@mui/lab/LoadingButton"
import { doToolAction, updateLog } from "../../../../services/notebook/notebookServices"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import pencilIcon from "../../../../assets/icons/pencil.svg"
import removeIcon from "../../../../assets/icons/trash.svg"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import plusIcon from "../../../../assets/icons/plusIcon.svg"
import { EmptyTools } from "../../Tools/EmptyTools"
import { PopOver } from "../../../../components/PopOver/PopOver"
import ToolsMap from "../../Tools/ToolsMap"
import { RISK_LEVELS } from "../../../../constants/appConstants"
import ToolsMenu from "../../Tools/ToolsMenu/ToolsMenu"
import { Controller } from "react-hook-form"
import { StepsCarousel } from "./components/StepsCarousel/StepsCarousel"
import { calculateAverageRisk } from "../../../../helpers/notebookHelpers"
import { RemoveDialog } from "../RemoveDialog/RemoveDialog"
import { FindingsList } from "../FindingsList/FindingsList"
import styles from "./Step.module.scss"

export function Step() {
    const dispatch = useDispatch()
    const { caseId, stepId } = useParams()
    const [
        openMenu,
        steps,
        selectedMenuItem,
        handleAddTools,
        isDataLoading,
        filteredTools,
        values,
        refreshPageData,
        integrations,
        toolsConfig,
        handleDeleteTool,
        control,
        setValue,
        watch,
        resetField,
        setFocus,
        entityTypes,
        addedToolId,
    ] = useOutletContext()
    const navigate = useNavigate()

    const isOpenFindingsList = useSelector((state) => state.findingsState.isOpen)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const markers = useSelector((state) => state.findingsState.markers)

    const [edit, setEdit] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingNewTool, setIsLoadingNewTool] = useState(false)
    const [isLoadingStepStatus, setIsLoadingStepStatus] = useState(false)
    const [removetoolDialogOpen, setRemovetoolDialogOpen] = useState(false)

    const currentStep = steps.find((step) => step.id === +stepId)
    const currentStepStatus = currentStep?.log?.status
    const filteredToolsByFather = filteredTools.filter((tool) => tool.father === +stepId)
    const stepsIds = steps.map((step) => +step.id)

    const averageRiskLevel = calculateAverageRisk(filteredToolsByFather)
    const riskObject = RISK_LEVELS.find((risk) => risk.value === averageRiskLevel)
    const riskColor = averageRiskLevel === 0 ? "#000" : riskObject ? riskObject.color : "#000"

    const stepBreadcrumbs = [
        <Link key="overviewLink" to={`/${caseId}`} className={styles.stepHeaderLink}>
            <Typography>Overview</Typography>
        </Link>,
        <Typography key="stepsTypo" color="#000">
            Steps
        </Typography>,
    ]

    const toogleEdit = () => {
        setEdit(!edit)
    }

    const handleOpenDelete = (e) => {
        e.stopPropagation()
        setRemovetoolDialogOpen(true)
    }

    const handleRemoveMarkers = () => {
        dispatch(setFindingsState(false, []))
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (stepId) {
                await updateLog(
                    values.case_id,
                    values.tools[stepId]?.id,
                    values.tools[stepId]?.log,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                setEdit(false)
                dispatch(handleShowToaster("success", "Successfully save step name"))
            }
        } catch (error) {
            console.log(error)
            setEdit(false)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    const handeRemove = async () => {
        setIsLoading(true)
        try {
            if (stepId && stepsIds.includes(+stepId)) {
                setRemovetoolDialogOpen(false)
                await handleDeleteTool(stepId, null, "Successfully removed step")

                const index = stepsIds.indexOf(+stepId)
                let redirectTo = null
                if (stepsIds.length === 1) {
                    redirectTo = `/${caseId}`
                } else if (index > 0) {
                    redirectTo = `/${caseId}/step/${stepsIds[index - 1]}`
                } else {
                    redirectTo = `/${caseId}/step/${stepsIds[index + 1]}`
                }
                navigate(redirectTo)
            }
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
            setEdit(false)
        } finally {
            await refreshPageData()
            setIsLoading(false)
        }
    }

    const handleCancel = () => {
        resetField(`tools.${stepId}.log.note`)
        toogleEdit()
    }

    const handleMarkStep = async (stepName, status) => {
        setIsLoadingStepStatus(true)
        handlePopoverClose()
        try {
            if (status === "Completed") {
                await updateLog(
                    values.case_id,
                    stepId,
                    { note: stepName, status: "Investigate" },
                    dispatch,
                    refreshAccessTokenCounter,
                )
            } else {
                await updateLog(
                    values.case_id,
                    stepId,
                    { note: stepName, status: "Completed" },
                    dispatch,
                    refreshAccessTokenCounter,
                )
            }
            await refreshPageData()
            dispatch(handleShowToaster("success", "Successfully changed status"))
        } catch (e) {
            dispatch(handleShowToaster("error", "Action failed"))
            console.log(e)
        } finally {
            setIsLoadingStepStatus(false)
        }
    }

    return (
        <Box
            className={styles.stepContainer}
            style={{
                padding: openMenu && selectedMenuItem == null ? "0 0 0 195px" : "20px 24px 0 24px",
            }}
        >
            <Box className={styles.stepHeader}>
                <Stack spacing={2}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                        {stepBreadcrumbs}
                    </Breadcrumbs>
                </Stack>
                <StepsCarousel
                    steps={steps}
                    openMenu={openMenu}
                    selectedMenuItem={selectedMenuItem}
                    handleAddTools={handleAddTools}
                />
                <PopOver
                    text={`Click to mark step as ${
                        currentStepStatus === "Completed" ? "incomplete" : "completed"
                    }`}
                    anchorEl={anchorEl}
                    handlePopoverClose={handlePopoverClose}
                />
            </Box>
            <Box className={styles.stepMainContainer}>
                <Box className={styles.stepInfo}>
                    <Box className={styles.stepInfoTitle}>
                        <Controller
                            name={`tools.${stepId}.log[note]`}
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!edit ? (
                                        <>
                                            {isLoading || isDataLoading ? (
                                                <CircularProgress
                                                    style={{
                                                        width: "25px",
                                                        height: "25px",
                                                        alignSelf: "center",
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    <Typography
                                                        className={styles.stepInfoTitleText}
                                                    >
                                                        {currentStep?.log?.note}
                                                    </Typography>
                                                    <img src={pencilIcon} onClick={toogleEdit} />
                                                    <img
                                                        src={removeIcon}
                                                        onClick={handleOpenDelete}
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <TextField
                                                {...field}
                                                value={currentStep?.log?.note || ""}
                                                onChange={(e) =>
                                                    setValue(
                                                        `tools.${stepId}.log.note`,
                                                        e.target.value,
                                                    )
                                                }
                                                disabled={isLoading}
                                                variant="outlined"
                                                InputProps={{
                                                    className: styles.input,
                                                }}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        />
                        {edit && (
                            <>
                                {isLoading ? (
                                    <CircularProgress
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            alignSelf: "center",
                                        }}
                                    />
                                ) : (
                                    <Box display="flex" gap="10px" alignItems="center">
                                        <CloseIcon
                                            onClick={handleCancel}
                                            className={styles.stepInfoIcon}
                                        />
                                        <DoneIcon
                                            onClick={handleSave}
                                            className={styles.stepInfoIcon}
                                        />
                                    </Box>
                                )}
                            </>
                        )}
                        {!isDataLoading && !isLoading && (
                            <Box
                                className={styles.stepInfoRiskLevel}
                                style={{ backgroundColor: riskColor }}
                            >
                                {averageRiskLevel || 0}
                            </Box>
                        )}
                    </Box>
                    <LoadingButton
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        loading={isLoadingStepStatus}
                        loadingIndicator={
                            <CircularProgress style={{ width: "25px", height: "25px" }} />
                        }
                        onClick={() => handleMarkStep(currentStep?.log?.note, currentStepStatus)}
                        className={
                            currentStepStatus === "Completed"
                                ? styles.stepHeaderBtnCompleted
                                : styles.stepHeaderBtnInvestigate
                        }
                    >
                        {currentStepStatus === "Completed" ? "Completed" : "Mark as completed"}
                    </LoadingButton>
                </Box>

                <Divider style={{ marginBottom: "20px" }} />
                <Box className={styles.stepMainWrapper}>
                    <Box className={styles.stepMainToolsWrapper}>
                        {currentStep && filteredToolsByFather.length === 0 ? (
                            <EmptyTools
                                handleAddTools={handleAddTools}
                                values={values}
                                integrations={integrations}
                                toolsConfig={toolsConfig}
                                index_x={currentStep?.index_x}
                                index_y={0}
                            />
                        ) : (
                            <>
                                {currentStep &&
                                    filteredToolsByFather.length > 0 &&
                                    filteredToolsByFather.map((filteredToolByFather, index) => {
                                        const id = filteredToolByFather.id
                                        const indexY = values?.tools[id]?.index_y || 0
                                        return (
                                            <Box
                                                key={`${id}+${indexY}+${index}`}
                                                width="100%"
                                                id={`tool-${id}`}
                                            >
                                                {values.tools[id] && (
                                                    <ToolsMap
                                                        key={`${values.tools}+${indexY}`}
                                                        refreshPageData={refreshPageData}
                                                        handleAddTools={handleAddTools}
                                                        handleDeleteTool={handleDeleteTool}
                                                        values={values}
                                                        tool_id={id}
                                                        tool={values.tools[id]}
                                                        index={0}
                                                        index_x={currentStep?.index_x}
                                                        index_y={indexY}
                                                        control={control}
                                                        setValue={setValue}
                                                        watch={watch}
                                                        resetField={resetField}
                                                        setFocus={setFocus}
                                                        integrations={integrations}
                                                        toolsConfig={toolsConfig}
                                                        entityTypes={entityTypes}
                                                        addedToolId={+addedToolId}
                                                        toolId={filteredTools.map(
                                                            (tool) => tool.id,
                                                        )}
                                                    />
                                                )}
                                            </Box>
                                        )
                                    })}

                                {currentStep && Object.keys(values.tools).length !== 0 && (
                                    <Box className={styles.stepAddNewTool}>
                                        {isLoadingNewTool ? (
                                            <CircularProgress
                                                style={{ width: "25px", height: "25px" }}
                                            />
                                        ) : (
                                            <ToolsMenu
                                                title={"Add New Tool"}
                                                titleColor={"#000"}
                                                titleSize={"14px"}
                                                handleAddTools={handleAddTools}
                                                index_x={currentStep?.index_x}
                                                index_y={filteredToolsByFather?.length}
                                                values={values}
                                                integrations={integrations}
                                                toolsConfig={toolsConfig}
                                                toolsId={filteredTools.map((tool) => tool.id)}
                                                icon={
                                                    <img
                                                        style={{
                                                            height: "14px",
                                                            width: "14px",
                                                            marginRight: "5px",
                                                        }}
                                                        src={plusIcon}
                                                    />
                                                }
                                                setIsLoadingNewTool={setIsLoadingNewTool}
                                            />
                                        )}
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>
                    {isOpenFindingsList && (
                        <FindingsList
                            values={values}
                            setValue={setValue}
                            refreshPageData={refreshPageData}
                        />
                    )}
                </Box>
            </Box>
            <RemoveDialog
                open={removetoolDialogOpen}
                setClosed={() => setRemovetoolDialogOpen(false)}
                handleAgree={() => handeRemove()}
                name="step"
            />
        </Box>
    )
}
