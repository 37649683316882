import React from "react"
import { Box } from "@mui/material"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { BlogCard } from "./components/BlogCard/BlogCard"
import { blogArticles } from "../../../../../../constants/BlogArticles"
import { useIsMobile } from "../../../../../../hooks/standart/useIsMobile"
import makeStyles from "@mui/styles/makeStyles"
import styles from "./RepoMainBlog.module.scss"

const useStyles = makeStyles(() => ({
    arrows: {
        "& .slick-next": {
            right: -25,
            transform: "none",
            "&:before": {
                color: "#6B869A",
            },
        },
        "& .slick-prev": {
            left: -25,
            transform: "none",
            "&:before": {
                color: "#6B869A",
            },
        },
    },
}))

export function RepoMainBlog() {
    const classes = useStyles()
    const isMobile = useIsMobile()

    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: isMobile ? 1 : 3,
        slidesToScroll: 1,
        centerMode: !isMobile,
        centerPadding: 10,
        draggable: isMobile ?? false,
    }

    return (
        <Box className={styles.repoMainBlogMainWrapper}>
            <h1 className={styles.repoMainBlogMainTitle}>Fraud Fighters Alliance Blog</h1>
            <Slider {...settings} className={classes.arrows}>
                {blogArticles.map((article) => (
                    <BlogCard article={article} key={article.link} />
                ))}
            </Slider>
        </Box>
    )
}
