import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { AllEntities } from "./components/AllEntities/AllEntities"
import { NewEntity } from "./components/NewEntity/NewEntity"
import { EntityInfo } from "./components/EntityInfo/EntityInfo"

const useStyles = makeStyles(() => ({
    entityWrapper: {
        backgroundColor: "#FFFFFF",
        width: "300px",
        padding: "20px 16px",
        height: "100%",
        borderRight: "1px solid #EFEEF1",
        overflow: "auto",
    },
}))

export function Entity({
    values,
    entityTypes,
    entities,
    handleAddTools,
    control,
    resetAllFields,
    handleDeleteTool,
    refreshPageData,
}) {
    const classes = useStyles()
    const [currentView, setCurrentView] = useState(0)
    const [currentEditingEntityId, setCurrentEditingEntityId] = useState(null)
    const [newEntityId, setNewEntityId] = useState(null)

    const onHandleSelelectEntity = (id) => {
        setCurrentEditingEntityId(id)
        setCurrentView(2)
    }

    const selectEntityView = (number) => {
        setCurrentView(number)
    }

    useEffect(() => {
        setNewEntityId(null)
    }, [currentView])

    const curentViewComponent = () => {
        switch (currentView) {
            case 0:
                return (
                    <AllEntities
                        entities={entities}
                        handleAddTools={handleAddTools}
                        onHandleSelelectEntity={onHandleSelelectEntity}
                        setNewEntityId={setNewEntityId}
                        refreshPageData={refreshPageData}
                        newEntityId={newEntityId}
                    />
                )
            case 1:
                return (
                    // Now not in use, due to changed flow
                    <NewEntity
                        selectEntityView={selectEntityView}
                        entityId={newEntityId}
                        control={control}
                        values={values}
                        refreshPageData={refreshPageData}
                        resetAllFields={resetAllFields}
                        handleDeleteTool={handleDeleteTool}
                        entityTypes={entityTypes}
                    />
                )
            case 2:
                return (
                    <EntityInfo
                        values={values}
                        entityTypes={entityTypes}
                        currentEditingEntityId={currentEditingEntityId}
                        selectEntityView={selectEntityView}
                        control={control}
                        resetAllFields={resetAllFields}
                        handleDeleteTool={handleDeleteTool}
                        refreshPageData={refreshPageData}
                    />
                )
        }
    }

    return <Box className={classes.entityWrapper}>{curentViewComponent()}</Box>
}
