import React, { useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Box,
    Button,
    TextField,
    Divider,
    CircularProgress,
    InputAdornment,
    IconButton,
} from "@mui/material"
import { FileCopy } from "@mui/icons-material"
import { useFormik } from "formik"
import { useAuth0 } from "@auth0/auth0-react"
import * as yup from "yup"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContentText from "@mui/material/DialogContentText"
// import { sendUserEmailInvitation } from "../../../../services/admin/adminServices"
import { CustomPopOver } from "../../../../../components/CustomPopOver/CustomPopOver"
import successIcon from "../../../../../assets/icons/successIcon.svg"
import { useRunners } from "../../../../../hooks/admin/useRunners"
import { generateDockerCommand } from "../../../../../helpers/standardHelpers"
import styles from "./NewRunnerDialog.module.scss"
import { LoadingButton } from "@mui/lab"

const validationSchema = yup.object({
    runnerName: yup
        .string()
        .required("Name is required")
        .max(20, "Name must be less than or equal to 20 characters"),
    runnerDescription: yup
        .string()
        .max(30, "Description must be less than or equal to 30 characters"),
})

export function NewRunnerDialog({ open, setNewRunnerModalOpen, handleCreateNewRunner }) {
    const textFieldRef = useRef(null)
    const [token, setToken] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isPopoverVisible, setPopoverVisible] = useState(false)

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const formik = useFormik({
        initialValues: {
            runnerName: "",
            runnerDescription: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setIsLoading(true)
                const response = await handleCreateNewRunner(
                    values.runnerName,
                    values.runnerDescription,
                    refreshAccessTokenCounter,
                )
                if (response && response.token) {
                    setToken(response.token)
                }
            } finally {
                setIsLoading(false)
            }
        },
    })

    const handleCopyToken = () => {
        navigator.clipboard
            .writeText(token)
            .then(() => {
                setPopoverVisible(true)
                setTimeout(() => {
                    setPopoverVisible(false)
                }, 2000)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const handleCloseAndReset = () => {
        resetForm({ values: { runnerName: "", runnerDescription: "" } })
        setNewRunnerModalOpen(false)
        setToken("")
    }

    const { handleChange, handleBlur, handleSubmit, values, touched, errors, resetForm } = formik

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                className: styles.dialogWrapper,
            }}
        >
            <DialogTitle id="alert-dialog-title" fontWeight="400">
                Add new runner.
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" fontSize="14px">
                    After creating a new runner, a field with the docker command will appear.
                </DialogContentText>
                <form onSubmit={handleSubmit}>
                    <Box className={styles.newRunnerDialogInputsWrapper}>
                        <TextField
                            name="runnerName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.runnerName}
                            variant="outlined"
                            label="Name"
                            disabled={isLoading || token}
                            error={touched.runnerName && Boolean(errors.runnerName)}
                            helperText={touched.runnerName && errors.runnerName}
                            InputProps={{
                                className: styles.newRunnerDialogInput,
                            }}
                            fullWidth
                        />
                        <TextField
                            name="runnerDescription"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.runnerDescription}
                            variant="outlined"
                            label="Description"
                            disabled={isLoading || token}
                            error={touched.runnerDescription && Boolean(errors.runnerDescription)}
                            helperText={touched.runnerDescription && errors.runnerDescription}
                            InputProps={{
                                className: styles.newRunnerDialogInput,
                            }}
                            fullWidth
                        />
                        {token && (
                            <TextField
                                value={generateDockerCommand(values.runnerName, token)}
                                variant="outlined"
                                label="Docker command"
                                disabled
                                fullWidth
                                InputProps={{
                                    className: styles.newRunnerDialogInput,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleCopyToken}>
                                                <FileCopy
                                                    ref={textFieldRef}
                                                    style={{ color: "#000" }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                multiline
                                rows={5}
                            />
                        )}
                    </Box>
                    <Divider style={{ alignSelf: "center", marginTop: "10px" }} />
                    <DialogActions style={{ height: "60px" }}>
                        <Button
                            disabled={isLoading}
                            onClick={handleCloseAndReset}
                            className={styles.cancelButton}
                            autoFocus
                        >
                            {token ? "Close" : "Cancel"}
                        </Button>
                        {!token && (
                            <LoadingButton
                                loading={isLoading}
                                loadingIndicator={
                                    <CircularProgress style={{ width: "15px", height: "15px" }} />
                                }
                                className={styles.addButton}
                                type="submit"
                            >
                                Add
                            </LoadingButton>
                        )}
                    </DialogActions>
                </form>
                <CustomPopOver
                    isVisible={isPopoverVisible}
                    text={
                        <div className={styles.newRunnerDialogSharePopOver}>
                            <img src={successIcon} alt="success" />
                            Link copied to clipboard
                        </div>
                    }
                    anchor={textFieldRef.current}
                />
            </DialogContent>
        </Dialog>
    )
}
