import React from "react"
import { Typography, Popover } from "@mui/material"

export function PopOver({
    text,
    anchorEl,
    handlePopoverClose,
    vertical = "bottom",
    horizontal = "left",
}) {
    const openPopUp = Boolean(anchorEl)

    return (
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: "none",
                marginTop: "5px",
            }}
            open={openPopUp}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: vertical,
                horizontal: horizontal,
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Typography sx={{ p: 1 }} fontSize="14px">
                {text}
            </Typography>
        </Popover>
    )
}
