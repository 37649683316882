import React from "react"
import { Box, Typography } from "@mui/material"
import styles from "./BlogCard.module.scss"
import { Link } from "react-router-dom"

export function BlogCard({ article }) {
    const { img, title, text, link } = article

    let truncatedTitle = title
    let truncatedText = text

    if (title.length > 48) {
        truncatedTitle = title.substring(0, 45) + "..."
    }

    if (text.length > 178) {
        truncatedText = text.substring(0, 175) + "..."
    }

    return (
        <Link to={link} replace target="_blank" className={styles.blogCardLink}>
            <Box className={styles.blogCardWrapper}>
                <img src={img} alt="img" />
                <Typography className={styles.blogCardTitle}>{truncatedTitle}</Typography>
                <Box className={styles.blogCardText}>{truncatedText}</Box>
            </Box>
        </Link>
    )
}
