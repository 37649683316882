import React from "react"
import Typography from "@mui/material/Typography"
import { List, ListItem } from "@mui/material"

const RulesOfConduct = () => {
    return (
        <div>
            <Typography variant="h4" align="left">
                ByValue’s Platform - Rules of Conduct
            </Typography>
            <Typography variant="body1" align="left">
                TO THE EXTENT LEGALLY PERMISSIBLE, THE PLATFORM AND THE CONTENT
                (AS DEFINED BELOW) AVAILABLE THEREIN ARE PROVIDED ON AN “AS IS”
                BASIS. BYVALUE ONLY FACILITATES THE CONNECTION BETWEEN
                DEVELOPERS AND CLIENTS. IT IS NOT AFFILIATED WITH ANY DEVELOPER
                AND/OR CLIENT. BYVALUE DOES NOT MAKE ANY REPRESENTATIONS AND/OR
                WARRANTIES CONCERNING THE LICENSED SOFTWARE AND/OR THE WORK
                PRODUCTS. THE DEVELOPERS HAVE THE SOLE RESPONSIBILITY AND
                ACCOUNTABILITY FOR ANY WORK PRODUCTS DEVELOPED BY THEM, AND FOR
                ANY LICENSED SOFTWARE GRANTED BY THEM VIA THE PLATFORM. THE
                DEVELOPER HEREBY REPRESENTS AND WARRANTS THAT THE WORK PRODUCTS
                AND LICENSED SOFTWARE: (A) ARE THE DEVELOPER’S ORIGINAL
                CREATION; AND (B) DO NOT INFRINGE ANY THIRD PARTY RIGHTS.
                DISPUTES CONCERNING A SOFTWARE ORDER OR A SOFTWARE LICENSE WILL
                BE HANDLED DIRECTLY BY THE DEVELOPER AND THE CLIENT.
                NOTWITHSTANDING THE FOREGOING, (A) BYVALUE MAY (BUT IS NOT
                17545/1000/7795870v11 REQUIRED TO) ASSIST USERS TO RESPOLVE
                DISPUTES, AND (B) BYVALUE MAY ENFORCE THESE TERMS AGAINST A USER
                IF THE DISPUTE RESULTED FROM THE USER’S BREACH OF THESE TERMS.
                YOU HEREBY RELEASE BYVALUE AND BYVALUE’S REPRESENTATIVES FROM
                ANY AND ALL COSTS, DAMAGES AND LOSSES OF ANY KIND OR NATURE,
                ARISING OUT OF OR IN ANY WAY RELATED TO YOUR DISPUTES.
            </Typography>
        </div>
    )
}

export default RulesOfConduct
