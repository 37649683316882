import { SET_IS_AUTHORIZED } from "../actions/types"

const initialState = false

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_AUTHORIZED:
            return action.payload
        default:
            return state
    }
}
