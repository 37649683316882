import React, { useState } from "react"
import { Box, Typography, ListItem } from "@mui/material"
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded"
import LoadingButton from "@mui/lab/LoadingButton"
import { useSelector, useDispatch } from "react-redux"
import { uploadToS3 } from "../../../../hooks/standart/uploadFile"
import { uuidv4 } from "../../../../helpers/standardHelpers"
import { getFileSize } from "../../../../helpers/fileHelpers"
import { doToolAction } from "../../../../services/notebook/notebookServices"
import _ from "lodash"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import { Attachment } from "./components/Attachment/Attachment"
import styles from "./Attachments.module.scss"

export function Attachments({
    values,
    attachments,
    tool_id,
    handleAddTools,
    handleDeleteTool,
    refreshPageData,
}) {
    const dispatch = useDispatch()
    const [isFileLoading, setIsFileLoading] = useState(false)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const handleUploadFile = async (data) => {
        setIsFileLoading(true)
        try {
            if (data[0]) {
                const result = await handleAddTools([8], -1, 0, null)
                const newAttachments = []
                for (let key in result) {
                    if (key in result && result[key].type === 8) {
                        newAttachments.push(result[key])
                    }
                }
                const file = data[0]
                const filenameWithoutExtension = file?.name?.slice(0, file.name.lastIndexOf("."))

                const response = await uploadToS3(
                    "file",
                    file,
                    `_${uuidv4()?.slice(0, 8)}_` +
                        filenameWithoutExtension +
                        file?.name?.slice(file?.name?.lastIndexOf(".")),
                    true,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                const newAttachement = _.differenceBy(newAttachments, attachments, "id")
                const image_address = response?.config?.url.slice(
                    0,
                    response?.config?.url?.indexOf("?"),
                )
                const fileSize = getFileSize(file)

                await doToolAction(
                    values.case_id,
                    newAttachement[0].id,
                    8,
                    {
                        ...values?.tools[tool_id]?.log,
                        url: image_address,
                        file_size: fileSize,
                    },
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                dispatch(handleShowToaster("success", "Successfully upload attachement"))
            }
        } catch (e) {
            console.log(e)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsFileLoading(false)
        }
    }

    return (
        <Box className={styles.attachmentsWrapper}>
            <Box paddingBottom="90px">
                <Box className={styles.attachmentsHeader}>
                    <Typography
                        style={{
                            color: "#000000",
                            fontSize: "20px",
                            fontWeight: "500",
                            lineHeight: "24px",
                        }}
                    >
                        Attachments
                    </Typography>
                </Box>
                <ListItem disablePadding sx={{ display: "block", marginTop: "20px" }}>
                    {attachments.map((attachment, index) => (
                        <Attachment
                            key={attachment?.id}
                            attachment={attachment}
                            handleDeleteTool={handleDeleteTool}
                            isFileLoading={isFileLoading}
                        />
                    ))}
                </ListItem>
                <Box className={styles.attachmentsAddBtnWrapper}>
                    <Box className={styles.attachmentsAddBtn}>
                        <LoadingButton
                            loading={isFileLoading}
                            style={{ textTransform: "none", width: "100%", fontWeight: 400 }}
                            variant="standart"
                        >
                            <label className={styles.attachmentsInputFile}>
                                <input
                                    id="files"
                                    name="files"
                                    type="file"
                                    onChange={(event) => {
                                        handleUploadFile(event.currentTarget.files)
                                    }}
                                />
                                <span>
                                    {!isFileLoading && (
                                        <>
                                            <AddCircleOutlineRoundedIcon
                                                style={{
                                                    width: "15px",
                                                    height: "15px",
                                                    marginRight: "10px",
                                                }}
                                            />
                                            Add New Attachement
                                        </>
                                    )}
                                </span>
                            </label>
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
