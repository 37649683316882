import axios from "../../utils/axiosConfig"
import { setRefreshAccessTokenCounter } from "../../redux/actions/refreshAccessTokenCounterActions"
import ENUMS from "../../constants/appEnums"

export const savedMenuConfigs = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.SAVED_MENU_CONFIGS)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const updateUserCompany = async (company_id, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.UPDATE_USER_COMPANY, { company_id })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const resetDemoUser = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.RESET_DEMO_USER)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}