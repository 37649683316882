import inconclusiveFlag from "../assets/icons/flags/whiteFlag.svg"
import lowRiskFlag from "../assets/icons/flags/greenFlag.svg"
import mediumRiskFlag from "../assets/icons/flags/yellowFlag.svg"
import redRiskFlag from "../assets/icons/flags/redFlag.svg"
import fraudulentFlag from "../assets/icons/flags/deepRedFlag.svg"
import myCasesLogo from "../assets/icons/AsideMenu/myCases.svg"
import myCasesLogoActive from "../assets/icons/AsideMenu/myCasesActive.svg"
import toolboxIcon from "../assets/icons/AsideMenu/toolboxIcon.svg"
import toolboxIconActive from "../assets/icons/AsideMenu/toolboxIconActive.svg"
import DashboardIcon from "@mui/icons-material/Dashboard"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import configsLogo from "../assets/icons/AsideMenu/configs.svg"
import configsLogoActive from "../assets/icons/AsideMenu/configsActive.svg"
import intergationsLogo from "../assets/icons/AsideMenu/intergations.svg"
import intergationsLogoActive from "../assets/icons/AsideMenu/intergationsActive.svg"
import caseInfoLogo from "../assets/icons/AsideMenu/caseDetails.svg"
import caseInfoLogoActive from "../assets/icons/AsideMenu/caseDetailsActive.svg"
import entitiesLogo from "../assets/icons/AsideMenu/entities.svg"
import entitiesLogoActive from "../assets/icons/AsideMenu/entitiesActive.svg"
import eventsLogo from "../assets/icons/AsideMenu/events.svg"
import eventsLogoActive from "../assets/icons/AsideMenu/eventsActive.svg"
import attachmentsIcon from "../assets/icons/AsideMenu/attachments.svg"
import attachmentsIconActive from "../assets/icons/AsideMenu/attachmentsActive.svg"
import recommendationIcon from "../assets/icons/AsideMenu/recommendation.svg"
import recommendationIconActive from "../assets/icons/AsideMenu/recommendationActive.svg"
import summarizeLogo from "../assets/icons/AsideMenu/summarize.svg"
import summarizeLogoActive from "../assets/icons/AsideMenu/summarizeActive.svg"
import savingsLogo from "../assets/icons/AsideMenu/savings.svg"
import savingsLogoActive from "../assets/icons/AsideMenu/savingsActive.svg"
import ENUMS from "./appEnums"
import { isRedstringsUser } from "../helpers/standardHelpers"

export const RISK_LEVELS = [
    {
        value: 4,
        title: "Fraudulent",
        img: fraudulentFlag,
        color: "#8A121B",
    },
    {
        value: 3,
        title: "High Risk",
        img: redRiskFlag,
        color: "#B91F2A",
    },
    {
        value: 2,
        title: "Medium Risk",
        img: mediumRiskFlag,
        color: "#FFAE4F",
    },
    {
        value: 1,
        title: "Low Risk",
        img: lowRiskFlag,
        color: "#4FBB7C",
    },
    {
        value: 0,
        title: "Inconclusive",
        img: inconclusiveFlag,
        color: "#FFFFFF",
    },
]

export const resolutions = (caseType) => ({
    bad: caseType === 6 ? "Fraud" : "Decline",
    good: caseType === 6 ? "Not Fraud" : "Approve",
})

export const PREVIEW_IMAGES_FORMATS = ["png", "jpg", "jpeg"]

export const MAIN_TABLE_CELLS = [
    { name: "Id", align: "left", width: "135px" },
    { name: "Crm Id", align: "left", width: "160px" },
    { name: "Title", align: "left", width: "300px" },
    { name: "Type", align: "left", width: "570px" },
    { name: "Urgency", align: "left", width: "150px" },
    { name: "Date Created", align: "right", width: "195px" },
]
export const MAIN_TABLE_CELLS_DONE = [
    { name: "Id", align: "left", width: "135px" },
    { name: "Crm Id", align: "left", width: "160px" },
    { name: "Title", align: "left", width: "300px" },
    { name: "Type", align: "center" },
    { name: "Resolution", align: "right" },
]

export const NOTEBOOK_TABLE_CELLS = [
    { name: "Risk Signals", align: "left", width: "250px" },
    { name: "Description", align: "left", width: "350px" },
    { name: "Step", align: "left", width: "220px" },
    { name: "Discovered By", align: "left", width: "150px" },
    { name: "Risk Level", align: "center", width: "150px" },
]

export const RUNNERS_TABLE_CELLS = [
    { name: "ID", align: "left", width: "135px" },
    { name: "TITLE", align: "left", width: "160px" },
    { name: "DESCRIPTION", align: "left", width: "300px" },
    { name: "STATUS", align: "left" },
    { name: "DATE CREATED", align: "right" },
]

export const INTEGRATION_LOGOS = {
    salesforce: "https://logos-world.net/wp-content/uploads/2020/10/Salesforce-Logo-700x394.png",
    middesk: "https://static-resources.redstrings.io/middesk_logo.png",
    postgres:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/1200px-Postgresql_elephant.svg.png",
    seon: "https://redstrings-static-resources.s3.eu-central-1.amazonaws.com/seon_logo.png",
    maxmind: "https://redstrings-static-resources.s3.eu-central-1.amazonaws.com/maxmind_logo.png",
    cobalt: "https://cobaltintelligence.com/assets/images/logo-a-transparent.png",
    serpAPI: "https://1000logos.net/wp-content/uploads/2021/05/Google-logo.png",
    socialMedia:
        "https://www.pngitem.com/pimgs/m/506-5065028_facebook-instagram-twitter-linkedin-hd-png-download.png",
    crunchbase:
        "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/unj63uuxb8ooxctihr1w",
    opencorporates:
        "https://redstrings-static-resources.s3.eu-central-1.amazonaws.com/open+corporates+logo.png",
    abstract:
        "https://global-uploads.webflow.com/5ebbd0a566a3996636e55959/61e07e26988731dc9367e304_logo-dark-new.svg",
    mapbox: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Mapbox_logo_2019.svg/1280px-Mapbox_logo_2019.svg.png",
}

export const mainPageMenuItems = (user) => [
    {
        id: 0,
        label: "Dashboard",
        icon: <DashboardIcon />,
        disabled: !isRedstringsUser(user),
        admin: true,
        activeIcon: <DashboardIcon style={{ color: "white" }} />,
        to: "/dashboard",
    },
    {
        id: 1,
        label: "My Cases",
        icon: <img src={myCasesLogo} alt="myCasesIcon" />,
        activeIcon: <img src={myCasesLogoActive} alt="myCasesIconActive" />,
        to: "/",
        admin: false,
    },
    {
        id: 2,
        label: "Internal Configs",
        icon: <img src={configsLogo} alt="internalConfigsIcon" />,
        activeIcon: <img src={configsLogoActive} alt="internalConfigsIconActive" />,
        to: "/internal/configs",
        admin: true,
    },
    {
        id: 3,
        label: "Integrations",
        icon: <img src={intergationsLogo} alt="integrationsIcon" />,
        activeIcon: <img src={intergationsLogoActive} alt="integrationsIconActive" />,
        to: "/all/integrations",
        admin: false,
    },
    {
        id: 4,
        label: "Admin Panel",
        icon: <ManageAccountsIcon />,
        activeIcon: <ManageAccountsIcon style={{ color: "white" }} />,
        to: "/admin-panel",
        admin: true,
    },
]

export const suggestionsElements = [
    {
        title: "Develop an Anti-trust Review",
        subtitle: "for potential company violations.",
        prompt: "Scrutiny of Company for potential anti-trust violations.",
    },
    {
        title: "Conduct an Insider Threat Assessment",
        subtitle: "linked to a specific person.",
        prompt: "Assessment of potential insider threats linked to Person.",
    },
    {
        title: "Analyze Suspicious Seller Transactions",
        subtitle: "for heightened security measures.",
        prompt: "Examination of suspicious transactions associated with Seller.",
    },
    {
        title: "Review CX Agent Transaction Patterns",
        subtitle: "to detect irregularities.",
        prompt: "Examination of suspicious transactions associated with CX Agent.",
    },
    {
        title: "Assess Leads for Conflicts",
        subtitle: "ensuring unbiased business decisions.",
        prompt: "Evaluation of Lead for potential conflicts of interest.",
    },
]

export const notebookMenuItems = [
    { icon: caseInfoLogo, activeIcon: caseInfoLogoActive, label: "Case Details" },
    { icon: entitiesLogo, activeIcon: entitiesLogoActive, label: "Entities" },
    { icon: eventsLogo, activeIcon: eventsLogoActive, label: "Events" },
    { icon: attachmentsIcon, activeIcon: attachmentsIconActive, label: "Attachments" },
    {
        icon: recommendationIcon,
        activeIcon: recommendationIconActive,
        label: "Recommendations",
    },
    { icon: summarizeLogo, activeIcon: summarizeLogoActive, label: "Summary" },
    { icon: savingsLogo, activeIcon: savingsLogoActive, label: "Case Cost & Time Saved" },
]

export const DATA_MODES = [
    {
        value: 1,
        title: "Online",
    },
    {
        value: 2,
        title: "AI",
    },
]

export const USER_REPO_FEEDBACK = {
    1: {
        object_type: 1,
        decision: 1,
    },
    2: {
        object_type: 1,
        decision: 2,
    },
}

export const ROUTES_TO_SHOW_DIALOG = [
    "/",
    ENUMS.ROUTES.TERMS,
    ENUMS.ROUTES.PRIVACY,
    ENUMS.ROUTES.ROC,
    ENUMS.ROUTES.REDIRECT,
    ENUMS.ROUTES.UNAUTHORIZED,
    ENUMS.ROUTES.OAUTH_CALLBACK,
    /^\/\d+$/,
    /^\/\d+\/step\/\d+$/,
    ENUMS.ROUTES.ALL_INTEGRATIONS,
    ENUMS.ROUTES.EASY_INTEGRATIONS,
    ENUMS.ROUTES.INTERNAL_CONFIGS,
    ENUMS.ROUTES.ADMIN_PANEL,
    ENUMS.ROUTES.MY_PLAYBOOKS,
    /^my-playbooks\/\d+$/,
    ENUMS.ROUTES.MY_PLAYBOOKS_ID,
    ENUMS.ROUTES.DASHBOARD,
    ENUMS.ROUTES.MAIN_TOOLBOX,
    /^\/(\d+)\/step\/(\d+)\/toolbox$/,
]

export const CALENDLY_LINK = "https://calendly.com/d/3f9-537-bf8/redstrings-demo"

export const DEFAULT_THUBNAIL =
    "https://redstrings-static-resources.s3.eu-central-1.amazonaws.com/redstrings_logo_social_share.png"

export const DEFAULT_THUBNAIL_FFA =
    "https://s3.amazonaws.com/static-resources.redstrings.io/FFA_PNG.png"

export const SENTRY_DSN =
    "https://256e3b8decc42989b0cbbf0d65c88853@o4506077657169920.ingest.sentry.io/4506077674733568"
