import React, { useState } from "react"
import { Box, TextField, CircularProgress, InputAdornment, Typography } from "@mui/material"
import FFA_LOGO_TEXT from "../../../../../../assets/icons/Repo/FFA-log-with-text.svg"
import FFA_LOGO_SMALL from "../../../../../../assets/icons/Repo/FFA-small.svg"
import ICON_STARS from "../../../../../../assets/icons/Repo/icon-stars.svg"
import makeStyles from "@mui/styles/makeStyles"
import { useIsMobile } from "../../../../../../hooks/standart/useIsMobile"
import { LoadingButton } from "@mui/lab"
import styles from "./RepoMainGenerator.module.scss"

const useStyles = makeStyles(() => ({
    input: {
        "& .MuiInputBase-root": {
            maxWidth: "790px",
            borderRadius: "65px",
            padding: "0 4px",
            height: "48px",
            borderColor: "#096bbb80",
            fontSize: "14px",
            margin: "0 auto",
        },
        "& .MuiOutlinedInput-root": {
            borderColor: "#096bbb80",
            "& > fieldset": { borderColor: "#096bbb" },
        },
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": { borderColor: "#908e8e" },
        },
        "& .Mui-focused": {
            borderRadius: "65px",
            borderColor: "#096bbb",
        },
        "& .Mui-disabled": {
            color: "#908e8e",
        },
        "& .MuiLoadingButton-loading": {
            color: "#000",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#096bbb80",
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#096bbb80",
        },
    },
    generateButton: {
        display: "flex",
        alignItems: "center",
        gap: "6px",
        backgroundColor: "#262626",
        height: "40px",
        borderRadius: "60px",
        textTransform: "none",
        color: "#fff",
        "&.Mui-disabled": {
            opacity: "0.8",
        },
        "&:hover": {
            backgroundColor: "#313030",
        },
    },
}))

export function RepoMainGenerator({
    handleCreateTemplate,
    isLoading,
    inputValue,
    setInputValue,
    generateInputRef,
    title = null,
}) {
    const classes = useStyles()
    const isMobile = useIsMobile()

    const inputPlaceholder = isMobile
        ? ""
        : "Describe a case you've encountered or detail the investigation playbook you'd like to create"

    const renderLoadingButton = () => (
        <LoadingButton
            style={isMobile ? { width: "115px" } : { width: "150px" }}
            type="submit"
            loading={isLoading}
            loadingIndicator={<CircularProgress size={20} />}
            disabled={!inputValue.trim()}
            className={classes.generateButton}
        >
            {!isMobile && <img src={ICON_STARS} />}
            Generate
        </LoadingButton>
    )

    const handleInputChange = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = (event, text) => {
        event.preventDefault()
        if (text.trim()) {
            handleCreateTemplate(event, text)
        }
    }

    return (
        <form
            className={styles.repoMainGeneratorContainer}
            onSubmit={(e) => handleSubmit(e, inputValue)}
        >
            {title ? (
                <Typography color="#262626">{title}</Typography>
            ) : (
                <>
                    <Box className={styles.repoMainGeneratorText}>
                        <h1 className={styles.repoMainGeneratorTextTitle}>
                            Harness The Power Of {" "}
                            <span className={styles.repoMainGeneratorTextTitleBlue}>
                                AI
                            </span>
                        </h1>
                        <h2 className={styles.repoMainGeneratorTextDescription}>
                            Generate playbooks for any fraud typology with AI, customize to fit your use-case and save them to your private repository
                        </h2>
                    </Box>
                </>
            )}

            <Box className={styles.repoMainGeneratorInput}>
                <TextField
                    inputRef={generateInputRef}
                    disabled={isLoading}
                    className={classes.input}
                    fullWidth
                    placeholder={inputPlaceholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <img
                                    style={{ width: "35px", height: "35px" }}
                                    src={FFA_LOGO_SMALL}
                                    alt="logo"
                                />
                            </InputAdornment>
                        ),
                        endAdornment: renderLoadingButton(),
                    }}
                />
            </Box>
        </form>
    )
}
