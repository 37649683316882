import React from "react"
import { Modal, CircularProgress, Box } from "@mui/material"
import { CALENDLY_LINK } from "../../../../constants/appConstants"
import styles from "./CalendlyModal.module.scss"

export function CalendlyModal({ open, onClose, loading, handleIframeLoad }) {
    return (
        <Modal open={open} onClose={onClose}>
            <Box className={styles.calendlyModalWrapper}>
                {loading && (
                    <Box className={styles.calendlyModalLoading}>
                        <CircularProgress />
                    </Box>
                )}
                <iframe src={CALENDLY_LINK} onLoad={handleIframeLoad}></iframe>
            </Box>
        </Modal>
    )
}
