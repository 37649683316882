export const onConversationsAPIReady = (email) => {
    var _hsq = (window._hsq = window._hsq || [])
    _hsq.push([
        "identify",
        {
            email: email,
        },
    ])
    _hsq.push(["trackPageView"])
    setTimeout(() => {
        try {
            window.HubSpotConversations.widget.refresh()
        } catch (e) {}
    }, 1000)
}

export const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    )
}

export function capitalizeFirstLetter(string) {
    if (!string) return
    const words = string?.toLowerCase()?.split(" ")
    const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
            return word
        }
        const firstLetter = word.charAt(0).toUpperCase()
        const restOfWord = word.slice(1)
        return firstLetter + restOfWord
    })
    return capitalizedWords.join(" ")
}

export function generateDockerCommand(name, token) {
    if (!name && !token) return ""
    return `docker run -d --${name} \
        -p 5050:5050 \
        -e AUTHORIZATION_TOKEN=${token} \
        -e RS_SERVER_PATH=ws://backend.redstrings.io \
        -v ./config:/app/config \
        runner_app`
        .trim()
        .replace(/\s+/g, " ")
}

export function isRedstringsUser(user) {
    const userEmail = typeof user === "string" ? user : user?.email
    return userEmail && (userEmail.endsWith("redstrings.io") || userEmail.endsWith("@demo.com"))
}
