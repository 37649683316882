import React from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { createIsMarked } from "../../../../../helpers/notebookHelpers"

export function CaseReportStepSection({ section, markers, values, tool_id, index }) {
    const maxItemsPerColumn = 10
    const isMarked = createIsMarked(markers, values, tool_id, index)

    const styles = StyleSheet.create({
        sectionWrapper: {
            position: "relative",
            padding: "12px 18px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            border: "1px solid #EFEEF1",
            width: "100%",
            boxSizing: "border-box",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        sectionName: {
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "5px",
            fontSize: "12px",
            fontWeight: "500",
        },
        sectionLink: {
            fontSize: "12px",
            fontWeight: "500",
            maxWidth: "98%",
            boxSizing: "border-box",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        sectionLinkUrl: {
            color: "#374957",
        },
        sectionListTitle: {
            fontSize: "12px",
        },
        sectionListItem: {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            color: "#374957",
            fontSize: "12px",
            fontWeight: "400",
            width: "100%",
        },
        sectionListItemTitle: {
            display: "flex",
            flexDirection: "column",
            flexWrap: "",
            paddingTop: "5px",
            gap: "10px",
            width: "100%",
            color: "#222",
            fontSize: "12px",
            fontWeight: "500",
        },
        sectionListItemTitleText: {
            maxWidth: "90%",
            maxHeight: "100%",
        },
        sectionListItemValue: {
            color: "#374957",
            fontSize: "12px",
            fontWeight: "400",
        },
        sectionMoreInfo: {
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontSize: "12px",
            fontWeight: "500",
            marginTop: "5px",
        },
        riskLow: {
            backgroundColor: "#4fbb7c",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "24px",
            minHeight: "20px",
            height: "20px",
            borderRadius: "4px",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 600,
            position: "relative",
        },
        riskMedium: {
            backgroundColor: "#ffae4f",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "24px",
            height: "20px",
            borderRadius: "4px",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 600,
        },
        riskHigh: {
            backgroundColor: "#b91f2a",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "24px",
            minHeight: "20px",
            borderRadius: "4px",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 600,
        },
        riskFraudulent: {
            backgroundColor: "#8a121b",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "24px",
            height: "20px",
            borderRadius: "4px",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 600,
        },
        riskDescription: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "7px 12px",
            borderRadius: "8px",
            border: "1px solid #EFEEF1",
            backgroundColor: "#F4F5FF",
            fontSize: "12px",
            fontWeight: 500,
            color: "#262626",
        },
        riskDescriptionText: {
            fontWeight: 400,
        },
    })

    const renderAttributes = (attributes) => {
        const attrs = Array.isArray(attributes) ? attributes : [attributes]
        return attrs.flatMap((item) =>
            Object.entries(item).map(([name, value], index) =>
                renderItem(name, value, `${name}-${index}-more-info-attr`, true),
            ),
        )
    }

    const renderList = (name, value) => {
        const columnCount = Math.ceil(value.length / maxItemsPerColumn)
        const { color: marked, _, riskLevel, description } = isMarked(name, value)
        const itemClass = styles[marked]

        return (
            <View>
                <Text style={styles.sectionListTitle}>{name}:</Text>
                <View style={{ columnCount }}>
                    <View style={styles.sectionListItemTitle}>
                        {value.map((item, itemIndex) => (
                            <Text key={itemIndex} style={styles.sectionListItemTitleText}>
                                {String(item)}
                            </Text>
                        ))}
                        {riskLevel > 0 && (
                            <View style={itemClass}>
                                <Text>{riskLevel}</Text>
                            </View>
                        )}
                    </View>
                    {description && (
                        <View style={styles.riskDescription}>
                            <Text style={styles.riskDescriptionTitle}>
                                Risk Note:{" "}
                                <Text style={styles.riskDescriptionText}>{description}</Text>
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        )
    }

    const renderItem = (name, value, key) => {
        const { color: marked, _, riskLevel, description } = isMarked(name, value)
        const itemClass = styles[marked]
        const cleanedValue = String(value).replace(/\n/g, "")

        return (
            <View key={key} style={styles.sectionListItem}>
                <View style={styles.sectionListItemTitle}>
                    <Text style={styles.sectionListItemTitleText}>
                        {name}: <Text style={styles.sectionListItemValue}>{cleanedValue}</Text>
                    </Text>
                    {riskLevel > 0 && (
                        <View style={itemClass}>
                            <Text>{riskLevel}</Text>
                        </View>
                    )}
                </View>
                {description && (
                    <View style={styles.riskDescription}>
                        <Text style={styles.riskDescriptionTitle}>
                            Risk Note: <Text style={styles.riskDescriptionText}>{description}</Text>
                        </Text>
                    </View>
                )}
            </View>
        )
    }

    const renderSectionAttributes = (sectionAttributes) => {
        if (!sectionAttributes) return null
        let moreInfo
        const elements = Object.entries(sectionAttributes).flatMap(([name, value]) => {
            if (name === "more_info") {
                moreInfo = (
                    <View key={`${name}-more-info`}>
                        <Text>More Info: </Text>
                        <View style={styles.sectionMoreInfo}>
                            {renderAttributes(sectionAttributes.more_info.attributes)}
                        </View>
                    </View>
                )
                return []
            }

            return (
                <View key={name}>
                    {Array.isArray(value) ? renderList(name, value) : renderItem(name, value)}
                </View>
            )
        })

        if (moreInfo) elements.push(moreInfo)
        return elements
    }

    return (
        <View style={styles.sectionWrapper}>
            {section?.name && <Text style={styles.sectionName}>{section.name}</Text>}
            {section?.url && (
                <Text style={styles.sectionLink}>
                    Link: <Text style={styles.sectionLinkUrl}>{section.url}</Text>
                </Text>
            )}
            {renderSectionAttributes(section?.attributes)}
        </View>
    )
}
