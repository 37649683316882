import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Box, Select, MenuItem, CircularProgress } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import { useAuth0 } from "@auth0/auth0-react"
import { updateUserDeletedStatus } from "../../../../services/admin/adminServices"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import styles from "./AdminPanelUser.module.scss"

export function AdminPanelUser({ id, email, created_at, photoURL, auth0_id, deleted }) {
    const dispatch = useDispatch()
    const { user } = useAuth0()

    const [isDeleted, setIsDeleted] = useState(deleted)
    const [isLoading, setIsLoading] = useState(deleted)

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const handleDeleteUser = async ({ target: { value } }) => {
        if (!auth0_id)
            return dispatch(handleShowToaster("error", "Action failed, user has no Auth0 ID"))
        setIsLoading(true)
        try {
            await updateUserDeletedStatus(id, value, auth0_id, dispatch, refreshAccessTokenCounter)
            dispatch(handleShowToaster("success", "Successfully change user status"))
            setIsDeleted(value)
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box className={styles.adminPanelUserWrapper}>
            <Box className={styles.adminPanelUserName}>
                <img src={photoURL}></img>
                <Box>{email}</Box>
            </Box>
            <Box className={styles.adminPanelUserStatus}>
                Status:
                {isLoading ? (
                    <Box className={styles.adminPanelUserStatusLoaderWrapper}>
                        <CircularProgress style={{ width: "25px", height: "25px" }} />
                    </Box>
                ) : (
                    <FormControl style={{ width: "100%" }}>
                        <Select
                            disabled={email === user?.email}
                            value={isDeleted}
                            id="user_comany"
                            className={styles.adminPanelUserStatusInput}
                            onChange={handleDeleteUser}
                        >
                            {" "}
                            <MenuItem value={false}>Active</MenuItem>
                            <MenuItem value={true}>Deleted</MenuItem>
                        </Select>
                    </FormControl>
                )}
            </Box>
        </Box>
    )
}
