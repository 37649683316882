import React, { useState, useEffect, useMemo } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch } from "react-redux"
import expandAccordionIcon from "../../../../assets/icons/toolMenuAccordion.svg"
import {
    Box,
    Typography,
    Autocomplete,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import { ButtonWithLogo } from "../../../Notebook/components/ButtonWithLogo/ButtonWithLogo"
import styles from "./ToolMenuToolbox.module.scss"

const useStyles = makeStyles(() => ({
    accordion: {
        backgroundColor: "#fff",
        boxShadow: "none",
        "&::before": {
            display: "none",
        },
        "& .Mui-expanded": {
            margin: "0",
        },
    },
    accordionImage: {
        gap: "10px",
        "& .Mui-expanded": {
            color: "#5E4AFD",
        },
        "& .MuiAccordionSummary-content": {
            justifyContent: "space-between",
            order: "2",
        },
        "& .MuiAccordionSummary-expandIconWrapper": {
            order: "1",
            "&.Mui-expanded": {
                transform: "rotate(90deg)",
            },
        },
        "& .MuiButtonBase-root-MuiAccordionSummary-root.Mui-focusVisible": {
            backgroundColor: "#000",
        },
    },
    selectInput: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            border: "1px solid #EFEEF1",
            background: "#F7F8FD",
            fontSize: "14px",
        },
    },
    autoSelect: {
        borderRadius: "10px",
        maxHeight: window.innerWidth < 1600 ? "270px" : "",
    },
}))

export const ToolMenuToolbox = ({
    handleAddTools,
    index_x,
    index_y,
    integrations,
    toolsConfig,
    handleCloseDotsMenu,
    setIsLoadingNewTool,
    setIsLoadingTool,
}) => {
    const classes = useStyles()
    const { stepId } = useParams()
    const dispatch = useDispatch()
    const [menuCategories, setMenuCategories] = useState([])

    const addTool = async (tool_type_id) => {
        setIsLoadingTool && setIsLoadingTool(true)
        handleCloseDotsMenu && handleCloseDotsMenu()
        setIsLoadingNewTool && setIsLoadingNewTool(true)

        try {
            await handleAddTools([tool_type_id], index_x, index_y + 1, stepId)
            // dispatch(handleShowToaster("success", "Successfully add new tool"))
        } catch (e) {
            console.log(e)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoadingTool && setIsLoadingTool(false)
            setIsLoadingNewTool && setIsLoadingNewTool(false)
        }
    }

    useEffect(() => {
        if (toolsConfig && toolsConfig.length > 0) {
            const toolsNotHide = toolsConfig.filter((toolConfig) => !toolConfig["hide"])
            setMenuCategories([
                ...new Set(toolsNotHide.map((toolConfig) => toolConfig["menu_category"])),
            ])
        }
    }, [toolsConfig])

    const filteredTools = useMemo(() => {
        if (!toolsConfig) return []
        return toolsConfig
            .filter((tool) => !tool.hide)
            .map((tool) => ({ label: tool.title, id: tool.id }))
    }, [toolsConfig])

    const accordionContents = menuCategories?.map((category) => {
        const toolsInCategory = toolsConfig?.filter(
            (toolConfig) => !toolConfig["hide"] && toolConfig?.menu_category === category,
        )
        const toolOrTools = toolsInCategory?.length > 1 ? "tools" : "tool"
        const toolsToRender = toolsInCategory.map((toolConfig) => {
            const tool_type_id = toolConfig?.id
            if (
                [16, 32].includes(tool_type_id) &&
                !(integrations && integrations.includes("middesk"))
            ) {
                return <></>
            }
            if (
                [39, 43, 44, 45].includes(tool_type_id) &&
                !(integrations && integrations.includes("postgres"))
            ) {
                return <></>
            } else {
                return (
                    <ButtonWithLogo
                        key={toolConfig.id}
                        onClick={() => addTool(tool_type_id)}
                        logo_url={toolConfig.logo}
                        title={toolConfig?.title}
                    />
                )
            }
        })
        return { category, toolOrTools, toolsToRender }
    })

    const handleAddTool = (_, element) => {
        if (!element) return
        addTool(element?.id)
    }

    return (
        <Box className={styles.toolMenuToolboxContainer}>
            <Typography>Select Tool</Typography>
            <Box>
                <Autocomplete
                    disablePortal
                    id="tool-autocomplete"
                    options={filteredTools}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.label}
                            </li>
                        )
                    }}
                    onChange={handleAddTool}
                    noOptionsText={"No tools found. Refine your search or browse our complete list"}
                    ListboxProps={{
                        className: classes.autoSelect,
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search a tool"
                            className={classes?.selectInput}
                            margin="normal"
                        />
                    )}
                />
            </Box>
            {accordionContents?.map(({ category, toolOrTools, toolsToRender }) => (
                <Accordion key={category} className={classes.accordion}>
                    <AccordionSummary
                        className={classes.accordionImage}
                        expandIcon={<img src={expandAccordionIcon} />}
                        style={{ padding: "0" }}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        disableGutters={true}
                    >
                        <Typography className={styles.toolMenuToolboxToolCategory}>
                            {category}
                        </Typography>
                        <Typography className={styles.toolMenuToolboxToolCategoryNumber}>
                            ({toolsToRender.length} {toolOrTools})
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={styles.toolMenuToolboxToolCategoryWrapper}>
                        {toolsToRender}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    )
}
