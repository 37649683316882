import React from "react"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import GetAppIcon from "@mui/icons-material/GetApp"
import AttachMoney from "@mui/icons-material/AttachMoney"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import makeStyles from "@mui/styles/makeStyles"
import Fab from "@mui/material/Fab"
import { blue, green } from "@mui/material/colors"
import AddIcon from "@mui/icons-material/Add"
import ExitToAppSharpIcon from "@mui/icons-material/ExitToAppSharp"
import Avatar from "@mui/material/Avatar"

const useStylesDownloadUploadAndCreateWorkItem = makeStyles((theme) => ({
    downloadButton: {
        color: theme.palette.getContrastText(blue[600]),
        backgroundColor: blue[600],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
    uploadButton: {
        color: theme.palette.getContrastText(green[600]),
        backgroundColor: green[600],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
}))

export const DownloadButton = ({ onClick, disabled }) => {
    const classes = useStylesDownloadUploadAndCreateWorkItem()
    return (
        <Button
            variant="contained"
            className={classes.downloadButton}
            onClick={onClick}
            disabled={disabled}
        >
            <GetAppIcon />
        </Button>
    )
}

export const UploadButton = ({ id, fileName, disabled }) => {
    const classes = useStylesDownloadUploadAndCreateWorkItem()
    return (
        <label htmlFor={id}>
            <Button
                component="span"
                variant="contained"
                className={classes.uploadButton}
                disabled={disabled}
            >
                <CloudUploadIcon className={classes.leftIcon} />
                {fileName ? fileName : "UPLOAD YOURS"}
            </Button>
        </label>
    )
}

export const useStylesCustomButtons = makeStyles((theme) => ({
    createWorkItemButton: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            color: theme.palette.getContrastText(blue[700]),
            backgroundColor: blue[700],
        },
        margin: theme.spacing(1), // You might not need this now
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    marketButton: {
        borderRadius: "5px",
        color: "white",
        backgroundColor: "#61BF36",
        "&:hover": {
            color: "white",
            backgroundColor: "#78D14F",
        },
        // marginBottom: 20,
    },

    revertMarketButton: {
        borderRadius: "5px",
        color: "#61BF36",
        backgroundColor: "#E9E9E9",
        "&:hover": {
            color: "#61BF36",
            backgroundColor: "white",
        },
    },

    marketButtonDialog: {
        border: "1px solid transparent",
        color: "#61BF36",
        "&:hover": {
            color: "#61BF36",
            border: "1px solid #61BF36",
        },
    },

    marketButtonDanger: {
        border: "1px solid transparent",
        color: "#CD5C5C",
        "&:hover": {
            color: "#CD5C5C",
            border: "1px solid #CD5C5C",
        },
    },

    marketButtonSecondary: {
        border: "1px solid #61BF36",
        color: "#61BF36",
        minWidth: "max-content",
        ["@media screen and (max-width: 425px)"]: {
            padding: "4px",
        },
    },

    marketButtonSecondaryNoColor: {
        border: "1px solid white",
    },
    marketButtonSecondaryNoBorder: {
        color: "#61BF36",
    },

    nonColorButton: {
        // border: "1px solid white",
        // borerWidth: "thin",
        color: "#E9E9E9",
        border: "1px solid #E9E9E9",
        // backgroundColor: "#8B8B8B",
        "&:hover": {
            color: "white",
            // backgroundColor: "#2D2D2D",
        },
    },
    colorButtonLogin: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        width: "120px",
        height: "38px",
        borderRadius: "38px",
        backgroundColor: "#096bbb",
        textTransform: "none",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#2679BC",
        },
    },
    submitSolutionButton: {
        color: theme.palette.getContrastText(green[600]),
        backgroundColor: green[600],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    loginButton: {
        color: blue[500],
    },
    leftIcon: {
        // marginRight: theme.spacing(1)
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    signOutButton: {
        color: "#2D2D2D",
    },
    fab: {
        margin: theme.spacing(), // You might not need this now
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    landingPageButton: {
        borderRadius: "5px",
        color: "white",
        backgroundColor: "#cc9c23",
        "&:hover": {
            color: theme.palette.getContrastText("#F2E205"),
            backgroundColor: "#F2E205",
        },
        height: "50px",
        width: "140px",
    },
    landingPAgeButtonText: {
        fontWeight: "bold",
    },
}))

export const LandingPageButton = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            size="small"
            aria-label={props.text}
            classes={{
                root: classes.landingPageButton,
                text: classes.landingPAgeButtonText,
            }}
            {...props}
        >
            {props.text}
        </Button>
    )
}

export const CreateWorkItemButton = ({ disabled, component, to }) => {
    const classes = useStylesCustomButtons()
    return (
        <Fab
            component={component}
            to={to}
            size="small"
            aria-label="CREATE WORK ITEM"
            className={classes.createWorkItemButton}
            disabled={disabled}
        >
            <AddIcon className={classes.leftIcon} />
            NEW BLUEPRINT
        </Fab>
    )
}

export const MarketButton = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            size={props.size ? props.size : "small"}
            aria-label={props.text}
            className={classes.marketButton}
            {...props}
        >
            {props.text}
        </Button>
    )
}

export const RevertMarketButton = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            size={props.size ? props.size : "small"}
            aria-label={props.text}
            className={classes.revertMarketButton}
            {...props}
        >
            {props.text}
        </Button>
    )
}

export const MarketButtonSecondary = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            size="small"
            aria-label={props.text}
            className={classes.marketButtonSecondary}
            {...props}
        >
            {props.text}
        </Button>
    )
}
export const MarketButtonSecondaryNoColor = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            size={props.size ? props.size : "small"}
            aria-label={props.text}
            className={classes.marketButtonSecondaryNoColor}
            {...props}
        >
            {props.text}
        </Button>
    )
}

export const MarketButtonSecondaryLarge = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            size="large"
            aria-label={props.text}
            className={classes.marketButtonSecondary}
            {...props}
        >
            {props.text}
        </Button>
    )
}
export const MarketButtonSecondaryLargeNoBorder = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            size="large"
            aria-label={props.text}
            className={classes.marketButtonSecondaryNoBorder}
            {...props}
        >
            {props.text}
        </Button>
    )
}
export const MarketButtonSecondaryNoBorder = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            aria-label={props.text}
            className={classes.marketButtonSecondaryNoBorder}
            {...props}
        >
            {props.text}
        </Button>
    )
}

export const NonColorButton = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button size="small" aria-label={props.text} className={classes.nonColorButton} {...props}>
            {props.icon}
            {props.text}
        </Button>
    )
}

export const ColorButtonLogin = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            size="small"
            aria-label={props.text}
            className={classes.colorButtonLogin}
            {...props}
        >
            {props.icon}
            {props.text}
        </Button>
    )
}

export const MarketButtonDialog = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            size="small"
            aria-label={props.text}
            className={classes.marketButtonDialog}
            {...props}
        >
            {props.text}
        </Button>
    )
}

export const MarketButtonDialogDanger = (props) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            size="small"
            aria-label={props.text}
            className={classes.marketButtonDanger}
            {...props}
        >
            {props.text}
        </Button>
    )
}

export const CreateMarketFunctionButton = ({ disabled, component, to }) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            component={component}
            to={to}
            size="small"
            aria-label="CREATE MARKET FUNCTION"
            className={classes.marketButton}
            disabled={disabled}
        >
            <AddIcon />
            NEW FUNCTION
        </Button>
    )
}

export const MoneyButton = ({ label, disabled, type, onClick }) => {
    const classes = useStylesCustomButtons()
    return (
        <Button
            variant="text"
            color="primary"
            aria-label="add"
            className={classes.submitSolutionButton}
            disabled={disabled}
            type={type}
            onClick={onClick}
        >
            <AttachMoney className={classes.leftIcon} />
            {label}
        </Button>
    )
}

export const SignOutButton = ({ onClick }) => {
    const classes = useStylesCustomButtons()
    return (
        <IconButton
            onClick={onClick}
            aria-label="sign out"
            className={classes.signOutButton}
            size="large"
        >
            <ExitToAppSharpIcon fontSize="large" />
        </IconButton>
    )
}

export const ProfileButton = ({ alt, src }) => {
    return (
        <IconButton aria-label="profile" size="large">
            <Avatar alt={alt} src={src} />
        </IconButton>
    )
}
