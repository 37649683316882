import React, { useMemo, useState } from "react"
import { Box, Button, TextField } from "@mui/material"
import { useSelector } from "react-redux"
import { AdminPanelUser } from "./components/AdminPanelUser/AdminPanelUser"
import NewUserDialog from "./components/NewUserDialog/NewUserDialog"
import styles from "./AdminPanel.module.scss"
import { RunnersTable } from "./components/Runners/RunnersTable/RunnersTable"

export function AdminPanel() {
    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)
    const isAdmin = useSelector((state) => state.userState.user.admin)
    const users = useSelector((state) => state.userState.users || [])

    const [searchValue, setSearchValue] = useState("")
    const [newUserModalOpen, setNewUserModalOpen] = useState(false)

    const isDemoUser = useMemo(() => currentCompanyId === 3, [currentCompanyId])

    const handleSearch = (value) => {
        setSearchValue(value)
    }

    const handleOpenAddNewUser = () => {
        setNewUserModalOpen(true)
    }

    if (!isAdmin) return null

    return (
        <Box className={styles.adminPanelWrapper}>
            <RunnersTable />
            {isDemoUser && (
                <>
                    <Box className={styles.adminPanelTitle}>
                        <p className={styles.adminPanelTitleText}>Admin Panel</p>
                        <Button
                            onClick={handleOpenAddNewUser}
                            className={styles.adminPanelTitleButton}
                            variant="outlined"
                            color="success"
                        >
                            + Add new user
                        </Button>
                    </Box>
                    <Box>
                        <TextField
                            onChange={(e) => {
                                handleSearch(e.target.value)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: "#6B869A",
                                },
                            }}
                            value={searchValue}
                            variant="outlined"
                            label={"Search user"}
                            InputProps={{
                                className: styles.input,
                            }}
                            fullWidth
                        />
                    </Box>
                    <Box className={styles.adminPanelListWrapper}>
                        {users
                            ?.filter((user) =>
                                user?.email?.toLowerCase().includes(searchValue.toLowerCase()),
                            )
                            ?.map((user) => (
                                <AdminPanelUser key={user?.id} {...user} />
                            ))}
                    </Box>
                    <NewUserDialog
                        open={newUserModalOpen}
                        setNewUserModalOpen={setNewUserModalOpen}
                    />
                </>
            )}
        </Box>
    )
}
