import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Box, CircularProgress, Typography } from "@mui/material"
import { useOutletContext, useParams } from "react-router-dom"
import { ToolMenuToolbox } from "./components/ToolMenuToolbox/ToolMenuToolbox"
import { useAuth0 } from "@auth0/auth0-react"
import { Instructions } from "./components/Instructions/Instructions"
import ToolsMap from "../Notebook/Tools/ToolsMap"
import { FindingsList } from "../Notebook/components/FindingsList/FindingsList"
import { RepoFooter } from "../Playbook/pages/Repo/components/RepoFooter/RepoFooter"
import { getToolsConfig } from "../../services/standart/standartServices"
import { savedMenuConfigs } from "../../services/configPage/configPageServices"
import styles from "./Toolbox.module.scss"
import { setFindingsState } from "../../redux/actions/findingsActions"

export function Toolbox() {
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const dispatch = useDispatch()

    const [toolBoxToolsConfig, setToolBoxToolsConfig] = useState([])

    const [
        openMenu,
        steps,
        selectedMenuItem,
        handleAddTools,
        isDataLoading,
        filteredTools,
        values,
        refreshPageData,
        integrations,
        toolsConfig,
        handleDeleteTool,
        control,
        setValue,
        watch,
        resetField,
        setFocus,
        entityTypes,
        addedToolId,
    ] = useOutletContext()

    const [isLoadingTool, setIsLoadingTool] = useState(false)

    const { user } = useAuth0()
    const { caseId, stepId } = useParams()

    const currentStep = steps.find((step) => step.id === +stepId)
    const filteredToolsByFather = filteredTools.filter((tool) => tool.father === +stepId)

    const isOpenFindingsList = useSelector((state) => state.findingsState.isOpen)

    // tools menu config
    useEffect(() => {
        if (isAuthorized) {
            ;(async () => {
                try {
                    const ret = await savedMenuConfigs(dispatch, refreshAccessTokenCounter)
                    const toolsConfigResults = await getToolsConfig(
                        ret["My Toolbox"],
                        dispatch,
                        refreshAccessTokenCounter,
                    )
                    setToolBoxToolsConfig(toolsConfigResults)
                } catch (e) {
                    console.log(e.message)
                }
                // finally {
                //     setLoadData((loadData) => ({
                //         ...loadData,
                //         toolsMenuConfig: true,
                //     }))
                // }
            })()
        }
    }, [isAuthorized])

    useEffect(() => {
        if (filteredToolsByFather && filteredToolsByFather?.length === 1) {
            dispatch(setFindingsState(true, null))
        }
    }, [filteredToolsByFather?.length])

    return (
        <>
            <Box className={styles.toolboxContainer}>
                <Box className={styles.toolboxHeader}>
                    <Typography fontSize="24px" color="#000">
                        Toolbox
                    </Typography>
                </Box>
                <Box className={styles.toolboxMainWrapper}>
                    <Box>
                        <ToolMenuToolbox
                            title={"Add New Tool"}
                            titleColor={"#000"}
                            titleSize={"14px"}
                            handleAddTools={handleAddTools}
                            // index_x={currentStep?.index_x}
                            index_x={0}
                            index_y={filteredToolsByFather?.length}
                            values={values}
                            integrations={integrations}
                            toolsConfig={toolBoxToolsConfig}
                            toolsId={filteredTools.map((tool) => tool.id)}
                            setIsLoadingTool={setIsLoadingTool}
                        />
                    </Box>
                    {filteredToolsByFather.length === 0 ? (
                        <Instructions />
                    ) : (
                        <>
                            {isLoadingTool ? (
                                <Box className={styles.toolboxMainLoader}>
                                    <CircularProgress style={{ width: "25px", height: "25px" }} />
                                </Box>
                            ) : (
                                <Box className={styles.toolboxMainTool}>
                                    {filteredToolsByFather.length > 0 &&
                                        (() => {
                                            const filteredToolByFather =
                                                filteredToolsByFather[
                                                    filteredToolsByFather.length - 1
                                                ]
                                            const id = filteredToolByFather.id
                                            const indexY = values?.tools[id]?.index_y || 0
                                            return (
                                                <Box
                                                    key={`${id}+${indexY}`}
                                                    width="100%"
                                                    id={`tool-${id}`}
                                                >
                                                    {values.tools[id] && (
                                                        <ToolsMap
                                                            key={`${values.tools}+${indexY}`}
                                                            refreshPageData={refreshPageData}
                                                            handleAddTools={handleAddTools}
                                                            handleDeleteTool={handleDeleteTool}
                                                            values={values}
                                                            tool_id={id}
                                                            tool={values.tools[id]}
                                                            // index_x={currentStep?.index_x}
                                                            index_x={0}
                                                            index_y={indexY}
                                                            control={control}
                                                            setValue={setValue}
                                                            watch={watch}
                                                            resetField={resetField}
                                                            setFocus={setFocus}
                                                            integrations={integrations}
                                                            toolsConfig={toolBoxToolsConfig}
                                                            entityTypes={entityTypes}
                                                            addedToolId={+addedToolId}
                                                            toolId={filteredTools.map(
                                                                (tool) => tool.id,
                                                            )}
                                                        />
                                                    )}
                                                </Box>
                                            )
                                        })()}
                                </Box>
                            )}
                        </>
                    )}
                    {isOpenFindingsList && (
                        <FindingsList
                            values={values}
                            setValue={setValue}
                            refreshPageData={refreshPageData}
                            toolbox={true}
                        />
                    )}
                </Box>
            </Box>
            <RepoFooter user={user} />
        </>
    )
}
