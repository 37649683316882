import React, { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Box,
    Button,
    TextField,
    Divider,
    CircularProgress,
    InputAdornment,
    IconButton,
} from "@mui/material"
import { FileCopy } from "@mui/icons-material"
import { useFormik } from "formik"
import { useAuth0 } from "@auth0/auth0-react"
import * as yup from "yup"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContentText from "@mui/material/DialogContentText"
// import { sendUserEmailInvitation } from "../../../../services/admin/adminServices"
import { CustomPopOver } from "../../../../../components/CustomPopOver/CustomPopOver"
import successIcon from "../../../../../assets/icons/successIcon.svg"
import { useRunners } from "../../../../../hooks/admin/useRunners"
import { generateDockerCommand } from "../../../../../helpers/standardHelpers"
import { LoadingButton } from "@mui/lab"
import moment from "moment/moment"
import { RemoveDialog } from "../../../../Notebook/components/RemoveDialog/RemoveDialog"
import styles from "./EditRunnerDialog.module.scss"

const validationSchema = yup.object({
    runnerName: yup
        .string()
        .required("Name is required")
        .max(20, "Name must be less than or equal to 20 characters"),
    runnerDescription: yup
        .string()
        .max(30, "Description must be less than or equal to 30 characters"),
})

export function EditRunnerDialog({
    open,
    currentEditingRunner,
    setEditRunnerModalOpen,
    setCurrentEditingRunner,
    handleUpdateRunner,
    handleDeleteRunner,
}) {
    if (!currentEditingRunner) return null

    const textFieldRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const [removetoolDialogOpen, setRemovetoolDialogOpen] = useState(false)
    const [isPopoverVisible, setPopoverVisible] = useState(false)

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const formik = useFormik({
        initialValues: {
            runnerName: currentEditingRunner.name || "",
            runnerDescription: currentEditingRunner.description || "",
            runnerToken: currentEditingRunner.token || "",
            runnerCreatedAt:
                moment(currentEditingRunner.created_at)?.format("DD-MM-YYYY HH:mm") || "",
            runnerLastHeartbeatAt: currentEditingRunner.last_heartbeat_at || "",
            runnerConnectedAt: currentEditingRunner.connected_at || "",
            runnerDockerCommand:
                generateDockerCommand(currentEditingRunner?.name, currentEditingRunner?.token) ||
                "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setIsLoading(true)
                const response = await handleUpdateRunner(
                    currentEditingRunner.id,
                    values.runnerName,
                    values.runnerDescription,
                    refreshAccessTokenCounter,
                )
                setEditRunnerModalOpen(false)
            } finally {
                setIsLoading(false)
            }
        },
    })

    const handleCloseAndReset = () => {
        setEditRunnerModalOpen(false)
        setCurrentEditingRunner(null)
    }

    const handleCopyToken = () => {
        navigator.clipboard
            .writeText(values.runnerDockerCommand)
            .then(() => {
                setPopoverVisible(true)
                setTimeout(() => {
                    setPopoverVisible(false)
                }, 2000)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const deleteRunner = async () => {
        try {
            await handleDeleteRunner(currentEditingRunner.id)
            setEditRunnerModalOpen(false)
            setRemovetoolDialogOpen(false)
        } catch (error) {
            console.log(error)
        }
    }

    const { handleChange, handleBlur, handleSubmit, values, touched, errors } = formik

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                className: styles.dialogWrapper,
            }}
        >
            <DialogTitle id="alert-dialog-title" fontWeight="400">
                Edit {currentEditingRunner.id} runner.
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" fontSize="14px">
                    Runner details
                </DialogContentText>
                <form onSubmit={handleSubmit}>
                    <Box className={styles.editRunnerDialogInputsWrapper}>
                        <TextField
                            name="runnerName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.runnerName}
                            variant="outlined"
                            label="Name"
                            disabled={isLoading}
                            error={touched.runnerName && Boolean(errors.runnerName)}
                            helperText={touched.runnerName && errors.runnerName}
                            InputProps={{
                                className: styles.editRunnerDialogInput,
                            }}
                            fullWidth
                        />
                        <TextField
                            name="runnerDescription"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.runnerDescription}
                            variant="outlined"
                            label="Description"
                            disabled={isLoading}
                            error={touched.runnerDescription && Boolean(errors.runnerDescription)}
                            helperText={touched.runnerDescription && errors.runnerDescription}
                            InputProps={{
                                className: styles.editRunnerDialogInput,
                            }}
                            fullWidth
                        />
                        <TextField
                            name="runnerToken"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.runnerToken}
                            variant="outlined"
                            label="Token"
                            disabled={true}
                            InputProps={{
                                className: styles.editRunnerDialogInput,
                            }}
                            fullWidth
                        />
                        <TextField
                            name="runnerCreatedAt"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.runnerCreatedAt}
                            variant="outlined"
                            label="Created"
                            disabled={true}
                            InputProps={{
                                className: styles.editRunnerDialogInput,
                            }}
                            fullWidth
                        />
                        <TextField
                            name="runnerConnectedAt"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.runnerConnectedAt}
                            variant="outlined"
                            label="Connected"
                            disabled={true}
                            InputProps={{
                                className: styles.editRunnerDialogInput,
                            }}
                            fullWidth
                        />
                        <TextField
                            name="runnerLastHeartbeatAt"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.runnerLastHeartbeatAt}
                            variant="outlined"
                            label="Last heartbeat"
                            disabled={true}
                            InputProps={{
                                className: styles.editRunnerDialogInput,
                            }}
                            fullWidth
                        />
                        <TextField
                            name="runnerDockerCommand"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.runnerDockerCommand}
                            variant="outlined"
                            label="Docker Command"
                            disabled={true}
                            InputProps={{
                                className: styles.newRunnerDialogInput,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleCopyToken}>
                                            <FileCopy
                                                ref={textFieldRef}
                                                style={{ color: "#000" }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            multiline
                            rows={5}
                        />
                    </Box>
                    <Divider style={{ alignSelf: "center", marginTop: "10px" }} />
                    <DialogActions className={styles.editRunnerDialogButtonsWrapper}>
                        <Button
                            disabled={isLoading}
                            onClick={() => setRemovetoolDialogOpen(true)}
                            className={styles.deleteButton}
                            autoFocus
                        >
                            Delete
                        </Button>
                        <Box display={"flex"} gap={"10px"}>
                            <Button
                                disabled={isLoading}
                                onClick={handleCloseAndReset}
                                className={styles.cancelButton}
                                autoFocus
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                disabled={!formik.dirty || isLoading}
                                loading={isLoading}
                                loadingIndicator={
                                    <CircularProgress style={{ width: "15px", height: "15px" }} />
                                }
                                className={styles.saveButton}
                                type="submit"
                            >
                                Save
                            </LoadingButton>
                        </Box>
                    </DialogActions>
                </form>
                <CustomPopOver
                    isVisible={isPopoverVisible}
                    text={
                        <div className={styles.editRunnerDialogSharePopOver}>
                            <img src={successIcon} alt="success" />
                            Link copied to clipboard
                        </div>
                    }
                    anchor={textFieldRef.current}
                />
            </DialogContent>
            <RemoveDialog
                open={removetoolDialogOpen}
                setClosed={() => setRemovetoolDialogOpen(false)}
                handleAgree={deleteRunner}
                name="runner"
            />
        </Dialog>
    )
}
