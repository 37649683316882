import { SET_LOADER_MESSAGE } from "../actions/types"

const initialState = ""

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADER_MESSAGE:
            return action.payload
        default:
            return state
    }
}
