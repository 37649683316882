import React, { useState } from "react"
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    CircularProgress,
} from "@mui/material"
import { useSelector } from "react-redux"
import { useAuth0 } from "@auth0/auth0-react"
import { NOTEBOOK_TABLE_CELLS, RISK_LEVELS } from "../../../../../../constants/appConstants"
import { DiscoveryRow } from "../DiscoveryRow/DiscoveryRow"
import { DiscoveryEmpty } from "../DiscoveryEmpty/DiscoveryEmpty"
import styles from "./Discovery.module.scss"

export function Discovery({ isDataLoading, filteredTools, steps }) {
    const markers = useSelector((state) => state.findingsState.markers)
    const { user } = useAuth0()

    const [currentPage, setCurrentPage] = useState(1)

    const itemsPerPage = 7
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    const transformData = (filteredTools, steps, user) => {
        const transformedData = []

        filteredTools.forEach((item) => {
            if (item.log) {
                const { log } = item
                const riskLevel = log.risk_level || log.auto_risk_level
                const stepObj = steps.find((step) => step.id === item.father)

                /* TODO: Rewrite value to better way */
                if (riskLevel) {
                    transformedData.push({
                        link: null,
                        risk_level: RISK_LEVELS.find((level) => level.title === riskLevel)?.value,
                        name: item?.title,
                        value: " ",
                        tool_id: item.id,
                        description: log.risk_note,
                        user: user?.email?.split("@")[0] || "",
                        step: { ...stepObj?.log, id: stepObj?.id },
                    })
                }
            }
        })

        return transformedData
    }

    const transformedData = transformData(filteredTools, steps, user)
    const combinedData = [...markers, ...transformedData]
    const sortedData = combinedData.sort((a, b) => b.risk_level - a.risk_level)

    if (isDataLoading) {
        return (
            <Box className={styles.discoveryLoading}>
                <CircularProgress style={{ width: "30px", height: "30px" }} />
            </Box>
        )
    }

    return (
        <Box className={styles.discoveryTableContainer}>
            <Box className={styles.discoveryTitle}>Discovery</Box>
            {sortedData.length > 0 ? (
                <>
                    <TableContainer sx={{ borderRadius: "10px" }} component={Paper}>
                        <Table>
                            <TableHead className={styles.discoveryTableHead}>
                                <TableRow>
                                    {NOTEBOOK_TABLE_CELLS.map((cell, index) => (
                                        <TableCell
                                            style={{ width: cell?.width }}
                                            align={cell?.align}
                                            key={`${cell?.name}${index}`}
                                            className={styles.discoveryTitleCell}
                                        >
                                            <Typography
                                                window="100px"
                                                fontSize="13px"
                                                fontWeight="500"
                                            >
                                                {cell?.name}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.slice(startIndex, endIndex).map((marker, index) => (
                                    <DiscoveryRow key={index} {...marker} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box marginTop="10px">
                        <Pagination
                            count={Math.ceil(sortedData.length / itemsPerPage)}
                            page={currentPage}
                            onChange={(event, value) => setCurrentPage(value)}
                        />
                    </Box>
                </>
            ) : (
                <DiscoveryEmpty />
            )}
        </Box>
    )
}
