import React from "react"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"
import { List, ListItem } from "@mui/material"

const PrivacyComponent = () => {
    return (
        <div>
            <Typography variant="h4" align="left">
                ByValue’s Platform - Terms of Use
            </Typography>
            <Typography variant="subtitle1" align="left">
                Last Revised: April 23, 2020
            </Typography>
            <Typography variant="body1" align="left">
                ByValue Ltd. its subsidiaries and affiliates (“ByValue”, “we”,
                “us”, or “our”) welcomes you (the “User(s)”, or “you”) to our
                online platform at: https://app.byvalue.org/, in which Users may
                offer or purchase software products (the “Platform”, as further
                detailed in Section 2 below). Each of our Users may use the
                Platform in accordance with the terms and conditions hereunder.
                The term “User” or “you” refers to all types of users of our
                Platform, as further detailed below.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                1. Acceptance of the Terms
            </Typography>
            <Typography variant="body1" align="left">
                By entering to, connecting to, accessing or using the Platform,
                you acknowledge that you have read and understood the following
                terms of use, including the terms of our Privacy Policy
                available at: https://app.byvalue.org/privacy (collectively, the
                “Terms”) and you agree to be bound by them and to comply with
                all applicable laws and regulations regarding your use of the
                Platform. You acknowledge that these terms constitute a binding
                and enforceable legal contract between ByValue and you. By
                accepting these Terms, you represent that any and all
                information you provide through the Platform, is true and
                accurate. Any false, fraudulent or misleading information is
                strictly prohibited. IF YOU DO NOT AGREE TO THESE TERMS, PLEASE
                DO NOT ENTER TO, CONNECT TO, ACCESS OR USE OUR PLATFORM.
            </Typography>
            <br />
            <Typography variant="body1" align="left">
                The Platform is available only to individuals who (a) are at
                least sixteen (16) years old; and (b) possess the legal capacity
                to enter into these Terms and to form a binding agreement under
                any applicable law, or have received the required consent from
                their legal guardian to enter into these Terms. Therefore, you
                hereby represent that you possess the legal authority to enter
                into these Terms and to form a binding agreement under any
                applicable law, to use the Platform in accordance with these
                Terms, and to fully perform your obligations hereunder.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                2. Our Platform
            </Typography>
            <Typography variant="subtitle1" align="left">
                Introduction
            </Typography>
            <Typography variant="body1" align="left">
                Our Platform enables two types of software-related services:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="left">
                        1. offering and licensing proprietary software code or
                        products to others (the “Licensed Software” and the
                        “Software License”, accordingly) on the Platform’s
                        Marketplace Interface; and
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        2. development of an on-demand software code or product
                        (“Work Product(s)”) as defined in a standard software
                        services (the “Software Order”) that is published on the
                        Platform’s Labs Interface.
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body1" align="left">
                Users granting Software Licenses and/or developing software
                on-demand for others in accordance with a Software Order shall
                be referred to herein as “Developers”. Users purchasing
                subscriptions to the Licensed Software from the Developer and/or
                placing Software Orders (to be performed by a Developer) shall
                be referred to herein as “Clients”.
            </Typography>
            <br />
            <Typography variant="subtitle1" align="left">
                Marketplace Interface General Rules
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="left">
                        • Each Software License listed in the Marketplace
                        Interface by a Developer will include the license terms
                        and payment terms that apply to the Licensed Software.
                        The Client’s approval of the Software License terms
                        shall create an enforceable agreement between him/her
                        and the applicable Developer.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        • All intellectual property rights in or to the Licensed
                        Software that was uploaded to the Marketplace Interface
                        are owned by its respective Developer.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        • It is the Developer’s responsibility to ensure that
                        the Licensed Software will not infringe intellectual
                        property rights of any third party.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        • Payments to the Developer and to us will be made only
                        via the Platform, with the use of online payment
                        processors that we work with (e.g. PayPal).
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        • The Developer may not change the license fees or other
                        consideration that have already been approved by the
                        Client without our prior written consent. However, the
                        Developer may offer the Licensed Software to other
                        Clients under different pricing or payment terms.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        • The Client’s dissatisfaction with the Licensed
                        Software shall not constitute grounds for cancellation
                        and/or refund of any of the payments made to the
                        Developer or of ByValue’s fee.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        • We reserve the right to suspend and/or cancel
                        purchases or place payments on hold at our sole
                        discretion if we believe fraudulent or illegal
                        transactions were made through our Platform.
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="subtitle1" align="left">
                PLEASE READ CAREFULLY:
            </Typography>
            <Typography variant="body1" align="left">
                ALL USERS MUST COMPLY WITH OUR RULES OF CONDUCT, AS MAY BE
                PUBLISHED AND UPDATED ON THE PLATFORM FROM TIME TO TIME, WHICH
                ARE AVAILABLE AT:
                <Link to="/roc"> HTTPS://APP.BYVALUE.ORG/ROC</Link>. TO THE
                EXTENT LEGALLY PERMISSIBLE, THE PLATFORM AND THE CONTENT (AS
                DEFINED BELOW) AVAILABLE THEREIN ARE PROVIDED ON AN “AS IS”
                BASIS. BYVALUE ONLY FACILITATES THE CONNECTION BETWEEN
                DEVELOPERS AND CLIENTS. IT IS NOT AFFILIATED WITH ANY DEVELOPER
                AND/OR CLIENT. BYVALUE DOES NOT MAKE ANY REPRESENTATIONS AND/OR
                WARRANTIES CONCERNING THE LICENSED SOFTWARE AND/OR THE WORK
                PRODUCTS. THE DEVELOPERS HAVE THE SOLE RESPONSIBILITY AND
                ACCOUNTABILITY FOR ANY WORK PRODUCTS DEVELOPED BY THEM, AND FOR
                ANY LICENSED SOFTWARE GRANTED BY THEM VIA THE PLATFORM. THE
                DEVELOPER HEREBY REPRESENTS AND WARRANTS THAT THE WORK PRODUCTS
                AND LICENSED SOFTWARE: (A) ARE THE DEVELOPER’S ORIGINAL
                CREATION; AND (B) DO NOT INFRINGE ANY THIRD PARTY RIGHTS.
                DISPUTES CONCERNING A SOFTWARE ORDER OR A SOFTWARE LICENSE WILL
                BE HANDLED DIRECTLY BY THE DEVELOPER AND THE CLIENT.
                NOTWITHSTANDING THE FOREGOING, (A) BYVALUE MAY (BUT IS NOT
                17545/1000/7795870v11 REQUIRED TO) ASSIST USERS TO RESPOLVE
                DISPUTES, AND (B) BYVALUE MAY ENFORCE THESE TERMS AGAINST A USER
                IF THE DISPUTE RESULTED FROM THE USER’S BREACH OF THESE TERMS.
                YOU HEREBY RELEASE BYVALUE AND BYVALUE’S REPRESENTATIVES FROM
                ANY AND ALL COSTS, DAMAGES AND LOSSES OF ANY KIND OR NATURE,
                ARISING OUT OF OR IN ANY WAY RELATED TO YOUR DISPUTES.
            </Typography>
            <br />
            <Typography variant="body1" align="left">
                The Platform may include information regarding ByValue, and may
                include any other content related to ByValue and its services
                such as contact information, recommendations for pricing
                methods, news regarding ByValue, job opportunities, videos,
                texts, files, logos, button icons, images, data compilations,
                links, other specialized content, technical data, documentation,
                know-how, specifications materials, designs, data, the “look and
                feel” of the Platform, algorithms, source and object code,
                interface, GUI, interactive features, related graphics,
                illustrations, drawings, animations, and other features obtained
                from or through the Platform (collectively, the “Content”). In
                order to use the Platform you acknowledge and agree that you
                will be required to provide us with certain information,
                including personally identifiable information. You expressly
                acknowledge and agree that in order for us to provide you with
                our services, we may have to access and/or use the information
                you provided us. A comprehensive explanation regarding the
                information that we collect from our Users appears in our
                Privacy Policy.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                3. Registration and User Account
            </Typography>
            <Typography variant="body1" align="left">
                Users who wish to enjoy the full scope of the services offered
                by our Platform must register and create an account (the
                “Account”) by supplying a few details via the registration form
                in the Platform. You may not have more than one (1) active
                Account. Creating multiple Accounts for the same User (including
                the same legal entity) are strictly prohibited. Your Account is
                password protected. In order to protect the security of your
                information available on your account, to the greatest extent
                possible, you must safeguard and not disclose your Account
                log-in details and you must supervise the use of such Account.
                You must provide accurate and complete information when creating
                an Account and you agree to: (i) not to misrepresent your
                identity or your Account information, and (ii) keep your Account
                information up to date and accurate. You are solely and fully
                responsible for maintaining the confidentiality of your password
                and Account and for all activities that occur under your
                Account. You must notify us immediately of any unauthorized use
                of your Account or any other breach of security and in such
                events, you must change your password immediately. You may not
                assign or transfer your rights or delegate your duties under the
                Account without the prior written consent of ByValue. We cannot
                and will not be liable for any loss or damage arising from any
                failure of yours to comply with these Terms, i.e., if someone
                else accesses your Account through the registration information
                s/he has obtained from you, or for any unauthorized use of your
                Account or any other breach of security. If you wish to either
                change your username or password to log-in, or cancel and remove
                your Account, you may use the setting menu or you can send us an
                e-mail of your request to: support@byvalue.org. Your Account
                will be terminated within reasonable time following your request
                to terminate your Account. IMPORTANT: CANCELLING YOUR ACCOUNT
                MAY RESULT IN THE LOSS OF THE INFORMATION OR FILES YOU HAD
                PROVIDED TO US, OR OTHER INFORMATION THAT WAS ADDED BY OTHER
                USERS (E.G. COMMENTS OR REVIEWS) IN CONNECTION THEREWITH. WE
                SHALL HAVE NO RESPONSIBILITY OR LIABILITY FOR SUCH LOSSES.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                4. Consideration and Fee
            </Typography>
            <Typography variant="body1" align="left">
                Developer’s Fee. A Developer shall be entitled to consideration
                from the Client for the development of Work Products (as
                indicated in the Software Order) and/or for the licensing of
                his/her/its Licensed Software (as indicated in the Software
                License) (collectively, the “Developer’s Fee”). The Developer’s
                Fee shall be paid solely via our Online Payment Processors (as
                such term is defined in Section 5 below). The Developer will be
                automatically notified of the receipt of payment(s) via
                his/her/its Account or by e-mail. 17545/1000/7795870v11 Unless
                the Client (a) cancels or changes a Software Order within two
                (2) hours of its publication on the Labs Interface, or (b)
                requests us via support@byvalue.org to cancel or change a
                Software Order, and we accepted such request, the Developer will
                be entitled to the Developer’s Fee. In cases (a) and (b), the
                Online Payment Processors will enable the Client to withdraw the
                payment and terminate its relations with the Developer.
                ByValue’s Fee. As the operator of the Platform, ByValue will be
                entitled to a certain commission on each successful delivery of
                Work Products or Licensed Software, in accordance with the
                payment model specified in our pricing page at:
                https://app.byvalue.org/pricing (“ByValue’s Fee” or “Our Fee”).
                We reserve the right to modify ByValue Fee at any time and at
                our sole discretion, so please check the updated amounts in our
                pricing page before using the Platform. Note, however, that
                pricing changes will NOT affect existing projects that were
                registered before said price change. Unless otherwise stated,
                ByValue’s Fee does not include any taxes, levies, duties or
                similar governmental assessments, including VAT, sales, use or
                withholding taxes assessable by any local, state, provincial or
                foreign jurisdiction (collectively “Taxes”). You are responsible
                for paying Taxes except those assessable against ByValue based
                on its income. We will invoice you for such Taxes if we have a
                legal obligation to do so.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                5. Online Payment Processors
            </Typography>
            <Typography variant="body1" align="left">
                All payments via the Platform will be processed via certain
                online payment service providers listed in the platform (“Online
                Payment Processors”). We may add or change the Online Payment
                Processors in our sole discretion. The Online Payment Processors
                enable you to send payments securely online using a credit card,
                debit card or bank account. We do not control and are not
                affiliated with such Online Payment Processors. These Online
                Payment Processors are independent contractors and have no
                employment or agency relationship with ByValue. ByValue is not
                responsible in any way for the actions or performance (or lack
                thereof) of the Online Payment Processors. The use of the Online
                Payment Processors is at your own risk. It is your
                responsibility to abide by all the terms specified by the Online
                Payment Processors in their terms of use and privacy policies.
                You acknowledge that you are fully assuming the risks of
                conducting any transactions via the Online Payment Processors.
            </Typography>
            <Typography variant="body1" align="left">
                A CLIENT MAY NOT OFFER TO A DEVELOPER, AND A DEVELOPER MAY NOT
                RECEIVE FROM A CLIENT, THE DEVELOPER’S FEE OR ANY OTHER
                PAYMENTS, OTHER THAN THROUGH THE PLATFORM AND OUR ONLINE PAYMENT
                PROCESSORS. IN CASE YOU HAVE BEEN ASKED TO USE AN ALTERNATE
                PAYMENT METHOD, PLEASE REPORT IT TO US IMMEDIATELY.{" "}
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                6. Use Restrictions
            </Typography>
            <Typography variant="body1" align="left">
                There are certain conducts which are strictly prohibited when
                using the Platform. Please read the following restrictions
                carefully. Your failure to comply with any of the provisions set
                forth herein may result (at ByValue’s sole discretion) in the
                termination of your use of the Platform and may also expose you
                to civil and/or criminal liability. Unless otherwise explicitly
                permitted under these Terms or in writing by ByValue, you may
                not (and you may not permit anyone to): (a) use the Platform
                and/or the Content for any illegal, immoral, unlawful and/or
                unauthorized purposes; (b) use the Platform and/or Content for
                non-personal or commercial purposes; (c) remove or disassociate,
                from the Content and/or the Platform any restrictions and signs
                indicating proprietary rights of ByValue or its licensors,
                including but not limited to any proprietary notices contained
                in such materials (such as ©,™, or ®), and you represent and
                warrant that you will abide by all applicable laws in this
                respect; (d) interfere with or violate Users’ rights to privacy
                and other rights, or harvest or collect personally identifiable
                information about Users without their express consent, whether
                manually or with the use of any robot, spider, crawler, any
                search or retrieval application, or use other manual or
                automatic device, process or method to access the Platform and
                retrieve, index and/or data-mine information; (e) interfere with
                or disrupt the operation of the Platform or the servers or
                networks that host them, or disobey any laws, regulations,
                requirements, procedures, or policies of such servers or
                networks; (f) falsely state or otherwise misrepresent your
                affiliation with any person or entity, or express or imply that
                ByValue endorses you, your site, your business, your Licensed
                Software, Software Order or Work Orders, or any statement you
                make, or present false or inaccurate information about the
                Platform; (g) take any action that imposes, or may impose, an
                unreasonable or disproportionately large load on our Platform’s
                infrastructure, as determined by us; (h) bypass any measures we
                may use to prevent or restrict access to the Platform; (i) copy,
                modify, alter, adapt, make available, translate, port, reverse
                engineer, decompile, or disassemble any portion of the Content
                made accessible by ByValue on or through the Platform, or
                publicly display, reproduce, create derivative works from,
                perform, distribute, or otherwise use such Content; (j) copy,
                distribute, display, execute publicly, make available to the
                public, reduce to human readable form, 17545/1000/7795870v11
                decompile, disassemble, adapt, sublicense, make any commercial
                use, sell, rent, transfer, lend, process, compile, reverse
                engineer, combine with other software, translate, modify or
                create derivative works of any material that is subject to
                ByValue‘s proprietary rights, including ByValue‘s Intellectual
                Property (as such term is defined below), in any way or by any
                means; (k) make any use of the Content on any other site or
                networked computer environment for any purpose without ByValue’s
                prior written consent; (l) create a browser or border
                environment around ByValue Content (no frames or inline linking
                is allowed); (m) sell, license, or exploit for any commercial
                purposes any use of or access to the Platform and/or the
                Content; (n) frame or mirror any part of the Platform without
                ByValue’s prior express written authorization; (o) create a
                database by systematically downloading and storing all or any of
                the Content; (p) transmit or otherwise make available in
                connection with the Platform any virus, worm, Trojan Horse, time
                bomb, web bug, spyware, or any other computer code, file, or
                program that may or is intended to damage or hijack the
                operation of any hardware, software, or telecommunications
                equipment, or any other actually or potentially harmful,
                disruptive, or invasive code or component; (q) use the Platform
                for any purpose for which it is not intended; (r) breach and/or
                violate any of the Terms, and/or (s) use the Platform to send
                any offensive content, including but not limited to: (i) any
                content deemed illegal under law; (ii) any content which
                infringes any third party rights, including copyright, right for
                privacy, good name, property rights, publicity rights, trade
                secrets; (iii) any content deemed as pornography, including
                child pornography; (iv) any other content which may be
                considered offensive by ByValue’s sole discretion.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                7. Privacy Policy
            </Typography>
            <Typography variant="body1" align="left">
                We respect your privacy and are committed to protect the
                information you share with us. We believe that you have a right
                to know our practices regarding the information we collect when
                you connect to, access or use the Platform. Our policy and
                practices and the type of information collected are described in
                detail in our Privacy Policy at: https://app.byvalue.org/privacy
                which is incorporated herein by reference. You agree that we may
                use personal information that you provide or make available to
                us in accordance with the Privacy Policy. If you intend to
                connect to, access or use the Platform you must first read and
                agree to the Privacy Policy.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                8. Trademarks and Trade names
            </Typography>
            <Typography variant="body1" align="left">
                “ByValue” and all logos and other proprietary identifiers used
                by ByValue in connection with our Platform (“ByValue
                Trademarks”) are all trademarks and/or trade names of ByValue,
                whether or not registered. All other trademarks, service marks,
                trade names and logos which may appear in the Platform belong to
                their respective owners (“Third Party Marks”). No right,
                license, or interest to ByValue Trademarks and/or to the Third
                Party Marks is granted hereunder, and you agree that no such
                right, license, or interest shall be asserted by you with
                respect to ByValue Trademarks or the Third Party Marks and
                therefore you will avoid using any of those marks, unless
                expressly permitted herein.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                9. ByValue’s Intellectual Property Rights
            </Typography>
            <Typography variant="body1" align="left">
                The Platform, the Content and ByValue’s proprietary assets and
                any and all intellectual property rights pertaining thereto,
                including, but not limited to, inventions, patents and patent
                applications, trademarks, trade names, service marks,
                copyrightable materials, domain names and trade secrets, whether
                or not registered or capable of being registered (collectively,
                “Intellectual Property”), are owned by and/or licensed to
                ByValue and are protected by applicable patent, copyright and
                other intellectual property laws and international conventions
                and treaties. All rights not expressly granted to you hereunder
                are reserved by ByValue and its licensors. The Terms do not
                convey to you an interest in or to ByValue’s Intellectual
                Property, but only a limited revocable right of use in
                accordance with the Terms. Nothing in the Terms constitutes a
                waiver of ByValue’s Intellectual Property under any law. To the
                extent you provide any feedbacks, comments or suggestions to
                ByValue (“Feedback”), ByValue shall have an exclusive,
                royalty-free, fully paid up, worldwide, perpetual and
                irrevocable license to incorporate the Feedback into any ByValue
                current or future services, technologies or services and use
                same for any purpose all without further compensation to you and
                without your approval. You agree that all such Feedback shall be
                deemed to be non-confidential. Further, you warrant that your
                Feedback is not subject to any license terms that would purport
                to require ByValue to comply with any additional obligations
                with respect to any ByValue’s current or future products,
                technologies or services that incorporate any Feedback.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                10. Your Intellectual Property Rights
            </Typography>
            <Typography variant="body1" align="left">
                We do not claim ownership of the Licensed Software or the Work
                Products. However, we need certain licenses to them so that they
                can be offered, hosted and/or transmitted via the Platform.
                Therefore, when you upload, submit, post, publish or make
                available any Licensed Software or Work Products on the
                Platform, you grant us a perpetual, non-exclusive, royalty-free,
                worldwide, transferable, sublicensable license to publicly
                display, communicate, distribute, host, publicly perform,
                publish, reproduce, store and use them solely in connection with
                the Platform, whether through the Internet, mobile devices or
                otherwise, in any media formats and through any media channels
                known today and developed in the future for the purpose of (1)
                allowing their transfer to the applicable Client(s), (2) dispute
                resolution and for other legal purposes (e.g. to satisfy
                applicable laws, governmental requests, enforce the Terms and
                other ByValue terms or policies, including investigation or
                potential violations thereof), detect or prevent fraud and
                malware, protect the rights of ByValue, its Users or the general
                public, etc., and (3) for other reasonable business purposes.
                You hereby waive any moral rights, attribution rights and
                publicity rights (if any) with respect to our use of your
                Licensed Software or Work Products in accordance with these
                Terms
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                11. Linking to Our Platform and Links to Third Party Sites
            </Typography>
            <Typography variant="body1" align="left">
                We welcome links to any page on our Platform. You are free to
                establish a hypertext link to the Platform so long as the link
                does not state or imply any connection or approval of your
                website and/or services by ByValue and does not portray ByValue
                in a false or otherwise offensive manner. You may not link to
                our Platform from a site that you do not own or have permission
                to use. In the event that you link to ByValue’s Platform, you
                represent that your site does not contain content that is
                unlawful, offensive or infringing third party rights. However,
                we do not permit framing or inline linking. Certain links
                provided within the Platform permit our Users to leave this
                Platform and enter non-ByValue sites or services. Those linked
                sites and services are provided solely as a convenience to you.
                These linked sites and services are not under the control of
                ByValue and it is not responsible for the availability of such
                external sites or services, and does not endorse and is not
                responsible or liable for any content including but not limited
                to content advertising, products or other information on or
                available from such linked sites and services or any link
                contained in linked sites or service. In addition, ByValue is
                not responsible or liable for such linked sites and services’
                privacy practices and/or any other practices. Your access to,
                use of and reliance upon any such sites, services and content
                and your dealings with such third parties are at your sole risk
                and expense. ByValue reserves the right to terminate any link at
                any time. You further acknowledge and agree that ByValue shall
                not be responsible or liable, directly or indirectly, for any
                damage or loss caused or alleged to be caused, by or in
                connection with use of or reliance on any services, content,
                products or other materials available on or through such linked
                sites or resource. Most of such linked sites and services
                provide legal documents, including terms of use and privacy
                policy, governing the use thereof. It is always advisable, and
                we encourage you to read such documents carefully before using
                those sites and services, inter alia, in order to know what kind
                of information about you is being collected.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                12. Changes to The Platform
            </Typography>
            <Typography variant="body1" align="left">
                ByValue reserves the right to modify, correct, amend, enhance,
                improve, make any other changes to, or discontinue, temporarily
                or permanently, the Platform (or any part thereof, including but
                not limited to the Content) without notice, at any time. In
                addition, you hereby acknowledge that the Content provided under
                this Platform may be changed, extended in terms of content and
                form or removed at any time without any notice to you. You agree
                that ByValue shall not be liable to you or to any third party
                for any modification, suspension, or discontinuance of this
                Platform, or the Content included therein. You hereby agree that
                ByValue is not responsible for any errors or malfunctions that
                may occur in connection with the performance of such changes.{" "}
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                13. Disclaimer and Warranties
            </Typography>
            <Typography variant="body1" align="left">
                TO THE FULLEST EXTENT LEGALLY PERMISSIBLE, THE PLATFORM AND THE
                CONTENT ARE PROVIDED ON AN “AS IS”, “WITH ALL FAULTS” AND “AS
                AVAILABLE” BASIS, AND BYVALUE, INCLUDING ITS VENDORS, OFFICERS,
                SHAREHOLDERS, SUB-CONTRACTORS, DIRECTORS, EMPLOYEES, AFFILIATES,
                SUBSIDIARIES, LICENSORS, AGENTS AND SUPPLIERS (COLLECTIVELY,
                “BYVALUE’S REPRESENTATIVES”), DISCLAIM ALL WARRANTIES OF ANY
                KIND, EXPRESSED, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED
                TO WARRANTIES OF TITLE OR NON-INFRINGEMENT OR IMPLIED WARRANTIES
                OF USE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE AND
                THOSE ARISING FROM A COURSE OF DEALING OR USAGE OF TRADE. YOU
                MAY HAVE ADDITIONAL CONSUMER RIGHTS UNDER YOUR LOCAL LAWS THAT
                THIS AGREEMENT CANNOT CHANGE. 17545/1000/7795870v11 YOU ARE
                SOLELY RESPONSIBLE FOR BACKING UP ANY DATA UPLOADED TO THE
                PLATFORM AND YOUR ACCOUNT. WE DO NOT ACCEPT ANY LIABILITY FOR
                ANY LOSS OF SUCH DATA. WE DO NOT WARRANT (I) THAT THE USE AND
                OPERATION OF THE PLATFORM OR CONTENT IS OR WILL BE SECURE,
                TIMELY, ACCURATE, COMPLETE, UNINTERRUPTED, WITHOUT ERRORS, OR
                FREE OF VIRUSES, MALWARS, DEFECTS, WORMS, OTHER HARMFUL
                COMPONENTS OR OTHER PROGRAM LIMITATIONS, (II) THAT WE WILL
                CORRECT ANY ERRORS OR DEFECTS IN THE PLATFORM AND/OR, (III) MAKE
                ANY REPRESENTATION REGARDING THE USE, INABILITY TO USE OR
                OPERATE, OR THE RESULTS OF THE USE OF THE PLATFORM AND/OR
                CONTENT AVAILABLE THEREON OR THROUGH THE PLATFORM OR (INCLUDING
                THAT THE RESULTS OF USING THE PLATFORM WILL MEET YOUR
                REQUIREMENTS). BYVALUE AND BYVALUE’S REPRESENTATIVES DISCLAIM
                ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE USE OF THE
                PLATFORM, INCLUDING BUT NOT LIMITED TO THE AVAILABILITY,
                RELIABILITY OR THE QUALITY OF THE PLATFORM, AND ARE NOT AND
                SHALL NOT BE RESPONSIBLE FOR ANY ERROR, FAULT OR MISTAKE RELATED
                TO CONTENT AND/OR INFORMATION DISPLAYED WITHIN THEM. WE ARE NOT
                RESPONSIBLE AND HAVE NO LIABILITY FOR ANY ITEMS OR SERVICES
                PROVIDED BY ANY PERSON OR ENTITY OTHER THAN BYVALUE ITSELF (AS
                FURTHER CLARIFIED IN SECTION 2 ABOVE). WE ARE NOT RESPONSIBLE
                FOR ANY CONSEQUENCES TO YOU OR OTHERS THAT MAY RESULT FROM
                TECHNICAL PROBLEMS (INCLUDING WITHOUT LIMITATION IN CONNECTION
                WITH THE INTERNET, SUCH AS SLOW CONNECTIONS, TRAFFIC CONGESTION,
                OVERLOAD OF SERVERS, DELAYS OR INTERRUPTIONS) OR ANY
                TELECOMMUNICATIONS OR INTERNET PROVIDERS. YOU AGREE THAT THE USE
                OF THE PLATFORM AND THE CONTENT IS ENTIRELY AT YOUR OWN RISK.
                INASMUCH AS SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSIONS OR
                LIMITATIONS AS SET FORTH HEREIN, THE FULL EXTENT OF THE ABOVE
                EXCLUSIONS AND LIMITATIONS MAY NOT APPLY.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                14. Limitation of Liability
            </Typography>
            <Typography variant="body1" align="left">
                TO THE MAXIMUM EXTENT LEGALLY PERMISSIBLE, IN NO EVENT SHALL
                BYVALUE, INCLUDING BYVALUE’S REPRESENTATIVES BE LIABLE FOR ANY
                DAMAGES WHATSOEVER, INCLUDING, BUT NOT LIMITED TO, DIRECT,
                INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY, INCIDENTAL OR
                CONSEQUENTIAL DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY
                (INCLUDING, WITHOUT LIMITATION, CONTRACT, NEGLIGENCE, TORT OR
                STRICT LIABILITY) (INCLUDING WITHOUT LIMITATION, LOSS OF
                GOODWILL, PROFITS OR DATA AND BUSINESS INTERRUPTION) ARISING
                HEREUNDER, RESULTING FROM OR ARISING OUT OF THE PLATFORM AND/OR
                THE CONTENT. YOUR USE OR INABILITY TO USE THE PLATFORM AND/OR
                THE CONTENT AND/OR THE FAILURE OF THE PLATFORM TO PERFORM AS
                REPRESENTED OR EXPECTED, OR FROM THE PERFORMANCE OR FAILURE OF
                BYVALUE TO PERFORM UNDER THESE TERMS, ANY OTHER ACT OR OMISSION
                OF BYVALUE OR BYVALUE’S REPRESENTATIVES BY ANY OTHER CAUSE
                WHATSOEVER; OR BASED UPON A BREACH OF WARRANTY, GUARANTEE OR
                CONDITION, BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY,
                TORT, OR ANY OTHER LEGAL THEORY, REGARDLESS OF WHETHER BYVALUE
                OR BYVALUE’S REPRESENTATIVES HAVE BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. IN ANY CASE, WITHOUT LIMITING THE
                GENERALITY OF THE FOREGOING AND TO THE MAXIMUM EXTENT LEGALLY
                PERMISSIBLE, BYVALUE AND BYVALUE’S REPRESENTATIVES’ TOTAL
                AGGREGATE LIABILITY FOR ALL DAMAGES OR LOSSES WHATSOEVER ARISING
                HEREUNDER OR IN CONNECTION WITH YOUR USE OR INABILITY TO USE THE
                PLATFORM OR THE CONTENT OR ANY SERVICES SHALL BE LIMITED TO THE
                COMISSION BYVALUE ACTUALLY RECEIVED FROM YOU IN CONNECTION WITH
                YOUR SPECIFIC TRANSACTION. INASMUCH AS SOME JURISDICTIONS DO NOT
                ALLOW THE EXCLUSIONS OR LIMITATIONS AS SET FORTH HEREIN, THE
                FULL EXTENT OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT
                APPLY.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                15. Indemnification
            </Typography>
            <Typography variant="body1" align="left">
                You agree to defend, indemnify and hold harmless ByValue,
                including ByValue’s Representatives from and against any and all
                claims, damages, obligations, losses, liabilities, costs, debts,
                and expenses (including but not limited to attorney’s
                17545/1000/7795870v11 fees) arising from: (i) your use, misuse
                of, inability to use and/or activities in connection with the
                Platform and/or Content; (ii) your violation of any of these
                Terms; (iii) your violation of any third party rights, including
                without limitation any intellectual property rights or privacy
                right of such third party; (iv) any damage of any sort, whether
                direct, indirect, special or consequential, you may cause to any
                third party with relation to the Platform; (v) any illegal or
                immoral content uploaded by you to the Platform; and (vi) for
                Developers - any claims, including product liability claims,
                arising out of or related to Licensed Software and/or Work
                Products provided by the Developer. It is hereby clarified that
                this defense and indemnification obligation will survive these
                Terms. Without derogating from the foregoing, we reserve the
                right to assume the exclusive defense and control of any matter
                which is subject to indemnification by you, which will not
                excuse your indemnity obligations hereunder and in which event
                you will fully cooperate with us in asserting any available
                defense. You agree not to settle any matter subject to an
                indemnification by you without first obtaining our prior express
                written approval.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                16. Amendments to the Terms
            </Typography>
            <Typography variant="body1" align="left">
                ByValue may, at its sole discretion, change the Terms from time
                to time, including any other policies incorporated thereto, so
                please re-visit this page frequently. In case of any material
                change, we will make reasonable efforts to post a clear notice
                on the Platform and/or will send you an e-mail (to the extent
                that you provided us with such e-mail address) regarding such
                change. Such material changes will take effect seven (7) days
                after such notice was provided on our Platform or sent via
                email, whichever is the earlier. Otherwise, all other changes to
                these Terms are effective as of the stated “Last Revised” date
                and your continued use of the Platform on or after the Last
                Revised date will constitute acceptance of, and agreement to be
                bound by, those changes. In the event that the Terms should be
                amended to comply with any legal requirements, the amendments
                may take effect immediately, or as required by the law and
                without any prior notice.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                17. Termination or Suspension of your Account and Termination of
                the Platform’s Operation
            </Typography>
            <Typography variant="body1" align="left">
                These Terms shall remain in effect until terminated as set forth
                herein. Your failure to comply herewith shall terminate these
                Terms. In the event of your failure to comply herewith, ByValue
                may immediately temporarily or permanently limit, suspend or
                terminate your Account. If you object to any term hereof, as may
                be amended from time to time, or become dissatisfied with the
                Platform, you may terminate these Terms at any time by stopping
                your use thereof and this will be you sole remedy in such
                circumstances. In such circumstance and upon termination of
                these Terms in the event of your failure to comply herewith: (i)
                all other rights granted to you hereunder will automatically
                terminate, (ii) you must immediately cease all use of the
                Platform, and (iii) the provisions of these Terms that, by their
                nature and content, must survive the termination of these Terms
                in order to achieve the fundamental purposes of these Terms
                shall so survive. Without limiting the generality of the
                forgoing, the Intellectual Property, Disclaimer and Warranties,
                Limitation of Liability, Indemnification and General Sections
                will survive the termination of the Terms. We note that we can
                suspend access to your Account if we believe, in our sole
                discretion, that one (or more) of the following events have
                occurred: (a) there is a risk to the security or privacy of your
                Account; (b) there is a threat to the security or integrity of
                our network or our servers; (c) suspension is needed to protect
                the rights, property or safety of ByValue, its Users or the
                general public; (d) there is a basis for termination of your
                Account; (e) you have violated these Terms; and/or (f) we are
                required to do so by law. We may provide you a notice in the
                event of any such suspension. During such suspension, you will
                not have the ability to use or access your Account. In the event
                that we will determine, in our sole discretion, that the reason
                for suspension of access to your Account has been resolved, we
                will restore access to your Account. Additionally, ByValue may,
                at any time, at its sole discretion, cease the operation of the
                Platform or any part thereof, temporarily or permanently, delete
                any information or Content from the Platform or correct, modify,
                amend, enhance, improve and make any other changes thereto or
                discontinue displaying or providing any information, Content or
                features therein without giving any prior notice. You agree and
                acknowledge that ByValue does not assume any responsibility with
                respect to, or in connection with the termination of the
                Platform’s operation and loss of any data.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                18. Minors
            </Typography>
            <Typography variant="body1" align="left">
                In order to use the Platform you must be over the age of sixteen
                (16). If you are between the ages of sixteen (16) and eighteen
                (18), you represent that your legal guardian has reviewed and
                agreed to these Terms. We reserve the right to request proof of
                age at any stage so that we can verify that minors under the age
                of sixteen (16) are not using the Platform. In the event that it
                comes to our knowledge that a minor under the age of sixteen
                (16) is using the Platform, we may prohibit and block
                17545/1000/7795870v11 such User from accessing the Platform and
                will make all efforts to promptly delete any Personal
                Information (as such term is defined in our Privacy Policy) with
                regard to such User.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                19. General
            </Typography>
            <Typography variant="body1" align="left">
                (a) These Terms constitute the entire terms and conditions
                between you and ByValue relating to the subject matter herein
                and supersedes any and all prior or contemporaneous written or
                oral agreements or understandings between you and ByValue, (b)
                any claim relating to the Platform or the use thereof will be
                governed by and interpreted in accordance with the laws of the
                State of Israel without reference to its conflict-of-laws
                principles and the United Nations Convention Relating to a
                Uniform Law on the International Sale of Goods may not be
                applied, (c) any dispute arising out of or related to the
                Platform will be brought in, and you hereby consent to exclusive
                jurisdiction and venue in, the competent courts of the
                TelAviv-Jaffa District, Israel. You agree to waive all defenses
                of lack of personal jurisdiction and forum non-convenience and
                agree that process may be served in a manner authorized by
                applicable law or court rule. Notwithstanding the foregoing,
                ByValue may seek injunctive relief in any court of competent
                jurisdiction, (d) these Terms do not, and shall not be construed
                to create any relationship, partnership, joint venture,
                employer-employee, agency, or franchisor-franchisee relationship
                between the parties hereto, (e) no waiver by either party of any
                breach or default hereunder will be deemed to be a waiver of any
                preceding or subsequent breach or default. Any heading, caption
                or section title contained herein is inserted only as a matter
                of convenience, and in no way defines or explains any section or
                provision hereof, (f) YOU ACKNOWLEDGE AND AGREE THAT ANY CAUSE
                OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATED TO THE
                PLATFORM, TO SERVICES SOLD DIRECTLY BY BYVALUE (IF ANY) AND/OR
                TO THESE TERMS, MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE
                CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS
                PERMANENTLY BARRED, (g) if any provision hereof is adjudged by
                any court of competent jurisdiction to be unenforceable, that
                provision shall be limited or eliminated to the minimum extent
                necessary so that these Terms shall otherwise remain in full
                force and effect while most nearly adhering to the intent
                expressed herein, (h) you may not assign or transfer these Terms
                (including all rights and obligations hereunder) without our
                prior written consent and any attempt to do so in violation of
                the foregoing shall be void. We may assign or transfer these
                Terms without restriction or notification, (i) no amendment
                hereof will be binding unless in writing and signed by ByValue,
                and (j) the parties agree that all correspondence relating to
                these Terms shall be written in the English language.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                20. For Information, Questions or Notification of Errors, Please
                Contact:
            </Typography>
            <Typography variant="body1" align="left">
                In order to contact us via the Platform, you will need to fill
                out and complete the contact form, which requires, inter alia,
                your name, e-mail address and a message. You may also send us an
                e-mail to: support@byvalue.org.{" "}
            </Typography>
        </div>
    )
}

export default PrivacyComponent
