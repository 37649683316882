import React from "react"
import { Box, MenuItem, Select, InputLabel } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"
import FormControl from "@mui/material/FormControl"
import { isRedstringsUser } from "../../../../helpers/standardHelpers"

export function CompanySelect({ currentCompany, handleSelectCompany, companies }) {
    const { user } = useAuth0()

    return (
        currentCompany &&
        isRedstringsUser(user) && (
            <Box>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel id="user_comany_label">Company</InputLabel>
                    <Select
                        id="user_comany"
                        labelId="user_comany_label"
                        value={currentCompany?.id}
                        onChange={handleSelectCompany}
                        label="Company"
                    >
                        {companies.map((company) => (
                            <MenuItem key={company.id} value={company.id}>
                                {company.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        )
    )
}
