import React from "react"
import ToolsMenu from "./ToolsMenu/ToolsMenu"
import { Box, Grid } from "@mui/material"

function Divider({
    tool_id,
    index,
    index_x,
    index_y,
    handleAddTools,
    values,
    integrations,
    toolsConfig,
}) {
    const note = values.tools[tool_id].log.note

    return (
        <Grid item xs={12}>
            <Box
                sx={{
                    marginTop: "50px",
                    borderRadius: "10px",
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    left: "-50px",
                }}
            >
                <Box
                    sx={{
                        flexGrow: "0",
                        fontWeight: "600",
                        fontSize: "1.5em",
                    }}
                >
                    {/* Account holder verification */}
                    {note}
                </Box>
            </Box>
        </Grid>
    )
}

export default Divider
