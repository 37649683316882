import React, { useEffect, useState } from "react"
import {
    connectedIntegrations,
    deleteIntegration,
    getUserIdByEmail,
} from "../../services/integrations/integrationsServices"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import withCustomAuthenticationRequired from "../../components/Hoc/with-custom-authentication-required"
import { Typography, Box } from "@mui/material"
import EmptyIntegration from "./components/EmptyIntegration"
import AppCard from "./components/AppCard"
import CredentialsConnect from "./components/CredentialsConnect"
import { changeLoaderState } from "../../redux/actions/loaderActions"
import { INTEGRATION_LOGOS } from "../../constants/appConstants"
import { isRedstringsUser } from "../../helpers/standardHelpers"
import { useAuth0 } from "@auth0/auth0-react"

const allIntegrations = ["salesforce", "middesk", "postgres"]

function Integrations() {
    const { user } = useAuth0()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)

    const [sfOauthUrl, setSfOauthUrl] = useState("")

    const [integrations, setIntegrations] = useState([])
    const [openCredentialsConnectPopup, setOpenCredentialsConnectPopup] = useState(false)
    const [credentialsConnectIntegrationName, setCredentialsConnectIntegrationName] = useState("")

    const [availableIntegrations, setAvailableIntegrations] = useState(allIntegrations)

    const getConnectedIntegrations = async () => {
        const currentIntegrations = await connectedIntegrations(dispatch, refreshAccessTokenCounter)
        setIntegrations(currentIntegrations)
        setAvailableIntegrations(allIntegrations.filter((n) => !currentIntegrations.includes(n)))
    }

    const handleDeleteIntegration = async (name) => {
        await deleteIntegration(name, dispatch, refreshAccessTokenCounter)
    }

    useEffect(() => {
        if (isAuthorized) {
            ;(async () => {
                try {
                    await getConnectedIntegrations()
                    const user_id = await getUserIdByEmail(dispatch, refreshAccessTokenCounter)
                    const address = `https://oauth${
                        process.env.REACT_APP_ENV != "prod" ? "-dev" : ""
                    }.redstrings.io/oauth/salesforce/${user_id}`
                    setSfOauthUrl(address)
                } catch (e) {
                    console.log(e.message)
                } finally {
                    dispatch(changeLoaderState(false))
                }
            })()
        }
    }, [isAuthorized])

    if (currentCompanyId !== 2 && !isRedstringsUser(user)) return null

    return (
        <Box
            sx={{
                margin: "auto",
                maxWidth: "1178px",
            }}
        >
            <CredentialsConnect
                openCredentialsConnectPopup={openCredentialsConnectPopup}
                setOpenCredentialsConnectPopup={setOpenCredentialsConnectPopup}
                name={credentialsConnectIntegrationName}
            />
            <Box>
                <Typography fontSize={{ xs: "32px" }} marginBottom="60px">
                    Apps & Integrations
                </Typography>

                <div>
                    <Typography fontSize={{ xs: "20px" }} marginBottom="15px">
                        My integrations
                    </Typography>
                    <Box
                        sx={{
                            borderRadius: "5px",
                            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.25)",
                            "> div:not(last-child)": {
                                borderBottom: "1px solid rgba(169, 169, 169, 0.5)",
                            },
                        }}
                    >
                        {integrations.includes("salesforce") && (
                            <AppCard
                                logo={INTEGRATION_LOGOS.salesforce}
                                name={"Salesforce"}
                                connected={true}
                                action={() => handleDeleteIntegration("salesforce")}
                            />
                        )}
                        {integrations.includes("middesk") && (
                            <AppCard
                                logo={INTEGRATION_LOGOS.middesk}
                                name={"Middesk"}
                                connected={true}
                                action={() => handleDeleteIntegration("middesk")}
                            />
                        )}
                        {integrations.includes("postgres") && (
                            <AppCard
                                logo={INTEGRATION_LOGOS.postgres}
                                name={"Postgres"}
                                connected={true}
                                action={() => handleDeleteIntegration("postgres")}
                            />
                        )}
                        <AppCard logo={INTEGRATION_LOGOS.seon} name={"Seon"} alwaysConnected />
                        <AppCard
                            logo={INTEGRATION_LOGOS.maxmind}
                            name={"MaxMind"}
                            alwaysConnected
                        />
                        <AppCard
                            logo={INTEGRATION_LOGOS.cobalt}
                            name={"Cobalt - Secretary of State"}
                            alwaysConnected
                        />
                        <AppCard
                            logo={INTEGRATION_LOGOS.crunchbase}
                            name={"Crunchbase"}
                            alwaysConnected
                        />
                        <AppCard
                            logo={INTEGRATION_LOGOS.serpAPI}
                            name={"SerpAPI - Google Search"}
                            alwaysConnected
                        />
                        <AppCard
                            logo={INTEGRATION_LOGOS.socialMedia}
                            name={"Social Media Info"}
                            alwaysConnected
                        />
                        <AppCard
                            logo={INTEGRATION_LOGOS.opencorporates}
                            name={"Opencorporates"}
                            alwaysConnected
                        />
                        <AppCard
                            logo={INTEGRATION_LOGOS.abstract}
                            name={"Abstract"}
                            alwaysConnected
                        />
                        <AppCard logo={INTEGRATION_LOGOS.mapbox} name={"Mapbox"} alwaysConnected />
                    </Box>
                </div>

                <div style={{ marginBottom: "20px" }}>
                    <Typography
                        fontSize={{ xs: "20px" }}
                        marginBottom="15px"
                        marginTop="236px !important"
                    >
                        Explore Apps
                    </Typography>
                    {availableIntegrations.length > 0 ? (
                        <Box
                            sx={{
                                borderRadius: "5px",
                                boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.25)",
                                "> div:not(last-child)": {
                                    borderBottom: "1px solid rgba(169, 169, 169, 0.5)",
                                },
                            }}
                        >
                            {availableIntegrations.includes("salesforce") && (
                                <AppCard
                                    logo={INTEGRATION_LOGOS.salesforce}
                                    name={"Salesforce"}
                                    connected={false}
                                    oauthUrl={sfOauthUrl}
                                />
                            )}
                            {availableIntegrations.includes("middesk") && (
                                <AppCard
                                    logo={INTEGRATION_LOGOS.middesk}
                                    name={"Middesk"}
                                    connected={false}
                                    action={() => {
                                        setCredentialsConnectIntegrationName("Middesk")
                                        setOpenCredentialsConnectPopup(!openCredentialsConnectPopup)
                                    }}
                                />
                            )}
                            {availableIntegrations.includes("postgres") && (
                                <AppCard
                                    logo={INTEGRATION_LOGOS.postgres}
                                    name={"Postgres"}
                                    connected={false}
                                    action={() => {
                                        setCredentialsConnectIntegrationName("Postgres")
                                        setOpenCredentialsConnectPopup(!openCredentialsConnectPopup)
                                    }}
                                />
                            )}
                        </Box>
                    ) : (
                        <EmptyIntegration text={"More apps coming soon"} />
                    )}
                </div>
            </Box>
        </Box>
    )
}

export default withCustomAuthenticationRequired(Integrations)
