import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { handleShowToaster } from "../../../../../../../../../../redux/actions/showToasterActions"
import { Modal, Box } from "@mui/material"
import { createTemplate } from "../../../../../../../../../../services/playbook/playbookServices"
import { RepoMainGenerator } from "../../../../../RepoMainGenerator/RepoMainGenerator"
import { LoadingDialog } from "../../../../../../../Playbook/components/LoadingDialog/LoadingDialog"
import styles from "./GenerateWithAiModal.module.scss"

export function GenerateWithAiModal({ open, onClose }) {
    const [isLoading, setIsLoading] = useState(false)
    const [inputValue, setInputValue] = useState("")

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userId = useSelector((state) => state.userState.user.id)
    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const handleCreateTemplate = async (e, text) => {
        setIsLoading(true)
        let resultData = null
        try {
            const result = await createTemplate(
                { user_id: userId, company_id: currentCompanyId, text } || null,
                dispatch,
                refreshAccessTokenCounter,
            )
            if (result.status && result.status === "error") {
                dispatch(handleShowToaster("error", result.message))
            } else if (result) {
                resultData = result
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setTimeout(() => {
                    dispatch(handleShowToaster("error", error.response.data.error))
                }, 2000)
            } else {
                setTimeout(() => {
                    dispatch(handleShowToaster("error", error.response.data.error))
                }, 2000)
                dispatch(handleShowToaster("error", "Action failed"))
                console.log(error)
            }
        } finally {
            setTimeout(() => {
                setIsLoading(false)
            }, 2000)
            if (resultData) {
                navigate(`/my-playbooks/${resultData}`, { replace: true })
                onClose()
            }
        }
    }

    return (
        <>
            {isLoading ? (
                <LoadingDialog isLoading={isLoading} />
            ) : (
                <Modal
                    open={open}
                    onClose={onClose}
                    className={styles.generateWithAiModalContainer}
                    role="dialog"
                >
                    <Box className={styles.generateWithAiModalWrapper}>
                        <RepoMainGenerator
                            handleCreateTemplate={handleCreateTemplate}
                            isLoading={isLoading}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            title={"Create new playbook using FFA’s AI playbook Generator"}
                        />
                    </Box>
                </Modal>
            )}
        </>
    )
}
