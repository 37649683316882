import React from "react"
import Lottie from "react-lottie"
import animationData from "./success.json"

export default function Success() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    }
    return (
        <div>
            <Lottie
                options={defaultOptions}
                style={{
                    height: 800,
                    width: 800,
                    minHeight: "max-content",
                    minWidth: "max-content",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "auto",
                    marginTop: 50,
                    padding: "auto",
                }}
            />
        </div>
    )
}
