import React from "react"
import { Box } from "@mui/material"
import addIcon from "../../../../../../../../../../assets/icons/Repo/addNewIcon.svg"
import styles from "./AddNewButton.module.scss"

export function AddNewButton({ action, title }) {
    return (
        <Box className={styles.addNewButtonContainer} onClick={() => action(true)}>
            <img src={addIcon} className={styles.buttonAddStepIcon} />
            Add New {title}
        </Box>
    )
}
