import React, { useState } from "react"
import { Box, Typography, Divider } from "@mui/material"
import styles from "./Recommendations.module.scss"
import LoadingButton from "@mui/lab/LoadingButton"
import { RecommendationItem } from "./components/RecommendationItem/RecommendationItem"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import { useDispatch } from "react-redux"

export function Recommendations({
    recommendations,
    control,
    resetField,
    values,
    refreshPageData,
    handleAddTools,
}) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const handleAddRecommendation = async () => {
        setLoading(true)
        try {
            await handleAddTools([26], -1, 0, null)
            await refreshPageData()
            dispatch(handleShowToaster("success", "Successfully created new recommendation"))
            setLoading(false)
        } catch (e) {
            console.log(e)
            dispatch(handleShowToaster("success", "Action failed"))
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box className={styles.recommendationWrapper}>
            <Box className={styles.recommendationHeader}>
                <Typography
                    style={{
                        color: "#000000",
                        fontSize: "20px",
                        fontWeight: "500",
                        lineHeight: "24px",
                    }}
                >
                    Recommendations
                </Typography>
                <LoadingButton
                    loading={loading}
                    onClick={handleAddRecommendation}
                    className={styles.addButton}
                    variant="outlined"
                >
                    + Add
                </LoadingButton>
            </Box>
            <Divider style={{ marginTop: "20px" }} />
            <Box paddingBottom="20px">
                {recommendations.map(({ log, id }, index) => (
                    <RecommendationItem
                        key={id}
                        text={log?.note}
                        number={index + 1}
                        id={id}
                        control={control}
                        resetField={resetField}
                        values={values}
                        refreshPageData={refreshPageData}
                    />
                ))}
            </Box>
        </Box>
    )
}
