import React from "react"
import { Box } from "@mui/material"
import styles from "./InformativeSection.module.scss"

export function InformativeSection({ title, value, inLimit = true }) {
    return (
        <Box className={styles.informativeSectionContainer}>
            <Box className={styles.informativeSectionTitle}>{title}</Box>
            <Box className={styles.informativeSectionValue}>{value}</Box>
            {inLimit ? (
                <Box className={styles.informativeSectionLimit}>In Limit</Box>
            ) : (
                <Box className={styles.informativeSectionLimit}>Above Limit</Box>
            )}
        </Box>
    )
}
