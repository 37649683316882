import React from "react"
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material"
import styles from "./RiskStrategies.module.scss"

const MaturityBadge = ({ color, text }) => (
    <div
        style={{
            backgroundColor: color,
            padding: "2px 12px",
            borderRadius: "24px",
            display: "inline-block",
            color: "#fff",
            fontWeight: 500,
        }}
    >
        {text}
    </div>
)

export function RiskStrategies({ rows, columns }) {
    return (
        <Box className={styles.riskStrategiesContainer}>
            <Typography fontSize="18px">Risk Strategies</Typography>
            <Box className={styles.riskStrategiesTable}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => (
                                    <TableCell key={index}>{column.label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {columns.map((column, colIndex) => (
                                        <TableCell key={colIndex}>
                                            {column.id === "maturity" ? (
                                                <MaturityBadge
                                                    color={row.maturityColor}
                                                    text={row[column.id]}
                                                />
                                            ) : (
                                                row[column.id]
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}
