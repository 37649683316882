import React from "react"
import { Box } from "@mui/material"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import styles from "./MetricsCard.module.scss"

export function MetricsCard({ mainValue, indicator, indicatorName, arrowColor, variant }) {
    const colors = {
        success: "#00B14F",
        warning: "#FFB800",
        danger: "#E02B38",
    }
    const сolorValue = arrowColor || colors[variant] || "#374957"

    return (
        <Box className={styles.metricsCardContainer}>
            <Box className={styles.metricsCardMainValue}>{mainValue}</Box>
            <Box className={styles.metricsCardIndicator}>
                <Box className={styles.metricsCardIndicatorValue}>
                    <ArrowUpwardIcon style={{ width: "15px", height: "15px", color: сolorValue }} />
                    <span style={{ color: сolorValue }}>{indicator}%</span>
                </Box>
                <Box>{indicatorName}</Box>
            </Box>
        </Box>
    )
}
