import React, { useState, useEffect } from "react"
import { doToolAction } from "../../../../services/notebook/notebookServices"
import { useSelector, useDispatch } from "react-redux"
import { Box, Typography, CircularProgress, TextField, Divider } from "@mui/material"
import { Controller } from "react-hook-form"
import makeStyles from "@mui/styles/makeStyles"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import accordionExpand from "../../../../assets/icons/accordionExpand.svg"
import { DotsMenu } from "../DotsMenu"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { RiskLevel } from "../../components/RiskLevel/RiskLevel"
import styles from "./Text.module.scss"

const useStyles = makeStyles(() => ({
    accordionImage: {
        "& .MuiAccordionSummary-expandIconWrapper": {
            "&.Mui-expanded": {
                transform: "rotate(90deg)",
            },
        },
    },
}))

function Text({
    tool_id,
    index_x,
    index_y,
    handleAddTools,
    handleDeleteTool,
    control,
    values,
    setValue,
    title,
    subtitle,
    logo,
    integrations,
    toolsConfig,
    refreshPageData,
    resetField,
    newTool,
    isFromToolbox,
}) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isLoadingNewTool, setIsLoadingNewTool] = useState(false)
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(newTool ? true : false)
    const [isLoading, setIsLoading] = useState(false)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (values) {
                await doToolAction(
                    values.case_id,
                    values.tools[tool_id]?.id,
                    values.tools[tool_id]?.type,
                    values.tools[tool_id]?.log,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                dispatch(handleShowToaster("success", "Successfully save data"))
            }
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleCancel = () => {
        resetField(`tools.${values.tools[tool_id]?.id}.log.note`)
    }

    const toggleAccordion = () => {
        setIsAccordionExpanded(!isAccordionExpanded)
    }

    useEffect(() => {
        if (newTool || (location.state?.scrollToElement && location.state?.id === tool_id)) {
            setIsAccordionExpanded(true)
        }
    }, [newTool, tool_id, location])

    return (
        <Box>
            <Accordion
                style={{ "border-radius": "10px", cursor: "default" }}
                className={styles.textAccordion}
                expanded={isAccordionExpanded}
            >
                <AccordionSummary
                    className={classes.accordionImage}
                    style={{
                        cursor: "default",
                        backgroundColor: "transparent",
                        flexDirection: "row-reverse",
                        gap: "10px",
                    }}
                    expandIcon={
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={toggleAccordion}
                            src={accordionExpand}
                        />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box className={styles.textTitleWrapper}>
                        {/* <img className={styles.standartApiTitleLogo} src={logo_url}></img> */}
                        <Box display="flex" flexDirection="column" gap="5px">
                            <Typography className={styles.textTitleText}>{title}</Typography>
                            <Typography className={styles.textSubtitle}>{subtitle}</Typography>
                        </Box>
                        {isDeleteLoading || isLoadingNewTool ? (
                            <Box className={styles.textLoaderWrapper}>
                                <CircularProgress
                                    color="inherit"
                                    style={{ width: "25px", height: "25px" }}
                                />
                            </Box>
                        ) : (
                            <Box className={styles.textActionsWrapper}>
                                <RiskLevel
                                    values={values}
                                    tool_id={tool_id}
                                    control={control}
                                    resetField={resetField}
                                    refreshPageData={refreshPageData}
                                    toolLog={values.tools[tool_id].log || {}}
                                />
                                {!isFromToolbox && (
                                    <DotsMenu
                                        handleAddTools={handleAddTools}
                                        index_x={index_x}
                                        index_y={index_y}
                                        values={values}
                                        integrations={integrations}
                                        toolsConfig={toolsConfig}
                                        handleDeleteTool={handleDeleteTool}
                                        tool_id={tool_id}
                                        setIsDeleteLoading={setIsDeleteLoading}
                                        setIsLoadingNewTool={setIsLoadingNewTool}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </AccordionSummary>
                <Divider className={styles.textDivider} />
                <Box className={styles.textInputWrapper}>
                    <Controller
                        name={`tools.${tool_id}.log.note`}
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                variant="outlined"
                                label="Your insight"
                                minRows={5}
                                multiline
                                InputProps={{
                                    className: styles.input,
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: "#6B869A",
                                    },
                                }}
                                fullWidth
                                disabled={isLoading}
                            />
                        )}
                    />
                    <Box className={styles.editIcons}>
                        {isLoading ? (
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        ) : (
                            <>
                                <Box display="flex" gap="15px">
                                    <CloseIcon
                                        onClick={handleCancel}
                                        className={styles.standartIcon}
                                    />
                                    <DoneIcon
                                        onClick={handleSave}
                                        className={styles.standartIcon}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Accordion>
        </Box>
    )
}

export default Text
