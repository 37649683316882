import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import Grid from "@mui/material/Grid"
import SendIcon from "@mui/icons-material/Send"
import MuiAlert from "@mui/material/Alert"
import { Box } from "@mui/material"
import { ReactMultiEmail, isEmail } from "react-multi-email"
import { CaseReport } from "./CaseReport/CaseReport"
import { pdf } from "@react-pdf/renderer"
import { useSelector, useDispatch } from "react-redux"
import { sendPDFReport } from "../../../services/notebook/notebookServices"
import { handleShowToaster } from "../../../redux/actions/showToasterActions"
import "react-multi-email/style.css"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function ShareCaseDialog({
    caseTitle,
    values,
    sendReportDialogOpen,
    setSendReportDialogOpen,
    setValue,
}) {
    const [emails, setEmails] = useState()

    const dispatch = useDispatch()

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const markers = useSelector((state) => state.findingsState.markers)

    const handleCloseMailingListDialog = (event, reason) => {
        setSendReportDialogOpen(false)
    }

    const handleSendPDF = async () => {
        const doc = <CaseReport caseTitle={caseTitle} values={values} markers={markers} />

        const blob = await pdf(doc).toBlob()

        const formData = new FormData()
        formData.append("pdf", blob, `case_${values?.case_id}_report.pdf`)
        formData.append("send_mail", emails)
        formData.append("case_id", values?.case_id)

        try {
            await sendPDFReport(formData, dispatch, refreshAccessTokenCounter)
            handleCloseMailingListDialog()
            dispatch(
                handleShowToaster("success", "The case report was sent to your mail successfuly"),
            )
        } catch (error) {
            dispatch(handleShowToaster("error", "Action failed"))
            console.error("Error sending PDF:", error)
        }
    }

    useEffect(() => {
        if (emails) {
            setValue("send_report_to", emails.join(","))
        }
    }, [emails])

    return (
        <div>
            <Dialog
                onClose={handleCloseMailingListDialog}
                open={sendReportDialogOpen}
                width="sm"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Share "{caseTitle}" Report</DialogTitle>
                <DialogContent style={{ padding: "16px 24px" }}>
                    <Grid item container direction="row" alignItems="center">
                        <Grid item xs={3}>
                            <Box
                                component="label"
                                for="mailing"
                                sx={{
                                    marginRight: "18px",
                                    fontSize: "18px",
                                }}
                            >
                                Mailing List
                            </Box>
                        </Grid>
                        <Grid item xs={9}>
                            <ReactMultiEmail
                                placeholder="email addresses"
                                emails={emails}
                                onChange={(_emails) => setEmails(_emails)}
                                validateEmail={(email) => {
                                    return isEmail(email)
                                }}
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                        <div data-tag key={index}>
                                            {email}
                                            <span
                                                data-tag-handle
                                                onClick={() => removeEmail(index)}
                                            >
                                                ×
                                            </span>
                                        </div>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSendPDF}
                        autoFocus
                        style={{
                            fontSize: "1.5em",
                            textTransform: "none",
                            padding: "6px 8px 0 8px",
                            flexDirection: "column",
                            color: values.send_report_to ? "#06D6A0" : "",
                        }}
                        disabled={!values.send_report_to}
                    >
                        <SendIcon
                            style={{
                                fontSize: "1.5em",
                            }}
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
