import React from "react"
import completedIcon from "../../../../../../../../assets/icons/stepCompleted.svg"
import { Box } from "@mui/material"
import styles from "../../StepsCarouselCard.module.scss"

export const StepContent = ({ log, index_x }) =>
    log?.status === "Completed" ? (
        <img src={completedIcon} alt="completedIcon" />
    ) : (
        <Box className={styles.stepsCarouselCardNumberRound}>{index_x + 1}</Box>
    )
