export const mainPageInitialValues = {
    recent_cases: [],
    case_statuses_by_date: {
        last_x_days: "30",
        statuses: [],
    },
    open_action_items: null,
    new_cases: [],
    in_progress: [],
    closed: [],
}

export const notebookContainerInitialValues = {
    case_id: null,
    title: null,
    reported_by: null,
    reported_date: null,
    tags: [],
    type: null,
    description: null,
    urgency: null,
    status: null,
    resolution: null,
    send_report_to: "",
    middesk_account_businesses_list: [],
    tools: [],
    templates: [],
    salesforce_case_info: {},
}

export const repoTemplateInitialValues = {
    name: "",
    displayName: "",
    description: "",
    thumbnail: "",
    linkedin_profile: "",
    tools: {
        ordered: [],
        unordered: [],
    },
    uuid: "",
}
