import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { RepoPlaybookComponent } from "./components/RepoPlaybookComponent/RepoPlaybookComponent"
import { getApprovedPlaybooks } from "../../../../../../services/playbook/playbookServices"
import { changeLoaderState } from "../../../../../../redux/actions/loaderActions"
import styles from "./RepoMainPlaybooksList.module.scss"

export function RepoMainPlaybooksList({ handleOpenRepoPlaybook, handleOpenRepoAuthModal }) {
    const dispatch = useDispatch()

    const [playbooks, setPlaybooks] = useState([])

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const isAuthorized = useSelector((state) => state.isAuthorized)

    const handleGetApprovedPlaybooks = async () => {
        try {
            dispatch(changeLoaderState(true))
            const result = await getApprovedPlaybooks(dispatch, refreshAccessTokenCounter)
            if (result) setPlaybooks(result)
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(changeLoaderState(false))
        }
    }

    useEffect(() => {
        handleGetApprovedPlaybooks()
    }, [])

    return (
        <Box className={styles.repoMainPlaybooksListContainer}>
            <Box className={styles.repoMainPlaybooksListTitle}>
                <p className={styles.repoMainPlaybooksListTitleSub}>Curated Best practices</p>
                <p className={styles.repoMainPlaybooksListTitleMain}>Explore Our Playbooks</p>
            </Box>
            {playbooks.length > 0 && (
                <Box className={styles.repoMainPlaybooksListMain}>
                    {playbooks.map((playbook, index) => (
                        <RepoPlaybookComponent
                            key={`${playbook?.uuid}${index}`}
                            name={playbook?.name || ""}
                            userName={playbook?.user_display_name || ""}
                            userEmail={playbook?.user_email || ""}
                            caseType={playbook?.case_type || ""}
                            templateId={playbook?.uuid}
                            handleOpenRepoPlaybook={handleOpenRepoPlaybook}
                            handleOpenRepoAuthModal={handleOpenRepoAuthModal}
                        />
                    ))}
                </Box>
            )}
        </Box>
    )
}
