import React from "react"
import { Box } from "@mui/material"
import designedLogo from "../../../../../../../../../../assets/icons/Repo/designed-icon.svg"
import styles from "../../TemplateInfo.module.scss"

export function DesignerInfo({ displayName }) {
    return (
        displayName && (
            <Box className={styles.templateInfoTitleDesigned}>
                <img src={designedLogo} alt="Designed Logo" /> Designed by: {displayName}
            </Box>
        )
    )
}
