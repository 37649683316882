import { SET_SUCCESS_STATE } from "./types"

export const applySuccessLoader = () => (dispatch) => {
    dispatch({
        type: SET_SUCCESS_STATE,
        payload: true,
    })
    setTimeout(() => {
        dispatch({
            type: SET_SUCCESS_STATE,
            payload: false,
        })
    }, 4400)
}

export const changeSuccessState = (successState) => {
    return {
        type: SET_SUCCESS_STATE,
        payload: successState,
    }
}
