import React from "react"
import { Box, Typography, Toolbar } from "@mui/material"
import redstringsLogo from "../../../assets/images/redstringsLogo.svg"
import { LoginLinkContainerAuth0 } from "../../../pages/Login/Auth0Login"
import styles from "../Topbar.module.scss"

function DefaultHeader({ user }) {
    return (
        <Toolbar className={styles.topBarToolBar}>
            <Box
                container
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                padding="0 20px"
                width="100%"
            >
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img src={redstringsLogo} />
                </Box>
                <Box display="flex" gap="10px" justifyContent="center" alignItems="center">
                    <LoginLinkContainerAuth0 />
                    <Typography fontSize="14px" color="#000000">
                        {user?.name}
                    </Typography>
                </Box>
            </Box>
        </Toolbar>
    )
}

export default DefaultHeader
