import { SET_TOASTER_STATE } from "../actions/types"

const initialState = {
    openToaster: false,
    severity: "success",
    title: "",
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_TOASTER_STATE:
            return {
                ...state,
                openToaster: action.openToaster,
                severity: action.severity || initialState.severity,
                title: action.title || initialState.title,
            }
        default:
            return state
    }
}
