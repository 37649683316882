import React, { useState, useRef } from "react"
import { Box, Typography, Divider } from "@mui/material"
import styles from "./Instructions.module.scss"
import toolboxInstructionsThumbnail from "../../../../assets/images/tagging-findings-thumb.jpg"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline" // Import play icon

export function Instructions() {
    const [isPlaying, setIsPlaying] = useState(false)
    const videoRef = useRef(null)

    const handleVideoPlay = () => {
        if (videoRef.current) {
            videoRef.current.play()
            setIsPlaying(true)
        }
    }

    return (
        <Box className={styles.instructionsContainer}>
            <Typography className={styles.instructionsHeader}>
                Usage instructions
                <Divider style={{ marginTop: "24px" }} />
            </Typography>

            <Box className={styles.instructionsText}>
                <p>1. Select any fraud tool</p>
                <p>2. Enter your inputs & run the tool</p>
                <p>3. Review response, tag data & assign risk scores</p>
            </Box>
            {/* Video Section */}
            <Box
                className={styles.videoContainer}
                style={{ position: "relative", width: "55%" }}
                onClick={handleVideoPlay}
            >
                <video
                    ref={videoRef}
                    src="https://redstrings-static-resources.s3.eu-central-1.amazonaws.com/toolbox_instructions.mp4"
                    alt="Instructional Video"
                    controls
                    loop
                    poster={toolboxInstructionsThumbnail} // Use the thumbnail image as the poster
                    style={{ width: "100%", height: "auto", opacity: isPlaying ? 1 : 0.75 }} // Video takes full width of the container
                />
                {!isPlaying && (
                    <Box
                        className={styles.playButton}
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            cursor: "pointer",
                            zIndex: 10, // Ensure it's above the video
                        }}
                    >
                        <PlayCircleOutlineIcon style={{ fontSize: "64px" }} />
                    </Box>
                )}
            </Box>
        </Box>
    )
}
