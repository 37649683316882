import React, { useState, useRef, useMemo, useEffect } from "react"
import { Box } from "@mui/material"
import { AttributeList } from "./components/AttributeList/AttributeList"
import { SectionList } from "./components/SectionList/SectionList"
import { MainSection } from "./components/MainSection/MainSection"
import { EmptyResult } from "./components/EmptyResult/EmptyResult"
import { isEmpty } from "lodash"
import { OptionsPopOver } from "./components/PopOver/OptionsPopOver/OptionsPopOver"
import { RiskPopOver } from "./components/PopOver/RiskPopOver/RiskPopOver"
import styles from "./ApiResponse.module.scss"

export function ApiResponse({
    name,
    url,
    profile_pic,
    attributes,
    sections,
    values,
    tool_id,
    setValue,
    isMoreInfoSubmitting,
    setisMoreInfoSubmitting,
    markers,
    refreshPageData,
    setIsAccordionExpanded,
    isFromToolbox
}) {
    const popOverRef = useRef()
    const wrapperRef = useRef(null)

    const [markedPath, setMarkedPath] = useState("")
    const [selectedElement, setSelectedElement] = useState({
        name: "",
        value: "",
    })
    const [position, setPosition] = useState({})
    const [markingAvailable, setMarkingAvailable] = useState(true)
    const [showRiskPopOver, setShowRiskPopOver] = useState(false)
    const [isLoadingMarking, setIsLoadingMarking] = useState(false)

    const handleNameClick = (event) => {
        const rect = event.target.getBoundingClientRect()
        const wrapperRect = wrapperRef.current.getBoundingClientRect()

        setPosition({
            top: `${rect.top - wrapperRect.top + 20}px`,
            left: `${rect.left - wrapperRect.left + 30}px`,
        })
    }

    const hasData = useMemo(
        () => !(isEmpty(sections) && isEmpty(attributes)),
        [sections, attributes],
    )

    const handleClickOutside = (event) => {
        if (popOverRef.current && !popOverRef.current.contains(event.target)) {
            setShowRiskPopOver(false)
        }
    }

    useEffect(() => {
        if (!isLoadingMarking) {
            document.addEventListener("mousedown", handleClickOutside)
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isLoadingMarking])

    if (!hasData) {
        return (
            <Box className={styles.apiResponseContainer}>
                <EmptyResult />
            </Box>
        )
    }

    return (
        <Box className={styles.apiResponseContainer} ref={wrapperRef}>
            {(name || url || profile_pic) && (
                <MainSection name={name} url={url} profile_pic={profile_pic} />
            )}
            {attributes && (
                <AttributeList
                    attributes={attributes}
                    setMarkedPath={setMarkedPath}
                    handleNameClick={handleNameClick}
                    setSelectedElement={setSelectedElement}
                    setMarkingAvailable={setMarkingAvailable}
                    setShowRiskPopOver={setShowRiskPopOver}
                    values={values}
                    tool_id={tool_id}
                    markers={markers}
                    setIsAccordionExpanded={setIsAccordionExpanded}
                />
            )}
            {sections && (
                <SectionList
                    sections={sections}
                    values={values}
                    tool_id={tool_id}
                    setValue={setValue}
                    isMoreInfoSubmitting={isMoreInfoSubmitting}
                    setisMoreInfoSubmitting={setisMoreInfoSubmitting}
                    setMarkedPath={setMarkedPath}
                    handleNameClick={handleNameClick}
                    setSelectedElement={setSelectedElement}
                    setMarkingAvailable={setMarkingAvailable}
                    setShowRiskPopOver={setShowRiskPopOver}
                    markers={markers}
                    setIsAccordionExpanded={setIsAccordionExpanded}
                />
            )}
            {showRiskPopOver && (
                <RiskPopOver
                    position={position}
                    setShowRiskPopOver={setShowRiskPopOver}
                    values={values}
                    tool_id={tool_id}
                    markers={markers}
                    markedPath={markedPath}
                    setValue={setValue}
                    refreshPageData={refreshPageData}
                    popOverRef={popOverRef}
                    setIsLoadingMarking={setIsLoadingMarking}
                    isLoadingMarking={isLoadingMarking}
                    isFromToolbox={isFromToolbox}
                    {...selectedElement}
                />
            )}
        </Box>
    )
}
