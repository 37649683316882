import React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import styles from "./RemoveDialog.module.scss"
import { Divider } from "@mui/material"

export function RemoveDialog({ handleAgree, open, setClosed, name = "tool" }) {
    return (
        <Dialog
            open={open}
            onClose={setClosed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                className: styles.dialogWrapper,
            }}
        >
            <DialogTitle id="alert-dialog-title" fontWeight="400">
                Are you sure you want to remove this {name}?
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
                <DialogContentText id="alert-dialog-description" fontSize="14px">
                    This action is permanent, it cannot be undone.
                </DialogContentText>
            </DialogContent>
            <Divider style={{ width: "90%", alignSelf: "center" }} />
            <DialogActions style={{ height: "60px" }}>
                <Button onClick={setClosed} className={styles.cancelButton} autoFocus>
                    Cancel
                </Button>
                <Button onClick={handleAgree} className={styles.removeButton}>
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    )
}
