import React, { useState } from "react"
import { Box, Divider, MenuItem, CircularProgress, TextField } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { RISK_LEVELS } from "../../../../../../../constants/appConstants"
import { updateLog } from "../../../../../../../services/notebook/notebookServices"
import { handleShowToaster } from "../../../../../../../redux/actions/showToasterActions"
import { setFindingsState } from "../../../../../../../redux/actions/findingsActions"
import { LoadingButton } from "@mui/lab"
import { useAuth0 } from "@auth0/auth0-react"
import { v4 as uuid } from "uuid"
import styles from "./RiskPopOver.module.scss"

export function RiskPopOver({
    position,
    setShowRiskPopOver,
    name,
    value,
    values,
    tool_id,
    markers,
    markedPath,
    setValue,
    refreshPageData,
    popOverRef,
    setIsLoadingMarking,
    isLoadingMarking,
    isFromToolbox
}) {
    const dispatch = useDispatch()
    const { stepId } = useParams()
    const { user } = useAuth0()

    const uniqueId = uuid()

    const [riskLevel, setRiskLevel] = useState(0)
    const [description, setDescription] = useState("")
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const handleChangeRiskLevel = async (value) => {
        setRiskLevel(value)
    }

    const handleCancel = () => {
        setShowRiskPopOver(false)
        setRiskLevel(null)
    }

    const handleSubmit = async () => {
        const currentStep = Object.values(values.tools)?.find((tool) => tool.id === +stepId)?.log
        if (markedPath && currentStep) {
            const { "Show Similar Results": _, ...request } =
                values.tools[tool_id]?.log?.request || {}
            const riskLevelObject = {
                link: markedPath,
                risk_level: riskLevel,
                description: description,
                name,
                value,
                tool_id,
                tool_name: values.tools[tool_id]?.title,
                request,
                uniqueId,
                user: user?.email?.split("@")[0] || "",
                step: { ...currentStep, id: stepId },
            }
            const currentRiskLevels = markers
            const newData = currentRiskLevels?.concat([riskLevelObject])
            try {
                setIsLoadingMarking(true)
                const log = await updateLog(
                    values.case_id,
                    values.tools[tool_id]?.id,
                    {
                        ...values.tools[tool_id].log,
                        standard_response_risk_levels: newData,
                    },
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                setRiskLevel(null)
                setDescription(null)
                setShowRiskPopOver(false)
                setValue(
                    `tools.${tool_id}.log.standard_response_risk_levels`,
                    log["standard_response_risk_levels"],
                )
                dispatch(handleShowToaster("success", "Successfully add new marker"))
                if (isFromToolbox) {
                    dispatch(setFindingsState(true, null))
                }
            } catch (error) {
                console.log(error)
                dispatch(handleShowToaster("error", "Action failed"))
            } finally {
                setIsLoadingMarking(false)
            }
        }
    }

    return (
        <Box ref={popOverRef} className={styles.riskPopOverWrapper} style={{ ...position }}>
            <Box className={styles.riskPopOverHeader}>
                <span className={styles.riskPopOverHeaderName}>{name}:</span>
                <span className={styles.riskPopOverHeaderValue}>{String(value)}</span>
            </Box>
            <Divider />
            <Box className={styles.riskPopOverNote}>
                <span className={styles.riskPopOverNoteTitle}>Type a note:</span>
                <Box className={styles.riskPopOverNoteText}>
                    <TextField
                        onChange={(e) => setDescription(e.target.value)}
                        disabled={isLoadingMarking}
                        variant="outlined"
                        minRows={5}
                        multiline
                        placeholder="Write here"
                        InputProps={{
                            className: styles.input,
                        }}
                        InputLabelProps={{
                            style: {
                                color: "#6B869A",
                            },
                        }}
                        fullWidth
                    />
                </Box>
            </Box>
            <Box className={styles.riskPopOverRiskScore}>
                <span className={styles.riskPopOverRiskScoreTitle}>Risk Score</span>
                <Box className={styles.riskPopOverRiskScoreIcons}>
                    {RISK_LEVELS.map((level) => (
                        <MenuItem
                            disabled={isLoadingMarking}
                            selected={riskLevel === level.value}
                            onClick={() => handleChangeRiskLevel(level.value)}
                            sx={{
                                "&.MuiMenuItem-root": {
                                    padding: "8px",
                                    borderRadius: "4px",
                                    border: "1px solid #EFEEF1",
                                },
                                "&.Mui-selected": {
                                    backgroundColor: "#EDEEF8",
                                    borderRadius: "4px",
                                    border: "1px solid #5E4AFD",
                                },
                            }}
                            key={level?.value}
                            value={level?.title}
                        >
                            <img src={level?.img}></img>
                        </MenuItem>
                    ))}
                </Box>
                <Box className={styles.riskPopOverButtons}>
                    <LoadingButton
                        disabled={isLoadingMarking}
                        onClick={handleCancel}
                        className={styles.riskPopOverButtonsCancel}
                    >
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        loadingIndicator={
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        }
                        loading={isLoadingMarking}
                        onClick={handleSubmit}
                        className={styles.riskPopOverButtonsSubmit}
                    >
                        Submit
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}
