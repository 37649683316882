import React from "react"
import { Box } from "@mui/material"
import { v4 as uuid } from "uuid"
import addIcon from "../../../../../../../../../../../../assets/icons/Repo/addNewIcon.svg"
import styles from "./ButtonAddStep.module.scss"

export function ButtonAddStep({
    disabled,
    setValue,
    templateData,
    handleSelectStep,
    setEditMode,
    setEditingStepId,
}) {
    const handleAddNewStep = () => {
        if (disabled) return
        const uniqueId = uuid()

        const newStep = {
            log: { note: "" },
            children: [],
            tool_type_id: 46,
            red_flags: [],
            uniqId: uniqueId,
        }

        const newSteps = [...templateData.tools.ordered, newStep]
        setEditMode(true)
        setValue("tools.ordered", newSteps, { shouldValidate: true })
        setEditingStepId(uniqueId)
        handleSelectStep(newSteps.length - 1)
    }

    return (
        <Box
            className={styles.buttonAddStepContainer}
            onClick={handleAddNewStep}
            style={{ opacity: disabled ? 0.7 : 1, cursor: disabled && "wait" }}
        >
            <img src={addIcon} className={styles.buttonAddStepIcon} />
            Add New Step
        </Box>
    )
}
