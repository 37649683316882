import { combineReducers } from "redux"
import getMenuConfig from "./getMenuConfig"
import getUserVisualPreferencesItems from "./getUserVisualPreferencesItems"
import getSidebarChoice from "./getSidebarChoice"
import getSidebarState from "./getSidebarState"
import getLoginCallbackActions from "./getLoginCallbackActions"
import getReferrerLink from "./getReferrerLink"
import getRefreshAccessTokenCounter from "./getRefreshAccessTokenCounter"
import getIsAuthorized from "./getIsAuthorized"
import getRedirectState from "./getRedirectState"
import getLoaderState from "./getLoaderState"
import getLoaderMessage from "./getLoaderMessage"
import getSuccessState from "./getSuccessState"
import getToasterState from "./getToasterState"
import getFindingsState from "./getFindingsState"
import getCompaniesState from "./getCompaniesState"
import getUserState from "./getUserState"
import getPlaybookTemplateState from "./getPlaybookTemplateState"
import getDataModeState from "./getDataModeState"

export default combineReducers({
    menuConfig: getMenuConfig,
    userVisualPreferencesItems: getUserVisualPreferencesItems,
    sidebarChoice: getSidebarChoice,
    sidebarState: getSidebarState,
    loginCallbackActions: getLoginCallbackActions,
    referrerLink: getReferrerLink,
    refreshAccessTokenCounter: getRefreshAccessTokenCounter,
    isAuthorized: getIsAuthorized,
    redirectState: getRedirectState,
    loaderState: getLoaderState,
    loaderMessage: getLoaderMessage,
    successState: getSuccessState,
    toasterState: getToasterState,
    findingsState: getFindingsState,
    companiesState: getCompaniesState,
    userState: getUserState,
    playbookTemplateState: getPlaybookTemplateState,
    dataModeState: getDataModeState,
})
