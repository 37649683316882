import React, { useState, useMemo, useRef, useEffect } from "react"
import { Box, Autocomplete, TextField, Button } from "@mui/material"
import questionIcon from "../../../../../../../../../../assets/icons/Repo/question-icon.svg"
import addIcon from "../../../../../../../../../../assets/icons/Repo/methodAddIcon.svg"
import { AddNewButton } from "../AddNewButton/AddNewButton"
import makeStyles from "@mui/styles/makeStyles"
import styles from "../../TemplateDescription.module.scss"

const useStyles = makeStyles(() => ({
    selectInput: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
        },
    },
    autoSelect: {
        borderRadius: "10px",
        maxHeight: window.innerWidth < 1600 ? "270px" : "",
    },
}))

export function Method({
    methods,
    toolsDim,
    setValue,
    templateData,
    selectedStepIndex,
    setToolsIdArray,
    toolsIdArray,
    toolsConfig,
    setEditMode,
    isAllowToEdit,
}) {
    const classes = useStyles()
    const [isEdit, setIsEdit] = useState(false)
    const [inputValue, setInputValue] = useState("")

    const textFieldRef = useRef(null)
    const componentRef = useRef(null)

    const filteredTools = useMemo(() => {
        if (!toolsConfig) return []
        const methodsIds = methods.map((method) => method.id)
        return toolsConfig
            .filter((tool) => !methodsIds.includes(tool.id))
            .map((tool) => ({ label: tool.title, id: tool.id }))
    }, [toolsConfig, methods])

    const handleEdit = (value) => {
        setIsEdit(value)
    }

    const handleSelectTool = (event, value) => {
        if (value) {
            const newMethods = [
                ...templateData.tools.ordered[selectedStepIndex].children,
                { tool_type_id: value.id, log: {}, log_map: {} },
            ]
            setValue(`tools.ordered[${selectedStepIndex}].children`, newMethods)
            setToolsIdArray([...toolsIdArray, value.id])
            handleEdit(false)
            setEditMode(true)
        }
    }

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue)
    }

    const handleAddNewMethod = () => {
        if (inputValue) {
            const newMethods = [
                ...templateData.tools.ordered[selectedStepIndex].children,
                { tool_type_id: 125, log: { note: inputValue }, log_map: {} },
            ]
            setValue(`tools.ordered[${selectedStepIndex}].children`, newMethods)
            // setToolsIdArray([...toolsIdArray, value.id])
            handleEdit(false)
            setEditMode(true)
        }
    }

    const handleDeleteTool = (index) => {
        if (!isAllowToEdit) return
        const sortedChildren = [...templateData.tools.ordered[selectedStepIndex].children]
        sortedChildren.splice(index, 1)
        setValue(`tools.ordered[${selectedStepIndex}].children`, sortedChildren)
        setEditMode(true)
    }

    const AddToolComponent = () => (
        <Button
            onClick={handleAddNewMethod}
            className={styles.templateDescriptionDesignationsMethodAddNew}
        >
            Add New Method
            <img src={addIcon} />
        </Button>
    )

    useEffect(() => {
        if (isEdit) {
            textFieldRef.current?.focus()

            const handleClickOutside = (event) => {
                if (componentRef.current && !componentRef.current.contains(event.target)) {
                    setIsEdit(false)
                }
            }

            document.addEventListener("mousedown", handleClickOutside)

            return () => {
                document.removeEventListener("mousedown", handleClickOutside)
            }
        }
    }, [isEdit])

    return (
        <Box ref={componentRef} className={styles.templateDescriptionDesignationsMethod}>
            <Box className={styles.templateDescriptionDesignationsMethodTitle}>
                <img src={questionIcon} />
                Method:
            </Box>
            <ul className={styles.templateDescriptionDesignationsMethodList}>
                {methods?.map((method, index) => (
                    <li
                        key={`${method}${index}`}
                        className={styles.templateDescriptionDesignationsMethodListItem}
                    >
                        {method?.title || method?.description}
                        {isAllowToEdit && (
                            <div
                                className={styles.templateDescriptionDesignationsMethodDeleteIcon}
                                onClick={() => handleDeleteTool(index)}
                            ></div>
                        )}
                    </li>
                ))}
            </ul>
            {isAllowToEdit && (
                <>
                    {isEdit ? (
                        <Autocomplete
                            disablePortal
                            id="method-autocomplete"
                            options={filteredTools}
                            onInputChange={handleInputChange}
                            onChange={handleSelectTool}
                            noOptionsText={<AddToolComponent />}
                            ListboxProps={{
                                className: classes.autoSelect,
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputRef={textFieldRef}
                                    label="Method"
                                    className={classes?.selectInput}
                                    margin="normal"
                                    placeholder="Choose a popular method or write your own"
                                />
                            )}
                            renderOption={(props, option) => (
                                <li
                                    {...props}
                                    className={
                                        styles.templateDescriptionDesignationsAutocompleteList
                                    }
                                >
                                    {option.label}
                                    <Button onClick={() => handleSelectTool(null, option)}>
                                        <img src={addIcon} />
                                    </Button>
                                </li>
                            )}
                        />
                    ) : (
                        <AddNewButton action={handleEdit} title="Method" />
                    )}
                </>
            )}
        </Box>
    )
}
