import React, { useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import { useSelector, useDispatch } from "react-redux"
import { deleteCase } from "../../../services/main/mainServices"
import { Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import LoadingButton from "@mui/lab/LoadingButton"

const DeleteCaseDialog = ({ deleteCasePopup, setDeleteCasePopup, caseId }) => {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const deleteCaseHandler = async () => {
        setIsSubmitting(true)
        await deleteCase(caseId, dispatch, refreshAccessTokenCounter)
        navigate("/")
    }

    return (
        <Dialog
            open={deleteCasePopup}
            onClose={() => setDeleteCasePopup(false)}
            maxWidth="md"
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete this case ?"}
            </DialogTitle>

            <DialogActions
                sx={{
                    justifyContent: "space-between",
                    padding: "20px 24px",
                }}
            >
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                        // background: '#F9F9F9 !important',
                        width: "200px",
                        height: "42.5px",
                        borderRadius: "10px",
                        borderColor: "#000000",
                    }}
                    onClick={() => setDeleteCasePopup(false)}
                >
                    <Typography
                        color="#000000"
                        fontSize="16px"
                        fontWeight="700"
                        sx={{
                            textTransform: "none",
                        }}
                    >
                        Cancel
                    </Typography>
                </Button>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    sx={{
                        background: "#FE0E44 !important",
                        width: "200px",
                        height: "42.5px",
                        borderRadius: "10px",
                    }}
                    onClick={deleteCaseHandler}
                    loading={isSubmitting}
                >
                    <Typography
                        color="#fff"
                        fontSize="16px"
                        fontWeight="700"
                        sx={{
                            textTransform: "none",
                        }}
                    >
                        Delete Case
                    </Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteCaseDialog
