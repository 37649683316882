import React, { useState, useEffect } from "react"
import { Box, Typography, Button, ListItem, ListItemButton, CircularProgress } from "@mui/material"
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded"
import { useDispatch } from "react-redux"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import lineIcon from "../../../../../../assets/icons/Events/Line.svg"
import calendarIcon from "../../../../../../assets/icons/Events/calendar-clock.svg"
import roundIcon from "../../../../../../assets/icons/Events/Ellipse.svg"
import rightArrow from "../../../../../../assets/icons/Entity/rightArrow.svg"
import _ from "lodash"
import moment from "moment/moment"
import styles from "./AllEvents.module.scss"

export function AllEvents({
    events,
    handleAddTools,
    onHandleSelelectEvent,
    setNewEventId,
    refreshPageData,
    newEventId,
}) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [newEvent, setNewEvent] = useState(null)

    useEffect(() => {
        if (newEventId && events.length > 0) {
            const newEntity = events.find((entity) => entity.id === newEventId)
            setNewEvent(newEntity)
        }
    }, [events, newEventId])

    const handelAddNewEvent = async () => {
        setLoading(true)
        try {
            // because events are not ordered by index
            const result = await handleAddTools([35], -1, 0, null)
            let newEvent = []
            for (let key in result) {
                if (key in result && result[key].type === 35) {
                    newEvent.push(result[key])
                }
            }
            let newEntity = _.differenceBy(newEvent, events, "id")
            setNewEventId(newEntity[0].id)
            await refreshPageData()
            dispatch(handleShowToaster("success", "Successfully created new event"))
            setLoading(false)
        } catch (e) {
            console.log(e)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setLoading(false)
        }
    }
    const handleSelectEvent = (id) => {
        onHandleSelelectEvent(id)
    }
    if (loading) {
        return (
            <Box display="flex" justifyContent="center" marginTop="30px">
                <CircularProgress />
            </Box>
        )
    }
    return (
        <>
            <Box display="flex" flexDirection="column" paddingBottom="90px">
                <Typography fontSize="12px" fontWeight="500" color="#374957">
                    EVENTS
                </Typography>
                <ListItem disablePadding sx={{ display: "block", marginTop: "20px" }}>
                    {events.map(({ log, id }, index) => (
                        <ListItemButton
                            onClick={() => handleSelectEvent(id)}
                            key={id}
                            className={
                                newEvent?.id === id ? styles.newEventElement : styles.eventElement
                            }
                        >
                            <img src={roundIcon} className={styles.roundIcon}></img>
                            {index !== events.length - 1 ? (
                                <img src={lineIcon} className={styles.lineIcon}></img>
                            ) : null}
                            <Typography>{log.Type}</Typography>
                            <Typography className={styles.date}>
                                <img src={calendarIcon} width="13px" height="13px"></img>
                                {moment.utc(log.Date).format("DD/MM/YYYY HH:mm")} UTC
                            </Typography>
                            <img src={rightArrow} className={styles.arrowIcon} />
                        </ListItemButton>
                    ))}
                </ListItem>
            </Box>
            <Box className={styles.newEntityBtnWrapper}>
                <Box className={styles.addEntityBtn}>
                    <Button
                        onClick={handelAddNewEvent}
                        style={{ textTransform: "none", width: "100%", fontWeight: 400 }}
                        variant="standart"
                    >
                        <AddCircleOutlineRoundedIcon
                            style={{ width: "15px", height: "15px", marginRight: "10px" }}
                        />
                        Add New Event
                    </Button>
                </Box>
            </Box>
        </>
    )
}
