import { SET_LOADER_STATE, SET_LOADER_MESSAGE } from "./types"

export const changeLoaderState = (loaderState) => {
    return {
        type: SET_LOADER_STATE,
        payload: loaderState,
    }
}

export const applyRedstringsLoader = () => (dispatch) => {
    dispatch({
        type: SET_LOADER_STATE,
        payload: true,
    })
    setTimeout(
        () =>
            dispatch({
                type: SET_LOADER_STATE,
                payload: false,
            }),
        3400,
    )
}
export const changeLoaderMessage = (loaderMessage) => (dispatch) => {
    dispatch({
        type: SET_LOADER_MESSAGE,
        payload: loaderMessage ? loaderMessage : "",
    })
}
