import axios from "../../utils/axiosConfig"
import { setRefreshAccessTokenCounter } from "../../redux/actions/refreshAccessTokenCounterActions"
import ENUMS from "../../constants/appEnums"

export const updateCaseInfo = async (case_id, type, value, dispatch, refreshAccessTokenCounter) => {
    try {
        await axios.post(ENUMS.API_ROUTES.UPDATE_CASE_INFO, {
            case_id,
            type,
            value,
        })
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const updateLog = async (
    case_id,
    tool_action_id,
    log,
    dispatch,
    refreshAccessTokenCounter,
    risk_level_action = false,
) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.UPDATE_LOG, {
            case_id,
            tool_action_id,
            log,
            risk_level_action,
        })
        return data
    } catch (err) {
        return { response: err?.response?.data }
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getCaseData = async (case_id, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_CASE_DATA, {
            case_id,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const addTools = async (
    case_id,
    tools,
    index_x,
    index_y,
    child_of,
    dispatch,
    refreshAccessTokenCounter,
) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.ADD_TOOLS, {
            case_id,
            tools,
            index_x,
            index_y,
            child_of,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const deleteToolAction = async (
    case_id,
    tool_action_id,
    dispatch,
    refreshAccessTokenCounter,
) => {
    try {
        await axios.post(ENUMS.API_ROUTES.DELETE_TOOL_ACTION, {
            case_id,
            tool_action_id,
        })
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const exportAuditTrail = async (
    case_id,
    send_mail,
    client_logo,
    dispatch,
    refreshAccessTokenCounter,
) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.EXPORT_AUDIT_TRAIL, {
            case_id,
            send_mail,
            client_logo,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const sendPDFReport = async (formData, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.SEND_PDF_REPORT, formData)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const doToolAction = async (
    case_id,
    tool_action_id,
    tool_type_id,
    log,
    dispatch,
    refreshAccessTokenCounter,
) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.DO_TOOL_ACTION, {
            case_id,
            tool_action_id,
            tool_type_id,
            log,
        })
        return data
    } catch (err) {
        return { response: err?.response?.data }
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getToolActionLog = async (tool_action_id, dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_TOOL_ACTION_LOG, { tool_action_id })
        return data
    } catch (err) {
        return { response: err?.response?.data }
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getMiddeskBusinessList = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.GET_MIDDESK_BUSINESSES)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getEntityRelatedCases = async (
    entity_type,
    entity_id,
    case_id,
    dispatch,
    refreshAccessTokenCounter,
) => {
    try {
        const { data } = await axios.post(ENUMS.API_ROUTES.GET_ENTITY_RELATED_CASES, {
            entity_type,
            entity_id,
            case_id,
        })
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const getEntitiesTypes = async (dispatch, refreshAccessTokenCounter) => {
    try {
        const { data } = await axios.get(ENUMS.API_ROUTES.GET_ENTITIES_TYPES)
        return data
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}
