import React from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { createIsMarked } from "../../../../../helpers/notebookHelpers"

export function CaseReportStepAttribute({ attributes, markers, values, tool_id }) {
    const isMarked = createIsMarked(markers, values, tool_id)

    const styles = StyleSheet.create({
        attributesWrapper: {
            position: "relative",
            padding: "12px 18px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            minHeight: "100px",
            maxHeight: "600px",
            border: "1px solid #EFEEF1",
        },
        attributesNameWrapper: {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            width: "100%",
        },
        attributesName: {
            fontSize: "12px",
            fontWeight: "500",
            maxWidth: "90%",
            boxSizing: "border-box",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        attributesValue: {
            fontSize: "12px",
            fontWeight: "400",
            color: "#374957",
        },
        riskLow: {
            backgroundColor: "#4fbb7c",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "24px",
            height: "20px",
            borderRadius: "4px",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 600,
            margin: "3px 0",
        },
        riskMedium: {
            backgroundColor: "#ffae4f",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "24px",
            height: "20px",
            borderRadius: "4px",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 600,
            margin: "3px 0",
        },
        riskHigh: {
            backgroundColor: "#b91f2a",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "24px",
            height: "20px",
            borderRadius: "4px",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 600,
            margin: "3px 0",
        },
        riskFraudulent: {
            backgroundColor: "#8a121b",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "24px",
            height: "20px",
            borderRadius: "4px",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 600,
            margin: "3px 0",
        },
        riskDescription: {
            display: "flex",
            padding: "7px 12px",
            fontSize: "12px",
            alignItems: "center",
            gap: "8px",
            borderRadius: "8px",
            border: "1px solid #EFEEF1",
            backgroundColor: "#F4F5FF",
            fontWeight: "600",
        },
        riskDescriptionText: {
            fontWeight: 400,
        },
    })

    const attrubuteElement = (name, value) => {
        const { color: marked, _, riskLevel, description } = isMarked(name, value)
        const itemClass = styles[marked]
        return (
            <>
                <View style={styles.attributesNameWrapper}>
                    <Text style={styles.attributesName}>
                        {name}:{" "}
                        <Text style={styles.attributesValue}>{String(value || "null")}</Text>
                    </Text>
                    {riskLevel > 0 && (
                        <View style={itemClass}>
                            <Text>{riskLevel}</Text>
                        </View>
                    )}
                </View>
                {description && (
                    <Text style={styles.riskDescription}>
                        <Text>Risk Note: </Text>
                        <Text style={styles.riskDescriptionText}>{description}</Text>
                    </Text>
                )}
            </>
        )
    }

    return (
        <View style={styles.attributesWrapper}>
            {Object.entries(attributes).map(([name, value], index) => (
                <View key={index}>{attrubuteElement(name, value)}</View>
            ))}
        </View>
    )
}
