import React, { useState, useEffect, useRef } from "react"
import { Box, Select, MenuItem } from "@mui/material"
import { Controller } from "react-hook-form"
import styles from "./CategoryInfo.module.scss"

export function CategoryInfo({
    caseTypes,
    currentCaseType,
    control,
    isAllowToEdit,
    editCategory,
    handleEditCategory,
    templateDataLoading,
}) {
    if (!currentCaseType || caseTypes.length === 0) return null

    const caseTypeName = caseTypes.find((caseType) => caseType.id === currentCaseType)?.name

    return (
        <Box width="300px">
            <Controller
                name={"case_type_id"}
                defaultValue=""
                control={control}
                render={({ field }) => (
                    <>
                        {!editCategory ? (
                            <>
                                <span className={styles.categoryInfo}>
                                    | Category: {caseTypeName}
                                    {isAllowToEdit && (
                                        <div
                                            className={styles.nameEditIcon}
                                            onClick={handleEditCategory}
                                        ></div>
                                    )}
                                </span>
                            </>
                        ) : (
                            <>
                                <Select
                                    {...field}
                                    id="case_type_id"
                                    disabled={templateDataLoading}
                                    onChange={(value) => {
                                        field.onChange(value)
                                    }}
                                    className={styles.categoryInfoInput}
                                    fullWidth
                                >
                                    {caseTypes.map((elem) => (
                                        <MenuItem key={elem.id} value={elem.id}>
                                            {elem.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    </>
                )}
            />
        </Box>
    )
}
