import React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import Grid from "@mui/material/Grid"
import AttachEmailIcon from "@mui/icons-material/AttachEmail"
import NoteAddIcon from "@mui/icons-material/NoteAdd"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import Tooltip from "@mui/material/Tooltip"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { createCase } from "../../../services/main/mainServices"
import { CaseReport } from "./CaseReport/CaseReport"
import { PDFViewer, pdf } from "@react-pdf/renderer"

export default function CaseReportDialog({
    open,
    setClosed,
    caseTitle,
    values,
    setSendReportDialogOpen,
}) {
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const markers = useSelector((state) => state.findingsState.markers)
    const navigate = useNavigate()

    const handleDownloadPDF = async () => {
        const doc = <CaseReport caseTitle={caseTitle} values={values} markers={markers} />
        const asBlob = await pdf(doc).toBlob()
        const url = URL.createObjectURL(asBlob)
        const link = document.createElement("a")
        link.href = url
        link.download = `case_${values?.case_id}_report.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={setClosed}
                keepMounted
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        background: "rgba(30, 30, 30, 0.65)",
                        backdropFilter: "blur(4px)",
                    },
                }}
            >
                <DialogContent style={{ padding: 0 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        zIndex={1000}
                        style={{
                            padding: "20px 40px",
                            position: "sticky",
                            top: "0",
                            background:
                                "linear-gradient(180deg, rgba(30, 30, 30, 0.95) 0%, rgba(62, 62, 62, 0) 80.21%)",
                        }}
                    >
                        <Grid item xs={5}>
                            <Button
                                onClick={setClosed}
                                autoFocus
                                color="secondary"
                                style={{
                                    color: "#fff",
                                    fontSize: "1.5em",
                                    textTransform: "none",
                                }}
                            >
                                <ArrowBackIcon
                                    fontSize="inherit"
                                    style={{
                                        color: "#fff",
                                        fontSize: "1.5em",
                                        marginRight: "15px",
                                    }}
                                />
                                {caseTitle}
                            </Button>
                        </Grid>

                        <Grid item container xs={7} justifyContent="flex-end" alignItems="center">
                            <Grid item>
                                <Button
                                    onClick={() => setSendReportDialogOpen(true)}
                                    autoFocus
                                    color="secondary"
                                    style={{
                                        color: "#fff",
                                        fontSize: "1.5em",
                                        textTransform: "none",
                                        padding: "6px 8px 0 8px",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Tooltip title="Email Report">
                                        <AttachEmailIcon
                                            style={{
                                                color: "#fff",
                                                fontSize: "1.5em",
                                            }}
                                        />
                                    </Tooltip>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={async () => {
                                        const caseId = await createCase(
                                            false,
                                            dispatch,
                                            refreshAccessTokenCounter,
                                        )
                                        // redirect to the new case page
                                        navigate("/")
                                        navigate(`/${caseId}`)
                                    }}
                                    autoFocus
                                    color="secondary"
                                    style={{
                                        color: "#fff",
                                        fontSize: "1.5em",
                                        textTransform: "none",
                                        padding: "6px 8px 0 8px",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Tooltip title="New Case">
                                        <NoteAddIcon
                                            style={{
                                                color: "#fff",
                                                fontSize: "1.5em",
                                            }}
                                        />
                                    </Tooltip>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleDownloadPDF}
                                    autoFocus
                                    color="secondary"
                                    style={{
                                        color: "#fff",
                                        fontSize: "1.5em",
                                        textTransform: "none",
                                        padding: "6px 8px 0 8px",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Tooltip title="Download Report">
                                        <PictureAsPdfIcon
                                            style={{
                                                color: "#fff",
                                                fontSize: "1.5em",
                                            }}
                                        />
                                    </Tooltip>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{
                            width: "55vw",
                            margin: "0 auto",
                            height: "100%",
                        }}
                    >
                        <PDFViewer style={{ width: "100%", height: "100%" }} showToolbar={false}>
                            <CaseReport caseTitle={caseTitle} values={values} markers={markers} />
                        </PDFViewer>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}
