import React, { useState, useEffect } from "react"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import { Controller } from "react-hook-form"
import { doToolAction } from "../../../../services/notebook/notebookServices"
import { useSelector, useDispatch } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import InfoIcon from "@mui/icons-material/Info"
import WarningIcon from "@mui/icons-material/Warning"
import ReportIcon from "@mui/icons-material/Report"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Box, Typography, CircularProgress, Divider } from "@mui/material"
import { DotsMenu } from "../DotsMenu"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import accordionExpand from "../../../../assets/icons/accordionExpand.svg"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { RiskLevel } from "../../components/RiskLevel/RiskLevel"
import styles from "./ManualNote.module.scss"

const useStyles = makeStyles(() => ({
    accordionImage: {
        "& .MuiAccordionSummary-expandIconWrapper": {
            "&.Mui-expanded": {
                transform: "rotate(90deg)",
            },
        },
    },
}))

function ManualNote({
    tool_id,
    index,
    index_x,
    index_y,
    handleAddTools,
    handleDeleteTool,
    control,
    values,
    setValue,
    integrations,
    toolsConfig,
    refreshPageData,
    resetField,
    newTool,
    subtitle,
    isFromToolbox,
}) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(newTool ? true : false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isLoadingNewTool, setIsLoadingNewTool] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [chosenIcon, setChosenIcon] = useState(values?.tools[tool_id]?.log?.type)

    const iconList = [
        {
            type: "Info",
            icon: (
                <InfoIcon
                    fontSize="medium"
                    color={chosenIcon === "Info" ? "c24B0FF" : "disabled"}
                />
            ),
        },
        {
            type: "Valid",
            icon: (
                <CheckCircleIcon
                    fontSize="medium"
                    color={chosenIcon === "Valid" ? "c61BF36" : "disabled"}
                />
            ),
        },
        {
            type: "Suspicious",
            icon: (
                <WarningIcon
                    fontSize="medium"
                    color={chosenIcon === "Suspicious" ? "cFFC824" : "disabled"}
                />
            ),
        },
        {
            type: "Fraud",
            icon: (
                <ReportIcon
                    fontSize="medium"
                    color={chosenIcon === "Fraud" ? "cFF2424" : "disabled"}
                />
            ),
        },
    ]

    const noteColor =
        chosenIcon === "Info"
            ? "24B0FF"
            : chosenIcon === "Valid"
            ? "61BF36"
            : chosenIcon === "Suspicious"
            ? "FFC824"
            : "FF2424"
    const hex2rgba = (hex, alpha = 1) => {
        const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
        return `rgba(${r},${g},${b},${alpha})`
    }

    const rgbaColor = hex2rgba(noteColor, 0.15)

    const toggleAccordion = () => {
        setIsAccordionExpanded(!isAccordionExpanded)
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (values) {
                await doToolAction(
                    values.case_id,
                    values.tools[tool_id]?.id,
                    values.tools[tool_id]?.type,
                    { ...values.tools[tool_id]?.log, type: chosenIcon },
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                dispatch(handleShowToaster("success", "Successfully save data"))
            }
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleCancel = () => {
        resetField(`tools.${values.tools[tool_id]?.id}.log.note`)
    }

    useEffect(() => {
        if (newTool || (location.state?.scrollToElement && location.state?.id === tool_id)) {
            setIsAccordionExpanded(true)
        }
    }, [newTool, tool_id, location])

    return (
        <Box>
            <Accordion
                style={{ borderRadius: "10px", cursor: "default" }}
                className={styles.manualNoteAccordion}
                expanded={isAccordionExpanded}
            >
                <AccordionSummary
                    className={classes.accordionImage}
                    style={{
                        cursor: "default",
                        backgroundColor: "transparent",
                        flexDirection: "row-reverse",
                        gap: "10px",
                    }}
                    expandIcon={
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={toggleAccordion}
                            src={accordionExpand}
                        />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box className={styles.manualNoteTitleWrapper}>
                        <Box display="flex" flexDirection="column" gap="5px">
                            <Typography className={styles.manualNoteTitleText}>
                                Note - {chosenIcon}
                            </Typography>
                            <Typography className={styles.manualNoteSubtitle}>
                                {subtitle}
                            </Typography>
                        </Box>
                        {isDeleteLoading || isLoadingNewTool ? (
                            <Box className={styles.manualNoteLoaderWrapper}>
                                <CircularProgress
                                    color="inherit"
                                    style={{ width: "25px", height: "25px" }}
                                />
                            </Box>
                        ) : (
                            <Box className={styles.manualNoteActionsWrapper}>
                                <RiskLevel
                                    values={values}
                                    tool_id={tool_id}
                                    control={control}
                                    resetField={resetField}
                                    refreshPageData={refreshPageData}
                                    toolLog={values.tools[tool_id].log || {}}
                                />
                                {!isFromToolbox && (
                                    <DotsMenu
                                        handleAddTools={handleAddTools}
                                        index_x={index_x}
                                        index_y={index_y}
                                        values={values}
                                        integrations={integrations}
                                        toolsConfig={toolsConfig}
                                        handleDeleteTool={handleDeleteTool}
                                        tool_id={tool_id}
                                        setIsDeleteLoading={setIsDeleteLoading}
                                        setIsLoadingNewTool={setIsLoadingNewTool}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </AccordionSummary>
                <Divider className={styles.manualNoteDivider} />
                <Box className={styles.manualNoteInputWrapper}>
                    <Box className={styles.manualNoteInputWrapperIcons}>
                        {iconList.map((item) => (
                            <IconButton
                                key={item.type}
                                disabled={isLoading}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setChosenIcon(item.type)
                                }}
                                size="small"
                            >
                                {item.icon}
                            </IconButton>
                        ))}
                    </Box>
                    <Controller
                        name={`tools.${tool_id}.log.note`}
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                variant="outlined"
                                label="Note"
                                minRows={5}
                                multiline
                                InputProps={{
                                    className: styles.input,
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: "#6B869A",
                                    },
                                }}
                                sx={{
                                    borderRadius: "10px",
                                    backgroundColor: `${rgbaColor}`,
                                }}
                                fullWidth
                                disabled={isLoading}
                            />
                        )}
                    />
                    <Box className={styles.editIcons}>
                        {isLoading ? (
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        ) : (
                            <>
                                <Box display="flex" gap="15px">
                                    <CloseIcon
                                        data-testid="cancel-button"
                                        onClick={handleCancel}
                                        className={styles.standartIcon}
                                    />
                                    <DoneIcon
                                        onClick={handleSave}
                                        className={styles.standartIcon}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Accordion>
        </Box>
    )
}

export default ManualNote
