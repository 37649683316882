import React, { useState } from "react"
import { Dialog, DialogContent, TextField, CircularProgress, Box, Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { handleSubscribeUser } from "../../../../../../../../../../services/playbook/playbookServices"
import styles from "./SubscribeDialog.module.scss"
import { LoadingButton } from "@mui/lab"
import { useIsMobile } from "../../../../../../../../../../hooks/standart/useIsMobile"
import { handleShowToaster } from "../../../../../../../../../../redux/actions/showToasterActions"

export function SubscribeDialog({ open, onClose, forwardedRef }) {
    const isMobile = useIsMobile()
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()

    const onSubmit = async (data) => {
        if (!data) return
        setIsLoading(true)
        try {
            await handleSubscribeUser(data, dispatch, refreshAccessTokenCounter)
            onClose()
            reset()
            dispatch(handleShowToaster("success", "Successfully subscribed"))
        } catch (error) {
            dispatch(handleShowToaster("error", "Action failed"))
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const subscribeDialogContainerClass = isMobile
        ? styles.subscribeDialogContainerMobile
        : styles.subscribeDialogContainer

    return (
        <Dialog
            open={open}
            onClose={onClose}
            ref={forwardedRef}
            PaperProps={{ className: subscribeDialogContainerClass }}
        >
            <DialogContent className={isMobile ? styles.subscribeDialogContentMobile : ""}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        disabled={isLoading}
                        label="First Name"
                        {...register("firstName", { required: "First Name is required" })}
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName?.message}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            className: styles.subscribeInput,
                        }}
                    />
                    <TextField
                        disabled={isLoading}
                        label="Last Name"
                        {...register("lastName")}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            className: styles.subscribeInput,
                        }}
                    />
                    <TextField
                        disabled={isLoading}
                        label="Company Name (Optional)"
                        {...register("companyName")}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            className: styles.subscribeInput,
                        }}
                    />

                    <TextField
                        disabled={isLoading}
                        label="Email"
                        type="email"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                            },
                        })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            className: styles.subscribeInput,
                        }}
                    />
                    {/* 
                    <TextField
                        disabled={isLoading}
                        label="Message"
                        {...register("message")}
                        error={Boolean(errors.message)}
                        helperText={errors.message?.message}
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        InputProps={{
                            className: styles.subscribeInput,
                        }}
                    /> */}
                    <Box className={styles.subscribeButtonWrapper}>
                        <LoadingButton
                            loading={isLoading}
                            className={styles.subscribeButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            loadingIndicator={
                                <CircularProgress style={{ width: "15px", height: "15px" }} />
                            }
                        >
                            Subscribe
                        </LoadingButton>
                        <Button onClick={onClose} className={styles.subscribeButtonClose}>
                            Close
                        </Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    )
}
