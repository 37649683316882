import React, { useState, useEffect } from "react"
import TextField from "@mui/material/TextField"
import makeStyles from "@mui/styles/makeStyles"
import { Controller } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import LoadingButton from "@mui/lab/LoadingButton"
import { doToolAction } from "../../../../services/notebook/notebookServices"
import { Box, Typography, CircularProgress, LinearProgress } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import { DotsMenu } from "../DotsMenu"
import accordionExpand from "../../../../assets/icons/accordionExpand.svg"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import { capitalizeFirstLetter } from "../../../../helpers/standardHelpers"
import { RiskLevel } from "../../components/RiskLevel/RiskLevel"
import styles from "./RouteCheck.module.scss"

const useStyles = makeStyles(() => ({
    accordionImage: {
        "& .MuiAccordionSummary-expandIconWrapper": {
            "&.Mui-expanded": {
                transform: "rotate(90deg)",
            },
        },
    },
}))

function RouteCheck({
    tool_id,
    index_x,
    index_y,
    handleAddTools,
    handleDeleteTool,
    control,
    values,
    setValue,
    title,
    subtitle,
    inputTitle,
    buttonTitle,
    logo_url,
    logoHref,
    integrations,
    toolsConfig,
    newTool,
    resetField,
    refreshPageData,
    isFromToolbox,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isLoadingNewTool, setIsLoadingNewTool] = useState(false)
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(newTool ? true : false)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const getAPIResults = async () => {
        setIsSubmitting(true)

        let log_request
        try {
            log_request = JSON.parse(values?.tools[tool_id]?.log?.request)
        } catch (error) {
            console.error("JSON Error:", error)
            setIsSubmitting(false)
            return
        }

        const log = await doToolAction(
            values.case_id,
            values.tools[tool_id]?.id,
            values.tools[tool_id].type,
            // values.tools[tool_id].log,
            { request: log_request },
            dispatch,
            refreshAccessTokenCounter,
        )

        setValue(`tools.${tool_id}.log.response`, JSON.parse(log["response"]))
        setValue(`tools.${tool_id}.log.raw_response`, log["response"])
        setValue(`tools.${tool_id}.show_response`, true)
        setIsSubmitting(false)
    }

    const response = values.tools[tool_id].log.response

    useEffect(() => {
        if (newTool || (location.state?.scrollToElement && location.state?.id === tool_id)) {
            setIsAccordionExpanded(true)
        }
    }, [newTool, tool_id, location])

    useEffect(() => {
        doToolAction(
            values.case_id,
            values.tools[tool_id]?.id,
            // type 1 so it will change only the note and not trigger the API again!
            1,
            values.tools[tool_id].log,
            dispatch,
            refreshAccessTokenCounter,
        )
    }, [response])

    const route_duration_validity = values.tools[tool_id]?.log?.response?.route_duration_validity

    const toggleAccordion = () => {
        setIsAccordionExpanded(!isAccordionExpanded)
    }

    return (
        <Box>
            <Accordion
                style={{ "border-radius": "10px", cursor: "default" }}
                className={styles.routeCheckAccordion}
                expanded={isAccordionExpanded}
            >
                <AccordionSummary
                    className={classes.accordionImage}
                    style={{
                        cursor: "default",
                        backgroundColor: "transparent",
                        flexDirection: "row-reverse",
                        gap: "10px",
                    }}
                    expandIcon={
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={toggleAccordion}
                            src={accordionExpand}
                        />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box className={styles.routeCheckTitleWrapper}>
                        {/* <img className={styles.standartApiTitleLogo} src={logo_url}></img> */}
                        <Box display="flex" flexDirection="column" gap="5px">
                            <Typography className={styles.routeCheckTitleText}>{title}</Typography>
                            <Typography className={styles.routeCheckSubtitle}>
                                {subtitle}
                            </Typography>
                        </Box>
                        {isDeleteLoading || isLoadingNewTool ? (
                            <Box className={styles.routeCheckLoaderWrapper}>
                                <CircularProgress
                                    color="inherit"
                                    style={{ width: "25px", height: "25px" }}
                                />
                            </Box>
                        ) : (
                            <Box className={styles.routeCheckActionsWrapper}>
                                <RiskLevel
                                    values={values}
                                    tool_id={tool_id}
                                    control={control}
                                    resetField={resetField}
                                    refreshPageData={refreshPageData}
                                    toolLog={values.tools[tool_id].log || {}}
                                />
                                {!isFromToolbox && (
                                    <DotsMenu
                                        handleAddTools={handleAddTools}
                                        index_x={index_x}
                                        index_y={index_y}
                                        values={values}
                                        integrations={integrations}
                                        toolsConfig={toolsConfig}
                                        handleDeleteTool={handleDeleteTool}
                                        tool_id={tool_id}
                                        setIsDeleteLoading={setIsDeleteLoading}
                                        setIsLoadingNewTool={setIsLoadingNewTool}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </AccordionSummary>
                <Box className={styles.routeCheckSearch}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        marginBottom="10px"
                        alignItems="center"
                    >
                        <Typography fontSize="16px" fontWeight="500">
                            {inputTitle}
                        </Typography>
                        <LoadingButton
                            loadingIndicator={
                                <CircularProgress style={{ width: "15px", height: "15px" }} />
                            }
                            className={styles.routeCheckSearchButton}
                            variant="outlined"
                            onClick={getAPIResults}
                            loading={isSubmitting}
                        >
                            {capitalizeFirstLetter(buttonTitle)}
                        </LoadingButton>
                    </Box>

                    <Box>
                        <Controller
                            name={`tools.${tool_id}.log.request`}
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <Tooltip
                                    placement="top"
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                fontSize: "1em",
                                                bgcolor: "#707070",
                                                "& .MuiTooltip-arrow": {
                                                    color: "common.black",
                                                },
                                            },
                                        },
                                    }}
                                    title={
                                        "---To validate route use example format---" +
                                        JSON.stringify([
                                            {
                                                location: [32.466563, 35.045437],
                                                time: "2023-10-23T00:00:00Z",
                                            },
                                            {
                                                location: [32.113312, 34.804437],
                                                time: "2023-10-23T00:31:00Z",
                                            },
                                            {
                                                location: [31.954902, 34.808785],
                                                time: "2023-10-23T01:30:00Z",
                                            },
                                            {
                                                location: [32.466563, 35.045437],
                                                time: "2023-10-23T01:55:00Z",
                                            },
                                        ])
                                    }
                                >
                                    <TextField
                                        {...field}
                                        id="ip"
                                        variant="outlined"
                                        disabled={isSubmitting}
                                        fullWidth
                                        multiline
                                        minRows={5}
                                        InputProps={{
                                            className: styles.input,
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: "#6B869A",
                                            },
                                        }}
                                    />
                                </Tooltip>
                            )}
                        />
                    </Box>
                </Box>
                {values.tools[tool_id]["show_response"] && (
                    <Box className={styles.resultWrapper}>
                        {isSubmitting ? (
                            <LinearProgress color="inherit" />
                        ) : (
                            <Box className={styles.routeCheckIframeResult}>
                                <Typography>
                                    <span style={{ fontWeight: "700" }}>
                                        Route Duration Validity:
                                    </span>{" "}
                                    <span
                                        style={{
                                            color:
                                                route_duration_validity === "impossible"
                                                    ? "red"
                                                    : "#26DBA0",
                                            fontWeight: "700",
                                        }}
                                    >
                                        {route_duration_validity
                                            ?.toLowerCase()
                                            ?.split(" ")
                                            ?.map(
                                                (word) =>
                                                    word.charAt(0).toUpperCase() + word.slice(1),
                                            )
                                            .join(" ")}
                                    </span>
                                </Typography>
                                <img
                                    style={{
                                        width: "100%",
                                        display: "block",
                                        margin: "20px auto",
                                        borderRadius: "5px",
                                        border: "1px solid #fff",
                                    }}
                                    src={values.tools[tool_id].log.response.map_image_url}
                                />
                                <Typography>
                                    <span style={{ fontWeight: "700" }}>Detailed Journey:</span>{" "}
                                </Typography>
                                <ul>
                                    {values.tools[tool_id]?.log?.response?.legs?.map(
                                        (leg, index) => {
                                            const leg_story_split = leg.leg_story.split("\n")
                                            return (
                                                <li key={index}>
                                                    {leg_story_split.map((leg_story_part) => {
                                                        return (
                                                            <Typography key={leg_story_part}>
                                                                {leg_story_part}
                                                            </Typography>
                                                        )
                                                    })}
                                                </li>
                                            )
                                        },
                                    )}
                                </ul>
                            </Box>
                        )}
                    </Box>
                )}
            </Accordion>
        </Box>
    )
}

export default RouteCheck
