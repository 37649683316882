import React, { useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import makeStyles from "@mui/styles/makeStyles"
import CaseInformationMainPage from "./CaseInformationMainPage"
import { useSelector, useDispatch } from "react-redux"
import { createCase, runTemplate } from "../../../services/main/mainServices"
import { Button, Typography, Box } from "@mui/material"
import MuiAlert from "@mui/material/Alert"
import Checkbox from "@mui/material/Checkbox"
import { useNavigate } from "react-router-dom"
import { changeLoaderState } from "../../../redux/actions/loaderActions"
import LoadingButton from "@mui/lab/LoadingButton"
import { handleShowToaster } from "../../../redux/actions/showToasterActions"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const validationSchema = yup.object().shape({})

const useStyles = makeStyles(() => ({
    input: {
        height: 58,
        maxWidth: "100%",
        borderRadius: "10px",
    },
    label: {
        fontSize: "20px",
        color: "#000",
    },
    snackBarSuccess: {
        marginTop: "20px",
        "& .MuiPaper-root": {
            backgroundColor: "#4FBB7C",
            borderRadius: "12px",
            alignItems: "center",
        },
    },
}))

const NewAlert = ({
    openNewAlertPopup,
    setOpenNewAlertPopup,
    triggerFetchMainPageData,
    setTriggerFetchMainPageData,
    updateCaseInfoMap,
    loadMainPageData,
    caseTypes,
}) => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const navigate = useNavigate()

    const [createAnotherAlert, setCreateAnotherAlert] = useState(false)
    const [uploadingFile, setUploadingFile] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const initialValues = {
        title: "",
        template_file: "",
        reported_by: null,
        reported_date: null,
        type: null,
        description: "",
        urgency: "Low",
        template_id: -1,
    }

    const {
        formState: { errors },
        control,
        watch,
        setValue,
        reset,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    })

    const values = watch()

    const setNewAlert = async () => {
        // create case
        try {
            setIsSubmitting(true)
            // create case or cases from csv
            var caseId = null
            if (values?.template_id > 0) {
                if (values?.template_fields?.case_list_file) {
                    runTemplate(values, dispatch, refreshAccessTokenCounter)
                } else {
                    // dispatch(changeLoaderState(true))
                    // returning the first case id created
                    caseId = runTemplate(values, dispatch, refreshAccessTokenCounter)
                    dispatch(
                        handleShowToaster(
                            "success",
                            "Your cases are being processed, you will be notified by email when all cases are created",
                            6000,
                        ),
                    )
                    setOpenNewAlertPopup(false)
                }
            } else {
                caseId = await createCase(false, dispatch, refreshAccessTokenCounter)
                await updateCaseInfoMap(caseId, "title", values.title)
                await updateCaseInfoMap(caseId, "type", values.type)
                await updateCaseInfoMap(caseId, "description", values.description)
                await updateCaseInfoMap(caseId, "urgency", values.urgency)
                await updateCaseInfoMap(caseId, "status", "New")
                navigate(`/${caseId}`)
            }
            if (values?.template_fields?.case_list_file) {
                setOpenNewAlertPopup(false)
            } else if (createAnotherAlert) {
                setTriggerFetchMainPageData(triggerFetchMainPageData + 1)
                loadMainPageData()
                setOpenNewAlertPopup(false)
                dispatch(handleShowToaster("success", "Alert created"))
                setOpenNewAlertPopup(true)
            } else {
                setOpenNewAlertPopup(false)
                // navigate(`/${caseId}`)
            }
        } finally {
            reset(initialValues)
            setIsSubmitting(false)
        }
    }

    return (
        <>
            <Dialog
                open={openNewAlertPopup}
                onClose={() => {
                    reset(initialValues)
                    setOpenNewAlertPopup(false)
                }}
                maxWidth="md"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"New Case"}</DialogTitle>
                <DialogContent>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "100%",
                            marginBottom: "20px",
                        }}
                    >
                        <Box
                            component="label"
                            htmlFor="title"
                            sx={{
                                marginRight: "18px",
                                fontSize: "18px",
                            }}
                        >
                            Title
                        </Box>
                        <Controller
                            name="title"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="title"
                                    variant="outlined"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    placeholder="YOUR CASE TITLE GOES HERE"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <CaseInformationMainPage
                        control={control}
                        values={values}
                        setValue={setValue}
                        caseTypes={caseTypes}
                        setUploadingFile={setUploadingFile}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: "space-between",
                        padding: "20px 24px 0px 24px",
                    }}
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        sx={{
                            // background: '#F9F9F9 !importanttt',
                            width: "200px",
                            height: "42.5px",
                            borderRadius: "10px",
                            borderColor: "#000000",
                        }}
                        onClick={() => {
                            setOpenNewAlertPopup(false)
                            reset(initialValues)
                        }}
                        disabled={isSubmitting}
                    >
                        <Typography
                            color="#000000"
                            fontSize="16px"
                            fontWeight="700"
                            sx={{
                                textTransform: "none",
                            }}
                        >
                            Cancel
                        </Typography>
                    </Button>
                    <LoadingButton
                        variant="contained"
                        color="secondary"
                        sx={{
                            background: "#FFC824 !important",
                            width: "200px",
                            height: "42.5px",
                            borderRadius: "10px",
                        }}
                        onClick={setNewAlert}
                        loading={isSubmitting || uploadingFile}
                        disabled={uploadingFile}
                    >
                        <Typography
                            color="#000000"
                            fontSize="16px"
                            fontWeight="700"
                            sx={{
                                textTransform: "none",
                            }}
                        >
                            {values?.template_fields?.case_list_file
                                ? "Create Cases"
                                : "Create Case"}
                        </Typography>
                    </LoadingButton>
                </DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    paddingRight={5}
                >
                    <Grid item>
                        <Checkbox
                            checked={createAnotherAlert}
                            onChange={(e) => {
                                setCreateAnotherAlert(!createAnotherAlert)
                            }}
                            disabled={isSubmitting || values?.template_fields?.case_list_file}
                        />
                    </Grid>
                    <Grid item>
                        <Typography>Create another case</Typography>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

export default NewAlert
