import React from "react"
import Typography from "@mui/material/Typography"
import { List, ListItem } from "@mui/material"

const PrivacyComponent = () => {
    return (
        <div>
            <Typography variant="h4" align="left">
                ByValue’s Platform - Privacy Policy
            </Typography>
            <Typography variant="subtitle1" align="left">
                Last Revised: April 23, 2020
            </Typography>
            <Typography variant="body1" align="left">
                ByValue Ltd. its subsidiaries and affiliates (“ByValue”, “we” or “us”) respect the
                privacy of the visitors and/or users (“User” or “you”) of its Platform at:
                https://app.byvalue.org/ and are committed to protecting the personal information
                that its Users share with it. We believe that you have a right to know our practices
                regarding the information we may collect and use when you visit and/or use our
                Platform. Capitalized terms which are not defined herein, shall have the meaning
                ascribed to them in our Terms of Use at: https://app.byvalue.org/terms (“TOU”), into
                which this Privacy Policy is incorporated by reference.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                1. Your Consent (PLEASE READ CAREFULLY!)
            </Typography>
            <Typography variant="body1" align="left">
                BY ENTERING, CONNECTING TO, ACCESSING OR USING THE PLATFORM, YOU AGREE TO THE TERMS
                AND CONDITIONS SET FORTH IN THIS PRIVACY POLICY (THE “PRIVACY POLICY”), INCLUDING TO
                THE COLLECTION AND PROCESSING OF YOUR PERSONAL INFORMATION (AS DEFINED BELOW). IF
                YOU DISAGREE TO ANY TERM PROVIDED HEREIN, YOU MAY NOT ACCESS AND/OR USE THE
                PLATFORM.
            </Typography>
            <Typography variant="body1" align="left">
                FOR THE AVOIDANCE OF DOUBT, YOU HEREBY CONSENT TO THE COLLECTION, PROCESSING,
                TRANSFER AND USE OF YOUR PERSONAL INFORMATION. YOU ALSO ACKNOWLEDGE AND CONFIRM THAT
                YOU ARE NOT REQUIRED TO PROVIDE US WITH YOUR PERSONAL INFORMATION AND THAT SUCH
                INFORMATION IS VOLUNTARILY PROVIDED TO US.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                2. Which Information we may Collect on our Users?
            </Typography>
            <Typography variant="subtitle1" align="left">
                We may collect two types of data and information from our Users:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="h5" align="left">
                        2.1 Non-personal Information.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        The first type of information is non-identifiable and anonymous information,
                        which consists of technical information, behavioral information and
                        aggregated information that does not pertain to a specific individual
                        (“Non-personal Information”).
                    </Typography>
                </ListItem>
                <ListItem>
                    <List>
                        <ListItem>
                            <Typography variant="h5" align="left">
                                2.1.1 Technical and Behavioral Information
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <List>
                                <ListItem>
                                    <Typography variant="body1" align="left">
                                        • Like most websites and online services, we passively
                                        collect certain Non-personal Information from your devices
                                        when you access and browse the Platform, including: (i)
                                        technical information such as the type and version of your
                                        device and its operating system, the type of browser, screen
                                        resolution, device browser and keyboard language, Wi-Fi
                                        connectivity and the type and name of your device and/or
                                        browser, etc.; and (ii) behavioral information which may
                                        include the User’s click-stream on the Platform, the
                                        activities of the User on the Platform and additional
                                        information of a similar nature (collectively, “Technical
                                        and Behavioral Information”). We may also use third-party
                                        service providers such as Google Analytics to obtain
                                        detailed analytics on the device and the Users’ behavior on
                                        the Platform.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1" align="left">
                                        • Any Non-personal Information connected or linked to or
                                        associated with any Personal Information shall be deemed as
                                        Personal Information, as long as such connection, linkage or
                                        association exists.
                                    </Typography>
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem>
                    <Typography variant="h5" align="left">
                        2.2 Personal Information.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        The second type of information is individually identifiable information
                        (“Personal Information”). This information may identify an individual or may
                        be of a private and/or sensitive nature, including the following:
                    </Typography>
                </ListItem>
                <ListItem>
                    <List>
                        <ListItem>
                            <Typography variant="h5" align="left">
                                2.2.1 Personal Information which is provided voluntarily by the
                                Users:
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <List>
                                <ListItem>
                                    <Typography variant="body1" align="left">
                                        • Registration details. You may register and open an Account
                                        on our Platform. In order to complete such registration, you
                                        will be required to provide us with certain Personal
                                        Information, including: full name, email address and phone
                                        number.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1" align="left">
                                        • Communications with ByValue. We may collect and process
                                        any Personal Information you may provide us as part of any
                                        communications with us, by any means, including email
                                        correspondence and technical support.
                                    </Typography>
                                </ListItem>
                            </List>
                        </ListItem>
                        <ListItem>
                            <Typography variant="h5" align="left">
                                2.2.2 Personal Information collected via technology:
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <List>
                                <ListItem>
                                    <Typography variant="body1" align="left">
                                        • Technical and Behavioral Information: to the extent that
                                        the Technical and Behavioral Information detailed above
                                        under Section 2.1.1 will be linked to or associated with a
                                        specific individual, then such information will be
                                        considered as Personal Information.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1" align="left">
                                        • Identifiers: while using the Platform we will access
                                        collect, process, monitor and/or remotely store online
                                        identifiers such as Internet Protocol (IP) address, your
                                        Unique Device Identifier (UDID), or another unique
                                        identifiers, for statistical and metric purposes.
                                    </Typography>
                                </ListItem>
                            </List>
                        </ListItem>
                        <ListItem>
                            <Typography variant="h5" align="left">
                                2.2.3 Please note:
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <List>
                                <ListItem>
                                    <Typography variant="body1" align="left">
                                        • We may cross-reference Personal Information with other
                                        Personal Information we have about you and any Non-Personal
                                        Information connected or linked to or associated with any
                                        Personal Information shall be deemed as Personal Information
                                        as long as such connection, linkage or association exists.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1" align="left">
                                        • We use third-party tracking services and usage logs, to
                                        track and analyze data from Users of the Platform and for
                                        purposes of security and fraud prevention.
                                    </Typography>
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>
                </ListItem>
            </List>
            <br />
            <Typography variant="h5" align="left">
                3. How Do We Collect Information on Our Users?
            </Typography>
            <Typography variant="subtitle1" align="left">
                There are two main methods we use to collect information:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="left">
                        3.1 We collect information through your entry, connection, access and/or and
                        use of the Platform. In other words, when you are using the Platform, we are
                        aware of your usage of the Platform and may gather, collect and store the
                        information relating to such usage (such as the Non-personal Information
                        detailed above and your IP address), either independently or through the
                        help of third party service providers as detailed below.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        3.2 We collect information which you decide to provide us voluntarily. For
                        example, we collect Personal Information which you voluntarily provide when
                        you fill in the contact form on the Platform or open an Account. We may
                        gather, collect and store the Personal Information either independently or
                        through the help of our authorized third party service providers as detailed
                        below.
                    </Typography>
                </ListItem>
            </List>
            <br />
            <Typography variant="h5" align="left">
                4. What are the Purposes of the Collection of Information?
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="left">
                        4.1 Non-personal Information is collected in order to:
                    </Typography>
                </ListItem>
                <ListItem>
                    <List>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Use it for statistical and research purposes and for
                                customization, development and improvement of our Platform.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Keep the Platform safe and secured and for the prevention of fraud
                                and crime.
                            </Typography>
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        4.2 Personal Information is collected in order to:
                    </Typography>
                </ListItem>
                <ListItem>
                    <List>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Enable the operation of the Platform and administer your Account.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Personalize and enhance the User’s experience while using the
                                Platform.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Verify the User's identity when s/he signs into the Platform
                                through his/her Account
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Respond to the User's questions and concerns and provide technical
                                support.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Enable payment through third party Online Payment Processors.
                                Please note that we do not collect and store credit card or other
                                financial information.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Provide registered Users with updates about our developments, new
                                offerings, news regarding the Platform and other services, etc.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Add your contact details to our mailing list for the purposes of
                                sending you information and commercial materials, updates about out
                                Platform, new offerings or news by email, SMS, phone or through a
                                webbrowser notification. You have the right to opt-out of marketing
                                messages at any time by following the instructions in such message.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Dealing with inappropriate interactions of Users and/or fraudulent
                                use of our Platform.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Conduct internal operations, including troubleshooting, data
                                analysis, testing, research and statistical purposes.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1" align="left">
                                • Comply with our legal obligations and in order to be able to
                                protect our rights and legitimate interests, including in order to
                                improve the Platform.
                            </Typography>
                        </ListItem>
                    </List>
                </ListItem>
            </List>
            <br />
            <Typography variant="h5" align="left">
                5. Sharing Information with Third Parties
            </Typography>
            <Typography variant="body1" align="left">
                ByValue may share a User’s name and contact information with the applicable Client
                or Developer, to the extent the Licensed Software or the Work Products cannot be
                delivered via the Platform.
            </Typography>
            <Typography variant="body1" align="left">
                Without prejudice to the foregoing, ByValue will not share Personal Information with
                third parties other in the following cases: (a) to satisfy any applicable law,
                regulation, legal process, subpoena or governmental request; (b) to enforce this
                Privacy Policy and/or the TOU, including investigation of potential violations
                thereof; (c) to detect, prevent, or otherwise address fraud, security or technical
                issues; (d) to respond to Users’support requests; (e) respond to claims that any
                content available on the Platform violates the rights of third parties; (f) to
                respond to claims that contact information (e.g. name, e-mail address, etc.) of a
                third party has been posted or transmitted without their consent or as a form of
                harassment; (g) to protect the rights, property, or personal safety of ByValue , its
                Users, or the general public; (h) by virtue of undergoing any change in control,
                including by means of merger, acquisition or purchase of all or substantially all of
                its assets, so long as such acquirer maintains the same privacy terms hereunder; (i)
                to collect, hold and/or manage your Personal Information through ByValue’s
                authorized third parties service providers, as reasonable for business purposes,
                which may be located in a country that does not have the same data protection laws
                as your jurisdiction; (j) to store or process your Personal Information through
                ByValue’s affiliated companies, which may be located in a country that does not have
                the same data protection laws as your jurisdiction. ByValue requires its affiliated
                entities to agree to processing your Personal Information in compliance herewith; or
                (k) pursuant to your explicit approval prior to the disclosure. For the avoidance of
                doubt, ByValue may transfer and disclose Non-personal Information to third parties
                at its own discretion including without limitation for statistical, analytical and
                research purposes and for customization, developing and improvement of the Platform.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                6. Minors
            </Typography>
            <Typography variant="body1" align="left">
                The Platform is intended for Users over the age of sixteen (16). Therefore, ByValue
                does not intend and does not knowingly collect Personal Information from children
                under the age of sixteen (16) and does not wish to do so. We reserve the right to
                request proof of age at any stage so that we can verify that minors under the age of
                sixteen (16) are not using the Platform. If we learn that we collected Personal
                Information from children under the age of sixteen (16), we will delete that
                information as quickly as possible. Please contact ByValue at: security@byvalue.org
                if you have reasons to suspect that ByValue collected Personal Information from
                minors under the age of sixteen (16) and we will delete that information as quickly
                as possible.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                7. Online Payment Processors
            </Typography>
            <Typography variant="body1" align="left">
                We accept certain online payment methods, using our Online Payment Processors, which
                enable Users to send and receive payments securely online using a credit card or
                bank account. We may add or change the Online Payment Processors at our sole
                discretion. ByValue is not affiliated with such Online Payment Processors (for more
                information see our TOU), as each is an independent contractor, and neither is the
                agent or employee of the other, and neither is responsible in any way for the
                actions or performance (or lack thereof) of the other. The use of the Online Payment
                Processors is at the User’s discretion and sole liability and risk. It is the User's
                responsibility to abide by all the terms specified by the Online Payment Processors
                in their terms of use and privacy policies, including but not limited to any age
                restrictions specified therein. You acknowledge that you are fully assuming the
                risks of conducting any transactions via the Online Payment Processors.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                8. Security
            </Typography>
            <Typography variant="body1" align="left">
                We take reasonable measures to maintain the security and integrity of our Platform
                and User information and prevent unauthorized access to it or use thereof through
                generally accepted industry standard technologies and internal procedures. Please
                note, however, that there are inherent risks in transmission of information over the
                Internet or other methods of electronic storage and we cannot guarantee that
                unauthorized access or use of your Personal Information will never occur.
            </Typography>
            <Typography variant="body1" align="left">
                BYVALUE SHALL NOT BE RESPONSIBLE OR LIABLE FOR AN UNAUTHORIZED ACCESS, HACKING, OR
                OTHER SECURITY INTRUSIONS OR FAILURE TO STORE OR THE THEFT, DELETION, CORRUPTION,
                DESTRUCTION, DAMAGE, OR LOSS OF ANY DATA OR INFORMATION.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                9. Third Party Service Providers
            </Typography>
            <Typography variant="body1" align="left">
                We may be using third party software or service in order to collect, host, store
                and/or process the information detailed herein. ByValue uses commercially reasonable
                efforts to engage with third parties that post a privacy policy governing their
                collection, processing and use of Non-personal Information and Personal Information.
                Such software includes without limitation:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="left">
                        • PayPal.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        • Stripe.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="left">
                        • Auth0.
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body1" align="left">
                However, ByValue does not control such third party service providers. Please read
                such third party service providers’ terms of use and privacy policies to understand
                their privacy practices.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                10. Links to Third Party Websites
            </Typography>
            <Typography variant="body1" align="left">
                Certain links provided in the Platform permit Users to leave our Platform and enter
                non-ByValue sites or services. Most of such linked sites and services provide legal
                documents, including terms of use and privacy policy, governing the use thereof. It
                is always advisable to read such documents carefully before using those sites and
                services, inter alia, in order to know what kind of information they are collecting.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                11.Deletion or Modification of Personal Information{" "}
            </Typography>
            <Typography variant="body1" align="left">
                If for any reason you wish to delete or modify your Personal Information please send
                us an e-mail with adequate detail of your request to: security@byvalue.org and we
                will make reasonable efforts to modify or delete any such Personal Information
                pursuant to any applicable privacy laws. Note that unless you instruct us otherwise,
                we may retain your Personal Information for as long as reasonably required for the
                purposes of which such Personal Information was collected, including without
                limitation, for future analytics and analysis, in order to comply with our legal or
                business requirements or obligations and to resolve disputes or to enforce our
                Terms, all as permitted under any applicable privacy laws.
            </Typography>
            <Typography variant="body1" align="left">
                Aggregated and/or anonymous information derived from your use of the Platform may
                remain on our servers indefinitely.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                12.Direct Marketing
            </Typography>
            <Typography variant="body1" align="left">
                You hereby agree that we may use the contact details you provided us with for the
                purpose of informing you regarding our Platform and other services which may be of
                interest to you, and to send to you other marketing material, transmitted to the
                e-mail address you have provided us. You may withdraw your consent via sending a
                written notice to ByValue by email to the following address: security@byvalue.org or
                alternatively follow the instructions for removing yourself from the mailing list
                which are available in the e-mail transmitted to you.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                13. International Data Transfer
            </Typography>
            <Typography variant="body1" align="left">
                We may transfer information collected about you, including Personal Information, to
                affiliated entities, or to other third party service providers across borders and
                from your country or jurisdiction to other countries or jurisdictions around the
                world (all solely for legitimate business purposes). Please note that we may
                transfer such information to a country and jurisdiction that does not have the same
                data protection laws as your jurisdiction, and you consent to such transfer of
                information.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                14. Changes to the Privacy Policy
            </Typography>
            <Typography variant="body1" align="left">
                The terms of this Privacy Policy will govern the use of the Platform and any
                information collected therein. ByValue reserves the right to change this policy at
                any time, so please re-visit this page frequently. We will provide notice of
                substantial changes of this policy on the homepage of the Platform and/or we will
                send you an e-mail regarding such changes to the e-mail address that you may have
                provided us with. Such substantial changes will take effect seven (7) days after
                such notice was provided on our Platform or sent by e-mail, whichever is the
                earlier. Otherwise, all other changes to this Privacy Policy are effective as of the
                stated “Last Revised” date and your continued use of the Platform after the Last
                Revised date will constitute acceptance of, and agreement to be bound by, those
                changes. In the event that the Terms should be amended to comply with any legal
                requirements, the amendments may take effect immediately, or as required by law and
                without any prior notice.
            </Typography>
            <br />
            <Typography variant="h5" align="left">
                15. Got any Questions?
            </Typography>
            <Typography variant="body1" align="left">
                If you have any questions (or comments) concerning this Privacy Policy, you are most
                welcome to send us an email to the following address: security@byvalue.org and we
                will make an effort to reply within a reasonable timeframe.
            </Typography>
        </div>
    )
}

export default PrivacyComponent
