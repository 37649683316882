import React, { useState } from "react"
import TableCell from "@mui/material/TableCell"
import { Typography, Box, CircularProgress } from "@mui/material"
import { useDispatch } from "react-redux"
import TextField from "@mui/material/TextField"
import { Controller } from "react-hook-form"
import TableRow from "@mui/material/TableRow"
import makeStyles from "@mui/styles/makeStyles"
import MenuItem from "@mui/material/MenuItem"
import moment from "moment/moment"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import styles from "../../CasesTable.module.scss"

const useStyles = makeStyles(() => ({
    input: {
        height: 42,
        width: "440px",
        maxWidth: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
    },
}))

function CaseTableRow({
    row,
    index,
    done,
    controllerName,
    control,
    updateCaseInfoMap,
    cases,
    urgencyOptions,
    loadMainPageData,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [isUrgencyLoading, setIsUrgencyLoading] = useState(false)

    const handleOpenCase = (row) => {
        window.open(`/${row?.id}`, "_blank")
    }

    const handleChangeUrgencyLevel = async (value, index) => {
        setIsUrgencyLoading(true)
        try {
            await updateCaseInfoMap(cases[index]?.id, "urgency", value)
            await loadMainPageData()
            dispatch(handleShowToaster("success", "Successfully change urgency level"))
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsUrgencyLoading(false)
        }
    }

    const getUrgencyBackground = (index) => {
        switch (cases[index]?.urgency) {
            case "High":
                return "#E02B38"
            case "Medium":
                return "#FE797B"
            case "Low":
                return "#4FBB7C"
            default:
                return "#fff"
        }
    }

    const getUrgencyBorder = (index) => {
        switch (cases[index]?.urgency) {
            case "High":
                return "#BD1B26"
            case "Medium":
                return "#ea6e6e"
            case "Low":
                return "#299455"
            default:
                return "#fff"
        }
    }

    return (
        <TableRow key={row?.id}>
            <TableCell
                style={{ cursor: "pointer" }}
                className={styles.CasesTableMainCell}
                onClick={() => handleOpenCase(row)}
            >
                <Typography className={styles.textUnderline} fontSize="14px">
                    {row?.id}
                </Typography>
            </TableCell>
            <TableCell className={styles.CasesTableMainCell}>
                <a href={`${row?.sf_case_link}`} target="_blank" rel="noreferrer">
                    <Typography fontSize="14px">{row?.sf_case_number}</Typography>
                </a>
            </TableCell>
            <TableCell style={{ cursor: "pointer" }} className={styles.CasesTableMainCell}>
                <Typography
                    className={styles.textUnderline}
                    fontSize="14px"
                    onClick={() => handleOpenCase(row)}
                >
                    {row?.title ? row.title : "None"}
                </Typography>
            </TableCell>
            <TableCell align={done ? "center" : ""} className={styles.CasesTableMainCell}>
                <Typography fontSize="14px">{row?.type}</Typography>
            </TableCell>
            {!done && (
                <TableCell className={styles.CasesTableMainCell}>
                    {isUrgencyLoading ? (
                        <Box className={styles.CasesTableMainUrgencyLoader}>
                            <CircularProgress style={{ width: "25px", height: "25px" }} />
                        </Box>
                    ) : (
                        <Controller
                            name={`${controllerName}[${index}].urgency`}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    select
                                    {...field}
                                    onChange={(e, value) => {
                                        field.onChange(value)
                                        handleChangeUrgencyLevel(e.target.value, index)
                                    }}
                                    variant="outlined"
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    sx={{
                                        width: "83px",
                                        height: "25px",
                                        "& .MuiOutlinedInput-root": {
                                            backgroundColor: getUrgencyBackground(index),
                                            color: "#fff",
                                            textAlign: "center",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            border: `1px solid ${getUrgencyBorder(index)}`,
                                        },
                                        "& .MuiSelect-select": {
                                            padding: "0 !important",
                                        },
                                        "& svg": {
                                            display: "none",
                                        },
                                        "& input": {
                                            padding: 0,
                                            height: "25px",
                                        },
                                        "& fieldset": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    {urgencyOptions?.map((actionTypeOption) => {
                                        return (
                                            <MenuItem
                                                key={actionTypeOption}
                                                value={actionTypeOption}
                                            >
                                                {actionTypeOption}
                                            </MenuItem>
                                        )
                                    })}
                                </TextField>
                            )}
                        />
                    )}
                </TableCell>
            )}
            {done ? (
                <TableCell align="right" className={styles.CasesTableMainCell}>
                    <Typography fontSize="14px">
                        {row?.resolution ? row.resolution : "No Resolution"}
                    </Typography>
                </TableCell>
            ) : (
                <TableCell align="right" className={styles.CasesTableMainCell}>
                    <Typography fontSize="14px">
                        {moment(row?.created_at).format("DD/MM/YY HH:mm")}
                    </Typography>
                </TableCell>
            )}
        </TableRow>
    )
}

export default CaseTableRow
