import React from "react"
import { Box, Typography } from "@mui/material"
import styles from "./EmptyResult.module.scss"

export function EmptyResult() {
    return (
        <Box className={styles.emptyResultContainer}>
            <Typography className={styles.emptyResultTitle}>No Results Found</Typography>
            <Typography className={styles.emptyResultSubtitle}>
                Sorry, but no results were found for your search query or an error occurred.
            </Typography>
        </Box>
    )
}
