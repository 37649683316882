import { SET_REFERRER_LINK } from "../actions/types"

const initialState = ""

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_REFERRER_LINK:
            return action.payload
        default:
            return state
    }
}
