import React, { useCallback, useEffect, useRef } from "react"
import { Box } from "@mui/material"
import { getPath, createIsMarked } from "../../../../../../helpers/notebookHelpers"
import { useDispatch, useSelector } from "react-redux"
import {
    setFindingHoverId,
    setFindingClickId,
    setFindingsState,
} from "../../../../../../redux/actions/findingsActions"
import styles from "./AttributeList.module.scss"

export function AttributeList({
    attributes,
    setMarkedPath,
    handleNameClick,
    setSelectedElement,
    setMarkingAvailable,
    setShowRiskPopOver,
    values,
    tool_id,
    markers,
    setIsAccordionExpanded,
}) {
    const findingsClickedId = useSelector((state) => state.findingsState.clickedId)

    const attributeRef = useRef(null)
    const isMarked = createIsMarked(markers, values, tool_id)
    const dispatch = useDispatch()

    const handleSetAtributePath = useCallback(
        (event, name, value, hideMarkAsFinding) => {
            if (values.tools[tool_id].log.standard_response && name) {
                let path = getPath(
                    values?.tools[tool_id]?.log?.standard_response,
                    name,
                    value,
                    null,
                    "standard_response",
                )
                setMarkedPath(path)
            }
            setSelectedElement({ name, value })
            handleNameClick(event)
            if (!hideMarkAsFinding) {
                setShowRiskPopOver(true)
            } else {
                dispatch(setFindingsState(true, null))
            }
            setMarkingAvailable(!!hideMarkAsFinding)
        },
        [
            handleNameClick,
            setSelectedElement,
            setShowRiskPopOver,
            values.tools,
            tool_id,
            setMarkedPath,
        ],
    )

    const handleHoverItem = (e, id, mouseLeave = false) => {
        e.stopPropagation()
        if (id && !mouseLeave) dispatch(setFindingHoverId(id))
        if (id && mouseLeave) dispatch(setFindingHoverId(null))
    }

    useEffect(() => {
        if (findingsClickedId && findingsClickedId === isMarked?.uuid) {
            setIsAccordionExpanded(true)
            setTimeout(() => {
                attributeRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                })
                dispatch(setFindingClickId(null))
            }, 500)
        }
    }, [findingsClickedId, isMarked?.uuid])

    return (
        <Box className={styles.apiResponseAttributesWrapper} ref={attributeRef}>
            {Object.entries(attributes).map(([name, value], index) => (
                <Box key={name}>
                    <span
                        onClick={(event) =>
                            handleSetAtributePath(event, name, value, isMarked(name, value)?.color)
                        }
                        onMouseEnter={(e) => handleHoverItem(e, isMarked?.uuid)}
                        onMouseLeave={(e) => handleHoverItem(e, isMarked?.uuid, true)}
                        className={`
                            ${styles.apiResponseAttributesName}
                            ${styles[isMarked(name, value)?.color]}
                            ${isMarked(name, value)?.color ? "" : styles.hoverEffect}`}
                    >
                        {name}:
                    </span>{" "}
                    <span className={styles.apiResponseAttributesDescription}>{String(value)}</span>
                </Box>
            ))}
        </Box>
    )
}
