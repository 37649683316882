import React, { useState, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector, useDispatch } from "react-redux"
import { Box, Typography, CircularProgress } from "@mui/material"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { Controller } from "react-hook-form"
import { DotsMenu } from "../DotsMenu"
import TextField from "@mui/material/TextField"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import accordionExpand from "../../../../assets/icons/accordionExpand.svg"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import { doToolAction } from "../../../../services/notebook/notebookServices"
import { RiskLevel } from "../../components/RiskLevel/RiskLevel"
import styles from "./RichText.module.scss"

const useStyles = makeStyles(() => ({
    accordionImage: {
        "& .MuiAccordionSummary-expandIconWrapper": {
            "&.Mui-expanded": {
                transform: "rotate(90deg)",
            },
        },
    },
}))

function RichText({
    tool_id,
    index_x,
    index_y,
    handleAddTools,
    handleDeleteTool,
    control,
    values,
    setValue,
    watch,
    title,
    integrations,
    toolsConfig,
    refreshPageData,
    resetField,
    newTool,
    subtitle,
    isFromToolbox,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isLoadingNewTool, setIsLoadingNewTool] = useState(false)
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(newTool ? true : false)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const onEditorStateChange = (editorState) => {
        setValue(`tools.${tool_id}.log.note`, editorState)
    }

    const editorContent = watch(`tools.${tool_id}.log.note`)

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (values) {
                await doToolAction(
                    values.case_id,
                    values.tools[tool_id]?.id,
                    values.tools[tool_id]?.type,
                    values.tools[tool_id]?.log,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                dispatch(handleShowToaster("success", "Successfully save data"))
            }
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleCancel = () => {
        resetField(`tools.${values.tools[tool_id]?.id}.log.note`)
        resetField(`tools.${values.tools[tool_id]?.id}.log.source`)
    }

    const toggleAccordion = () => {
        setIsAccordionExpanded(!isAccordionExpanded)
    }

    useEffect(() => {
        if (values.tools[tool_id].type === 21 && !values.tools[tool_id]?.log?.note) {
            setValue(`tools.${tool_id}.log.note`, "<ol><li><br></li></ol>")
        }
    }, [values.tools[tool_id].type])

    useEffect(() => {
        if (newTool || (location.state?.scrollToElement && location.state?.id === tool_id)) {
            setIsAccordionExpanded(true)
        }
    }, [newTool, tool_id, location])

    return (
        <Box>
            <Accordion
                style={{ "border-radius": "10px", cursor: "default" }}
                className={styles.richTextAccordion}
                expanded={isAccordionExpanded}
            >
                <AccordionSummary
                    className={classes.accordionImage}
                    style={{
                        cursor: "default",
                        backgroundColor: "transparent",
                        flexDirection: "row-reverse",
                        gap: "10px",
                    }}
                    expandIcon={
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={toggleAccordion}
                            src={accordionExpand}
                        ></img>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box className={styles.richTextTitleWrapper}>
                        {/* <img className={styles.richTextTitleLogo} src={logo_url}></img> */}
                        <Box display="flex" flexDirection="column" gap="5px">
                            <Typography className={styles.richTextTitleText}>{title}</Typography>
                            <Typography className={styles.richTextSubtitle}>{subtitle}</Typography>
                        </Box>
                        {isDeleteLoading || isLoadingNewTool ? (
                            <Box className={styles.richTextLoaderWrapper}>
                                <CircularProgress
                                    color="inherit"
                                    style={{ width: "25px", height: "25px" }}
                                />
                            </Box>
                        ) : (
                            <Box className={styles.richTextActionsWrapper}>
                                <RiskLevel
                                    values={values}
                                    tool_id={tool_id}
                                    control={control}
                                    resetField={resetField}
                                    refreshPageData={refreshPageData}
                                    toolLog={values.tools[tool_id].log || {}}
                                />

                                {!isFromToolbox && (
                                    <DotsMenu
                                        handleAddTools={handleAddTools}
                                        index_x={index_x}
                                        index_y={index_y}
                                        values={values}
                                        integrations={integrations}
                                        toolsConfig={toolsConfig}
                                        handleDeleteTool={handleDeleteTool}
                                        tool_id={tool_id}
                                        setIsDeleteLoading={setIsDeleteLoading}
                                        setIsLoadingNewTool={setIsLoadingNewTool}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </AccordionSummary>
                <Box className={styles.resultWrapper}>
                    {values.tools[tool_id].type === 17 && (
                        <Box>
                            <Box marginTop="7px">
                                <Controller
                                    name={`tools.${tool_id}.log.source`}
                                    defaultValue=""
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            id="ip"
                                            variant="outlined"
                                            label="Source"
                                            disabled={isLoading || isDeleteLoading}
                                            InputProps={{
                                                className: styles.input,
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: "#6B869A",
                                                },
                                            }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                    )}
                    <ReactQuill
                        style={{ height: "200px", borderRadius: "10px" }}
                        theme="snow"
                        placeholder="Add documentation here"
                        value={editorContent || ""}
                        onChange={onEditorStateChange}
                    />
                    <Box className={styles.editIcons}>
                        {isLoading ? (
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        ) : (
                            <>
                                <Box display="flex" gap="15px">
                                    <CloseIcon
                                        onClick={handleCancel}
                                        className={styles.standartIcon}
                                    />
                                    <DoneIcon
                                        onClick={handleSave}
                                        className={styles.standartIcon}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Accordion>
        </Box>
    )
}

export default RichText
