import React, { useState } from "react"
import { styled } from "@mui/material/styles"
import MuiDrawer from "@mui/material/Drawer"
import {
    Box,
    List,
    CssBaseline,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { PopOver } from "../../../../components/PopOver/PopOver"
import { notebookMenuItems } from "../../../../constants/appConstants"
import styles from "./AsideMenu.module.scss"

const drawerWidth = 230

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: "ease",
        duration: "0.5s",
    }),
    color: "#9AA3AB",
    overflowX: "hidden",
    backgroundColor: "#000000",
    border: "none",
    top: "64px",
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: "ease",
        duration: "0.5s",
    }),
    overflowX: "hidden",
    backgroundColor: "#000000",
    color: "#9AA3AB",
    border: "none",
    top: "64px",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    }),
)

export function AsideMenu({ setSelectedMenuItem, selectedMenuItem, setOpenMenu, openMenu }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [hoveredMenuItemIndex, setHoveredMenuItemIndex] = useState(null)

    const handleDrawerOpen = () => {
        setOpenMenu(true)
    }

    const handleDrawerClose = () => {
        setOpenMenu(false)
    }

    const handleMenuItemClick = (item) => {
        if (selectedMenuItem === item) {
            setSelectedMenuItem(null)
        } else {
            setSelectedMenuItem(item)
        }
    }

    const handleMenuItemMouseEnter = (event, index) => {
        setHoveredMenuItemIndex(index)
        setAnchorEl(event.currentTarget)
    }

    const handleMenuItemMouseLeave = () => {
        setHoveredMenuItemIndex(null)
        setAnchorEl(null)
    }

    return (
        <Box className={styles.asideMenuContainer}>
            <CssBaseline />
            <Drawer variant="permanent" open={openMenu}>
                {openMenu ? (
                    <ArrowBackIosNewIcon
                        onClick={handleDrawerClose}
                        className={styles.asideMenuArrow}
                    />
                ) : (
                    <ArrowForwardIosIcon
                        onClick={handleDrawerOpen}
                        className={styles.asideMenuArrow}
                    />
                )}
                <List className={styles.asideMenuListWrapper}>
                    <ListItem disablePadding className={styles.asideMenuListItem}>
                        {notebookMenuItems.map((menuItem, index) => (
                            <ListItemButton
                                key={index}
                                onClick={() => handleMenuItemClick(index)}
                                className={styles.asideMenuListElement}
                                sx={{ backgroundColor: selectedMenuItem === index && "#292222" }}
                                onMouseEnter={(e) => handleMenuItemMouseEnter(e, index)}
                                onMouseLeave={handleMenuItemMouseLeave}
                            >
                                <ListItemIcon className={styles.asideMenuListIcon}>
                                    <img
                                        width="20px"
                                        src={
                                            selectedMenuItem === index
                                                ? menuItem.activeIcon
                                                : menuItem.icon
                                        }
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={menuItem.label}
                                    sx={{
                                        opacity: openMenu ? 1 : 0,
                                        transition: "all 0.5s ease",
                                        color: selectedMenuItem === index ? "#fff" : null,
                                    }}
                                />
                            </ListItemButton>
                        ))}
                    </ListItem>
                </List>
                {!openMenu && hoveredMenuItemIndex !== null && (
                    <PopOver
                        text={notebookMenuItems[hoveredMenuItemIndex].label}
                        vertical="center"
                        horizontal="right"
                        anchorEl={anchorEl}
                        handlePopoverClose={handleMenuItemMouseLeave}
                    />
                )}
            </Drawer>
        </Box>
    )
}
