import React from "react"
import { Typography, Box } from "@mui/material"
import styles from "./ButtonWithLogo.module.scss"

export function ButtonWithLogo({ logo_url, title, onClick, disabled, hide }) {
    return (
        <Box
            className={styles.buttonWithLogoContainer}
            style={{
                display: hide ? "none" : "flex",
            }}
        >
            <Box onClick={onClick} className={styles.buttonWithLogoBtn} disabled={disabled}>
                <Box className={styles.buttonWithLogoWrapper}>
                    <Box>
                        <Typography className={styles.buttonWithLogoTitle}>{`${title}`}</Typography>
                    </Box>
                    <Box>
                        {logo_url && (
                            <Box
                                component="a"
                                sx={{
                                    height: "auto",
                                    display: "inline",
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        maxWidth: "90px",
                                        maxHeight: "25px",

                                        paddingTop: "5px",
                                    }}
                                    src={logo_url}
                                    alt="logo"
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
