import googleIcon from "../assets/icons/steps/google-icon.svg"
import cbIcon from "../assets/icons/steps/cbIcon.svg"
import openCorparatesIcon from "../assets/icons/steps/openCorparatesIcon.svg"
import maxMindIcon from "../assets/icons/steps/maxMindIcon.svg"
import fbIcon from "../assets/icons/steps/facebookIcon.svg"
import linkedInIcon from "../assets/icons/steps/linkedinIcon.svg"
import instagramIcon from "../assets/icons/steps/instagramIcon.svg"
import { RISK_LEVELS } from "../constants/appConstants"

export const getPath = (obj, targetKey, targetValue, sectionIndex, path = "") => {
    if (obj && targetKey) {
        for (let key in obj) {
            if (obj[key] && typeof obj[key] === "object") {
                let newPath = path + "." + key
                if (Array.isArray(obj[key])) {
                    for (let i = 0; i < obj[key].length; i++) {
                        if (sectionIndex !== undefined && sectionIndex !== i) continue
                        let arrayPath = newPath + "[" + i + "]"
                        if (obj[key][i][targetKey] === targetValue) {
                            return arrayPath + "." + targetKey
                        } else {
                            let result = getPath(
                                obj[key][i],
                                targetKey,
                                targetValue,
                                sectionIndex,
                                arrayPath,
                            )
                            if (result) return result
                        }
                    }
                } else {
                    if (obj[key][targetKey] === targetValue) {
                        return newPath + "." + targetKey
                    } else {
                        let result = getPath(obj[key], targetKey, targetValue, undefined, newPath)
                        if (result) return result
                    }
                }
            }
        }
    } else {
        return null
    }
}

const getRiskColor = (risk_level) => {
    switch (risk_level) {
        case 0:
            return "riskNone"
        case 1:
            return "riskLow"
        case 2:
            return "riskMedium"
        case 3:
            return "riskHigh"
        case 4:
            return "riskFraudulent"
        default:
            return ""
    }
}

export const createIsMarked = (markers, values, tool_id, sectionIndex) => {
    return (name, value) => {
        if (!markers) return
        const attributePath = getPath(
            values?.tools[tool_id]?.log?.standard_response,
            name,
            value,
            sectionIndex,
            "standard_response",
        )
        const marker = markers.find((marker) => marker.link === attributePath)
        if (marker) {
            return {
                color: getRiskColor(marker?.risk_level),
                uuid: marker?.uniqueId,
                riskLevel: marker?.risk_level,
                description: marker?.description || null,
            }
        } else {
            return ""
        }
    }
}

export const findStepChildrenTypes = (steps, filteredTools) => {
    const childrenMap = {}

    steps.forEach((step) => {
        const stepId = step.id
        const children = filteredTools
            .filter((tool) => tool.father === stepId)
            .map((tool) => tool.type)

        childrenMap[stepId] = children
    })

    return childrenMap
}

export const createStepImageArray = (childrenMap) => {
    const imageObject = {}

    for (const stepId in childrenMap) {
        const children = childrenMap[stepId]

        const imageSet = new Set()

        children.forEach((type) => {
            let imageLink = null

            switch (type) {
                case 7:
                    imageLink = maxMindIcon
                    break
                case 34:
                    imageLink = [fbIcon, linkedInIcon, instagramIcon]
                    break
                case 36:
                    imageLink = googleIcon
                    break
                case 38:
                    imageLink = cbIcon
                    break
                case 42:
                    imageLink = openCorparatesIcon
                    break
                case 47:
                    imageLink = [fbIcon, linkedInIcon, instagramIcon]
                    break
                default:
                    imageLink = null
            }

            if (imageLink !== null) {
                if (Array.isArray(imageLink)) {
                    imageLink.forEach((link) => imageSet.add(link))
                } else {
                    imageSet.add(imageLink)
                }
            }
        })

        if (imageSet.size > 0) {
            imageObject[stepId] = Array.from(imageSet)
        } else {
            imageObject[stepId] = []
        }
    }

    return imageObject
}

export function calculateAverageRiskLevel(data) {
    if (!data || data?.length === 0) return null

    const filteredData = data?.filter((item) => item?.risk_level !== 0)
    const totalRiskLevel = filteredData?.reduce((sum, item) => sum + item?.risk_level, 0)
    const averageRiskLevel = totalRiskLevel / filteredData?.length || 0

    return Math.round(averageRiskLevel)
}

export function calculateAverageRisk(filteredToolsByFather) {
    let totalRiskLevel = 0
    let totalRiskCount = 0

    filteredToolsByFather.forEach((tool) => {
        const riskLevel = getRiskLevelForTool(tool)
        if (riskLevel === 0) return
        totalRiskLevel += riskLevel
        totalRiskCount++
    })

    return totalRiskCount > 0 ? Math.round(totalRiskLevel / totalRiskCount) : 0
}

export function getRiskLevelForTool(tool) {
    if (tool?.log?.risk_level || tool?.log?.auto_risk_level) {
        const riskLevel = RISK_LEVELS.find((level) => level.title === tool.log.risk_level)?.value
        const auto_risk_level = RISK_LEVELS.find(
            (level) => level?.title === tool?.log?.auto_risk_level,
        )?.value
        return riskLevel || auto_risk_level
    } else if (tool?.log?.standard_response_risk_levels?.length > 0) {
        const averageRiskLevel = calculateAverageRiskLevel(tool.log.standard_response_risk_levels)
        return averageRiskLevel
    } else {
        return 0
    }
}

export const isUserInToolboxPage = (pathname) => {
    return pathname.includes("/toolbox")
}
