import { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useLocation } from "react-router-dom"

const defaultOnRedirecting = () => <></>
const defaultReturnTo = () => `${window.location.pathname}${window.location.search}`

const withCustomAuthenticationRequired = (Component, options = {}) => {
    return function WithCustomAuthenticationRequired(props) {
        const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
        const {
            returnTo = defaultReturnTo,
            onRedirecting = defaultOnRedirecting,
            claimCheck = () => true,
            loginOptions = {},
        } = options

        /**
         * The route is authenticated if the user has valid auth and there are no
         * JWT claim mismatches.
         */
        const routeIsAuthenticated = isAuthenticated && claimCheck(user)

        /**
         * Checking if url from invitation organization
         */
        const location = useLocation()
        let email = ""
        let isSignup = "false"
        if (
            location.pathname.split("/").length === 3 &&
            location.pathname.split("/")[2].startsWith("email")
        ) {
            email = location.pathname.split("/")[2].split("=")[1].split("&")[0]
            isSignup = location.pathname.split("/")[2].split("=")[2]
        }

        // Check pathname for signup
        const pathname = location.pathname
        useEffect(() => {
            const isAuthorizeUrl = window.location.pathname.includes("/authorize")
            if (isAuthorizeUrl && routeIsAuthenticated) {
                return window.location.replace("/")
            }

            if (routeIsAuthenticated) {
                // remove the lastReturnTo from localStorage
                // localStorage.removeItem("lastReturnTo")

                if (pathname && pathname !== window.location.pathname) {
                    return window.location.replace(pathname)
                }

                return
            }

            const opts = {
                ...loginOptions,
                redirectUri:
                    window.location.origin + "/redirect?pathname=" + window.location.pathname,
                appState: {
                    ...(loginOptions && loginOptions.appState),
                    returnTo: window.location.pathname,
                },
            }

            if (email) {
                opts.login_hint = email
            }

            if (isSignup === "true") {
                opts.screen_hint = "signup"
            }

            ;(async () => {
                await loginWithRedirect(opts)
                // save current location to return to after login / signup
                localStorage.setItem("lastReturnTo", window.location.pathname)
            })()
        }, [
            isLoading,
            routeIsAuthenticated,
            loginWithRedirect,
            loginOptions,
            returnTo,
            email,
            isSignup,
            pathname,
        ])

        return routeIsAuthenticated ? <Component {...props} /> : onRedirecting()
    }
}

export default withCustomAuthenticationRequired
