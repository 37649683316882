import React, { useState } from "react"
import { Box, Typography, Divider } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import { useSelector, useDispatch } from "react-redux"
import { doToolAction } from "../../../../../../services/notebook/notebookServices"
import CircularProgress from "@mui/material/CircularProgress"
import { EntityFields } from "../../../../Tools/Entity"
import styles from "./NewEntity.module.scss"

const typeOptions = ["Person", "Company"]

export function NewEntity({
    selectEntityView,
    entityId,
    control,
    values,
    refreshPageData,
    resetAllFields,
    handleDeleteTool,
    entityTypes,
}) {
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const [logChanged, setLogChanged] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (entityId && values.tools[entityId]) {
                await doToolAction(
                    values.case_id,
                    values.tools[entityId]?.id,
                    values.tools[entityId]?.type,
                    values.tools[entityId].log,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                selectEntityView(0)
            }
        } catch (error) {
            console.log(error)
            resetAllFields()
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleCancel = async () => {
        try {
            setIsLoading(true)
            await handleDeleteTool(entityId)
            selectEntityView(0)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" marginTop="30px">
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box display="flex" flexDirection="column" gap="16px" paddingBottom={"80px"}>
            <Box
                display="flex"
                alignItems="center"
                gap="8px"
                onClick={() => selectEntityView(0)}
                sx={{ cursor: "pointer" }}
            >
                <KeyboardBackspaceIcon />
                <Typography
                    style={{
                        color: "#374957",
                        fontSize: "14px",
                        fontWeight: 500,
                    }}
                >
                    Back
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography
                    style={{
                        color: "#000000",
                        fontSize: "20px",
                    }}
                >
                    New Entity
                </Typography>
            </Box>
            <Divider />

            {entityId ? (
                <>
                    <EntityFields
                        tool_id={entityId}
                        control={control}
                        values={values}
                        entityTypes={entityTypes}
                        handleEntityPastEvents={null}
                        logChanged={logChanged}
                        setLogChanged={setLogChanged}
                    />
                </>
            ) : null}
            <Box className={styles.btnWrapper}>
                <LoadingButton
                    loading={isLoading}
                    loadingIndicator={
                        <CircularProgress style={{ width: "15px", height: "15px" }} />
                    }
                    onClick={handleCancel}
                    className={styles.cancelButton}
                    variant="outlined"
                >
                    Cancel
                </LoadingButton>
                <LoadingButton
                    loadingIndicator={
                        <CircularProgress style={{ width: "15px", height: "15px" }} />
                    }
                    loading={isLoading}
                    onClick={handleSave}
                    className={styles.saveButton}
                    variant="outlined"
                >
                    Save
                </LoadingButton>
            </Box>
        </Box>
    )
}
