import React, { useRef, useEffect } from "react"
import { Box } from "@mui/material"
import pencilIcon from "../../../../../../assets/icons/pencil.svg"
import removeIcon from "../../../../../../assets/icons/trash.svg"
import styles from "./FindingsItemOptionsPopOver.module.scss"

export function FindingsItemOptionsPopOver({
    setShowOptionsPopOver,
    dotsButtonRef,
    handleEdit,
    handleOpenDelete,
}) {
    const popOverRef = useRef()

    const handleClickOutside = (event) => {
        if (
            popOverRef.current &&
            !popOverRef.current.contains(event.target) &&
            !dotsButtonRef.current.contains(event.target)
        ) {
            setShowOptionsPopOver(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return (
        <Box ref={popOverRef} className={styles.findingsItemOptionsPopOverWrapper}>
            <Box className={styles.findingsItemOptionsPopOverItemsWrapper}>
                <Box onClick={handleEdit} className={styles.findingsItemOptionsPopOverItems}>
                    <img src={pencilIcon}></img>
                    Edit
                </Box>

                <Box onClick={handleOpenDelete} className={styles.findingsItemOptionsPopOverItems}>
                    <img src={removeIcon}></img>
                    Delete
                </Box>
            </Box>
        </Box>
    )
}
