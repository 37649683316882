import React, { useState, useEffect } from "react"
import { Box, Typography, MenuItem, Divider, TextField, Autocomplete } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { Controller } from "react-hook-form"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import { doToolAction } from "../../../../../../services/notebook/notebookServices"
import { useSelector, useDispatch } from "react-redux"
import CircularProgress from "@mui/material/CircularProgress"
import { RemoveDialog } from "../../../RemoveDialog/RemoveDialog"
import deleteIcon from "../../../../../../assets/icons/Entity/delete.svg"
import companyLogo from "../../../../../../assets/icons/Entity/companyBlue.svg"
import personLogo from "../../../../../../assets/icons/Entity/personBlue.svg"
import pencilIcon from "../../../../../../assets/icons/pencil.svg"
import trashIcon from "../../../../../../assets/icons/trash.svg"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import styles from "./EntityInfo.module.scss"

export function EntityInfo({
    values,
    entityTypes,
    currentEditingEntityId,
    selectEntityView,
    control,
    resetAllFields,
    handleDeleteTool,
    refreshPageData,
}) {
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const [edit, setEdit] = useState(false)
    const [logChanged, setLogChanged] = useState(0)
    const [allFields, setAllFields] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [removetoolDialogOpen, setRemovetoolDialogOpen] = useState(false)

    const toogleEdit = () => {
        setEdit(!edit)
    }

    const handleCancel = async () => {
        resetAllFields()
        setEdit(false)
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (currentEditingEntityId && values.tools[currentEditingEntityId]) {
                await doToolAction(
                    values.case_id,
                    values.tools[currentEditingEntityId]?.id,
                    values.tools[currentEditingEntityId]?.type,
                    values.tools[currentEditingEntityId].log,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                setEdit(false)
                dispatch(handleShowToaster("success", "Successfully save entity details"))
            }
        } catch (error) {
            console.log(error)
            setEdit(false)
            resetAllFields()
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    const handeRemove = async () => {
        try {
            setIsLoading(true)
            setRemovetoolDialogOpen(false)
            await handleDeleteTool(currentEditingEntityId)
            dispatch(handleShowToaster("success", "Successfully removed entity"))
            selectEntityView(0)
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
            setEdit(false)
        } finally {
            await refreshPageData()
            setIsLoading(false)
        }
    }

    const handleOpenDelete = (e) => {
        e.stopPropagation()
        setRemovetoolDialogOpen(true)
    }

    useEffect(() => {
        if (entityTypes) {
            let fields = Object.keys(entityTypes).reduce((res, v) => {
                return res.concat(entityTypes[v])
            }, [])
            var uniqueFieldsNames = [...new Set(fields.map((item) => item.field))]
            const uniqueFields = uniqueFieldsNames.map((field) => {
                for (let i = 0; i < fields.length; i++) {
                    if (field === fields[i].field) {
                        return fields[i]
                    }
                }
            })
            setAllFields(uniqueFields)
        }
    }, [entityTypes])

    const constructField = (field, hide) => {
        let fieldController = <></>
        if (field.type === "select-autocomplete") {
            fieldController = (
                <Controller
                    control={control}
                    name={`tools.${currentEditingEntityId}.log[${field.field}]`}
                    render={({ field: { onChange, value } }) => (
                        <Autocomplete
                            autoSelect
                            options={field.options.map((item) => item.display)}
                            onChange={(event, values) => {
                                onChange(values)
                                setLogChanged(logChanged + 1)
                            }}
                            value={value}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="All"
                                    variant="outlined"
                                    onChange={onChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                    )}
                />
            )
        } else {
            const name = field.field
            fieldController = (
                <Controller
                    name={`tools.${currentEditingEntityId}.log[${name}]`}
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                        <>
                            {!edit ? (
                                <Typography fontSize="16px">{field?.value || "None"}</Typography>
                            ) : (
                                <TextField
                                    {...field}
                                    disabled={isLoading}
                                    onChange={(value) => {
                                        field.onChange(value)
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: "#6B869A",
                                        },
                                    }}
                                    variant="outlined"
                                    label={name}
                                    InputProps={{
                                        className: styles.input,
                                    }}
                                    fullWidth
                                />
                            )}
                        </>
                    )}
                />
            )
        }
        return (
            <>
                <Box
                    sx={{
                        display: hide ? "none" : "flex",
                        flexDirection: "column",
                        gap: "8px",
                    }}
                >
                    {!edit && (
                        <Typography color="#374957" fontSize="14px">
                            {field.field}
                        </Typography>
                    )}
                    <Box
                        sx={{
                            display: hide ? "none" : "block",
                        }}
                    >
                        {fieldController}
                    </Box>
                </Box>
            </>
        )
    }

    if (!edit && isLoading)
        return (
            <Box display="flex" justifyContent="center" marginTop="30px">
                <CircularProgress />
            </Box>
        )

    return (
        <Box display="flex" flexDirection="column" gap="16px" paddingBottom={"80px"}>
            <Box
                display="flex"
                alignItems="center"
                gap="8px"
                onClick={() => selectEntityView(0)}
                sx={{
                    cursor: "pointer",
                }}
            >
                <KeyboardBackspaceIcon />
                <Typography
                    style={{
                        color: "#374957",
                        fontSize: "14px",
                        fontWeight: 500,
                    }}
                >
                    Back
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography
                    style={{
                        color: "#000000",
                        fontSize: "20px",
                    }}
                >
                    {edit && "Edit"} {values.tools[currentEditingEntityId]?.log?.Name || "None"}
                </Typography>
                {!edit && (
                    <Box display="flex" alignItems="center" gap="18px">
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={toogleEdit}
                            src={pencilIcon}
                        ></img>
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleOpenDelete(e)}
                            src={trashIcon}
                        ></img>
                    </Box>
                )}
            </Box>
            <Box className={styles.typeImage}>
                {values.tools[currentEditingEntityId]?.log?.Type === "Company" ? (
                    <>
                        <img src={companyLogo}></img>
                        <Typography color="#6B869A" fontSize="12px">
                            Company
                        </Typography>
                    </>
                ) : (
                    <>
                        <img width="12px" height="12px" src={personLogo}></img>
                        <Typography color="#6B869A" fontSize="12px">
                            Person
                        </Typography>
                    </>
                )}
            </Box>
            <Divider />
            {currentEditingEntityId ? (
                <>
                    {!edit ? (
                        <Box display="flex" flexDirection="column" gap="8px">
                            <Typography color="#374957" fontSize="14px">
                                Type
                            </Typography>
                            <Typography fontSize="16px">
                                {values.tools[currentEditingEntityId]?.log.Type}
                            </Typography>
                        </Box>
                    ) : (
                        <Controller
                            name={`tools.${currentEditingEntityId}.log.Type`}
                            defaultValue="Company"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    select
                                    {...field}
                                    onChange={(value) => {
                                        field.onChange(value)
                                        // if id check Related Cases
                                        // if (currentEditionEntity?.log?.id) {
                                        //     handleEntityPastEvents()
                                        // }
                                        setLogChanged(logChanged + 1)
                                    }}
                                    disabled={isLoading}
                                    required="true"
                                    variant="outlined"
                                    label="Type"
                                    InputProps={{
                                        className: styles.input,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            color: "#6B869A",
                                        },
                                    }}
                                    fullWidth
                                >
                                    {entityTypes &&
                                        Object.keys(entityTypes).map((actionTypeOption, index) => {
                                            return (
                                                <MenuItem key={index} value={actionTypeOption}>
                                                    {actionTypeOption}
                                                </MenuItem>
                                            )
                                        })}
                                </TextField>
                            )}
                        />
                    )}

                    <Box display="flex" flexDirection="column" gap={"20px"}>
                        {allFields &&
                            allFields.length > 0 &&
                            allFields?.map((field) => {
                                const hide = !entityTypes[
                                    values.tools[currentEditingEntityId]?.log?.Type
                                ]
                                    ?.map((field) => field.field)
                                    .includes(field.field)
                                return constructField(field, hide)
                            })}
                    </Box>
                </>
            ) : null}
            {edit && (
                <Box className={styles.btnWrapper}>
                    <LoadingButton
                        loading={isLoading}
                        loadingIndicator={
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        }
                        onClick={(e) => handleOpenDelete(e)}
                        className={styles.removeButton}
                        variant="outlined"
                    >
                        <img
                            width="12px"
                            height="12px"
                            style={{ display: isLoading ? "none" : "block" }}
                            src={deleteIcon}
                        ></img>
                        Remove
                    </LoadingButton>
                    <LoadingButton
                        loading={isLoading}
                        loadingIndicator={
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        }
                        onClick={handleCancel}
                        className={styles.cancelButton}
                        variant="outlined"
                    >
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        loading={isLoading}
                        loadingIndicator={
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        }
                        onClick={handleSave}
                        className={styles.saveButton}
                        variant="outlined"
                    >
                        Save
                    </LoadingButton>
                </Box>
            )}
            <RemoveDialog
                open={removetoolDialogOpen}
                setClosed={() => setRemovetoolDialogOpen(false)}
                handleAgree={() => handeRemove()}
                name="entity"
            />
        </Box>
    )
}
