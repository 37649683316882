import { createTheme, responsiveFontSizes } from "@mui/material/styles"

let theme = createTheme({
    palette: {
        primary: {
            main: "#c4c4c4",
            mainGradient: "linear-gradient(45deg, #E02B38 0%, #FEA993 100%)",
            dark: "#4d982b",
            light: "#8BC272",
        },
        secondary: {
            main: "#c4c4c4 ",
            mainGradient: "linear-gradient(39.91deg, #06D6A0 67.03%, #FEA993 115.5%)",
            dark: "#b78f18",
            light: "#FFDE7B",
        },
        c707070: {
            main: "#707070",
        },
        cBF3636: {
            main: "#BF3636",
        },
        c1D252B: {
            main: "#1D252B",
        },
        cblack: {
            main: "#000000",
        },
        c24B0FF: {
            main: "#24B0FF",
        },
        c61BF36: { main: "#61BF36" },
        cFFC824: { main: "#FFC824" },
        cFF2424: {
            main: "#FF2424",
        },
        c4FD6A0: { main: "#4FD6A0" },
    },
    typography: {
        h5: {
            "@media (max-width: 599.98px)": {
                fontSize: "1.2rem",
            },
        },
        header5: {
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: "400",
            fontSize: "1.714rem",
            lineHeight: "1.235",
            letterSpacing: "0.00735em",
            ["@media (min-width: 600px)"]: {
                fontSize: "2.3rem",
            },
            ["@media (min-width: 900px)"]: {
                fontSize: "2.6rem",
            },
        },
        subtitle0: {
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: "400",
            fontSize: "1.3rem",
            lineHeight: "1.5",
            letterSpacing: "0.00938em",
            ["@media (min-width: 600px)"]: {
                fontSize: "1.5rem",
            },
            ["@media (min-width: 900px)"]: {
                fontSize: "1.6rem",
            },
        },
    },
})
theme = responsiveFontSizes(theme)

export { theme }
