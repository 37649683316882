import React from "react"
import { Page, Text, View, StyleSheet, Svg, Circle } from "@react-pdf/renderer"
import { calculateAverageRiskLevel } from "../../../../../helpers/notebookHelpers"
import { RISK_LEVELS } from "../../../../../constants/appConstants"
import { CaseReportStepTool } from "./CaseReportStepTool"

export function CaseReportStep({ step, index, filteredTools, values, markers }) {
    if (!step) return null
    const { log } = step

    const stepFindings = markers.filter((item) => +item?.step?.id === step.id)
    const averageRiskLevel = calculateAverageRiskLevel(stepFindings) || 0
    const filteredToolsByFather = filteredTools.filter((tool) => tool.father === step?.id)
    const riskObject = RISK_LEVELS.find((risk) => risk.value === averageRiskLevel)
    const riskColor = averageRiskLevel === 0 ? "#000" : riskObject ? riskObject.color : "#000"

    const shouldRenderText = filteredToolsByFather?.some((tool) => tool.log?.standard_response)

    const styles = StyleSheet.create({
        sectionStep: {
            fontSize: "18px",
            fontWeight: 600,
        },
        circleStyle: {
            width: 10,
            height: 10,
            borderRadius: "50%",
            backgroundColor: "#5E4AFD",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        toolTitle: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "6px",
            fontSize: "14px",
            fontWeight: 600,
        },
        sectionStepTitle: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "6px",
        },
        stepRiskLevel: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "24px",
            height: "20px",
            borderRadius: "4px",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 600,
            backgroundColor: riskColor,
        },
        stepTools: {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginTop: "12px",
        },
    })

    return (
        shouldRenderText && (
            <View>
                <View style={styles.sectionStep}>
                    <View style={styles.sectionStepTitle}>
                        {filteredToolsByFather.length > 0 && shouldRenderText && (
                            <>
                                <Svg
                                    width="18"
                                    height="19"
                                    viewBox="0 0 18 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <Circle
                                        cx="9"
                                        cy="9.5"
                                        r="7"
                                        fill="#5E4AFD"
                                        stroke="#EDEEF8"
                                        strokeWidth="4"
                                    />
                                </Svg>
                                <Text>
                                    Step {index + 1}. {log.note}
                                </Text>
                            </>
                        )}
                        {averageRiskLevel > 0 && (
                            <View style={styles.stepRiskLevel}>
                                <Text>{averageRiskLevel}</Text>
                            </View>
                        )}
                    </View>
                    <View style={styles.stepTools}>
                        {filteredToolsByFather.map((tool) => (
                            <CaseReportStepTool
                                key={tool.id}
                                tool={tool}
                                markers={markers}
                                values={values}
                            />
                        ))}
                    </View>
                </View>
            </View>
        )
    )
}
