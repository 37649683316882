import React, { useState, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import {
    Box,
    MenuItem,
    Checkbox,
    ListItemText,
    Select,
    Input,
    TextField,
    CircularProgress,
    Switch,
    Divider,
} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { Controller } from "react-hook-form"
import FormControl from "@mui/material/FormControl"
import { useSelector, useDispatch } from "react-redux"
import { RISK_LEVELS } from "../../../../constants/appConstants"
import { updateLog } from "../../../../services/notebook/notebookServices"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import FormControlLabel from "@mui/material/FormControlLabel"
import checkIcon from "../../../../assets/icons/checkIcon.svg"
import checkedIcon from "../../../../assets/icons/checkedIcon.svg"
import CommentIcon from "@mui/icons-material/Comment"
import styles from "./RiskLevel.module.scss"

const MenuProps = {
    PaperProps: {
        style: {
            marginTop: "5px",
            padding: "5px",
            borderRadius: "10px",
            width: 225,
        },
    },
}

const useStyles = makeStyles(() => ({
    customInput: {
        borderRadius: "20px",
        width: "130px",
        textAlign: "center",
        height: "32px",
        padding: "0",
        fontSize: "13px",
        fontWeight: "400",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.8,
            transition: "all .4s ease",
        },
        "& .MuiSelect-select": {
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 10px",
        },
    },
    input: {
        border: "1px solid #000",
        "&:before": {
            display: "none",
        },
        "&:after": {
            display: "none",
        },
    },
    menuLast: {
        "&.MuiMenuItem-root": {
            borderRadius: "10px",
            position: "relative",
            marginTop: "10px",
            "&::before": {
                content: "''",
                display: "block",
                height: "1px",
                backgroundColor: "#EFEEF1",
                position: "absolute",
                top: "-5px",
                left: 0,
                right: 0,
            },
        },
    },
    menu: {
        "&.MuiMenuItem-root": {
            borderRadius: "10px",
            margin: "2px 0",
        },
    },
    menuText: {
        "& span": {
            fontSize: "14px",
            fontWeight: 400,
        },
    },
}))

export function RiskLevel({
    values,
    tool_id,
    control,
    resetField,
    refreshPageData,
    markers,
    autoCalculatedRiskLevel,
    toolLog,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const [showDescription, setShowDescription] = useState(false)
    const [selectOpen, setSelectOpen] = useState(false)
    const [autoRiskLevel, setAutoRiskLevel] = useState("")
    const [showDescriptionIcon, setShowDescriptionIcon] = useState(!!toolLog?.risk_note)
    const [isLoading, setIsLoading] = useState(false)
    const [autoCalculateSwitch, setAutoCalculateSwitch] = useState(!!toolLog?.auto_risk_level)
    const [isLoadingRiskStatus, setIsLoadingRiskStatus] = useState(false)
    const [riskLevel, setRiskLevel] = useState(
        toolLog?.risk_level || toolLog?.auto_risk_level || "Set risk level",
    )

    useEffect(() => {
        setRiskLevel(toolLog?.risk_level || toolLog?.auto_risk_level || "Set risk level")
        setShowDescriptionIcon(!!toolLog?.risk_note)
    }, [toolLog?.risk_note, toolLog?.risk_level, toolLog?.auto_risk_level, markers?.length])

    const findColor = (riskLevel) =>
        RISK_LEVELS.find((level) => level.title === riskLevel)?.color || "#fff"

    const handleDoToolAction = async (updatedLog) => {
        try {
            await updateLog(
                values.case_id,
                values.tools[tool_id]?.id,
                updatedLog,
                dispatch,
                refreshAccessTokenCounter,
                true,
            )
            await refreshPageData()
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
        }
    }

    const handleChangeAutoCalculate = async () => {
        setAutoCalculateSwitch(!autoCalculateSwitch)
        setSelectOpen(true)
        if (riskLevel && !autoCalculateSwitch) {
            await handleDoToolAction({
                ...toolLog,
                risk_level: "",
                auto_risk_level: autoRiskLevel,
            })
            setSelectOpen(false)
        }
        setIsLoadingRiskStatus(false)
    }

    useEffect(() => {
        if (autoCalculatedRiskLevel != null && autoRiskLevel && !toolLog?.risk_level) {
            setAutoCalculateSwitch(true)
        }
    }, [autoCalculatedRiskLevel, toolLog?.auto_risk_level, autoRiskLevel, toolLog?.risk_level])

    useEffect(() => {
        if (autoCalculatedRiskLevel != null && markers.length > 0 && !toolLog.riskLevel) {
            const newAutoRiskLevel = RISK_LEVELS.find(
                (level) => level?.value === autoCalculatedRiskLevel,
            )?.title
            setAutoRiskLevel(newAutoRiskLevel)
            if (autoCalculateSwitch) {
                setRiskLevel(newAutoRiskLevel)
            }
        }
    }, [autoCalculatedRiskLevel, autoCalculateSwitch, markers, toolLog?.risk_level])

    const handleChange = async (event) => {
        const {
            target: { value },
        } = event
        if (value === undefined) return
        setRiskLevel(value)
        setSelectOpen(false)
        setShowDescription(true)
        await handleChangeRiskStatus(value)
    }

    const handleCancel = () => {
        resetField(`tools.${tool_id}.log.risk_note`)
        setShowDescription(false)
    }

    const handleChangeRiskStatus = async (value) => {
        setIsLoadingRiskStatus(true)
        setAutoRiskLevel("")
        if (riskLevel) {
            await handleDoToolAction({ ...toolLog, risk_level: value, auto_risk_level: "" })
        }
        setIsLoadingRiskStatus(false)
    }
    const handleSaveDescription = async () => {
        if (riskLevel) {
            setIsLoading(true)
            await handleDoToolAction(toolLog)
            setShowDescription(false)
            dispatch(handleShowToaster("success", "Successfully save tool description"))
            setShowDescriptionIcon(true)
            setIsLoading(false)
        }
    }

    return (
        <Box className={styles.riskLevelContainer}>
            {isLoadingRiskStatus ? (
                <CircularProgress style={{ width: "15px", height: "15px" }} />
            ) : (
                <>
                    {showDescriptionIcon && (
                        <CommentIcon
                            onClick={() => setShowDescription(!showDescription)}
                            style={{ cursor: "pointer" }}
                        />
                    )}
                    <FormControl>
                        <Controller
                            name={`tools.${tool_id}.log.risk_level`}
                            control={control}
                            render={() => (
                                <>
                                    <Select
                                        id="risk_level"
                                        open={selectOpen}
                                        onClose={() => setSelectOpen(false)}
                                        onOpen={() => setSelectOpen(true)}
                                        value={riskLevel}
                                        style={{
                                            padding: "0",
                                            backgroundColor: `${findColor(riskLevel)}`,
                                            pointerEvents: showDescription && "none",
                                            color:
                                                riskLevel === "Inconclusive" ||
                                                riskLevel === "Set risk level"
                                                    ? "#000"
                                                    : "#fff",
                                        }}
                                        onChange={handleChange}
                                        className={classes.input}
                                        input={
                                            <Input
                                                className={classes.customInput}
                                                sx={{
                                                    "& .MuiSvgIcon-root": {
                                                        alignSelf: "center",
                                                        color:
                                                            riskLevel === "Inconclusive" ||
                                                            riskLevel === "Set risk level"
                                                                ? "#000"
                                                                : "#fff",
                                                    },
                                                    "& .Mui-disabled": {
                                                        color:
                                                            riskLevel === "Inconclusive" ||
                                                            riskLevel === "Set risk level"
                                                                ? "#000"
                                                                : "#fff",
                                                    },
                                                }}
                                            />
                                        }
                                        renderValue={(selected) => selected}
                                        MenuProps={MenuProps}
                                    >
                                        {markers?.length > 0 && (
                                            <Box className={styles.riskLevelAutoCalculate}>
                                                <FormControlLabel
                                                    labelPlacement="start"
                                                    control={
                                                        <Switch
                                                            checked={autoCalculateSwitch}
                                                            onChange={() =>
                                                                handleChangeAutoCalculate()
                                                            }
                                                            color="info"
                                                        />
                                                    }
                                                    label={
                                                        <span className={styles.label}>
                                                            Auto Calculated
                                                        </span>
                                                    }
                                                    sx={{ gap: "40px" }}
                                                />
                                                <Divider style={{ marginTop: "5px" }} />
                                            </Box>
                                        )}
                                        {RISK_LEVELS.map((level) => (
                                            <MenuItem
                                                disabled={
                                                    autoCalculateSwitch && markers?.length > 0
                                                }
                                                className={
                                                    level.value === 0
                                                        ? classes.menuLast
                                                        : classes.menu
                                                }
                                                sx={{
                                                    "&.Mui-selected ": {
                                                        backgroundColor: "#EDEEF8",
                                                        borderRadius: "10px",
                                                        borderBottom: `1px solid ${level.color}`,
                                                    },
                                                }}
                                                key={level.value}
                                                value={level.title}
                                            >
                                                <Checkbox
                                                    icon={<img src={checkIcon}></img>}
                                                    checkedIcon={<img src={checkedIcon}></img>}
                                                    checked={riskLevel?.indexOf(level?.title) > -1}
                                                />
                                                <ListItemText
                                                    className={styles.riskLevelMenuText}
                                                    primary={level.title}
                                                />
                                                <img src={level.img}></img>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                            )}
                        />
                        {showDescription && !isLoadingRiskStatus && (
                            <Box className={styles.riskLevelDescriptionWrapper}>
                                <Controller
                                    name={`tools.${tool_id}.log.risk_note`}
                                    defaultValue=""
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            label="Description"
                                            minRows={5}
                                            multiline
                                            InputProps={{
                                                className: styles.input,
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: "#6B869A",
                                                },
                                            }}
                                            fullWidth
                                        />
                                    )}
                                />
                                <Box className={styles.riskLevelBtnWrapper}>
                                    <LoadingButton
                                        loading={isLoading}
                                        loadingIndicator={
                                            <CircularProgress
                                                style={{ width: "15px", height: "15px" }}
                                            />
                                        }
                                        onClick={handleCancel}
                                        className={styles.cancelButton}
                                        variant="outlined"
                                    >
                                        Cancel
                                    </LoadingButton>
                                    <LoadingButton
                                        loading={isLoading}
                                        loadingIndicator={
                                            <CircularProgress
                                                style={{ width: "15px", height: "15px" }}
                                            />
                                        }
                                        onClick={handleSaveDescription}
                                        className={styles.saveButton}
                                        variant="outlined"
                                    >
                                        Save
                                    </LoadingButton>
                                </Box>
                            </Box>
                        )}
                    </FormControl>
                </>
            )}
        </Box>
    )
}
