import { Box, Typography } from "@mui/material"
import React from "react"

const EmptyIntegration = ({ text }) => {
    return (
        <Box
            sx={{
                borderRadius: "5px",
                boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.25)",
                background: "#ffffff",
                padding: "16px 40px 16px 55px",
            }}
        >
            <Typography fontSize={"16px"} color="#999999">
                {text}
            </Typography>
        </Box>
    )
}

export default EmptyIntegration
