import React from "react"
import { Box } from "@mui/material"
import { Section } from "../Section/Section"
import styles from "./SectionList.module.scss"

export function SectionList({
    sections,
    values,
    tool_id,
    setValue,
    isMoreInfoSubmitting,
    setisMoreInfoSubmitting,
    setMarkedPath,
    handleNameClick,
    setSelectedElement,
    setMarkingAvailable,
    setShowRiskPopOver,
    markers,
    setIsAccordionExpanded,
}) {
    return (
        <Box className={styles.apiResponseSection}>
            {sections &&
                sections?.map((section, index) => (
                    <Section
                        key={`${section?.name}${index}`}
                        section={section}
                        isMoreInfoLoading={isMoreInfoSubmitting}
                        setisMoreInfoSubmitting={setisMoreInfoSubmitting}
                        values={values}
                        tool_id={tool_id}
                        setValue={setValue}
                        setMarkedPath={setMarkedPath}
                        handleNameClick={handleNameClick}
                        setSelectedElement={setSelectedElement}
                        setMarkingAvailable={setMarkingAvailable}
                        setShowRiskPopOver={setShowRiskPopOver}
                        markers={markers}
                        index={index}
                        setIsAccordionExpanded={setIsAccordionExpanded}
                    />
                ))}
        </Box>
    )
}
