import React, { useState } from "react"
import { doToolAction } from "../../../../services/notebook/notebookServices"
import { Box, Typography, Divider } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import LoadingButton from "@mui/lab/LoadingButton"
import CircularProgress from "@mui/material/CircularProgress"
import refreshIcon from "../../../../assets/icons/refresh.svg"
import { Controller } from "react-hook-form"
import TextField from "@mui/material/TextField"
import pencilIcon from "../../../../assets/icons/pencilDark.svg"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import styles from "./Summary.module.scss"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"

export function Summary({ control, summaryTool, values, refreshPageData, setValue, resetField }) {
    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isGenerateLoading, setIsGenerateLoading] = useState(false)

    const toogleEdit = () => {
        setEdit(!edit)
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (summaryTool && values) {
                const { id } = summaryTool
                await doToolAction(
                    values.case_id,
                    values.tools[id]?.id,
                    values.tools[id]?.type,
                    values.tools[id]?.log,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                await refreshPageData()
                setEdit(false)
                dispatch(handleShowToaster("success", "Successfully save summary details"))
            }
        } catch (error) {
            console.log(error)
            setEdit(false)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    const handleCancel = () => {
        resetField(`tools.${summaryTool.id}.log.note`)
        toogleEdit()
    }

    const handleGenerateSummary = async (e) => {
        e.stopPropagation()
        setIsGenerateLoading(true)
        try {
            if (summaryTool && values) {
                const { id } = summaryTool
                const response = await doToolAction(
                    values.case_id,
                    values.tools[id]?.id,
                    values.tools[id]?.type,
                    { note: "Generate$Summary" },
                    dispatch,
                    refreshAccessTokenCounter,
                )
                setValue(`tools.${id}.log.note`, response?.note)
                dispatch(handleShowToaster("success", "Successfully auto-generate summary details"))
            }
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsGenerateLoading(false)
        }
    }

    return (
        <Box className={styles.summaryWrapper}>
            <Box className={styles.summaryHeader}>
                <Typography
                    style={{
                        color: "#000000",
                        fontSize: "20px",
                        fontWeight: "500",
                        lineHeight: "24px",
                    }}
                >
                    Summary
                </Typography>
                <LoadingButton
                    disabled={isGenerateLoading}
                    onClick={handleGenerateSummary}
                    className={styles.generateButton}
                    variant="outlined"
                >
                    <img className={isGenerateLoading && styles.rotating} src={refreshIcon}></img>
                    Auto-generate
                </LoadingButton>
            </Box>
            <Box marginTop="25px" position="relative">
                {!edit ? (
                    <>
                        <Divider sx={{ marginBottom: "5px" }} />
                        <Box padding="16px 14px" sx={{ wordWrap: "break-word" }}>
                            <Typography fontSize="16px">
                                {values.tools[summaryTool.id]?.log.note}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Controller
                        name={`tools.${summaryTool?.id}.log.note`}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                placeholder="Auto-generate or manually add your case summary"
                                variant="outlined"
                                disabled={!edit || isLoading}
                                InputProps={{
                                    className: styles.input,
                                }}
                                multiline
                                fullWidth
                            />
                        )}
                    />
                )}

                <Box className={!edit ? styles.pencilIcon : styles.editIcons}>
                    {isLoading || isGenerateLoading ? (
                        <CircularProgress style={{ width: "15px", height: "15px" }} />
                    ) : (
                        <>
                            {edit ? (
                                <Box display="flex" gap="15px">
                                    <CloseIcon
                                        onClick={handleCancel}
                                        className={styles.standartIcon}
                                    />
                                    <DoneIcon
                                        onClick={handleSave}
                                        className={styles.standartIcon}
                                    />
                                </Box>
                            ) : (
                                <img
                                    style={{ cursor: "pointer" }}
                                    onClick={toogleEdit}
                                    src={pencilIcon}
                                ></img>
                            )}
                        </>
                    )}
                </Box>
            </Box>
            {!edit && <Divider sx={{ marginTop: "5px" }} />}
        </Box>
    )
}
