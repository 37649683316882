import React, { useState } from "react"
import { Box, TextField, CircularProgress } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import FFA_LOGO_SMALL from "../../../../../../assets/icons/Repo/FFA-small.svg"
import LINKED_IN_LOGO from "../../../../../../assets/icons/Repo/linkedIn-logo.svg"
import { Link } from "react-router-dom"
import { LoadingButton } from "@mui/lab"
import { contactUsPlaybook } from "../../../../../../services/playbook/playbookServices"
import { handleShowToaster } from "../../../../../../redux/actions/showToasterActions"
import styles from "./RepoFooter.module.scss"

export function RepoFooter({ handleOpenRepoAuthModal = null, user }) {
    const dispatch = useDispatch()
    const [error, setError] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [contactInputValue, setContactInputValue] = useState("")

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const handleContactInputChange = (e) => {
        setError(false)
        const newValue = e.target.value
        setContactInputValue(newValue)
    }

    const handleContactUs = async (e) => {
        e.preventDefault()
        if (!user && handleOpenRepoAuthModal) {
            handleOpenRepoAuthModal()
            return
        }
        if (!contactInputValue.trim()) {
            setError(true)
            return
        }
        try {
            setIsLoading(true)
            await contactUsPlaybook(contactInputValue, dispatch, refreshAccessTokenCounter)
            dispatch(handleShowToaster("success", "Successfully sent you message"))
            setContactInputValue("")
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box className={styles.repoFooterContainer}>
            <form className={styles.repoFooterContainerMain} onSubmit={handleContactUs}>
                <p className={styles.repoFooterLogo}>
                    <img src={FFA_LOGO_SMALL} />
                    FRAUD FIGHTERS ALLIANCE
                </p>
                <Link
                    to="https://www.linkedin.com/groups/9508939/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                >
                    <p className={styles.repoFooterLinkedIn}>
                        <img src={LINKED_IN_LOGO} />
                        Join Our LinkedIn Community
                    </p>
                </Link>

                <Box className={styles.repoFooterContact}>
                    <p className={styles.repoFooterContactText}>Contact Us:</p>
                    <TextField
                        disabled={isLoading}
                        fullWidth
                        label="Your Message"
                        variant="standard"
                        value={contactInputValue}
                        onChange={handleContactInputChange}
                        color="secondary"
                        InputLabelProps={{
                            className: styles.repoFooterContactLabel,
                        }}
                        InputProps={{
                            className: styles.repoFooterContactInput,
                        }}
                        error={error}
                        helperText={error ? "This field cannot be empty" : ""}
                    />
                    <LoadingButton
                        className={styles.repoFooterContactButton}
                        type="submit"
                        loading={isLoading}
                        loadingIndicator={
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        }
                    >
                        Contact us
                    </LoadingButton>
                </Box>
            </form>
            <Box className={styles.repoFooterInfo}>
                {/* <Box className={styles.repoFooterInfoLinks}>
                    <Link>
                        <Box to="/">Terms</Box>
                    </Link>
                    <Link to="/">
                        <Box>Privacy</Box>
                    </Link>
                </Box> */}
                <Box className={styles.repoFooterInfoRights}>©2023. All Right Reserved.</Box>
            </Box>
        </Box>
    )
}
