import React, { useEffect, useState } from "react"
import IconButton from "@mui/material/IconButton"
import { Box, Grid, Typography } from "@mui/material"
import ListIcon from "@mui/icons-material/List"
import LinearScaleIcon from "@mui/icons-material/LinearScale"
import { styled } from "@mui/material/styles"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import makeStyles from "@mui/styles/makeStyles"

import PersonAddIcon from "@mui/icons-material/PersonAdd"
import PriceCheckIcon from "@mui/icons-material/PriceCheck"
import RequestQuoteIcon from "@mui/icons-material/RequestQuote"
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff"

import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector"
import Tooltip from "@mui/material/Tooltip"

import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { DotsMenu } from "./DotsMenu"
import moment from "moment/moment"

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderRadius: 1,
        backgroundImage:
            "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
}))

const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
}))

function ColorlibStepIcon({ event_type, className }) {
    const icons = {
        "Account Created": <PersonAddIcon />,
        "Payment Received": <PriceCheckIcon />,
        Chargeback: <CreditCardOffIcon />,
        "Payout Request": <RequestQuoteIcon />,
    }
    return <ColorlibStepIconRoot className={className}>{icons[event_type]}</ColorlibStepIconRoot>
}

const useStyles = makeStyles((theme) => ({
    input: {
        height: 42,
        backgroundColor: "#fff",
        marginBottom: "18px",
        [theme.breakpoints.up("sm")]: {
            marginBottom: "0",
        },
    },
    noBefore: {
        "&::before": {
            display: "none",
        },
    },
}))

function EventsTimeline({
    tool_id,
    index,
    index_x,
    index_y,
    handleAddTools,
    handleDeleteTool,
    values,
    title,
    subtitle,
    logo_url,
    logoHref,
    integrations,
    toolsConfig,
}) {
    const classes = useStyles()
    const [events, setEvents] = useState([])

    useEffect(() => {
        if (values.tools) {
            const tools = { ...values.tools }

            const ordered = Object.values(tools).sort((a, b) => {
                if (a?.id < b?.id) {
                    return -1
                }
                if (a?.id > b?.id) {
                    return 1
                }
                return 0
            })

            const extracted_events_tools = ordered.filter((tool) => tool.type === 35)
            var events = extracted_events_tools.map((tool) => tool.log)
            events = events.sort((a, b) => {
                if (Date.parse(a.Timestamp) < Date.parse(b.Timestamp)) {
                    return -1
                }
                if (Date.parse(a.Timestamp) > Date.parse(b.Timestamp)) {
                    return 1
                }
                return 0
            })
            setEvents(events)
        }
    }, [values.tools])

    return (
        <Grid
            container
            direction="row"
            position={"relative"}
            marginBottom={"15px"}
            marginTop={"15px"}
        >
            <Grid xs={12} style={{ padding: "16px 0 0 16px" }}>
                <Box
                    sx={{
                        background: "#e7e7e7",
                        borderRadius: "10px",
                    }}
                >
                    <DotsMenu
                        handleAddTools={handleAddTools}
                        index_x={index_x}
                        index_y={index_y}
                        values={values}
                        integrations={integrations}
                        toolsConfig={toolsConfig}
                        handleDeleteTool={handleDeleteTool}
                        tool_id={tool_id}
                    />
                    <Accordion
                        defaultExpanded
                        style={{
                            backgroundColor: "#f3f3f3",
                            boxShadow: "none",
                            borderRadius: "10px",
                            margin: "0",
                        }}
                        className={classes.noBefore}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ zIndex: "3" }}
                        >
                            <Typography color="black" variant="h6" fontWeight="400">
                                {title}
                            </Typography>
                        </AccordionSummary>
                        <Box
                            sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "space-between",
                                flexDirection: "column-reverse",
                                height: "100%",
                            }}
                        >
                            <Box
                                component="a"
                                sx={{
                                    padding: "10px",
                                    width: "100%",
                                    maxWidth: "90px",
                                    maxHeight: "15px",
                                    height: "auto",
                                    marginBottom: "5px",
                                    marginRight: "11px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                                href={logoHref}
                                target="_blank"
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        // maxWidth: "90px",
                                        maxHeight: "21px",
                                    }}
                                    src={logo_url}
                                    alt="logo"
                                ></Box>
                            </Box>
                        </Box>
                        <Grid container alignItems="center">
                            <Grid item>
                                <IconButton aria-label="expand row" size="large">
                                    <ListIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="expand row" size="large">
                                    <LinearScaleIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" paddingBottom={{ xs: "18px", sm: "0" }}>
                            <Grid
                                item
                                container
                                xs={12}
                                marginRight={{ xs: "0", sm: "36px" }}
                                spacing={3}
                                style={{
                                    overflow: "auto",
                                    margin: "0px",
                                    width: "100%",
                                }}
                            >
                                <Stepper
                                    style={{ height: "200px", alignItems: "center" }}
                                    alternativeLabel
                                    connector={<ColorlibConnector />}
                                >
                                    {events.map((event, index) => {
                                        return (
                                            <Step
                                                style={{ minWidth: "150px" }}
                                                active
                                                key={`${event.Timestamp}${index}`}
                                            >
                                                <Tooltip
                                                    title={event["Extra Info"]}
                                                    placement="bottom"
                                                >
                                                    <StepLabel
                                                        StepIconComponent={() => (
                                                            <ColorlibStepIcon
                                                                event_type={event.Type}
                                                            />
                                                        )}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                position: "absolute",
                                                                top: "-35px",
                                                                left: "50%",
                                                                transform: "translateX(-50%)",
                                                                textAlign: "center",
                                                                width: "150px",
                                                            }}
                                                        >
                                                            {event.Type}
                                                        </Typography>
                                                        <Typography>
                                                            {moment(event.Timestamp).format(
                                                                "YYYY-MM-DD HH:mm",
                                                            )}
                                                        </Typography>
                                                    </StepLabel>
                                                </Tooltip>
                                            </Step>
                                        )
                                    })}
                                </Stepper>
                            </Grid>
                        </Grid>
                    </Accordion>
                </Box>
            </Grid>
        </Grid>
    )
}

export default EventsTimeline
