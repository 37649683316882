import React from "react"
import { RadarChart, PolarGrid, PolarAngleAxis, Radar, Legend } from "recharts"

const data = [
    {
        area: "Marketing",
        SuccessfulCases: 120,
        fullMark: 150,
    },
    {
        area: "Sales",
        SuccessfulCases: 98,
        fullMark: 150,
    },
    {
        area: "Finance",
        SuccessfulCases: 86,
        fullMark: 150,
    },
    {
        area: "Customer Support",
        SuccessfulCases: 99,
        fullMark: 150,
    },
    {
        area: "Product Development",
        SuccessfulCases: 85,
        fullMark: 150,
    },
    {
        area: "Operations",
        SuccessfulCases: 65,
        fullMark: 150,
    },
]

export function RadarChartGraphic() {
    return (
        <RadarChart cx={300} cy={250} outerRadius={150} width={550} height={500} data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="area" />
            <Radar
                name="Successful Cases"
                dataKey="SuccessfulCases"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
            />
            <Legend />
        </RadarChart>
    )
}
