import { SET_PLAYBOOK_STATE } from "../actions/types"

const initialState = { id: null }

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PLAYBOOK_STATE:
            return { ...state, id: action.id }
        default:
            return state
    }
}
