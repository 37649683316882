import React, { useState, useCallback, useEffect, useRef } from "react"
import { Box, Divider, CircularProgress } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { doToolAction } from "../../../../../../services/notebook/notebookServices"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector, useDispatch } from "react-redux"
import { getPath, createIsMarked } from "../../../../../../helpers/notebookHelpers"
import {
    setFindingHoverId,
    setFindingClickId,
    setFindingsState,
} from "../../../../../../redux/actions/findingsActions"
import styles from "./Section.module.scss"

export const PresentPicture = ({ src, alt, className }) => {
    const [imgError, setImgError] = useState(false)

    const handleError = () => {
        setImgError(true)
    }

    if (imgError) {
        // Fallback to a link if the image can't be displayed
        return (
            <a href={src} target="_blank" rel="noopener noreferrer">
                Profile Picture
            </a>
        )
    } else {
        return <img className={className} src={src} alt={alt} onError={handleError} />
    }
}

const useStyles = makeStyles(() => ({
    moreInfoBtn: {
        display: "flex",
        marginTop: "15px",
        gap: "5px",
        alignItems: "center",
        width: "90px",
        height: "30px",
        textTransform: "none",
        borderRadius: "38px",
        color: "#fff",
        backgroundColor: "#000",
        "&:hover": {
            backgroundColor: "#343434",
            color: "#fff",
        },
        "&.Mui-disabled": {
            color: "#cdced8",
        },
        "&.MuiLoadingButton-loading": {
            color: "#000",
        },
    },
}))

export function Section({
    section,
    isMoreInfoLoading,
    setisMoreInfoSubmitting,
    values,
    tool_id,
    setValue,
    setMarkedPath,
    handleNameClick,
    setSelectedElement,
    setMarkingAvailable,
    setShowRiskPopOver,
    markers,
    index,
    setIsAccordionExpanded,
}) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const findingsClickedId = useSelector((state) => state.findingsState.clickedId)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const maxItemsPerColumn = 10
    const isMarked = createIsMarked(markers, values, tool_id, index)

    const handleSectionItemlClick = useCallback(
        (name, value, event, hideMarkAsFinding) => {
            if (values.tools[tool_id].log.standard_response && name) {
                let path = getPath(
                    values?.tools[tool_id]?.log?.standard_response,
                    name,
                    value,
                    index,
                    "standard_response",
                )
                setMarkedPath(path)
            }
            handleNameClick(event)
            setSelectedElement({ name, value })
            if (!hideMarkAsFinding) {
                setShowRiskPopOver(true)
            } else {
                dispatch(setFindingsState(true, null))
            }
            setMarkingAvailable(!!hideMarkAsFinding)
        },
        [
            handleNameClick,
            setSelectedElement,
            setShowRiskPopOver,
            values.tools,
            tool_id,
            setMarkedPath,
        ],
    )

    const getMoreInfoResults = useCallback(
        async (more_info_button_id) => {
            if (more_info_button_id) {
                try {
                    setisMoreInfoSubmitting(true)
                    setIsLoading(true)
                    const curr_log = more_info_button_id
                        ? { ...values.tools[tool_id].log, more_info_button_id: more_info_button_id }
                        : values.tool_id.log
                    const log = await doToolAction(
                        values.case_id,
                        values.tools[tool_id]?.id,
                        values.tools[tool_id].type,
                        curr_log,
                        dispatch,
                        refreshAccessTokenCounter,
                    )
                    setValue(`tools.${tool_id}.log.standard_response`, log["standard_response"])
                } catch (e) {
                    console.log(e)
                } finally {
                    setIsLoading(false)
                    setisMoreInfoSubmitting(false)
                }
            }
        },
        [
            setisMoreInfoSubmitting,
            setIsLoading,
            values.tools,
            tool_id,
            setValue,
            dispatch,
            refreshAccessTokenCounter,
        ],
    )

    const handleHoverItem = (e, id, mouseLeave = false) => {
        e.stopPropagation()
        if (id && !mouseLeave) dispatch(setFindingHoverId(id))
        if (id && mouseLeave) dispatch(setFindingHoverId(null))
    }

    const renderAttributes = (attributes) => {
        const attrs = Array.isArray(attributes) ? attributes : [attributes]
        return attrs.flatMap((item) =>
            Object.entries(item).map(([name, value], index) =>
                renderItem(name, value, `${name}-${index}-more-info-attr`, true),
            ),
        )
    }

    const sectionRef = useRef(null)

    useEffect(() => {
        if (findingsClickedId && findingsClickedId === sectionRef.current?.uuid) {
            setIsAccordionExpanded(true)
            setTimeout(() => {
                sectionRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                })
                dispatch(setFindingClickId(null))
            }, 500)
        }
    }, [findingsClickedId, dispatch, setFindingClickId])

    const renderList = (name, value) => {
        const columnCount = Math.ceil(value.length / maxItemsPerColumn)
        const { color: marked, uuid } = isMarked(name, value)
        const itemClass = `${styles.apiResponseSectionTitle} ${styles[marked]} ${
            marked ? "" : styles.hoverEffect
        }`

        return (
            <>
                <Box
                    onClick={(event) =>
                        handleSectionItemlClick(name, value, event, isMarked(name, value))
                    }
                    onMouseEnter={(e) => handleHoverItem(e, uuid)}
                    onMouseLeave={(e) => handleHoverItem(e, uuid, true)}
                    ref={sectionRef}
                    className={itemClass}
                    style={{ whiteSpace: "pre-line" }}
                >
                    {name}:
                </Box>
                <ul style={{ columnCount }} className={styles.apiResponseSectionColumn}>
                    {value.map((item, itemIndex) => (
                        <li key={itemIndex} className={styles.apiResponseSectionListItem}>
                            {String(item)}
                        </li>
                    ))}
                </ul>
            </>
        )
    }

    const itemRef = useRef(null)

    useEffect(() => {
        if (findingsClickedId && findingsClickedId === itemRef.current?.uuid) {
            setIsAccordionExpanded(true)
            setTimeout(() => {
                itemRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                })
                dispatch(setFindingClickId(null))
            }, 500)
        }
    }, [findingsClickedId, dispatch, setFindingClickId])

    const renderItem = (name, value, key) => {
        const { color: marked, uuid } = isMarked(name, value)
        const itemClass = `${styles.apiResponseSectionListItemTitle} ${styles[marked]} ${
            marked ? "" : styles.hoverEffect
        }`

        return (
            <Box
                key={key}
                ref={itemRef}
                className={styles.apiResponseSectionListItem}
                style={{ whiteSpace: "pre-line" }}
            >
                <span
                    onClick={(event) =>
                        handleSectionItemlClick(name, value, event, isMarked(name, value))
                    }
                    onMouseEnter={(e) => handleHoverItem(e, uuid)}
                    onMouseLeave={(e) => handleHoverItem(e, uuid, true)}
                    className={itemClass}
                >
                    {name}
                </span>
                : {String(value)}
            </Box>
        )
    }

    const renderSectionAttributes = (sectionAttributes) => {
        if (!sectionAttributes) return null
        let moreInfo
        const elements = Object.entries(sectionAttributes).flatMap(([name, value]) => {
            if (name === "more_info") {
                moreInfo = (
                    <Box key={`${name}-more-info`}>
                        <Divider style={{ marginTop: "10px" }} />
                        <Box className={styles.apiResponseSectionMoreInfoTitle}>More Info</Box>
                        <Box>{renderAttributes(sectionAttributes.more_info.attributes)}</Box>
                    </Box>
                )
                return []
            }

            return (
                <Box key={name} className={styles.apiResponseSectionList}>
                    {Array.isArray(value) ? renderList(name, value) : renderItem(name, value)}
                </Box>
            )
        })

        if (moreInfo) elements.push(moreInfo)
        return elements
    }

    return (
        <Box className={styles.apiResponseSectionWrapper}>
            {section?.name && (
                <Box className={styles.apiResponseSectionName}>
                    {section?.profile_pic && (
                        <PresentPicture
                            src={section.profile_pic}
                            alt="profile"
                            className={styles.apiResponseSectionNameImage}
                        />
                    )}
                    {section.name}
                </Box>
            )}
            {section?.url && (
                <Box className={styles.apiResponseSectionLink}>
                    Link:
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.apiResponseSectionTitleLink}
                        href={section.url}
                    >
                        {section.url}
                    </a>
                </Box>
            )}
            {renderSectionAttributes(section?.attributes)}
            {section?.more_info_button_id && !section?.attributes?.more_info && (
                <LoadingButton
                    loading={isLoading}
                    disabled={isMoreInfoLoading}
                    loadingIndicator={
                        <CircularProgress style={{ width: "15px", height: "15px" }} />
                    }
                    onClick={() => getMoreInfoResults(section?.more_info_button_id)}
                    className={classes.moreInfoBtn}
                >
                    More info
                </LoadingButton>
            )}
        </Box>
    )
}
