import React from "react"
import styles from "./CustomPopOver.module.scss"

export function CustomPopOver({ isVisible, text, anchor }) {
    if (!isVisible || !anchor) {
        return null
    }

    const position = anchor.getBoundingClientRect()

    return (
        <div
            className={styles.customPopOverContainer}
            style={{
                top: `${position.bottom}px`,
                left: `${position.left}px`,
            }}
        >
            {text}
        </div>
    )
}
