export const blogArticles = [
    {
        img: "https://www.redstrings.io/wp-content/uploads/2024/01/3.png",
        title: "The Role of AI in Risk, Fraud, and Compliance Investigations",
        text: "AI in general and specifically Generative AI are becoming more and more mainstream, powerful and useful. Here’s our take on how can AI be used to assist Fraud fighters to be better at their jobs.",
        link: "https://www.redstrings.io/the-role-of-ai-in-risk-fraud-and-compliance-investigations/",
    },
    {
        img: "https://www.redstrings.io/wp-content/uploads/2024/01/Redstrings-Blog-Features-1.png",
        title: "Here’s what’s broken in Risk Ops",
        text: "A year long research process with the world’s best Risk, Fraud and Compliance experts. Here’s what we’ve learned.",
        link: "https://www.redstrings.io/heres-whats-broken-in-risk-ops/",
    },
    {
        img: "https://www.redstrings.io/wp-content/uploads/2024/01/4.png",
        title: "The Vital Role of Manual Reviews, Investigations, and Human Insights in Fraud Risk and Compliance",
        text: "In the age of rapid technological advancements and complex financial transactions, businesses face an ever-increasing threat of fraud. While automated systems and algorithms play a crucial role in detecting suspicious activities, the importance of manual reviews, investigations, and human insights cannot be overstated. In this article, we delve into the significance of these manual processes in mitigating fraud risk and ensuring compliance.",
        link: "https://www.redstrings.io/the-vital-role-of-manual-reviews-investigations-and-human-insights-in-fraud-risk-and-compliance/",
    },
    {
        img: "https://www.redstrings.io/wp-content/uploads/2024/01/1.png",
        title: "Streamlining Fraud Risk and Compliance Investigations: The Crucial Role of Integrated Tools",
        text: "In an era dominated by digital transactions and complex regulatory landscapes, organizations face an ever-increasing risk of fraud and non-compliance. To combat these threats effectively, it is paramount to have the right tools in place for investigating fraud risk and compliance cases. However, a significant challenge arises when multiple disparate tools and systems are employed, hindering the efficiency and effectiveness of these crucial processes. This article explores the importance of having integrated tools for investigations and the challenges associated with using multiple disjointed systems.",
        link: "https://www.redstrings.io/streamlining-fraud-risk-and-compliance-investigations-the-crucial-role-of-integrated-tools/",
    },
    {
        img: "https://www.redstrings.io/wp-content/uploads/2024/01/2.png",
        title: "Empowering Investigations: Introducing Innovative AI Capabilities to the Redstrings Platform",
        text: "Redstrings stands at the forefront of investigation technology, transforming the way risk, fraud, and compliance departments operate. Our mission is to elevate the entire investigative process, empowering professionals with sophisticated tools designed to optimize accuracy and efficiency. We are excited to announce the integration of AI capabilities into our platform, further enhancing our users’ experience and the platform’s effectiveness.",
        link: "https://www.redstrings.io/empowering-investigations-introducing-innovative-ai-capabilities-to-the-redstrings-platform/",
    },
]
