import { SET_REFRESH_ACCESS_TOKEN_COUNTER } from "../actions/types"

const initialState = 0

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_REFRESH_ACCESS_TOKEN_COUNTER:
            return action.payload
        default:
            return state
    }
}
