import React, { useEffect, useState } from "react"
import { Step } from "../Step/Step"
import { Link } from "react-router-dom"
import { Box, Accordion, AccordionSummary, Button } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useIsMobile } from "../../../../../../../../../../hooks/standart/useIsMobile"
import { TemplateDescription } from "../../../../../RepoElement/components/TemplateDescription/TemplateDescription"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { ButtonAddStep } from "./components/ButtonAddStep/ButtonAddStep"
import { reorderSteps } from "../../../../../../../../../../helpers/playbookHelpers"
import styles from "./Steps.module.scss"

export function Steps({
    steps,
    templateData,
    selectedStepData,
    handleSelectStep,
    selectedStepIndex,
    toolsDim,
    handleOpenSubscribeDialog,
    control,
    handleEditMode,
    editMode,
    setValue,
    setEditMode,
    isAllowToEdit,
    templateDataLoading,
    setToolsIdArray,
    toolsIdArray,
    toolsConfig,
    errors,
}) {
    const isMobile = useIsMobile()
    const [editingStepId, setEditingStepId] = useState(null)

    const onDragStart = (begin) => {
        if (!isAllowToEdit || !begin.source.index) return
        const index = begin.source.index
        handleSelectStep(index)
    }

    const onDragEnd = (result) => {
        if (!isAllowToEdit || !result.destination) {
            return
        }
        const newSteps = reorderSteps(steps, result.source.index, result.destination.index)
        handleSelectStep(result.destination.index)
        setEditMode(true)
        setValue("tools.ordered", newSteps, { shouldValidate: true })
    }

    const handleDeleteStep = (index) => {
        if (!isAllowToEdit) {
            return
        }
        setEditMode(true)
        const newSteps = [...steps]
        newSteps.splice(index, 1)
        setValue("tools.ordered", newSteps, { shouldValidate: true })
    }

    useEffect(() => {
        if (!editMode) {
            setEditingStepId(null)
        }
    }, [editMode])

    if (isMobile) {
        return (
            <>
                <Box className={styles.stepsTitle}>Playbook Steps</Box>
                <Box className={styles.stepsContainerMobile}>
                    <Box className={styles.stepsMainMobile}>
                        {steps.map((step, index) => (
                            <Accordion
                                style={{ borderRadius: "10px", cursor: "default" }}
                                className={styles.stepsAccordion}
                                key={`${index}${step.description}`}
                                onChange={(event, expanded) => {
                                    if (!expanded) {
                                        handleSelectStep(null)
                                    } else {
                                        handleSelectStep(index)
                                    }
                                }}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Step
                                        step={step}
                                        index={index}
                                        handleSelectStep={handleSelectStep}
                                        selectedStepIndex={selectedStepIndex}
                                        control={control}
                                        handleEditMode={handleEditMode}
                                        editMode={editMode}
                                        isAllowToEdit={isAllowToEdit}
                                        templateDataLoading={templateDataLoading}
                                        handleDeleteStep={handleDeleteStep}
                                        errors={errors}
                                    />
                                </AccordionSummary>
                                {index === selectedStepIndex && (
                                    <TemplateDescription
                                        toolsDim={toolsDim}
                                        templateData={templateData}
                                        selectedStepData={
                                            templateData?.tools?.ordered[selectedStepIndex]
                                        }
                                        selectedStepChildren={
                                            templateData?.tools?.ordered[selectedStepIndex]
                                                ?.children
                                        }
                                        selectedStepIndex={selectedStepIndex}
                                        setValue={setValue}
                                        setToolsIdArray={setToolsIdArray}
                                        toolsIdArray={toolsIdArray}
                                        toolsConfig={toolsConfig}
                                        setEditMode={setEditMode}
                                        editMode={editMode}
                                        isAllowToEdit={isAllowToEdit}
                                    />
                                )}
                            </Accordion>
                        ))}
                        <Box className={styles.stepsMobileButtonWrapper}>
                            <Link
                                to="https://www.linkedin.com/groups/9508939/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className={styles.stepsMobileButton}>
                                    Join our community
                                </Button>
                            </Link>
                            <Button
                                onClick={handleOpenSubscribeDialog}
                                className={styles.stepsMobileButton}
                            >
                                Subscribe
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </>
        )
    } else {
        return (
            <>
                <Box className={styles.stepsTitle}>Playbook Steps</Box>
                <Box className={styles.stepsContainer}>
                    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                        <Droppable droppableId="droppableSteps">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={styles.stepsMain}
                                >
                                    {steps.map((step, index) => (
                                        <Draggable
                                            key={step?.uniqId}
                                            draggableId={step?.uniqId}
                                            index={index}
                                            isDragDisabled={!isAllowToEdit || templateDataLoading}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Step
                                                        key={`${index}${step.description}`}
                                                        step={step}
                                                        index={index}
                                                        handleSelectStep={handleSelectStep}
                                                        selectedStepIndex={selectedStepIndex}
                                                        control={control}
                                                        handleEditMode={handleEditMode}
                                                        editMode={editMode}
                                                        isAllowToEdit={isAllowToEdit}
                                                        templateDataLoading={templateDataLoading}
                                                        handleDeleteStep={handleDeleteStep}
                                                        editingStepId={editingStepId}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    {isAllowToEdit && (
                                        <ButtonAddStep
                                            disabled={templateDataLoading}
                                            setValue={setValue}
                                            templateData={templateData}
                                            handleSelectStep={handleSelectStep}
                                            setEditMode={setEditMode}
                                            setEditingStepId={setEditingStepId}
                                        />
                                    )}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Box>
            </>
        )
    }
}
