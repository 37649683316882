import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Button from "@mui/material/Button"
import LoadingButton from "@mui/lab/LoadingButton"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { Divider, CircularProgress } from "@mui/material"
import { handleShowToaster } from "../../../../redux/actions/showToasterActions"
import { differenceBy } from "lodash"
import styles from "./NewToolDialog.module.scss"

export function NewToolDialog({
    handleAgree,
    handleAddTools,
    open,
    setClosed,
    index,
    index_x,
    index_y,
    toolType,
    setIsAccordionExpanded,
    logValues,
    values,
    doToolAction,
    tool_id,
    setValue,
    refreshPageData,
    request,
    isFromToolbox
}) {
    const dispatch = useDispatch()
    const { stepId } = useParams()
    const [isNewToolCreating, setIsNewToolCreating] = useState(false)
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const handleAddSimilarTool = async () => {
        const newToolLog = {
            request,
            auto_risk_level: "",
            risk_level: "",
            standard_response_risk_levels: [],
        }

        setIsNewToolCreating(true)
        try {
            const addToolResponse = await handleAddTools([toolType], index_x, index_y, stepId)
            const newTool = differenceBy(
                Object.values(addToolResponse),
                Object.values(values.tools),
                "id",
            )[0]
            const log = await doToolAction(
                values.case_id,
                newTool?.id,
                values.tools[tool_id].type,
                newToolLog,
                dispatch,
                refreshAccessTokenCounter,
            )
            setValue(`tools.${newTool?.id}.log.standard_response`, log["standard_response"] || {})
            await refreshPageData()
            dispatch(handleShowToaster("success", "Successfully add new tool"))
            setClosed()
            setIsAccordionExpanded(false)
        } catch (e) {
            console.log(e)
            dispatch(handleShowToaster("error", "Action failed"))
        } finally {
            setIsNewToolCreating(false)
        }
    }
    return (
        <Dialog
            open={open}
            onClose={setClosed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                className: styles.dialogWrapper,
            }}
        >
            <DialogTitle id="alert-dialog-title" fontWeight="400">
                Re-run this tool?
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
                <DialogContentText id="alert-dialog-description" fontSize="14px">
                    Performing this action will reset the current risk level and findings.
                </DialogContentText>
            </DialogContent>
            <Divider style={{ width: "90%", alignSelf: "center" }} />
            <DialogActions style={{ height: "60px" }}>
                <Button
                    disabled={isNewToolCreating}
                    onClick={setClosed}
                    className={styles.cancelButton}
                    autoFocus
                >
                    No
                </Button>
                {!isFromToolbox && (                
                    <LoadingButton
                        loading={isNewToolCreating}
                        loadingIndicator={
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        }
                        onClick={handleAddSimilarTool}
                        className={styles.searchButton}
                    >
                        Run in a new instance
                    </LoadingButton>
                )}
                <Button
                    disabled={isNewToolCreating}
                    onClick={handleAgree}
                    className={styles.similarToolButton}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}
