import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { ColorButtonLogin } from "../../components/StyledComponents/Buttons"
import { useLocation } from "react-router-dom"

export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0()
    const location = useLocation()
    return (
        <ColorButtonLogin
            text="Log In"
            onClick={() =>
                loginWithRedirect({
                    appState: {
                        returnTo: location,
                    },
                })
            }
        />
    )
}

export const LoginLinkContainerAuth0 = () => {
    const { user, isAuthenticated } = useAuth0()
    if (isAuthenticated)
        return (
            // <Link to="/profile/overview">
            <img
                src={user.picture}
                alt={user.name}
                style={{
                    height: "35px",
                    borderRadius: "50%",
                    display: "block",
                }}
            />
            // </Link>
        )
    else return <LoginButton />
}
