import React, { useState } from "react"
import { Box, Typography, Button, Tab, Tabs } from "@mui/material"
import reportIcon from "../../assets/icons/report.svg"
import styles from "./Dashboard.module.scss"
import { Operations } from "./components/Operations/Operations"
import { Fraud } from "./components/Fraud/Fraud.js"
import { Compliance } from "./components/Compliance/Compliance"

export function Dashboard() {
    const [selectedTab, setSelectedTab] = useState(0)

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    return (
        <Box className={styles.dashboardContainer}>
            <Box className={styles.dashboardHeaderWrapper}>
                <Typography fontSize="24px" color="#000">
                    Dashboard
                </Typography>
                <Button className={styles.dashboardHeaderButton}>
                    <img src={reportIcon}></img>Export PDF
                </Button>
            </Box>
            <Box className={styles.dashboardTabWrapper}>
                <Tabs value={selectedTab} onChange={handleTabChange} textColor="#000" centered>
                    <Tab className={styles.dashboardTab} label="Fraud" />
                    <Tab className={styles.dashboardTab} label="Compliance" />
                    <Tab className={styles.dashboardTab} label="Operations" />
                </Tabs>
            </Box>

            {selectedTab === 0 && <Fraud />}
            {selectedTab === 1 && <Compliance />}
            {selectedTab === 2 && <Operations />}
        </Box>
    )
}
