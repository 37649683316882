import React, { useState, useRef, useEffect } from "react"
import { Box, TextField, InputAdornment } from "@mui/material"
import redFlagIcon from "../../../../../../../../../../assets/icons/Repo/red-flag-icon.svg"
import { AddNewButton } from "../AddNewButton/AddNewButton"
import CheckIcon from "@mui/icons-material/Check"
import styles from "../../TemplateDescription.module.scss"

export function RedFlags({
    redFlags,
    isAllowToEdit,
    setValue,
    templateData,
    selectedStepIndex,
    setEditMode,
}) {
    const [isEdit, setIsEdit] = useState(false)
    const [newFlag, setNewFlag] = useState("")
    const [error, setError] = useState(false)

    const textFieldRef = useRef(null)
    const componentRef = useRef(null)

    const handleEdit = (value) => {
        setIsEdit(value)
        if (!value) {
            setError(false)
        }
    }

    const handleInputChange = (e) => {
        const inputValue = e.target.value.trim()
        setNewFlag(inputValue)

        if (inputValue.length < 5) {
            setError("Please enter minimum 5 symbols")
        } else {
            setError(false)
        }
    }

    const handleAddRedflag = () => {
        if (newFlag.length >= 5) {
            const currentRedFlags = templateData.tools.ordered[selectedStepIndex].red_flags || []

            const newRedflags = [...currentRedFlags, newFlag]

            setValue(`tools.ordered[${selectedStepIndex}].red_flags`, newRedflags)

            setNewFlag("")
            handleEdit(false)
            setEditMode(true)
        } else {
            setError("Please enter minimum 5 symbols")
        }
    }

    const handleDeleteRedflag = (index) => {
        if (!isAllowToEdit || !templateData.tools.ordered[selectedStepIndex].red_flags) return
        const newRedflags = [...templateData.tools.ordered[selectedStepIndex].red_flags]
        newRedflags.splice(index, 1)
        setValue(`tools.ordered[${selectedStepIndex}].red_flags`, newRedflags)
        setEditMode(true)
    }

    useEffect(() => {
        if (isEdit) {
            textFieldRef.current?.focus()

            const handleClickOutside = (event) => {
                if (componentRef.current && !componentRef.current.contains(event.target)) {
                    setIsEdit(false)
                }
            }

            document.addEventListener("mousedown", handleClickOutside)

            return () => {
                document.removeEventListener("mousedown", handleClickOutside)
            }
        }
    }, [isEdit])

    return (
        <Box ref={componentRef} className={styles.templateDescriptionDesignationsRedFlag}>
            <Box className={styles.templateDescriptionDesignationsRedFlagTitle}>
                <img src={redFlagIcon} />
                Red Flags:
            </Box>
            <ul className={styles.templateDescriptionDesignationsRedFlagList}>
                {redFlags?.map((flag, index) => (
                    <li
                        key={`${flag}${index}`}
                        className={styles.templateDescriptionDesignationsRedFlagListItem}
                    >
                        {flag}
                        {isAllowToEdit && (
                            <div
                                className={styles.templateDescriptionDesignationsMethodDeleteIcon}
                                onClick={() => handleDeleteRedflag(index)}
                            ></div>
                        )}
                    </li>
                ))}
            </ul>
            {isAllowToEdit && (
                <>
                    {isEdit ? (
                        <TextField
                            onChange={handleInputChange}
                            inputRef={textFieldRef}
                            margin="normal"
                            variant="outlined"
                            label={"Red Flags"}
                            InputProps={{
                                className: styles.input,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CheckIcon
                                            onClick={handleAddRedflag}
                                            style={{
                                                cursor: "pointer",
                                                color: !error && newFlag ? "6d5afe" : "",
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            error={!!error}
                            helperText={error}
                        />
                    ) : (
                        <AddNewButton action={handleEdit} title="Red Flag" />
                    )}
                </>
            )}
        </Box>
    )
}
