import React, { useEffect, useState, useRef, useMemo } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import makeStyles from "@mui/styles/makeStyles"
import AppBar from "@mui/material/AppBar"
import { changeSidebarState } from "../../redux/actions/sidebarActions"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { resolutions } from "../../constants/appConstants"
import { NotebookHeader } from "./components/NotebookHeader"
import DefaultHeader from "./components/DefaultHeader"
import { CalendlyModal } from "./components/CalendlyModal/CalendlyModal"
import styles from "./Topbar.module.scss"
import { PlaybookModal } from "../PlaybookModal/PlaybookModal"
import { set } from "lodash"

const useStyles = makeStyles(() => ({
    customInput: {
        "& .MuiInputBase-input": {
            borderRadius: 20,
            width: "100px",
            position: "relative",
            backgroundColor: "#000000",
            fontSize: 16,
            color: "#FFFFFF",
            fontWeight: "500",
            textAlign: "center",
            padding: "10px 26px 10px 12px",
            "&:focus": {
                borderRadius: 20,
                borderColor: "#80bdff",
            },
        },
        "&::before": { display: "none" },
        "&::after": { display: "none" },
        "& .MuiSvgIcon-root": {
            color: "#FFFFFF",
        },
    },
    input: {
        height: "100%",
        width: "100%",
        border: "0",
        backgroundColor: "#000000",
        borderRadius: 20,
    },
}))

export default function Topbar({
    caseTitle,
    control,
    setValue,
    updateCaseInfoMap,
    caseType,
    values,
    steps,
    isDataLoading,
    caseTypes = [],
    isFromToolbox = false,
}) {
    const { user } = useAuth0()
    const classes = useStyles()
    const dispatch = useDispatch()
    const location = useLocation()
    const sidebarRef = useRef()

    const [inNotebook, setInNotebook] = useState(false)
    const [pendingStepsLength, setPendingStepsLength] = useState([])
    const [caseStatuses, setCaseStatuses] = useState([])
    const [openCalendlyModal, setOpenCalendlyModal] = useState(false)
    const [openPlaybookModal, setOpenPlaybookModal] = useState(false)
    const [loadingCalendly, setLoadingCalendly] = useState(true)

    const sidebarState = useSelector((state) => state.sidebarState)
    const currentResolutions = resolutions(caseType)

    const mock = useMemo(() => {
        return values?.mock
    }, [values?.mock])

    useEffect(() => {
        if (steps?.length > 0) {
            setPendingStepsLength(steps.filter((step) => step?.log?.status !== "Completed").length)
        }

        if (currentResolutions) {
            setCaseStatuses([
                { value: 0, title: "New" },
                { value: 1, title: "In Progress" },
                { value: 2, title: currentResolutions.good },
                { value: 3, title: currentResolutions.bad },
            ])
        }
    }, [steps])

    const caseId = parseInt(location.pathname.replace("/", ""))

    useEffect(() => {
        if (caseId) {
            setInNotebook(true)
        } else {
            setInNotebook(false)
        }
    }, [location])

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (sidebarState && !sidebarRef.current?.contains(event.target)) {
                dispatch(changeSidebarState(false))
            }
        }

        document.body.addEventListener("click", handleDocumentClick)

        return () => {
            document.body.removeEventListener("click", handleDocumentClick)
        }
    }, [sidebarState, dispatch])

    // Calendly handlers
    const handleOpenCalendly = () => {
        setOpenCalendlyModal(true)
    }

    const handleCloseCalendly = () => {
        setOpenCalendlyModal(false)
        setLoadingCalendly(true)
    }

    const handleIframeLoad = () => {
        setLoadingCalendly(false)
    }

    // Playbook handlers
    const handleOpenPlaybook = () => {
        setOpenPlaybookModal(true)
    }

    const handleClosePlaybook = () => {
        setOpenPlaybookModal(false)
    }

    return (
        <AppBar elevation={2} className={styles.topBarContainer}>
            {inNotebook ? (
                <NotebookHeader
                    classes={classes}
                    sidebarRef={sidebarRef}
                    mock={mock}
                    caseId={caseId}
                    control={control}
                    caseTitle={caseTitle}
                    handleOpenCalendly={handleOpenCalendly}
                    handleOpenPlaybook={handleOpenPlaybook}
                    steps={steps}
                    pendingStepsLength={pendingStepsLength}
                    isDataLoading={isDataLoading}
                    currentResolutions={currentResolutions}
                    sidebarState={sidebarState}
                    changeSidebarState={changeSidebarState}
                    setValue={setValue}
                    caseStatuses={caseStatuses}
                    updateCaseInfoMap={updateCaseInfoMap}
                    isFromToolbox={isFromToolbox}
                />
            ) : (
                <DefaultHeader classes={classes} user={user} />
            )}
            <CalendlyModal
                open={openCalendlyModal}
                onClose={handleCloseCalendly}
                loading={loadingCalendly}
                handleIframeLoad={handleIframeLoad}
            />
            <PlaybookModal
                open={openPlaybookModal}
                onClose={handleClosePlaybook}
                values={values}
                caseTypes={caseTypes}
            />
        </AppBar>
    )
}
