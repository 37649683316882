import React from "react"
import { Box } from "@mui/material"
import styles from "./DiscoveryEmpty.module.scss"

export function DiscoveryEmpty() {
    return (
        <Box className={styles.discoveryEmptyContainer}>
            <Box className={styles.discoveryEmptyText}>
                <Box className={styles.discoveryEmptyTextTitle}>Risk Signals Discovery</Box>
                <Box className={styles.discoveryEmptyTextSubTitle}>
                    No Risk signals where found for this case
                </Box>
            </Box>
        </Box>
    )
}
