import React, { useState, useEffect } from "react"
import { alpha, styled } from "@mui/material/styles"
import { DataGrid, gridClasses } from "@mui/x-data-grid"
import { Typography, Box, Pagination, TablePagination } from "@mui/material"

function generateRandom() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = ""
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
    }
    return retVal
}

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: "rgb(249 249 249)",
        "&:hover, &.Mui-hovered": {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            "@media (hover: none)": {
                backgroundColor: "transparent",
            },
        },
        "&.Mui-selected": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
    ["& .MuiDataGrid-columnHeaders"]: {
        backgroundColor: "rgb(249 249 249)",
        "&:hover, &.Mui-hovered": {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            "@media (hover: none)": {
                backgroundColor: "transparent",
            },
        },
        "&.Mui-selected": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
    ["& 	.MuiDataGrid-paper"]: {
        boxShadow: "none",
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: "rgb(243 243 243)",
        "&:hover, &.Mui-hovered": {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            "@media (hover: none)": {
                backgroundColor: "transparent",
            },
        },
        "&.Mui-selected": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
    ["& .MuiDataGrid-virtualScroller::-webkit-scrollbar"]: {
        width: "1px",
        height: 6,
    },
    ["& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track"]: {
        background: "#F3F3F3",
    },
    ["& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb"]: {
        backgroundColor: "#C2C2C2",
    },
    ["& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover"]: {
        background: "#949494",
    },
}))

export default function DataTable({ data, rowSelectionModel, setRowSelectionModel }) {
    const [columns, setColumns] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [page, setPage] = useState(0)
    const paginationModel = { page: page, pageSize: rowsPerPage, rowCount: data?.length }

    const getRowId = (row) => {
        return row?.id || generateRandom()
    }

    const [dataWithID, setDataWithID] = useState(null)
    const [dataMessage, setDataMessage] = useState(null)

    useEffect(() => {
        if (Array.isArray(data)) {
            setDataMessage(null)
            if (data.length > 0) {
                if (data[0]["id"] != undefined) {
                    setDataWithID(data)
                } else {
                    setDataWithID(data.map((row, index) => ({ id: index, ...row })))
                }
            }
        } else if (typeof data === "string") {
            setDataWithID(null)
            setDataMessage(data)
        }
    }, [data])

    useEffect(() => {
        if (dataWithID && dataWithID?.length > 0) {
            setColumns(
                Object.keys(dataWithID[0])?.map((key) => {
                    let ret = {
                        field: key,
                        headerName: key,
                        headerClassName: "super-app-theme--header",
                    }
                    if (key === "id") {
                        ret["width"] = 70
                    }
                    if (key === "Date") {
                        ret["width"] = 120
                        ret["renderCell"] = (cellValues) => {
                            return (
                                <Box>
                                    <div>{cellValues ? cellValues?.value?.split(" ")[0] : " "}</div>
                                    <div>{cellValues ? cellValues?.value?.split(" ")[1] : " "}</div>
                                </Box>
                            )
                        }
                    } else {
                        ret["width"] = 180
                    }
                    return ret
                }),
            )
        }
    }, [dataWithID])
    const handleChangePage = (event, newPage) => {
        setPage(newPage - 1)
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }
    const CustomPagination = () => {
        return (
            <>
                <Typography sx={{ fontSize: "14px" }}>
                    Showing {page * rowsPerPage + 1} to {rowsPerPage * (page + 1)} of{" "}
                    {dataWithID?.length} entries
                </Typography>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={dataWithID?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ ".MuiTablePagination-actions": { display: "none" } }}
                />
                <Pagination
                    shape="rounded"
                    count={Math.ceil(dataWithID?.length / rowsPerPage)}
                    onChange={handleChangePage}
                    sx={{
                        ".MuiPaginationItem-previousNext": {
                            backgroundColor: "#333333",
                            color: "white",
                            borderRadius: "5px",
                        },
                    }}
                />
            </>
        )
    }

    return (
        <Box
            sx={{
                minHeight: "max-content",
                maxHeight: "max-content",
                width: "100%",
                "& .super-app-theme--header": {
                    fontWeight: 100,
                    fontSize: "1.2em",
                },
                backgroundColor: "#FFF",
            }}
        >
            {dataWithID && dataWithID?.length > 0 ? (
                <StripedDataGrid
                    rows={dataWithID}
                    columns={columns}
                    columnVisibilityModel={{
                        // Hide column "id", the other columns will remain visible
                        id: false,
                    }}
                    columnHeaderHeight={80}
                    components={{
                        Pagination: CustomPagination,
                    }}
                    paginationModel={paginationModel}
                    autoHeight
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                    }
                    initialState={{
                        ...(dataWithID?.initialState || {}),
                        pagination: {
                            paginationModel: { pageSize: rowsPerPage },
                        },
                    }}
                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel)
                    }}
                    rowSelectionModel={rowSelectionModel}
                />
            ) : dataMessage ? (
                <div
                    style={{ backgroundColor: "#f7f8fd" }}
                    dangerouslySetInnerHTML={{ __html: dataMessage }}
                ></div>
            ) : (
                <Typography
                    color="black"
                    variant="h6"
                    fontWeight="400"
                    style={{ display: "inline" }}
                >
                    Did not find results
                </Typography>
            )}
        </Box>
    )
}
