import { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    getRunners,
    createNewRunner,
    deleteRunner,
    updateRunner,
} from "../../services/admin/adminServices"
import { handleShowToaster } from "../../redux/actions/showToasterActions"

export function useRunners(refreshAccessTokenCounter) {
    const [runners, setRunners] = useState([])
    const [loadingRunners, setLoadingRunners] = useState(false)
    const dispatch = useDispatch()

    const fetchRunners = useCallback(async () => {
        setLoadingRunners(true)
        try {
            const response = await getRunners(dispatch, refreshAccessTokenCounter)
            if (response) {
                const sortedResponse = response.sort((a, b) => a.id - b.id)
                setRunners(sortedResponse)
            }
        } catch (error) {
            console.log(error)
            dispatch(handleShowToaster("error", "Action failed during getting runners"))
        } finally {
            setLoadingRunners(false)
        }
    }, [])

    const handleCreateNewRunner = useCallback(
        async (name, description, refreshAccessTokenCounter) => {
            try {
                const response = await createNewRunner(
                    name,
                    description,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                dispatch(handleShowToaster("success", "Successfully created new runner"))
                await fetchRunners()
                return response
            } catch (error) {
                console.log(error?.response?.data?.error || error)
                dispatch(
                    handleShowToaster("error", error?.response?.data?.error || "Action failed"),
                )
            }
        },
        [],
    )

    const handleDeleteRunner = useCallback(async (id, refreshAccessTokenCounter) => {
        try {
            const response = await deleteRunner(id, dispatch, refreshAccessTokenCounter)
            dispatch(handleShowToaster("success", `Successfully delete runner ${id}`))
            await fetchRunners()
            return response
        } catch (error) {
            console.log(error?.response?.data?.error || error)
            dispatch(handleShowToaster("error", error?.response?.data?.error || "Action failed"))
        }
    }, [])

    const handleUpdateRunner = useCallback(
        async (id, name, description, refreshAccessTokenCounter) => {
            try {
                const response = await updateRunner(
                    id,
                    name,
                    description,
                    dispatch,
                    refreshAccessTokenCounter,
                )
                dispatch(handleShowToaster("success", "Successfully updated runner"))
                await fetchRunners()
                return response
            } catch (error) {
                console.log(error?.response?.data?.error || error)
                dispatch(
                    handleShowToaster("error", error?.response?.data?.error || "Action failed"),
                )
            }
        },
        [],
    )

    useEffect(() => {
        fetchRunners()
    }, [dispatch])

    return {
        runners,
        loadingRunners,
        handleCreateNewRunner,
        handleDeleteRunner,
        handleUpdateRunner,
    }
}
