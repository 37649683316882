import React from "react"
import { Box, TextField, Select, MenuItem, Typography } from "@mui/material"
import { MetricsCard } from "./components/MetricsCard/MetricsCard"
import styles from "./Fraud.module.scss"
import { RiskStrategies } from "./components/RiskStrategies/RiskStrategies"

export function Fraud() {
    const filterOprions = ["Last 7 days", "Last month"]

    const metricsData = [
        { mainValue: "12.65%", indicator: 30, indicatorName: "FP Rate", variant: "danger" },
        { mainValue: "$123K", indicator: 30, indicatorName: "Fraud Loss", variant: "danger" },
        { mainValue: "$2K", indicator: 30, indicatorName: "Cost", variant: "danger" },
        { mainValue: "10m 3s", indicator: 30, indicatorName: "MTTR", variant: "danger" },
    ]

    const rows = [
        {
            name: "New Account Fraud",
            maturity: "AI Agent",
            maturityColor: "#299455",
            fpRate: "0.04%",
            fraudLoss: "$0",
            cost: "$1.2K",
            mttr: "1m",
        },
        {
            name: "Rogue CX Agents",
            maturity: "Automated",
            maturityColor: "#BE984F",
            fpRate: "0.10%",
            fraudLoss: "$101K",
            cost: "$300",
            mttr: "2m 55s",
        },
        {
            name: "Account Takeover",
            maturity: "Manual",
            maturityColor: "#BE4F4F",
            fpRate: "0.14%",
            fraudLoss: "$22K",
            cost: "$500",
            mttr: "1h 2m 2s",
        },
    ]

    const columns = [
        { id: "name", label: "" },
        { id: "maturity", label: "Maturity" },
        { id: "fpRate", label: "FP Rate" },
        { id: "fraudLoss", label: "Fraud Loss" },
        { id: "cost", label: "Cost" },
        { id: "mttr", label: "MTTR" },
    ]

    return (
        <Box className={styles.fraudContainer}>
            <Box className={styles.fraudHeader}>
                <Typography fontSize="18px">Global Metrics</Typography>
                <Box className={styles.fraudHeaderFilters}>
                    Filters:
                    <TextField
                        select
                        variant="outlined"
                        InputProps={{
                            className: styles.fraudInput,
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                color: "#6B869A",
                            },
                        }}
                        fullWidth
                        defaultValue={filterOprions[0]}
                    >
                        {filterOprions.map((option) => {
                            return (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </Box>
            </Box>
            <Box className={styles.fraudMetricsWrapper}>
                {metricsData.map((data, index) => (
                    <MetricsCard key={index} {...data} />
                ))}
            </Box>
            <Box>
                <RiskStrategies rows={rows} columns={columns} />
            </Box>
        </Box>
    )
}
