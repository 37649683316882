import axios from "../../utils/axiosConfig"
import { setRefreshAccessTokenCounter } from "../../redux/actions/refreshAccessTokenCounterActions"
import ENUMS from "../../constants/appEnums"

export const uploadToS3 = async (
    file_class,
    file,
    file_name,
    public_file,
    dispatch,
    refreshAccessTokenCounter,
) => {
    let url = ""
    // get from server side the s3 url to send the file to
    try {
        url = await axios.post(
            `${process.env.REACT_APP_SERVER_API_ADDRESS}${ENUMS.API_ROUTES.GET_PRE_SIGNED_URL_TO_UPLOAD_FILE}`,
            {
                file_class: file_class,
                file_type: file.type,
                file_name: file_name ? file_name : file.name,
                public: public_file ? true : false,
            },
        )
        url = url.data
        // send the file to s3
        const instance = axios.create()
        instance.defaults.headers.common = {}
        const pre_signed_headers = public_file
            ? {
                  "content-type": file.type,
                  "x-amz-acl": "public-read",
              }
            : {
                  "content-type": file.type,
              }
        const response = await instance.put(url, file, {
            headers: pre_signed_headers,
        })
        return response
    } catch (err) {
        throw err
    } finally {
        dispatch(setRefreshAccessTokenCounter(refreshAccessTokenCounter + 1))
    }
}

export const uploadToS3Json = async (file) => {
    let url = ""
    let json_file_name = ""
    // get from server side the s3 url to send the file to
    try {
        let res = await axios.post(
            `${process.env.REACT_APP_SERVER_API_ADDRESS}${ENUMS.API_ROUTES.GET_PRE_SIGNED_URL_TO_UPLOAD_FILE_JSON}`,
            {
                file_type: file.type,
                file_name: file.name,
                file_path: file.path,
            },
        )
        url = res.data.pre_signed_url
        json_file_name = res.data.file_name
    } catch (err) {
        // console.log(err)
    }
    // send the file to s3
    try {
        const instance = axios.create()
        instance.defaults.headers.common = {}
        const response = await instance.put(url, file, {
            headers: {
                "content-type": file.type,
            },
        })
        return json_file_name
    } catch (err) {
        // console.log(err)
    }
}

export const createAndUploadToS3 = async (
    user_email,
    file_class,
    work_item_id,
    solution_id,
    file_name,
    file_content_in_string,
) => {
    try {
        await axios.post(
            `${process.env.REACT_APP_SERVER_API_ADDRESS}${ENUMS.API_ROUTES.CREATE_AND_UPLOAD_FILE}`,
            {
                user_email,
                file_class,
                work_item_id,
                solution_id, // if needed
                file_name,
                file_content_in_string,
            },
        )
    } catch (err) {
        throw err
    }
}
