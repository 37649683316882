import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUserPlaybooks } from "../../services/playbook/playbookServices"
import moment from "moment"

export function useUserPlaybooks(refreshAccessTokenCounter) {
    const [playbooks, setPlaybooks] = useState([])
    const [recentlyGeneratedPlaybooks, setRecentlyGeneratedPlaybooks] = useState([])
    const [loadingPlaybooks, setLoadingPlaybooks] = useState(false)
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const dispatch = useDispatch()

    const fetchUserPlaybooks = async () => {
        setLoadingPlaybooks(true)
        if (isAuthorized) {
            try {
                const response = await getUserPlaybooks(dispatch, refreshAccessTokenCounter)
                if (response && Array.isArray(response) && response.length > 0) {
                    const sortedPlaybooks = response.sort((a, b) =>
                        moment(b.created_at).diff(moment(a.created_at)),
                    )
                    setPlaybooks(sortedPlaybooks)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoadingPlaybooks(false)
            }
        }
    }

    useEffect(() => {
        if (playbooks.length > 0) {
            const lastWeek = moment().subtract(7, "days")
            const recentlyGenerated = playbooks.filter((playbook) =>
                moment(playbook.created_at).isAfter(lastWeek),
            )
            setRecentlyGeneratedPlaybooks(recentlyGenerated)
        }
    }, [playbooks])

    useEffect(() => {
        fetchUserPlaybooks()
    }, [dispatch, refreshAccessTokenCounter, isAuthorized])

    return {
        playbooks,
        loadingPlaybooks,
        fetchUserPlaybooks,
        setPlaybooks,
        recentlyGeneratedPlaybooks,
    }
}
