import React, { useState } from "react"
import { Box, CircularProgress } from "@mui/material"
import styles from "./RepoMainUserPlaybooksList.module.scss"
import { RepoMainUserPlaybooksListHeader } from "./components/RepoMainUserPlaybooksListHeader/RepoMainUserPlaybooksListHeader"
import { PlaybooksList } from "./components/PlaybooksList/PlaybooksList"
import { PaginationComponent } from "../../../../../../components/PaginationComponent/PaginationComponent"
import { PlaybooksListEmpty } from "./components/PlaybooksListEmpty/PlaybooksListEmpty"

export function RepoMainUserPlaybooksList({
    playbooks,
    recentlyGeneratedPlaybooks,
    loadingPlaybooks,
    setPlaybooks,
    fetchUserPlaybooks,
    generateInputRef = null,
}) {
    const [searchQuery, setSearchQuery] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [activeTab, setActiveTab] = useState(0)

    const myPlaybooksColumns = [
        { name: "Title", width: "60%" },
        { name: "Category", width: "40%" },
        { name: "Date", width: "20%" },
    ]
    const recentlyGeneratedPlaybooksColumns = [
        { name: "Title", width: "60%" },
        { name: "Prompt", width: "50%" },
    ]
    // If more than 1 page make a search visible
    const itemsPerPage = 7

    const filteredPlaybooks = playbooks.filter(
        (playbook) =>
            playbook?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            playbook?.case_type?.toLowerCase().includes(searchQuery.toLowerCase()),
    )

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
    }

    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const currentPagePlaybooks = filteredPlaybooks.slice(startIndex, endIndex)

    return (
        <div>
            <h1 className={styles.repoMainUserPlaybooksListTitle}>My Playbooks Repository</h1>
            {loadingPlaybooks ? (
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    height={"calc(100vh - 370px)"}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Box display="flex" flexDirection={"column"}>
                        <RepoMainUserPlaybooksListHeader
                            fetchUserPlaybooks={fetchUserPlaybooks}
                            myPlaybookQuantity={filteredPlaybooks?.length}
                            recentlyGeneratedPlaybooksQuantity={recentlyGeneratedPlaybooks.length}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            generateInputRef={generateInputRef}
                            showRecentlyGenerated={playbooks.length > 0}
                        />
                        {playbooks.length === 0 ? (
                            <PlaybooksListEmpty
                                fetchUserPlaybooks={fetchUserPlaybooks}
                                generateInputRef={generateInputRef}
                            />
                        ) : (
                            <>
                                {activeTab === 0 && (
                                    <>
                                        <PlaybooksList
                                            playbooks={currentPagePlaybooks}
                                            setPlaybooks={setPlaybooks}
                                            columns={myPlaybooksColumns}
                                        />
                                        {filteredPlaybooks.length > 7 && (
                                            <PaginationComponent
                                                count={Math.ceil(
                                                    filteredPlaybooks.length / itemsPerPage,
                                                )}
                                                page={currentPage}
                                                onChange={handleChangePage}
                                            />
                                        )}
                                    </>
                                )}
                                {activeTab === 1 && (
                                    <>
                                        <PlaybooksList
                                            playbooks={recentlyGeneratedPlaybooks}
                                            setPlaybooks={setPlaybooks}
                                            columns={recentlyGeneratedPlaybooksColumns}
                                            showPrompt={true}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </>
            )}
        </div>
    )
}
