import React from "react"
import { Pagination, Box } from "@mui/material"

export function PaginationComponent({ count, page, onChange }) {
    return (
        <Box mt={"12px"}>
            <Pagination count={count} page={page} onChange={onChange} />
        </Box>
    )
}
