import React, { useState, useRef, useEffect } from "react"
import { Box, CircularProgress } from "@mui/material"
import moment from "moment/moment"
import styles from "./RepoMainUserPlaybooksListCard.module.scss"
import dots from "../../../../../../../../assets/icons/Repo/greyDots.svg"
import { Link } from "react-router-dom"
import { deleteUserPlaybook } from "../../../../../../../../services/playbook/playbookServices"
import { useDispatch, useSelector } from "react-redux"
import { handleShowToaster } from "../../../../../../../../redux/actions/showToasterActions"
import { RemoveDialog } from "../../../../../../../Notebook/components/RemoveDialog/RemoveDialog"

export function RepoMainUserPlaybooksListCard({ playbook, setPlaybooks, showPrompt }) {
    const [isLoading, setIsLoading] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [removetoolDialogOpen, setRemovetoolDialogOpen] = useState(false)
    const optionsRef = useRef(null)
    const dotsRef = useRef(null)
    const dispatch = useDispatch()

    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)

    const { name, case_type, created_at, uuid, prompt } = playbook

    const handleDotsClick = () => {
        setShowOptions(!showOptions)
    }
    const handleClickOutside = (event) => {
        const clickedOutsideOptions = optionsRef.current?.contains(event.target)
        const clickedOutsideDots = dotsRef.current?.contains(event.target)

        if (!clickedOutsideOptions && !clickedOutsideDots) {
            setShowOptions(false)
        }
    }

    const handleDeleteTemplate = async (uuid) => {
        if (uuid) {
            setRemovetoolDialogOpen(false)
            setIsLoading(true)
            try {
                setShowOptions(false)
                const result = await deleteUserPlaybook(uuid, dispatch, refreshAccessTokenCounter)
                if (result && result.length > 0) {
                    setPlaybooks(
                        result.sort((a, b) => {
                            return new Date(b.created_at) - new Date(a.created_at)
                        }),
                    )
                }
                dispatch(handleShowToaster("success", "Successfully delete a playbook"))
            } catch (error) {
                console.error(error)
                dispatch(handleShowToaster("error", "Action failed"))
            } finally {
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return (
        <Box className={styles.repoMainUserPlaybooksListCardWrapper}>
            <Box className={styles.repoMainUserPlaybooksListCardContent}>
                {name && <Link to={`/my-playbooks/${uuid}`}>{name}</Link>}
                {showPrompt ? (
                    <span
                        style={{
                            width: "50%",
                            maxWidth: "700px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {prompt}
                    </span>
                ) : (
                    <>
                        <span style={{ width: "40%" }}>{case_type}</span>
                        {created_at && (
                            <span style={{ width: "20%", fontWeight: 400 }}>
                                {moment(created_at).format("DD/MM/YYYY")}
                            </span>
                        )}
                    </>
                )}
            </Box>
            {isLoading ? (
                <CircularProgress style={{ width: "20px", height: "20px" }} />
            ) : (
                <Box
                    ref={dotsRef}
                    className={styles.repoMainUserPlaybooksListCardDotsWrapper}
                    onClick={handleDotsClick}
                >
                    <img src={dots} />
                </Box>
            )}

            {showOptions && (
                <Box ref={optionsRef} className={styles.repoMainUserPlaybooksListCardOptions}>
                    <span
                        className={styles.repoMainUserPlaybooksListCardOptionsItem}
                        onClick={() => setRemovetoolDialogOpen(true)}
                    >
                        <Box className={styles.repoMainUserPlaybooksListCardOptionsIconEmpty} />
                        Delete
                    </span>
                </Box>
            )}
            <RemoveDialog
                open={removetoolDialogOpen}
                setClosed={() => setRemovetoolDialogOpen(false)}
                handleAgree={() => handleDeleteTemplate(uuid)}
                name="playbook"
            />
        </Box>
    )
}
