import React, { useState, useEffect } from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { RISK_LEVELS } from "../../../../../constants/appConstants"
import { CaseReportStepSection } from "./CaseReportStepSection"
import { CaseReportStepAttribute } from "./CaseReportStepAttribute"
import { calculateAverageRiskLevel } from "../../../../../helpers/notebookHelpers"

export function CaseReportStepTool({ tool, markers, values }) {
    if (!tool) return null
    const { log, id } = tool
    const standard_response = log?.standard_response
    const sections = standard_response?.sections
    const attributes = standard_response?.attributes

    const [autoCalculatedRiskLevel, setAutoRiskLevel] = useState()
    const [riskLevel, setRiskLevel] = useState(log?.risk_level || log?.auto_risk_level)

    useEffect(() => {
        if (markers.length > 0) setAutoRiskLevel(calculateAverageRiskLevel(markers))
    }, [markers])

    useEffect(() => {
        setRiskLevel(log?.risk_level || log?.auto_risk_level)
    }, [log?.risk_level, log?.auto_risk_level])

    const riskLevelFiltered = RISK_LEVELS.find(
        (risk) => risk.title === riskLevel || risk.value === autoCalculatedRiskLevel,
    )

    const styles = StyleSheet.create({
        sectionStep: {
            display: "flex",
            flexDirection: "column",
            gap: "6px",
        },
        toolTitle: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            fontSize: "14px",
            fontWeight: 600,
            width: "100%",
        },
        toolTitleText: {
            fontWeight: 500,
        },
        riskNoteWrapper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "7px 12px",
            borderRadius: "8px",
            border: "1px solid #EFEEF1",
            backgroundColor: "#F4F5FF",
            fontSize: "12px",
            fontWeight: 500,
            color: "#262626",
        },
        stepRiskLevel: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "24px",
            height: "20px",
            borderRadius: "4px",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 600,
            backgroundColor: riskLevelFiltered?.color,
        },
        stepSections: {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
        },
    })
    if (!sections && !attributes) return null
    return (
        <View style={styles.sectionStep}>
            <View style={styles.toolTitle}>
                <Text style={styles.toolTitleText}>{tool.title}</Text>
                {riskLevelFiltered?.value > 0 && (
                    <View style={styles.stepRiskLevel}>
                        <Text>{riskLevelFiltered?.value}</Text>
                    </View>
                )}
            </View>
            {log.risk_note && (
                <Text style={styles.riskNoteWrapper}>
                    <Text>Risk Note: </Text>
                    <Text>{log.risk_note}</Text>
                </Text>
            )}
            <View style={styles.stepSections}>
                {sections &&
                    sections?.map((section, index) => (
                        <CaseReportStepSection
                            key={index}
                            section={section}
                            markers={markers}
                            values={values}
                            tool_id={id}
                            index={index}
                        />
                    ))}
                {attributes && (
                    <CaseReportStepAttribute
                        attributes={attributes}
                        values={values}
                        tool_id={id}
                        markers={markers}
                    />
                )}
            </View>
        </View>
    )
}
