export function extractFileName(url, length) {
    if (url) {
        const decodedURL = decodeURIComponent(url)
        const parts = decodedURL.split("/")
        const fileNameWithExt = parts[parts.length - 1]

        const trimmedFileName = fileNameWithExt.replace(/^_[^_]+_|_[^_]+_$/g, "")

        const fileName = trimmedFileName.replace(/%20/g, " ")

        if (length && fileName.length > length) {
            const nameWithoutExt = fileName.substring(0, fileName.lastIndexOf("."))
            const nameStart = nameWithoutExt.slice(0, length)
            const extension = fileName.slice(nameWithoutExt.length)
            return nameStart + "... " + extension
        }

        return fileName
    }
}

export function extractFileExtension(url) {
    const pattern = /\/file\/.+?(\.\w+)$/
    const match = url.match(pattern)
    if (!match) return null

    const extension = match[1].substring(1)
    return extension
}

export const getFileSize = (file) => {
    if (file) {
        const fileSizeInMB = file.size / (1024 * 1024) // file size in MB

        if (fileSizeInMB >= 1) {
            return fileSizeInMB.toFixed(2) + " MB"
        } else {
            const fileSizeInKB = file.size / 1024 // file size in KB
            return fileSizeInKB.toFixed(2) + " KB"
        }
    }
}
