import React, { useState, useEffect } from "react"
import Readme from "./Readme"
import ManualNote from "./ManualNote/ManualNote"
import UploadImage from "./UploadImage/UploadImage"
import ShareCode from "./ShareCode/ShareCode"
import StandardAPIReq from "./StandartAPIReq/StandardAPIReq"
import EventsTimeline from "./EventsTimeline"
import RouteCheck from "./RouteCheck/RouteCheck"
import MiddeskRetrieveBusiness from "./MiddeskRetrieveBusiness"
import RichText from "./RichText/RichText"
import Text from "./Text/Text"
import Divider from "./Divider"
import EasterEgg from "./EasterEgg"
import EasterEggHint from "./EasterEggHint"
import ActionItems from "./ActionItems"
import { isUserInToolboxPage } from "../../../helpers/notebookHelpers"

const ToolsMap = ({
    refreshPageData,
    handleAddTools,
    handleDeleteTool,
    values,
    tool_id,
    tool,
    index_x,
    index_y,
    control,
    setValue,
    watch,
    resetField,
    setFocus,
    integrations,
    toolsConfig,
    entityTypes,
    addedToolId,
}) => {
    const [isFromToolbox, setIsFromToolbox] = useState(false)

    const chosenToolConfig = toolsConfig?.filter(
        (tool_config) => tool_config["id"] === tool?.type,
    )[0]

    useEffect(() => {
        if (isUserInToolboxPage(location.pathname)) setIsFromToolbox(true)
    }, [location])

    if (chosenToolConfig) {
        if (tool.type === 1) {
            return (
                <ManualNote
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    control={control}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    refreshPageData={refreshPageData}
                    resetField={resetField}
                    newTool={tool_id === addedToolId ? true : false}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    isFromToolbox={isFromToolbox}
                />
            )
        } else if (tool.type === 15) {
            return (
                <ShareCode
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    control={control}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    refreshPageData={refreshPageData}
                    resetField={resetField}
                    newTool={tool_id === addedToolId ? true : false}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    isFromToolbox={isFromToolbox}
                />
            )
        } else if (tool.type === 16) {
            return null
        } else if (tool.type === 17) {
            return (
                <RichText
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    control={control}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    watch={watch}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    refreshPageData={refreshPageData}
                    resetField={resetField}
                    newTool={tool_id === addedToolId ? true : false}
                    isFromToolbox={isFromToolbox}
                />
            )
        } else if (tool.type === 20) {
            return (
                <Readme
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                />
            )
        } else if (tool.type === 21) {
            return (
                <RichText
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    watch={watch}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    refreshPageData={refreshPageData}
                    resetField={resetField}
                    newTool={tool_id === addedToolId ? true : false}
                    isFromToolbox={isFromToolbox}
                />
            )
        } else if (tool.type === 22) {
            return (
                <RichText
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    watch={watch}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    refreshPageData={refreshPageData}
                    resetField={resetField}
                    newTool={tool_id === addedToolId ? true : false}
                    isFromToolbox={isFromToolbox}
                />
            )
        } else if (tool.type === 23) {
            return (
                <RichText
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    watch={watch}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    refreshPageData={refreshPageData}
                    resetField={resetField}
                    newTool={tool_id === addedToolId ? true : false}
                    isFromToolbox={isFromToolbox}
                />
            )
        } else if (tool.type === 24) {
            return (
                <RichText
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    watch={watch}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    refreshPageData={refreshPageData}
                    resetField={resetField}
                    newTool={tool_id === addedToolId ? true : false}
                    isFromToolbox={isFromToolbox}
                />
            )
        } else if ([25, 26, 27, 28].includes(tool.type)) {
            return (
                <Text
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    control={control}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    // logo={logo}
                    refreshPageData={refreshPageData}
                    resetField={resetField}
                    newTool={tool_id === addedToolId ? true : false}
                    isFromToolbox={isFromToolbox}
                />
            )
        } else if (tool.type === 29) {
            return (
                <ActionItems
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    control={control}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    resetField={resetField}
                    watch={watch}
                    setFocus={setFocus}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    isFromToolbox={isFromToolbox}
                />
            )
        } else if (tool.type === 30) {
            return (
                <RouteCheck
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    control={control}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    buttonTitle={chosenToolConfig["button_title"]}
                    logo_url={chosenToolConfig["logo"]}
                    logoHref={chosenToolConfig["logo_href"]}
                    inputTitle="Enter route data points"
                    newTool={tool_id === addedToolId ? true : false}
                    resetField={resetField}
                    refreshPageData={refreshPageData}
                    isFromToolbox={isFromToolbox}
                />
            )
        } else if (tool.type === 40) {
            return (
                <EventsTimeline
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    control={control}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    buttonTitle={chosenToolConfig["button_title"]}
                    logo_url={chosenToolConfig["logo"]}
                    logoHref={chosenToolConfig["logo_href"]}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                />
            )
        } else if (tool.type === 46) {
            return (
                <Divider
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    control={control}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                />
            )
        } else if (tool.type === 48) {
            return (
                <EasterEgg
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    control={control}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    logo={chosenToolConfig["logo"]}
                />
            )
        } else if (tool.type === 49) {
            return (
                <EasterEggHint
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    control={control}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    logo={chosenToolConfig["logo"]}
                    isFromToolbox={isFromToolbox}
                />
            )
        } else {
            return (
                <StandardAPIReq
                    refreshPageData={refreshPageData}
                    tool_id={tool_id}
                    index_x={index_x}
                    index_y={index_y}
                    control={control}
                    handleAddTools={handleAddTools}
                    handleDeleteTool={handleDeleteTool}
                    values={values}
                    setValue={setValue}
                    title={chosenToolConfig["title"]}
                    subtitle={chosenToolConfig["subtitle"]}
                    buttonTitle={chosenToolConfig["button_title"]}
                    logo_url={chosenToolConfig["logo"]}
                    logoHref={chosenToolConfig["logo_href"]}
                    listOfFields={chosenToolConfig["list_of_fields"]}
                    integrations={integrations}
                    toolsConfig={toolsConfig}
                    newTool={tool_id === addedToolId ? true : false}
                    resetField={resetField}
                    isFromToolbox={isFromToolbox}
                />
            )
        }
    }
}

export default ToolsMap
