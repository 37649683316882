import React, { useState, useEffect, useCallback, useMemo } from "react"
import {
    TextField,
    Box,
    Grid,
    Typography,
    Modal,
    Fade,
    CircularProgress,
    Divider,
    LinearProgress,
    Accordion,
    AccordionSummary,
} from "@mui/material"
import { Controller } from "react-hook-form"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector, useDispatch } from "react-redux"
import LoadingButton from "@mui/lab/LoadingButton"
import { doToolAction, getToolActionLog } from "../../../../services/notebook/notebookServices"
import { calculateAverageRiskLevel } from "../../../../helpers/notebookHelpers"
import { capitalizeFirstLetter } from "../../../../helpers/standardHelpers"
import accordionExpand from "../../../../assets/icons/accordionExpand.svg"
import DataTable from "../DataTable"
import { useLocation } from "react-router-dom"
import { DotsMenu } from "../DotsMenu"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { RiskLevel } from "../../components/RiskLevel/RiskLevel"
import { ApiResponse } from "../../components/ApiResponse/ApiResponse"
import { NewToolDialog } from "../../components/NewToolDialog/NewToolDialog"
import { ApiRiskLevel } from "../../components/ApiResponse/components/ApiRiskLevels/ApiRiskLevel"
import { isEmpty } from "lodash"
import { IconAIComponents } from "../../components/IconAIComponents"
import { SelectField, AutocompleteField, CheckboxField } from "./components/Fields"
import styles from "./StandartAPIReq.module.scss"

const useStyles = makeStyles(() => ({
    accordionImage: {
        "& .MuiAccordionSummary-expandIconWrapper": {
            "&.Mui-expanded": {
                transform: "rotate(90deg)",
            },
        },
    },
    selectInput: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
        },
    },
}))

function StandardAPIReq({
    refreshPageData,
    tool_id,
    index_x,
    index_y,
    handleAddTools,
    handleDeleteTool,
    control,
    values,
    setValue,
    title,
    subtitle,
    buttonTitle,
    logo_url,
    logoHref,
    listOfFields,
    middeskCreateBusiness,
    integrations,
    toolsConfig,
    newTool,
    resetField,
    isFromToolbox,
}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const location = useLocation()
    const [openModal, setOpenModal] = useState(false)
    const [rowSelectionModel, setRowSelectionModel] = useState([])
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isMoreInfoSubmitting, setisMoreInfoSubmitting] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [newSearchDialogOpen, setNewSearchDialogOpen] = useState(false)
    const [isLoadingNewTool, setIsLoadingNewTool] = useState(false)
    const [markers, setMarkers] = useState(
        values.tools[tool_id].log?.standard_response_risk_levels || [],
    )
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const markersTrigger = useSelector((state) => state.findingsState.markers)
    const currentMarkersToolId = useSelector((state) => state.findingsState.tool_id)
    const isAuthorized = useSelector((state) => state.isAuthorized)
    const currentDataMode = useSelector((state) => state.dataModeState.mode)

    const mockTool = values.tools[tool_id]?.mock_tool && !values.tools[tool_id]?.response_schema
    const pendingResponse = values.tools[tool_id].log?.pending_response

    useEffect(() => {
        if (newTool || (location.state?.scrollToElement && location.state?.id === tool_id) || isFromToolbox) {
            setIsAccordionExpanded(true)
        }
    }, [newTool, tool_id, location, isFromToolbox])

    useEffect(() => {
        if (values.tools[tool_id].log?.standard_response_risk_levels) {
            setMarkers(values.tools[tool_id].log?.standard_response_risk_levels)
        }
    }, [values.tools[tool_id].log?.standard_response_risk_levels, markersTrigger])

    const toolTitle = useMemo(() => {
        if (values.tools[tool_id].type === 125) {
            return values.tools[tool_id].log?.note
        } else {
            return title
        }
    }, [title, values.tools[tool_id].type])

    // useEffect(() => {
    //     const handler = (ev) => {
    //         const childWindow = document.getElementById(`iframe_${tool_id}`)?.contentWindow
    //         if (ev.source !== childWindow) {
    //             return
    //         }
    //         if (typeof ev.data !== "object") return
    //         if (!ev.data.type) return
    //         if (ev.data.type !== "button-click") return
    //         if (!ev.data.message) return
    //         setiFrameMessage(ev.data.message)
    //     }
    //     window.addEventListener("message", handler)
    //     return () => window.removeEventListener("message", handler)
    // }, [])

    useEffect(() => {
        if (isAuthorized && pendingResponse) {
            const interval = setInterval(() => {
                loadResponse()
            }, 20000)
            return () => clearInterval(interval)
        }
    }, [isAuthorized, pendingResponse])

    const handleClose = () => {
        setOpenModal(false)
    }

    const InputFieldController = ({ item, renderFunc, defaultValue = "" }) => {
        return (
            <Controller
                name={`tools.${tool_id}.log.request.${item.field}`}
                defaultValue={defaultValue}
                control={control}
                render={renderFunc}
            />
        )
    }

    const getInputField = useCallback(
        (item) => {
            const commonProps = {
                variant: "outlined",
                disabled: isSubmitting || isDeleteLoading,
                label: capitalizeFirstLetter(item.field.replace("_", " ")),
                fullWidth: true,
            }

            const textFieldProps = {
                ...commonProps,
                InputProps: { className: styles.input },
                InputLabelProps: { style: { color: "#6B869A" } },
            }

            switch (item?.type) {
                case "select":
                    return (
                        <InputFieldController
                            item={item}
                            renderFunc={({ field }) => (
                                <SelectField
                                    item={item}
                                    field={field}
                                    textFieldProps={textFieldProps}
                                />
                            )}
                        />
                    )
                case "select-autocomplete":
                    return (
                        <InputFieldController
                            item={item}
                            renderFunc={({ field }) => (
                                <AutocompleteField
                                    item={item}
                                    field={field}
                                    commonProps={commonProps}
                                    classes={classes}
                                />
                            )}
                        />
                    )
                case "checkbox":
                    return (
                        <InputFieldController
                            item={item}
                            renderFunc={({ field }) => <CheckboxField item={item} field={field} />}
                            defaultValue={true}
                        />
                    )
                default:
                    return (
                        <InputFieldController
                            item={item}
                            renderFunc={({ field }) => (
                                <TextField {...field} {...textFieldProps} multiline rows={1} />
                            )}
                        />
                    )
            }
        },
        [isSubmitting, isDeleteLoading, classes, control, tool_id, styles],
    )
    const getAPIResults = async () => {
        const { sections, attributes } = values?.tools[tool_id].log?.standard_response || {}

        if (!newSearchDialogOpen && !(isEmpty(sections) && isEmpty(attributes))) {
            setNewSearchDialogOpen(true)
        } else if (isAuthorized) {
            setValue(`tools.${tool_id}.log.mock_data`, currentDataMode?.value === 2 || values?.mock)
            setValue(`tools.${tool_id}.log.standard_response_risk_levels`, [])
            setValue(`tools.${tool_id}.log.risk_level`, "")
            setValue(`tools.${tool_id}.log.auto_risk_level`, "")
            setValue(`tools.${tool_id}.log.risk_note`, "")
            setNewSearchDialogOpen(false)
            setIsSubmitting(true)

            // Prevent the more_info_button_id from being sent to the backend on main request
            let logValues = { ...values.tools[tool_id].log }
            if ("more_info_button_id" in logValues) {
                delete logValues["more_info_button_id"]
            }

            const log = await doToolAction(
                values.case_id,
                values.tools[tool_id]?.id,
                values.tools[tool_id].type,
                logValues,
                dispatch,
                refreshAccessTokenCounter,
            )
            await refreshPageData()

            setValue(`tools.${tool_id}.log.standard_response`, log["standard_response"] || {})
            setValue(
                `tools.${tool_id}.log.standard_response_risk_levels`,
                log["standard_response_risk_levels"],
            )
            setValue(`tools.${tool_id}.log.data_response`, log["data_response"])
            if (middeskCreateBusiness) {
                // logging the middesk_business_id so the webhook could find the log
                setValue(`tools.${tool_id}.log.middesk_business_id`, log["middesk_business_id"])
                setValue(`tools.${tool_id}.log.pending_response`, log["pending_response"])
            }
            setValue(`tools.${tool_id}.show_response`, true)
            setIsSubmitting(false)
        }
    }

    const getReadyInputFields = useCallback(() => {
        if (mockTool) return <div className={styles.comingSoon}>Coming soon...</div>
        const fieldsWrapped = listOfFields.map((item) => (
            <Grid key={item.field} item style={{ width: "218.5px" }}>
                {getInputField(item)}
            </Grid>
        ))
        var readyToDisplay = []
        const chunkSize = 4
        for (let i = 0; i < fieldsWrapped.length; i += chunkSize) {
            const chunk = fieldsWrapped.slice(i, i + chunkSize)
            readyToDisplay.push(
                <Box key={i} className={styles.standartApiSearch}>
                    {chunk}
                    <LoadingButton
                        loadingIndicator={
                            <CircularProgress style={{ width: "15px", height: "15px" }} />
                        }
                        className={styles.standartApiSearchButton}
                        variant="outlined"
                        onClick={getAPIResults}
                        loading={isSubmitting}
                    >
                        {capitalizeFirstLetter(buttonTitle)}
                    </LoadingButton>
                </Box>,
            )
        }
        return readyToDisplay
    }, [mockTool, listOfFields, getAPIResults, isSubmitting, buttonTitle, styles, getInputField])

    const loadResponse = async () => {
        try {
            const log = await getToolActionLog(
                values.tools[tool_id]?.id,
                dispatch,
                refreshAccessTokenCounter,
            )
            setValue(`tools.${tool_id}.log.standard_response`, log["standard_response"])
            setValue(`tools.${tool_id}.log.pending_response`, log["pending_response"])
        } catch (e) {
            console.log(e.message)
        } finally {
        }
    }

    const getResponse = () => {
        if (mockTool) return null
        if (
            values.tools[tool_id].log?.standard_response ||
            values.tools[tool_id].log?.data_response
        ) {
            let content = null
            if (values.tools[tool_id].log?.data_response) {
                content = (
                    <>
                        <div className={styles.standartApiIframeResult}>
                            <Box position="relative" marginTop="15px">
                                <Box
                                    style={{
                                        color: values.tools[tool_id].log?.data_more_info?.color,
                                    }}
                                    className={styles.standartApiIframeResultMoreInfo}
                                >
                                    {values.tools[tool_id].log?.data_more_info?.text}
                                </Box>
                                <DataTable
                                    data={values.tools[tool_id].log?.data_response}
                                    rowSelectionModel={rowSelectionModel}
                                    setRowSelectionModel={setRowSelectionModel}
                                />
                                {!openModal && values.tools[tool_id].log?.data_response && (
                                    <OpenInNewIcon
                                        onClick={() => setOpenModal(true)}
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: "0px",
                                            top: "-25px",
                                            zIndex: 2,
                                        }}
                                    />
                                )}
                            </Box>
                        </div>
                    </>
                )
            } else {
                content = (
                    <div className={styles.standartApiIframeResult}>
                        <ApiResponse
                            {...values.tools[tool_id].log?.standard_response}
                            values={values}
                            tool_id={tool_id}
                            setValue={setValue}
                            isMoreInfoSubmitting={isMoreInfoSubmitting}
                            setisMoreInfoSubmitting={setisMoreInfoSubmitting}
                            markers={markers}
                            refreshPageData={refreshPageData}
                            setIsAccordionExpanded={setIsAccordionExpanded}
                            isFromToolbox={isFromToolbox}
                        />
                    </div>
                )
            }
            return (
                <Box className={styles.resultWrapper}>
                    <Box>
                        <div
                            style={{
                                borderRadius: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            {content}
                        </div>
                    </Box>
                </Box>
            )
        } else {
            return <></>
        }
    }

    return (
        <Box>
            <Modal
                className={styles.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={openModal} timeout={500} className={styles.img}>
                    <div style={{ maxHeight: "90%", maxWidth: "90%" }}>{getResponse()}</div>
                </Fade>
            </Modal>
            <Box style={{ position: "relative" }}>
                <Accordion
                    expanded={isAccordionExpanded}
                    style={{
                        borderRadius: "10px",
                        cursor: "default",
                    }}
                    className={styles.standartApiAccordion}
                >
                    <AccordionSummary
                        className={classes.accordionImage}
                        style={{
                            cursor: "default",
                            backgroundColor: "transparent",
                            flexDirection: "row-reverse",
                            gap: "10px",
                        }}
                        expandIcon={
                            <img
                                style={{ cursor: "pointer" }}
                                onClick={() => setIsAccordionExpanded(!isAccordionExpanded)}
                                src={accordionExpand}
                            />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Box className={styles.standartApiTitleContainer}>
                            {/* <img className={styles.standartApiTitleLogo} src={logo_url}></img> */}
                            <Box className={styles.standartApiTitleWrapper}>
                                <Box display="flex" flexDirection="column" gap="5px">
                                    <Typography className={styles.standartApiTitleText}>
                                        {toolTitle}
                                    </Typography>
                                    <Typography className={styles.standartApiSubtitle}>
                                        {subtitle}
                                        <IconAIComponents
                                            mock={values?.tools[tool_id].log?.mock_data}
                                        />
                                    </Typography>
                                </Box>
                                {isDeleteLoading || isLoadingNewTool ? (
                                    <Box className={styles.standartApiLoaderWrapper}>
                                        <CircularProgress
                                            color="inherit"
                                            style={{ width: "25px", height: "25px" }}
                                        />
                                    </Box>
                                ) : (
                                    <Box className={styles.standartApiActionsWrapper}>
                                        {markers?.length > 0 && (
                                            <ApiRiskLevel
                                                riskLevel={calculateAverageRiskLevel(markers)}
                                                quantity={markers?.length}
                                            />
                                        )}

                                        <RiskLevel
                                            values={values}
                                            toolLog={values.tools[tool_id].log || {}}
                                            tool_id={tool_id}
                                            control={control}
                                            resetField={resetField}
                                            refreshPageData={refreshPageData}
                                            markers={markers}
                                            autoCalculatedRiskLevel={calculateAverageRiskLevel(
                                                markers,
                                            )}
                                        />
                                        {!isFromToolbox && (
                                            <DotsMenu
                                                handleAddTools={handleAddTools}
                                                index_x={index_x}
                                                index_y={index_y}
                                                values={values}
                                                integrations={integrations}
                                                toolsConfig={toolsConfig}
                                                handleDeleteTool={handleDeleteTool}
                                                tool_id={tool_id}
                                                setIsDeleteLoading={setIsDeleteLoading}
                                                setIsLoadingNewTool={setIsLoadingNewTool}
                                            />
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <Box className={styles.standartApiAccordionBody}>
                        <Grid alignItems="center">
                            <Divider className={styles.standartApiDivider} />
                            {getReadyInputFields()}
                            {isSubmitting ? <LinearProgress color="inherit" /> : getResponse()}
                        </Grid>
                    </Box>
                </Accordion>
            </Box>
            <NewToolDialog
                open={newSearchDialogOpen}
                setClosed={() => setNewSearchDialogOpen(false)}
                handleAgree={getAPIResults}
                handleAddTools={handleAddTools}
                index_x={index_x}
                index_y={index_y}
                toolType={values?.tools[tool_id]?.type}
                setIsAccordionExpanded={setIsAccordionExpanded}
                request={values.tools[tool_id]?.log?.request}
                values={values}
                doToolAction={doToolAction}
                tool_id={tool_id}
                setValue={setValue}
                refreshPageData={refreshPageData}
                isFromToolbox={isFromToolbox}
            />
        </Box>
    )
}

export default StandardAPIReq
