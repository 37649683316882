import React from "react"
import addStepIcon from "../../../../../../../../assets/icons/addStepIcon.svg"
import styles from "../../StepsCarouselCard.module.scss"

export const AddStepIcon = ({ index_x, onClick }) => (
    <img
        src={addStepIcon}
        alt="Add Step"
        className={styles.addImage}
        onClick={() => onClick(index_x)}
    />
)
