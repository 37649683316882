import React from "react"
import { Box, TextField, Select, MenuItem, Typography } from "@mui/material"
import { MetricsCard } from "../Fraud/components/MetricsCard/MetricsCard"
import styles from "../Fraud/Fraud.module.scss"
import { RiskStrategies } from "../Fraud/components/RiskStrategies/RiskStrategies"

export function Compliance() {
    const filterOprions = ["Last 7 days", "Last month"]

    const metricsData = [
        { mainValue: "13", indicator: 30, indicatorName: "SAR" },
        { mainValue: "120", indicator: 30, indicatorName: "Cases", variant: "success" },
        { mainValue: "$2K", indicator: 8, indicatorName: "Cost", variant: "danger" },
        { mainValue: "10m 3s", indicator: 30, indicatorName: "MTTR", variant: "danger" },
    ]

    const rows = [
        {
            name: "Enhanced Due-Diligence - Business",
            maturity: "AI Agent",
            maturityColor: "#299455",
            sar: "0",
            cases: "100",
            cost: "$1.2K",
            mttr: "1m",
        },
        {
            name: "Rogue CX Agents",
            maturity: "Automated",
            maturityColor: "#BE984F",
            sar: "10",
            cases: "20",
            cost: "$300",
            mttr: "2m 55s",
        },
    ]

    const columns = [
        { id: "name", label: "" },
        { id: "maturity", label: "Maturity" },
        { id: "sar", label: "SAR" },
        { id: "cases", label: "Cases" },
        { id: "cost", label: "Cost" },
        { id: "mttr", label: "MTTR" },
    ]

    return (
        <Box className={styles.fraudContainer}>
            <Box className={styles.fraudHeader}>
                <Typography fontSize="18px">Global Metrics</Typography>
                <Box className={styles.fraudHeaderFilters}>
                    Filters:
                    <TextField
                        select
                        variant="outlined"
                        InputProps={{
                            className: styles.fraudInput,
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                color: "#6B869A",
                            },
                        }}
                        fullWidth
                        defaultValue={filterOprions[0]}
                    >
                        {filterOprions.map((option) => {
                            return (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </Box>
            </Box>
            <Box className={styles.fraudMetricsWrapper}>
                {metricsData.map((data, index) => (
                    <MetricsCard key={index} {...data} />
                ))}
            </Box>
            <Box>
                <RiskStrategies rows={rows} columns={columns} />
            </Box>
        </Box>
    )
}
